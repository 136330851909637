import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import RoleForm from "./RoleForm";
import PropTypes from "prop-types";

/**
  * @typedef {{
 * Id: number, 
 * Name: string,
 * }} Permission;
 * 
 * @typedef {{
 * Id: number, 
 * Name: string,
 * Permissions?: Permission[]
 * }} Role;
 * 
 * @type {React.FC<{
 * handleModalToggle:  () => void,
 * isOpen: boolean,
 * sendRole: (role: Role) => void,
 * onClose: ()  => void,
 * role: Role,
 * permissions: Permission[],
 * loading: boolean,
 * onDelete: (id: number) => void
 * }>}
 */
const RoleModal = ({
  handleModalToggle,
  onDelete,
  isOpen,
  sendRole,
  onClose,
  role,
  permissions,
  loading,
}) => {
  const handleClose = () => {
    onClose();
    handleModalToggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered"
    >
      <ModalHeader>{role?.Id ? "Update Role" : "Create a new Role"}</ModalHeader>
      <div className="modal-body">
        <RoleForm
          onSubmit={sendRole}
          onCancel={handleClose}
          onDelete={onDelete}
          roleData={role}
          permissions={permissions}
          loading={loading}
        />
      </div>
    </Modal>
  );
};

RoleModal.propTypes = {
  handleModalToggle: PropTypes.func,
  handleRoleCreation: PropTypes.func,
  isOpen: PropTypes.bool,
  defaultRole: PropTypes.number,
  populateRolesSelect: PropTypes.func,
  sendRole: PropTypes.func,
  onClose: PropTypes.func,
};

export default RoleModal;
