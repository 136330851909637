import React, { useState } from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import SnapshotModal from "./SnapshotModal.jsx";
import authHeader from "../../helpers/auth-header.js";
import Swal from "sweetalert2";
import httpClient from "../../helpers/http-client-singleton";
import {useTheme} from "@chakra-ui/core";

const http = httpClient.getInstance();

const SnapshotOptionsDropdown = (props) => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupType, setPopupType] = useState(false);

  const theme = useTheme();

  const removeSnapshot = () => {
    Swal.fire({
      type: "warning",
      title: "Warning!",
      text: "This action will remove this snapshot forever.",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
    }).then((result) => {
      if (result.value) {
        http
          .delete("/snapshots/" + props.s_key, {
            headers: authHeader(),
          })
          .then(() => {
            Swal.fire({
              type: "success",
              title: "Success",
              text: "Snapshot Removed!",
            }).then(() => {
              props.update();
            });
          })
          .catch(() => {
            Swal.fire({
              type: "error",
              title: "Error",
              text:
                "There appears to be an error removing this snapshot, please try again.",
            }).then(() => {
              props.update();
            });
          });
      }
    });
  };

  const openPopupHandler = (Type) => {
    setShowPopup(true);
    setPopupType(Type);
  };

  const closePopupHandler = () => {
    setShowPopup(false);
  };

  return (
    <>
      <UncontrolledDropdown>
        <DropdownToggle
          size={props.size}
          color="neutral"
          onClick={(e) => e.stopPropagation()}
        >
          {props.children}
        </DropdownToggle>
        <DropdownMenu
          className="dropdown-menu-arrow"
          positionFixed
          right
          onClick={(e) => e.stopPropagation()}
        >
          <DropdownItem onClick={() => openPopupHandler("load")}>
            Load in Player
          </DropdownItem>
          <DropdownItem onClick={() => openPopupHandler("update")}>
            Update Snapshot
          </DropdownItem>
          <div className="dropdown-divider" />
          <DropdownItem onClick={() => removeSnapshot()}>
            Delete Snapshot
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledDropdown>
      {showPopup && (
        <SnapshotModal
          modal={showPopup}
          notifySuccess={props.notifySuccess}
          loading={props.loading}
          close={closePopupHandler}
          s_key={props.s_key}
          update={props.update}
          s_desc={props.s_desc}
          playerId={props.playerId}
          type={popupType}
        />
      )}
    </>
  );
};

export default SnapshotOptionsDropdown;
