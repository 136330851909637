import React, { useEffect, useState, useCallback,  useMemo,} from "react";
import {
  Flex,
  Box,
  Text,
  IconButton,
  useDisclosure,
  Heading,
  Skeleton,
  Select,
} from "@chakra-ui/core";
import {
  Card,
  CardBody,
  Collapse,
  Container,
  Table,
} from "reactstrap";


import { Link } from "react-router-dom";
import SearchInput from "../../common/SearchInput";
import { formatIdleCurrency } from "../../../helpers/currency-format";
import { userService } from "../../../services/user.service";
import { Modal,Button, ModalHeader } from "reactstrap";

const LoadingPlayer = (
  <>
    <tr>
      <td width="5%">
        <Skeleton height="20px" />
      </td>
      <td width="55%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
    <tr>
      <td width="5%">
        <Skeleton height="20px" />
      </td>
      <td width="55%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
    <tr>
      <td width="5%">
        <Skeleton height="20px" />
      </td>
      <td width="55%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
  </>
);



/** 
 * @type {React.FC<{
 * exportCsv: () => void ,
 * attributeNames: string[],
 * loading: boolean,
 * leaderboardPlayers: any[]
 * onRemovePlayer: () => void,
 * onModalClose: () => void,
 * isModalOpen: boolean,
 * }>} 
 **/
const LeaderboardPlayers = ({ leaderboardPlayers, loading, attributeNames,exportCsv,onRemovePlayer, isModalOpen, onModalClose }) => {
  const project = useMemo(() => window.localStorage.getItem("project"),[]);

const isIdle = useMemo(() => { 
  const data = window.localStorage.getItem("isIdle")
  return data !== null ? data?.toLowerCase() === 'true' : project !== "hp"
},[project]);
  
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedPlayer, setSelectedPlayer] = useState({});
  const [search, setSearch] = useState("");
  const [filteredPlayers, setFilteredPlayers] = useState(leaderboardPlayers);
  const [selectedScore, setSelectedScore] = useState((isIdle) ? 'default' : attributeNames[0]);
  
  const handleClick = (player) => {
    setSelectedPlayer(player);
    onOpen();
  };

  useEffect(() => {
    setSelectedScore((isIdle) ? 'default' : attributeNames.find(x => !x.includes("current_level")))
  },[attributeNames, isIdle])

  const filterAndSortPlayers = useCallback((players, search, orderKey,fromAttribute) => {    
   
    return players
      .sort((player1, player2) => {
        if(!fromAttribute) return player2[orderKey] - player1[orderKey]
        else  return player2.Attributes[orderKey] - player1.Attributes[orderKey]
      })
      .map((player, index) => ({ ...player, Rank: index + 1 }))
      .filter((player) => player.PlayerId.includes(search) || player?.Nickname?.toLowerCase()?.includes(search?.toLowerCase()));
  }, []);

  /**@type {(playerData: any) => string } */
  const handleScore = useCallback(playerData => {
    if(!playerData) return "--";
    switch(selectedScore){
      case 'default': return playerData.Score ? formatIdleCurrency(playerData.Score) : "--";
      default: return formatIdleCurrency(playerData.Attributes[selectedScore]);
    }

  },[selectedScore]);

  const onOrderClick = useCallback(() => {
    setFilteredPlayers([...filteredPlayers.reverse()]);
  }, [filteredPlayers]);

  useEffect(() => {
    // Filter and sort the shown players by Score
    if (leaderboardPlayers) {
      const idle = selectedScore === 'default';
      const orderKey =  idle ? "Score" : selectedScore; 

      setFilteredPlayers(
        filterAndSortPlayers(leaderboardPlayers, search,orderKey,!idle)
      );
    }
  }, [search, leaderboardPlayers, filterAndSortPlayers, selectedScore]);

  useEffect(() => {
    setSelectedPlayer({});
    onClose();
  }, [onClose, leaderboardPlayers]);

  const table = (
    <Box>
      <Flex justifyContent="space-between" alignItems="center">
        <Heading
          size="sm"
          paddingX={4}
          marginBottom={0}
          marginRight={2}
          color="#525f7f"
          >
        </Heading>
        <SearchInput
          placeholder="Player ID Or Nickname"
          className="flex-grow-1 search"
          onSearch={(search) => setSearch(search)}
          onClear={() => setSearch("")}
        >
          <Button 
            disabled={loading || !userService.hasPermission("EXPORT_DATA")}
            size="sm"
            onClick={() => exportCsv()}>
            Export as .csv
          </Button>
        </SearchInput>
        <Flex alignItems="center">
        <small>Score Attribute:</small>
        <Select
          textTransform="capitalize"
          size="sm"
          marginRight={4}
          onChange={(e) => e?.target?.value && setSelectedScore(e.target.value)}
          rootProps={{ display: "inline-block" }}
        >
          {(isIdle ? ["default",...attributeNames] : attributeNames).map((attr) => (
            <option key={`attsel_${attr}`} value={attr} defaultValue="default">
              {attr}
            </option>
          ))}
        </Select>
        <Text fontWeight={600} padding={4} marginBottom={0}>
          Total: {(filteredPlayers && filteredPlayers.length) || "-"}
        </Text>
        </Flex>
      </Flex>
      <Box minHeight={300} maxHeight={800} overflow="auto">
        <Table striped responsive>
          <thead className="thead-light">
            <tr>
              <th>Rank</th>
              <th>Player ID</th>
              <th>Nickname</th>
              <th className="sort" data-sort="scores" onClick={onOrderClick}>
                Leaderboard Score
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody className="list">
            {loading
              ? LoadingPlayer
              : filteredPlayers &&
                filteredPlayers.map((player,index) => (
                  <React.Fragment key={"PL" + player.PlayerId}>
                  <tr>
                    <td width="10%">{player.Rank + 'º'}</td>
                    <td width="30%">
                      <Link
                        to={`/acp/player/${player.PlayerId}`}
                        className="playerId"
                      >
                        {player.PlayerId}
                      </Link>
                    </td>
                    <td width="25%">{player.Nickname || "-"}</td>
                    <td width="20%">
                      {handleScore(player) || "-"}
                    </td>
                    <td width="15%">
                      <IconButton
                        mr="0.5rem"
                        fontWeight="bold"
                        disabled={selectedPlayer.PlayerId === player.PlayerId}
                        onClick={() => handleClick(player)}
                        aria-label="See Player Scores"
                        icon="arrow-forward"
                        size="sm"
                        variant="outline"
                        isRound
                        variantColor="lumenPrimary"
                      />
                      <IconButton
                        fontWeight="bold"
                        onClick={() => onRemovePlayer(player.PlayerId)}
                        aria-label="Remove From Leaderboard"
                        icon={() => <i className="fas fa-trash" />}
                        size="sm"
                        variant="outline"
                        isRound
                        variantColor="lumenPrimary"
                      />
                    </td>
                  </tr>
                   <tr>
                   <td colSpan={5} className="p-0">
                     <Collapse isOpen={selectedPlayer.PlayerId === player.PlayerId}>
                       <Card className="mb-0 no-box-shadow">
                         <CardBody className="px-0 py-3">
                           <Container fluid>
                             <Table style={{background: "transparent"}}>
                               <tbody>
                                  {Object.keys(player?.Attributes || {}).map((score) => {
                                      return (
                                        <tr>
                                          <td width="75%">
                                            <code>{score}</code>
                                          </td>
                                          <td>{player?.Attributes?.[score]}</td>
                                        </tr>
                                      );
                                  })}
                                </tbody>
                              </Table>
                           </Container>
                         </CardBody>
                       </Card>
                     </Collapse>
                   </td>
                 </tr>
                 </React.Fragment>
                ))}
          </tbody>
        </Table>
      </Box>
    </Box>
  );

  return (Array.isArray(leaderboardPlayers) || loading) && (
    <Modal
    isOpen={isModalOpen}
    toggle={onModalClose}
    className="modal-dialog-centered"
    style={{maxWidth: "max-content"}}
  >
    <ModalHeader>Leaderboard Player's Information</ModalHeader>
      <Box borderRadius="md" backgroundColor="white">
        {table}
      </Box>
      </Modal>
  )
};


export default LeaderboardPlayers;
