import React from 'react';
import { Table } from 'reactstrap';
import UserOptionsDropdown from "../User/UserOptionsDropdown";

// import { Container } from './styles';

function UserTable({tableRef,users,getUsers,handleOpenUpdateModal}) {
  return (
    <div ref={tableRef}>
      <Table
        hover
        className="align-items-center table-flush"
        style={{ maxWidth: "auto", msOverflowX: "scroll" }}
      >
        <thead className="thead-light">
          <tr>
            <th className="sort" data-sort="name" scope="col">
              Name
            </th>
            <th className="sort" data-sort="email" scope="col">
              E-mail
            </th>
            <th
              className="sort"
              style={{ width: "20%" }}
              data-sort="access_level"
              scope="col"
            >
              Access Level
            </th>
            <th style={{ width: "3%" }} scope="col" />
          </tr>
        </thead>
        <tbody className="list">
          {users.map((data, index) => (
            <tr key={index}>
              <td className="name">
                {data["FirstName"] + " " + data["LastName"]}
              </td>
              <td className="email">{data["Email"]}</td>
              <td className="access_level">
                {data?.Role?.Name || data["RoleId"] || "Admin"}
              </td>
              <td>
                <UserOptionsDropdown
                  size="sm"
                  className="btn-icon-only text-light"
                  user={data}
                  getUsers={getUsers}
                  modalUpdate={() =>
                    handleOpenUpdateModal(
                      data.Id,
                      data.Email,
                      data.FirstName,
                      data.LastName,
                      data.RoleId
                    )
                  }
                >
                  <i className="fas fa-ellipsis-v" />
                </UserOptionsDropdown>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default UserTable;