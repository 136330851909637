import { parseDate } from '../../helpers/date-helper';

/** @typedef {import('./RegattaTimeline').Regatta} Regatta */

/**
 * @param {Regatta} reg
 * @returns {import('../../helpers/date-helper').SwDate} 
 */
export function getRegattaRealEndDate(reg){
  const {Cooldown,Duration,EndDateLastRegatta} = reg;
  return parseDate(EndDateLastRegatta).addMin(Duration + Cooldown);
}

/**
 * @param {Regatta} reg
 * @returns {boolean} 
 */
 export function isRegattaFinished(reg){
  var endTime = getRegattaRealEndDate(reg);
  return endTime.isBefore();
}