import ErrorBox from "../../common/ErrorBox";
import React from "react";
import SimpleHeader from "../../Headers/SimpleHeader";
import {Container} from "reactstrap";

import PropTypes from "prop-types";

const LeaderboardDefinition = React.memo(({ error, children }) => {
  return error ? (
    <ErrorBox>Couldn't get the Leaderboard details</ErrorBox>
  ) : (
    <>
      <SimpleHeader clean parentName="Players" name="Player Info"/>
      <Container className="mt--6" fluid>
        {children}
      </Container>
    </>
  );
});

LeaderboardDefinition.propTypes = {
  error: PropTypes.any,
};

export default LeaderboardDefinition;
