/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { userService } from "../../services/user.service";

class Calendar extends React.Component {
  render() {
    const isAdmin = userService.isAdmin();
    const branch = userService.isAdmin() ? process.env.REACT_APP_BRANCH : process.env.REACT_APP_BRANCH.split("/")[0];

    return (
      <>
        <Container fluid>
          <footer className="footer pt-0">
            <Row className="align-items-center justify-content-lg-between">
              <Col lg="12" style={{display:"flex", justifyContent: "space-between", alignItems: "center"}}>
                <div className="copyright text-center text-lg-left text-muted">
                  © {new Date().getFullYear()}{" "}
                  <a
                    className="font-weight-bold ml-1"
                    href="https://lumen.games"
                    target="_blank"
                  >
                    Lumen Games
                  </a>
                </div>
                  <small className="text-muted wrap-text">{isAdmin && <b>{process.env.REACT_APP_COMMIT}/</b>}{branch}</small>
              </Col>
            </Row>
          </footer>
        </Container>
      </>
    );
  }
}

export default Calendar;
