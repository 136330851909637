import { theme } from "@chakra-ui/core";

const swTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    lumenPrimary: {
      50: "#dffdf0",
      100: "#baf2db",
      200: "#93e9c4",
      300: "#6bdfae",
      400: "#43d697",
      500: "#29bc7d",
      600: "#1c9260",
      700: "#106945",
      800: "#034028",
      900: "#00170a",
    },
    lumenSecondary: {
      50: "#dffff3",
      100: "#b3ffde",
      200: "#84fec9",
      300: "#56feb5",
      400: "#33fea0",
      500: "#25e586",
      600: "#19b268",
      700: "#0d804a",
      800: "#004d2c",
      900: "#001b0d",
    },
    borderColor: "#dee2e6",
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },
};
export default swTheme;
