import React, { useReducer, useEffect, useCallback } from "react";
import Swal from "sweetalert2";
import { Container, Row, Col, TabPane } from "reactstrap";
import moment from "moment";
import "moment-duration-format";

import PlayerOverview from "../components/Player/PlayerOverview";
import PlayerGifts from "../components/Player/PlayerGifts";
import PlayerPersistence from "../components/Player/PlayerPersistence";
import PlayerAttributes from "../components/Player/PlayerAttributes";
import SimpleHeader from "../components/Headers/SimpleHeader";
import LoadingContainer from "../components/Layout/LoadingContainer";
import PurchaseComponent from "../components/Purchases/PurchaseComponent";
import ModalJson from "../components/Layout/ModalJson";
import PlayerGiftModal from "../components/Player/PlayerGiftModal";
import PlayerTabs from "../components/Player/PlayerTabs";
import "./css/playerTabs.css";


import authHeader from "../helpers/auth-header";
import { stringfyKeepOrder } from "../helpers/json-helpers";

import { Redirect } from "react-router";

import httpClient from "../helpers/http-client-singleton";
import PlayerLeaderboards from "../components/Player/PlayerLeaderboards";
import PlayerEvents from "../components/Player/PlayerEvents";
import { tryParse } from "../helpers/json-helpers";
import ImportModal from "../components/common/ImportModal";

const http = httpClient.getInstance();
const reducer = (state, action) => {
  switch (action.type) {
    case "CLOSE_MESSAGE_MODAL":
      return { ...state, messageOpen: false, messageContent: {} };
    case "OPEN_MESSAGE_MODAL":
      return { ...state, messageOpen: true, messageContent: action.payload };
    case "TOGGLE_IMPORT_PERSISTENCE_MODAL":
      return { ...state, importModalOpen: !state.importModalOpen }
    case "TOGGLE_JSON_MODAL":
      return {
        ...state,
        ...action.payload,
        jsonPopupOpen: !state.jsonPopupOpen,
      };
    case "TOGGLE_MODAL_OPEN":
      return { ...state, modalOpen: !state.modalOpen };
    case "FETCH_PLAYER":
      return { ...state, loading: true };
    case "FETCHED_PLAYER":
      return { ...state, ...action.payload };
    case "FETCH_ERROR":
      return { ...state, ...action.payload };
    case "FETCH_PERSISTENCE":
      return { ...state, loadingPersistence: true };
    case "FETCH_ATTRIBUTES":
      return { ...state, loadingAttributes: true };
    case "FETCHED_PERSISTENCE":
      return { ...state, loadingPersistence: false, ...action.payload };
    case "FETCHED_ATTRIBUTES":
      return { ...state, ...action.payload };
    case "FETCHED_DEBUGGER":
      return { ...state, ...action.payload };
    case "UPDATED_PERSISTENCE":
      return { ...state, ...action.payload };
    case "FETCH_GIFTS":
      return { ...state, loadingMessages: true };
    case "FETCHED_GIFTS":
      return { ...state, ...action.payload };
    case "FETCH_LEADERBOARDS":
      return { ...state, loadingLeaderboards: true };
    case "FETCHED_LEADERBOARDS":
      return { ...state, ...action.payload };
    case "FETCH_EVENTS":
      return { ...state, loadingEvents: true };
    case "FETCHED_EVENTS":
      return { ...state, ...action.payload };
    case "FETCHED_SOCIAL_MEDIAS":
      return { ...state, ...action.payload };
    case "UPDATED_PLAYER":
      return { ...state, ...action.payload };
    case "UPDATED_GOALS":
      return { ...state, ...action.payload };
    case "UPDATED_STAGES":
      return { ...state, ...action.payload };
    case "SENDING_ATTRIBUTE":
      return { ...state, loadingAttributeModal: true };
    case "ATTRIBUTE_SENT_ERROR":
      return { ...state, loadingAttributeModal: false };
    case "ATTRIBUTE_SENT":
      return { ...state, loadingAttributeModal: false, modalOpen: false };
    case "SEARCH_TYPED":
      return { ...state, ...action.payload };
    case "CHANGE_PLAYER_PERSISTENCE":
      return { ...state, ...action.payload };
    default:
      throw new Error();
  }
};

const Player = (props) => {
  const playerId = props.match.params.param;
  const [state, dispatch] = useReducer(reducer, {
    messages: [],
    persistence: [],
    devices: [],
    attributes: [],
    leaderboards: [],
    socialMedias: [],
    events: [],
    playerData: {},
    debugger: "",
    searchedKey: "",
    loading: true,
    loadingPersistence: false,
    loadingEvents: false,
    loadingMessages: false,
    loadingPurchaseHistory: false,
    loadingAttributes: false,
    loadingAttributeModal: false,
    loadingLeaderboards: false,
    messageOpen: false,
    modalOpen: false,
    importModalOpen: false,
    messageContent: {},
    jsonPopupOpen: false,
    selectedPopupKey: null,
    serverResponse: "",
    stageData: [],
    goalsData: [],
  });

  const toggleModalOpen = () => dispatch({ type: "TOGGLE_MODAL_OPEN" });

  const closeMessageModal = () => {
    dispatch({ type: "CLOSE_MESSAGE_MODAL" });
  };

  const closeJsonModal = () => toggleJsonModal(null);
  const toggleJsonModal = (key) => {
    dispatch({
      type: "TOGGLE_JSON_MODAL",
      payload: { selectedPopupKey: key },
    });
  };

  const toggleImportPersistenceModal = () => {
    dispatch({ type: "TOGGLE_IMPORT_PERSISTENCE_MODAL" });
  }

  const sendAttribute = (attribute) => {
    dispatch({ type: "SENDING_ATTRIBUTE" });
    const expirationDate =
      (attribute.expirationDate &&
        moment
          .duration(moment.utc(attribute.expirationDate).diff(moment()))
          .format("HH:mm:ss")) ||
      undefined;
    const body = { attributes: [{ ...attribute, playerId, expirationDate }] };
    http
      .put("/attributes/", body, { headers: authHeader() })
      .then(() => {
        dispatch({ type: "ATTRIBUTE_SENT" });
        Swal.fire(
          "Attribute Created",
          "The attribute has been created successfully",
          "success"
        );
        getPersistence();
      })
      .catch(() => {
        dispatch({ type: "ATTRIBUTE_SENT_ERROR" });
        Swal.fire(
          "Attribute not created",
          "An error occurred when creating the attribute",
          "error"
        );
      });
  };


  const getPlayerEvents = useCallback(() => {
    dispatch({ type: "FETCH_EVENTS" });
    http
      .get(`players/${playerId}/eventplayers`, { headers: authHeader() })
      .then((response) => {
        dispatch({
          type: "FETCHED_EVENTS",
          payload: {
            events: response.data.data,
            loadingEvents: false,
          },
        });
      });
  }, [playerId]);

  const addPlayerToEvent = (eventId, onError) => {
    return http
      .put(`/events/${eventId}/players/${playerId}`, null, { headers: { ...authHeader() } })
      .then(getPlayerEvents)
      .catch(onError);
  };

  const deleteEventFromPlayer = (eventId) => {
    return http
      .delete(`/events/${eventId}/players/${playerId}`, {
        headers: authHeader(),
      })
      .then(getPlayerEvents);
  };

  const getPersistence = useCallback(() => {
    dispatch({ type: "FETCH_PERSISTENCE" });
    http
      .get("/persistences/" + playerId, { headers: authHeader() })
      .then((response) => {
        dispatch({
          type: "FETCHED_PERSISTENCE",
          payload: {
            persistence: response.data.data.m_playerPersistences,
            loadingPersistence: false,
          },
        });
        dispatch({ type: "FETCH_ATTRIBUTES" });
        http
          .get(`players/${playerId}/attributes`, {
            headers: authHeader(),
          })
          .then((res) => {
            dispatch({
              type: "FETCHED_ATTRIBUTES",
              payload: {
                attributes: res.data.data.Attributes,
                loadingAttributes: false,
              },
            });
          });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_ERROR",
          payload: { loading: false, serverResponse: error },
        });
      });
  }, [playerId]);

  const getDebuggerInfo = useCallback(() => {
    http
      .get(`/players/${playerId}/debugger`, {
        headers: authHeader(),
      })
      .then((response) => {
        const debug_info =
          response.data.data.m_playerDebuggerTTL === -1
            ? null
            : response.data.data.m_playerDebuggerTTL;
        dispatch({
          type: "FETCHED_DEBUGGER",
          payload: {
            debugger: debug_info,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_ERROR",
          payload: { loading: false, serverResponse: error },
        });
      });
  }, [playerId]);

  const getAllGifts = useCallback(() => {
    dispatch({ type: "FETCH_GIFTS" });
    http
      .get(`/messages/${playerId}`, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        dispatch({
          type: "FETCHED_GIFTS",
          payload: {
            messages: response.data.data,
            loadingMessages: false,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_ERROR",
          payload: {
            playerMessage: [],
            loadingMessages: false,
            serverResponse: error,
          },
        });
      });
  }, [playerId]);

  const getLeaderboards = useCallback((eventId) => {
    dispatch({ type: "FETCH_LEADERBOARDS" });
    http
      .get(`/events/${eventId}/players/${playerId}/leaderboards`, {
        headers: { ...authHeader() },
      })
      .then((response) => {
        dispatch({
          type: "FETCHED_LEADERBOARDS",
          payload: {
            leaderboards: response.data.data.Leaderboards,
            loadingLeaderboards: false,
          },
        });
      });
  }, [playerId]);

  const getPlayer = useCallback(() => {
    dispatch({ type: "FETCH_PLAYER" });
    http
      .get(`/players/${playerId}/info/`, { headers: authHeader() })
      .then((response) => {
        if (response.data.data.PlayerId) {
          http
            .get(`/players/${response.data.data.PlayerId}/devices/`, { headers: authHeader(), })
            .then((res) => {
              dispatch({
                type: "FETCHED_PLAYER",
                payload: {
                  playerData: response.data.data,
                  loading: false,
                  devices: res.data.data.Devices,
                },
              });
              if (response.data.data.LastEventId)
                getLeaderboards(response.data.data.LastEventId);
            });
        } else {
          dispatch({
            type: "FETCHED_PLAYER",
            payload: {
              playerData: response.data.data,
              loading: false,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: "FETCH_ERROR",
          payload: {
            playerData: null,
            loading: false,
            serverResponse: error,
          },
        });
      });
  }, [getLeaderboards, playerId]);

  const getSocialMedias = useCallback(() => {
    http
      .get(`players/${playerId}/social-medias`, { headers: authHeader() })
      .then((response) => {
        dispatch({
          type: "FETCHED_SOCIAL_MEDIAS",
          payload: {
            socialMedias: response.data.data.Medias,
          },
        });
      });
  }, [playerId]);

  const updatePage = useCallback(() => {
    getPlayer();
    getPlayerEvents();
    getDebuggerInfo();
    getAllGifts();
    getPersistence();
    getSocialMedias();

  }, [getAllGifts, getDebuggerInfo, getPersistence, getPlayer, getPlayerEvents, getSocialMedias]);

  useEffect(() => updatePage(), [updatePage]);

  const onSaveClick = () => {
    const updatedKeys = state.persistence.filter((el) => el.Updated);
    Swal.fire({
      title: "Save Persistence",
      html: /*html*/`<div>
        <div>Are you sure you want to apply these changes?</div>
        <div style="display: flex;text-align: left;justify-content: center;">
          <table>
            <thead>
              <tr>
                <th>Key</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              ${updatedKeys.map(key => (`<tr>
                <td>${key.Key}</td>
                <td>${key.Deleted ? "<strong style=\"color:red\">Delete</strong>" : key.NewKey ? "<strong style=\"color:green\">Add</strong>" : "<strong style=\"color:blue\">Update</strong>"}</td>
              </tr>`)).join("\n")}
            </tbody>
          </table>
        </div>
      </div>`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: () => updatePlayerPersistence(updatedKeys).catch(() => ({ error: true })),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.dismiss) return;

      if (!result.value.error) Swal.fire({ title: `Persistence updated successfully!`, icon: "success", });
      else Swal.fire({ title: "Couldn't update the persistence.", icon: "error" });
    });
  }

  const updatePlayerPersistence = async (body) => {
    if (!body) body = state.persistence.filter((el) => el.Updated);
    const authHeaders = { headers: authHeader() }
    dispatch({ type: "FETCH_PERSISTENCE" });

    await http
      .put(`/players/${playerId}/persistence/override`,
        body,
        {
          ...authHeaders,
          params: { cleanSave: false },
        }
      )
      .then(() => http.put(`/players/${playerId}/persistence/outdated/`))
      .catch((serverResponse) =>
        dispatch({ type: "FETCH_ERROR", payload: { serverResponse } })
      ).then((response) => {
        getPlayer();
        getPersistence();
        return response.data;
      })
      .catch((serverResponse) =>
        dispatch({ type: "FETCH_ERROR", payload: { serverResponse } })
      );

    dispatch({
      type: "UPDATED_PERSISTENCE",
      payload: {
        playerData: { ...state.playerData, isLocalPersistenceOutdated: true },
        selectedPopupKey: null,
      },
    });
  };

  const updatePlayerInformations = () => {
    const body = [...state.goalsData];
    if (state.stageData.length > 0)
      body.push(state.stageData[0]);

    dispatch({ type: "FETCH_PERSISTENCE" });
    http
      .put(`/players/${playerId}/persistence/override`,
        body,
        {
          headers: authHeader(),
          params: { cleanSave: false },
        })
      .then(() => {
        http
          .put(`/players/${playerId}/persistence/outdated/`, null, authHeader())
          .then((response) => {
            return response.data;
          });
        getPlayer();
        getPersistence();
        dispatch({
          type: "UPDATED_PLAYER",
          payload: {
            playerData: {
              ...state.playerData,
              isLocalPersistenceOutdated: true,
            },
            selectedPopupKey: null,
            stageData: [],
            goalsData: [],
          },
        });
      })
  };

  const handlePersistenceInfoChange = (stageInfo, goalsInfo) => {
    if (goalsInfo !== "") {
      dispatch({
        type: "UPDATED_GOALS",
        payload: {
          goalsData: goalsInfo.filter((x) => x !== 0),
        },
      });
    }
    if (stageInfo !== "") {
      dispatch({
        type: "UPDATED_STAGES",
        payload: {
          stageData: stageInfo,
        },
      });
    }
  };

  const searchByKey = (key) => {
    dispatch({ type: "SEARCH_TYPED", payload: { searchedKey: key } });
  };

  const clearSearch = () =>
    dispatch({ type: "SEARCH_TYPED", payload: { searchedKey: "" } });

  /** @type {(data: import("../components/Layout/ModalJson").JsonProp) => void} */
  const changePlayerPersistence = (data) => {
    const isNewKey = state.selectedPopupKey && data.Key !== state.selectedPopupKey;
    data.Updated = true;

    const newPersistence = [...state.persistence];
    const persistenceItem = newPersistence.find((p) => p.Key === (isNewKey ? state.selectedPopupKey : data.Key));

    if (persistenceItem) {
      persistenceItem.Updated = (data.Value !== undefined && data.Value !== persistenceItem.Value) || data.Deleted;
      persistenceItem.Value = data.Value === undefined ? persistenceItem.Value : data.Value;
      persistenceItem.Deleted = isNewKey || data.Deleted;
    }

    if (isNewKey) {
      data.NewKey = true;
      newPersistence.push(data);
    }

    dispatch({
      type: "CHANGE_PLAYER_PERSISTENCE",
      payload: { persistence: newPersistence },
    });
  };

  /** @type {(key: string, toDelete: boolean) => void} */
  const toggleDeletedKeyFromPersistence = (key, toDelete) => changePlayerPersistence({ Key: key, Value: undefined, Deleted: toDelete })


  const exportPersistence = async () => {
    const parsedPersistence = state.persistence.map(x => {
      const value = tryParse(x.Value);
      return { ...x, Value: value };
    })

    const jsonData = JSON.stringify(parsedPersistence, null, 2);
    const url = window.URL.createObjectURL(new Blob([jsonData], { type: "application/json" }));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `player_${playerId}_persistence_${(new Date()).valueOf()}.json`);
    document.body.appendChild(link);
    link.click();
  }

  const tabs = (
    <>
      <TabPane tabId="1" className="player-tab-pane rounded-bottom">
        {state.loading ? (
          <LoadingContainer />
        ) : (
          <PlayerOverview
            playerData={state.playerData}
            devices={state.devices}
            debuggerInfo={state.debugger}
            getDebuggerInfo={getDebuggerInfo}
            updatePage={updatePage}
            socialMedias={state.socialMedias}
          />
        )}
      </TabPane>
      <TabPane tabId="2" className="player-tab-pane">
        {state.loading ? (
          <LoadingContainer />
        ) : (
          <PlayerAttributes
            handlePersistenceInfoChange={handlePersistenceInfoChange}
            persistence={state.persistence}
            attributes={state.attributes}
            playerId={playerId}
            updatePlayerInformations={updatePlayerInformations}
            onSubmit={sendAttribute}
            loading={state.loadingAttributeModal}
            modalOpen={state.modalOpen}
            toggleModalOpen={toggleModalOpen}
            buttonEnabled={
              state.stageData &&
              (state.stageData.length > 0 || state.goalsData.length > 0)
            }
          />
        )}
      </TabPane>
      <TabPane tabId="3" className="player-tab-pane">
        {state.loadingPersistence ? (
          <LoadingContainer />
        ) : (
          <PlayerPersistence
            persistence={state.persistence}
            searchedKey={state.searchedKey}
            exportJson={exportPersistence}
            toggleJsonModal={toggleJsonModal}
            toggleImportModal={toggleImportPersistenceModal}
            onSearch={searchByKey}
            onClear={clearSearch}
            toggleDeletedKey={toggleDeletedKeyFromPersistence}
            updatePlayerPersistence={onSaveClick}
          />
        )}
      </TabPane>
      <TabPane tabId="4" className="player-tab-pane">
        {state.loadingPurchaseHistory
          ? (<LoadingContainer />)
          : (<PurchaseComponent playerId={playerId} />)}
      </TabPane>
      <TabPane tabId="5" className="player-tab-pane">
        {state.loadingMessages
          ? (<LoadingContainer />)
          : (<PlayerGifts
            messages={state.messages}
            getMessages={getAllGifts}
            playerId={playerId}
          />)}
      </TabPane>
      <TabPane tabId="6" className="player-tab-pane">
        {state.loading
          ? (<LoadingContainer />)
          : (<PlayerLeaderboards leaderboards={state.leaderboards} />)}
      </TabPane>
      <TabPane tabId="7" className="player-tab-pane">
        {state.loadingEvents
          ? (<LoadingContainer />)
          : (<PlayerEvents
            events={state.events}
            onCreate={addPlayerToEvent}
            onDelete={deleteEventFromPlayer}
          />)}
      </TabPane>
    </>
  );
  if (!state.playerData && state.serverResponse) {
    return (
      <Redirect to={{ pathname: "/acp/players-listing", state: { error: "bad_request" }, }} />
    );
  }
  const persist = state.persistence.find((p) => p.Key === state.selectedPopupKey);



  const importPersistence = async (file) => {
    const data = JSON.parse(await file.text()).map(x => {
      const stringValue = typeof x.Value === "string"
        ? x.Value
        : stringfyKeepOrder(x.Value)
      return { ...x, Value: stringValue }
    });
    await http
      .put(`players/${playerId}/persistence/override`, data, { headers: authHeader() });
    toggleImportPersistenceModal();
  };

  return (
    <div>
      <PlayerGiftModal
        modal={state.messageOpen}
        playerId={playerId}
        update={updatePage}
        className="modal-dialog-centered"
        content={state.messageContent}
        close={closeMessageModal}
      />
      <SimpleHeader clean parentName="Players" name="Player Info" />
      <Container className="mt--6" fluid>
        <Row>
          <Col className="col-12">
            <PlayerTabs>{tabs}</PlayerTabs>
          </Col>
        </Row>
      </Container>
      <ModalJson
        edit
        isOpen={state.jsonPopupOpen}
        onChange={changePlayerPersistence}
        onClose={closeJsonModal}
        data={persist || { Key: "NewKey", Value: "{}" }}
      />
      {
        <ImportModal
          handleModalToggle={() => toggleImportPersistenceModal()}
          isOpen={state.importModalOpen}
          onSubmit={importPersistence}
          preview
        />
      }
    </div>
  );
};

export default Player;
