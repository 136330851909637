import React from "react";
import {
  Box,
  Divider,
  Flex,
  Heading,
  PseudoBox,
  Skeleton,
  Text,
} from "@chakra-ui/core";
import PropTypes from "prop-types";

const AttributesList = React.memo(({ attributes, loading }) => {
  const loadingAttributes = (
    <>
      <Skeleton height="30px" margin={4} />
      <Skeleton height="30px" margin={4} />
      <Skeleton height="30px" margin={4} />
    </>
  );

  return (
    <Box
      backgroundColor="#fffdfd"
      borderRadius="md"
      height={300}
      overflow="auto"
    >
      <Heading size="sm" p={4} color="#525f7f" pl={6}>
        Attribute Names
      </Heading>
      <Divider />
      <Flex direction="column">
        <Box bg="white" spacing={3}>
          {loading
            ? loadingAttributes
            : attributes.map((attribute) => (
                <PseudoBox
                  key={"attr" + attribute}
                  display="flex"
                  padding={4}
                  _hover={{ bg: "#edf2f7", cursor: "default" }}
                  backgroundColor="#fffdfd"
                  alignItems="center"
                >
                  <Text
                    lineHeight={1}
                    marginLeft={3}
                    marginBottom={0}
                    overflowWrap="anywhere"
                  >
                    {attribute}
                  </Text>
                </PseudoBox>
              ))}
        </Box>
      </Flex>
    </Box>
  );
});

AttributesList.propTypes = {
  attributes: PropTypes.array,
  loading: PropTypes.bool,
};

export default AttributesList;
