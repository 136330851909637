import React from "react";
import TableCard from "../../Layout/TableCard";
import PlayerNicknameDropdown from "./PlayerNicknameDropdown";
import GT from "../../Table/GenericTable";
import PaginationHeader from "../../common/PaginationHeader";
import SimplePaginationComponent from "../../common/SimplePaginationComponent";

/**
* @template T
* @typedef {import('../../Table/GenericTable').TableKey<T>} Key<T>
* @typedef {import("../../../types/models").PaginationData<T>} PaginationData<T>
* 
* */

/**
 * @typedef {import("../../../types/models").Player} Player
 * @typedef {{
 *  data: PaginationData<Player>,
 *  fetchPage: (page: number, loadCount: number) => Promise<void>
 *  loading?: boolean,
 *  onResetClick: () => void,
 *  onRemoveFromListClick: () => void,
 *  onNicknameChange: () => void,
 * }} ReportedPlayersProps
 */

/** @type {React.FC<ReportedPlayersProps>} */
const ReportedPlayers = ({
  data: { data: players, currentPage, total, objectsPerPage },
  fetchPage,
  loading,
  onResetClick,
  onRemoveFromListClick,
  onNicknameChange,
}) => {

  /** @type {Key<{}>[]} */
  const columns = [
    {
      headerOptions: { title: "Player ID" },
      key: "PlayerId",
      bodyOptions: {
        type: "LINK",
        to: "/acp/player/",
      },
    },
    {
      headerOptions: { title: "Nickname" },
      key: "Name",
    },
    {
      headerOptions: { title: "Votes" },
      key: "Votes",
    },
    {
      headerOptions: { title: "Options" },
      bodyOptions: {
        render: (_, index) => (
          <PlayerNicknameDropdown
            onResetClick={() => onResetClick(index)}
            onRemoveClick={() => onRemoveFromListClick(index)}
            onChangeClick={(newNickname) => onNicknameChange(index, newNickname)}
          />
        )
      }
    },
  ];
  const header = (
    <PaginationHeader
      skip={objectsPerPage * currentPage}
      take={objectsPerPage}
      total={total}
      onObjectsPerPageChange={(value) => fetchPage(currentPage, value)}
    />
  );

  const footer = (
    <SimplePaginationComponent
      currentPage={currentPage}
      fetchPage={page => fetchPage(page, objectsPerPage)}
      objectsPerPage={objectsPerPage}
      totalObjects={total}
    />
  );

  return (
    <TableCard
      headerProps={{ className: "p-2" }}
      className="mb-0"
      header={header}
      footer={footer}
      loading={loading}
    >
      <GT.Table
        loading={loading}
        data={players}
        onEmpty="Couldn't find any reported bad names."
        keys={columns}
        rowKeyfn={(entry) => entry.PlayerId}
      />
    </TableCard>
  );
};

export default ReportedPlayers;
