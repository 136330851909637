import React, { useState, useRef, useEffect } from "react";
import { Flex, IconButton, Input, InputGroup, Text } from "@chakra-ui/core";

/** @type {React.FC<{defaultValue:string, onSubmit:  (val: string) => void, placeholder: string, inputSize: number, textWeight: number, textSize: number }>} */
const EditableColumn = ({ defaultValue, onSubmit, placeholder, inputSize, textWeight, textSize, ...restProps }) => {
  const [value, setValue] = useState(defaultValue);
  const [isEditing, setIsEditing] = useState(false);

  const inputRef = useRef();

  useEffect(() => {
    if (isEditing) handleEditOpening();
  }, [isEditing]);

  const handleSubmit = () => {
    setIsEditing(false);
    onSubmit(value);
  };

  const handleEditOpening = () => inputRef.current.focus();

  const handleCancelEdit = () => {
    setValue(defaultValue);
    setIsEditing(false);
  };

  return isEditing ? (
    <Flex {...restProps}>
      <InputGroup flexGrow={1} size={inputSize}>
        <Input
          paddingY={2}
          placeholder={placeholder || defaultValue}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          ref={inputRef}
          marginRight={2}
        />
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="Confirm Edit"
          onClick={handleSubmit}
          icon="check"
          color="green.500"
        />
        <IconButton
          size="sm"
          variant="ghost"
          aria-label="Confirm Edit"
          onClick={handleCancelEdit}
          icon="close"
        />
      </InputGroup>
    </Flex>
  ) : (
    <Flex {...restProps}>
      <Text
        marginRight={4}
        marginBottom={0}
        lineHeight={2.5}
        fontSize={textSize || 13}
        fontWeight={textWeight || 400}
        fontFamily="Open Sans"
      >
        {defaultValue}
      </Text>
      <IconButton
        size="sm"
        aria-label="Edit Column"
        icon="edit"
        variant="ghost"
        color="blue.600"
        onClick={() => setIsEditing(true)}
      />
    </Flex>
  );
};

export default EditableColumn;
