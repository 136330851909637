import React from "react";
import authHeader from "../../../helpers/auth-header.js";

import {Card, CardBody, Nav, NavItem, NavLink, TabContent, Table, TabPane,} from "reactstrap";
// create classname components
import classnames from "classnames";
import TableCard from "../../Layout/TableCard.jsx";
import SimplePaginationComponent from "../../common/SimplePaginationComponent.jsx";
import httpClient from "../../../helpers/http-client-singleton";
import ErrorBox from "../../common/ErrorBox";

const http = httpClient.getInstance();

export default class BuildTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabs: 1,
      loading: true,
      search: this.props.search,
      tableAndroidData: [],
      tableIosData: [],
      objectsPerPage: 15,
      currentAndroidPage: 0,
      currentIosPage: 0,
      totalAndroidObjects: null,
      totalIosObjects: null,
    };
  }

  componentDidMount() {
    this.androidPage(0);
    this.iosPage(0);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.search !== this.props.search) {
      this.androidPage(0);
      this.iosPage(0);
    }
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  handleObjectsPerPage = (number) => {
    this.setState({ objectsPerPage: number }, () => {
      this.androidPage(0);
      this.iosPage(0);
    });
  };

  androidPage = (pageNumber) => {
    let auxPage = 0;
    if (pageNumber === "next") {
      auxPage = this.state.currentAndroidPage + 1;
      this.setState({
        currentAndroidPage: this.state.currentAndroidPage + 1,
        loading: true,
      });
    } else if (pageNumber === "prev") {
      auxPage = this.state.currentAndroidPage - 1;
      this.setState({
        currentAndroidPage: this.state.currentAndroidPage - 1,
        loading: true,
      });
    } else {
      auxPage = pageNumber;
      this.setState({
        currentAndroidPage: pageNumber,
        loading: true,
      });
    }

    this.setState({ tableAndroidData: [], loading: true });
    let request = this.props.search
      ? http
          .get("/apimatch/" + this.props.search, {
            headers: { ...authHeader(), platform: "android" },
          })
          .then((response) => {
            this.setState({
              tableAndroidData: [response.data.data.m_apiMatch],
              loading: false,
              totalAndroidObjects: 1,
            });
          })
      : http
          .get("/apimatch/paginated/android", {
            params: {
              desc: true,
              skip: this.state.objectsPerPage * auxPage,
              take: this.state.objectsPerPage,
            },
          })
          .then((response) => {
            this.setState({
              tableAndroidData: response.data.data.Result,
              currentAndroidPage: auxPage,
              totalAndroidObjects: response.data.data.Total,
              loading: false,
            });
          });

    request.catch((error) => {
      this.setState({ errorMessage: error.body });
    });
  };

  getNotUniqueAccess(builds, buildNumber) {
    let nextBuild = -1;
    const notUnique = builds.filter((build) => !build["StaticRoute"]);
    notUnique.sort((x, y) => x["BuildNumber"] - y["BuildNumber"]);
    const isLast =
      notUnique.findIndex((build) => build["BuildNumber"] === buildNumber) ===
      notUnique.length - 1;
    if (!isLast) {
      const nextBuildIndex =
        notUnique.findIndex((build) => build["BuildNumber"] === buildNumber) +
        1;
      let nextBuildNumber = notUnique[nextBuildIndex]["BuildNumber"] - 1;
      const findBuildNumber = (build) =>
        nextBuildNumber === build["BuildNumber"];
      while (nextBuildNumber > buildNumber) {
        const build = builds.find(findBuildNumber);
        if (build) {
          if (build["StaticRoute"]) {
            nextBuildNumber--;
          }
        } else {
          break;
        }
      }
      nextBuild = nextBuildNumber;
    }
    return nextBuild;
  }

  iosPage = (pageNumber) => {
    let auxPage = 0;
    if (pageNumber === "next") {
      auxPage = this.state.currentIosPage + 1;
      this.setState({
        currentIosPage: this.state.currentIosPage + 1,
        loading: true,
      });
    } else if (pageNumber === "prev") {
      auxPage = this.state.currentIosPage - 1;
      this.setState({
        currentIosPage: this.state.currentIosPage - 1,
        loading: true,
      });
    } else {
      auxPage = pageNumber;
      this.setState({
        currentIosPage: pageNumber,
        loading: true,
      });
    }

    let request = this.props.search
      ? http
          .get("/apimatch/" + this.props.search, {
            headers: { ...authHeader(), platform: "ios" },
          })
          .then((response) => {
            this.setState({
              tableIosData: [response.data.data.m_apiMatch],
              loading: false,
              totalIosObjects: 1,
            });
          })
      : http
          .get("/apimatch/paginated/ios", {
            params: {
              desc: true,
              skip: this.state.objectsPerPage * auxPage,
              take: this.state.objectsPerPage,
            },
          })
          .then((response) => {
            this.setState({
              tableIosData: response.data.data.Result,
              currentIosPage: auxPage,
              totalIosObjects: response.data.data.Total,
              loading: false,
            });
          });

    request.catch((error) => {
      this.setState({ errorMessage: error.body });
    });
  };

  getBuildNumberInfo = (data, buildData) => {
    const nextBuild = this.getNotUniqueAccess(data, buildData["BuildNumber"]);
    const arrow = (
      <span>
        {" "}
        >> {nextBuild !== -1 ? nextBuild : <i className="fas fa-infinity" />}
      </span>
    );
    return (
      <>
        {buildData["BuildNumber"]}
        {this.props.search ? null : buildData["StaticRoute"] ? null : arrow}
      </>
    );
  };

  renderTable(tableData) {
    return ( tableData.length > 0 ?
      <Table hover className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">BUILD NUMBER</th>
            <th scope="col" className="text-center">
              API VERSION
            </th>
            <th scope="col" className="text-center">
              UNIQUE ACCESS
            </th>
            <th scope="col" className="text-center">
              CONTENT BUCKET
            </th>
            <th scope="col" className="text-center">
              STAGING BUILD
            </th>
            <th scope="col" className="text-center">
              UPDATE REQUIRED
            </th>
            <th scope="col" className="text-center">
              DEBUGGER BUILD
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <tr key={"row" + index}>
              <td
                key={data["BuildNumber"] + "-bnb"}
                className="buildNumber"
              >{this.getBuildNumberInfo(tableData, data)}</td>
              <td
                key={data["Version"] + "-vrs"}
                className="version text-center"
              >
                {data["Version"]}
              </td>
              <td
                key={data["StaticRoute"] + "-strt"}
                className="staticRoute text-center"
              >
                <i
                  className={
                    data["StaticRoute"]
                      ? "fa fa-check text-success"
                      : "fas fa-times text-gray"
                  }
                />
              </td>
              <td
                key={data["ContentEnvironment"] + "-cenv"}
                className="text-center contentEnvironment"
              >
                {data["ContentEnvironment"]}
              </td>
              <td
                key={data["IsStaging"] + "-stg"}
                className="isStaging text-center"
              >
                <i
                  className={
                    data["IsStaging"]
                      ? "fa fa-check text-success"
                      : "fas fa-times text-gray"
                  }
                />
              </td>
              <td
                key={data["UpdateRequired"] + "-updt"}
                className="updateRequired text-center"
              >
                <i
                  className={
                    data["UpdateRequired"]
                      ? "fa fa-check text-success"
                      : "fas fa-times text-gray"
                  }
                />
              </td>
              <td
                key={data["DebuggerBuild"] + "-dbg"}
                className="debuggerBuild text-center"
              >
                <i
                  className={
                    data["DebuggerBuild"]
                      ? "fa fa-check text-success"
                      : "fas fa-times text-gray"
                  }
                />
              </td>
            </tr>
          ))}
        </tbody>
      </Table> : <ErrorBox>There isn't any build to show.</ErrorBox>
    );
  }

  render() {
    const android = this.state.loading
      ? ""
      : this.renderTable(this.state.tableAndroidData);
    const ios = this.state.loading
      ? ""
      : this.renderTable(this.state.tableIosData);

    return (
        <Card>
          <CardBody>
            <div className="nav-wrapper">
              <Nav
                  className="nav-fill flex-column flex-md-row"
                  id="tabs-icons-text"
                  pills
                  role="tablist"
              >
                <NavItem>
                  <NavLink
                      aria-selected={this.state.tabs === 1}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.tabs === 1,
                      })}
                      onClick={(e) => this.toggleNavs(e, "tabs", 1)}
                      href="#android"
                      role="tab"
                  >
                    <i className="fab fa-android mr-2"/>
                    Android
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                      aria-selected={this.state.tabs === 2}
                      className={classnames("mb-sm-3 mb-md-0", {
                        active: this.state.tabs === 2,
                      })}
                      onClick={(e) => this.toggleNavs(e, "tabs", 2)}
                      href="#ios"
                      role="tab"
                  >
                    <i className="fab fa-apple mr-2"/>
                    iOS
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
            <TabContent activeTab={"tab" + this.state.tabs}>
              <TabPane tabId="tab1">
                <TableCard
                    loading={this.state.loading}
                    total={this.state.totalAndroidObjects}
                    skip={this.state.currentAndroidPage * this.state.objectsPerPage}
                    take={this.state.objectsPerPage}
                    onObjectsPerPageChange={this.handleObjectsPerPage}
                    paginated
                >
                  {android}
                </TableCard>
                {this.state.totalAndroidObjects !== null ? (
                    <SimplePaginationComponent
                        fetchPage={this.androidPage}
                        currentPage={this.state.currentAndroidPage}
                        objectsPerPage={this.state.objectsPerPage}
                        totalObjects={this.state.totalAndroidObjects}
                    />
                ) : null}
              </TabPane>
              <TabPane tabId="tab2">
                <TableCard
                    loading={this.state.loading}
                    total={this.state.totalIosObjects}
                    skip={this.state.currentIosPage * this.state.objectsPerPage}
                    take={this.state.objectsPerPage}
                    onObjectsPerPageChange={this.handleObjectsPerPage}
                    paginated
                >
                  {ios}
                </TableCard>
                {this.state.totalIosObjects !== null ? (
                    <SimplePaginationComponent
                        fetchPage={this.iosPage}
                        currentPage={this.state.currentIosPage}
                        objectsPerPage={this.state.objectsPerPage}
                        totalObjects={this.state.totalIosObjects}
                    />
                ) : null}
              </TabPane>
            </TabContent>
          </CardBody>
        </Card>
    );
  }
}
