import React, { useEffect, useState } from "react";
import SimpleHeader from "../components/Headers/SimpleHeader";
import DataContainer from "../components/Versioning/Buckets/DataContainer";
import { SimpleGrid, ButtonGroup, IconButton } from "@chakra-ui/core";
import httpClient from "../helpers/http-client-singleton";
import LoadingContainer from "../components/Layout/LoadingContainer";

const http = httpClient.getInstance();

// This function return all keys from a object that contains the regex expr passed as argument
const filterKeys = (obj, filter) => {
  let key,
    keys = [];
  for (key in obj) {
    if (obj.hasOwnProperty(key) && filter.test(key)) {
      keys.push(key);
    }
  }
  return keys;
};

const moveProperty = (from, to, key) => {
  const keys = filterKeys(from, RegExp(key));
  // This creates an array of objects with the bucket info
  to[key] = keys.map((k) => ({
    ...from[k],
    bucket_name: k.replace(key + "/", ""),
    type: key,
  }));
  keys.forEach((k) => delete from[k]);
};

const separateLiveByStatus = (data, liveInfo) => {
  const live = {};
  data.live.forEach((content) => {
    if (liveInfo[content.bucket_name].deprecated) {
      if (live.hasOwnProperty("live (d)"))
        live["live (d)"].push(content);
      else live["live (d)"] = [content];
    } else {
      if (live.hasOwnProperty("live (c)"))
        live["live (c)"].push(content);
      else live["live (c)"] = [content];
    }
  });
  delete data.live;
  return {
    "live (c)": live["live (c)"],
    "live (d)": live["live (d)"],
    ...data,
  };
};

const DataInformation = () => {
  const [multiSelect, setMultiSelect] = useState(false);

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  const getLiveInfo = (lives) => {
    const livesByBucket = {};
    lives.forEach((live) => {
      const content = live.ContentEnvironment.substring(
        live.ContentEnvironment.lastIndexOf("/") + 1
      );
      if (livesByBucket.hasOwnProperty(content))
        livesByBucket[content].builds.push(live);
      else {
        livesByBucket[content] = {};
        livesByBucket[content].builds = [live];
        livesByBucket[content].deprecated = true;
      }
    });
    Object.keys(livesByBucket).forEach((key) => {
      if (livesByBucket[key].builds.some((build) => !build.UpdateRequired)) {
        livesByBucket[key].deprecated = false;
      }
    });
    return livesByBucket;
  };

  useEffect(() => {
    if (!(Object.keys(data).length === 0 && data.constructor === Object)) {
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    let cdnUrl = localStorage.getItem("cdnUrl");
    //use a new instance of httpclient to get rid of any default parameter (token, headers, etc)
    httpClient.create().get(cdnUrl + "/dataInformation.json").then(async (dataInfo) => {
      let data = parseJson(dataInfo.data);
      const match = await http.get("/apimatch/");
      // Get only the builds that are on live environment
      const liveInfo = getLiveInfo(match.data.data.filter((build) =>
        build.ContentEnvironment &&
        build.ContentEnvironment.includes("live")
      )
      );
      // Separate builds as deprecated or not deprecated
      if (localStorage.getItem("env") === "Production") {
        data = separateLiveByStatus(data, liveInfo);
      }
      setData(data);
      setLoading(false);
    })
  }, []);

  const parseJson = (json) => {
    const data = {};
    moveProperty(json, data, "live");
    moveProperty(json, data, "personal");
    moveProperty(json, data, "design");
    //Sort live information by descending live number
    data.live = data.live.sort(
      (a, b) =>
        Number(b.bucket_name.substring(1)) - Number(a.bucket_name.substring(1))
    );
    data.others = [];
    Object.keys(json).forEach((k) =>
      data.others.push({ ...json[k], bucket_name: k })
    );
    return data;
  };

  const content = multiSelect ? (
    <SimpleGrid columns={{ xs: 1, lg: 2 }}>
      <DataContainer data={data} className="mt--6" multiSelect={multiSelect} />
      <DataContainer data={data} className="mt--6" multiSelect={multiSelect} />
    </SimpleGrid>
  ) : (
    <DataContainer data={data} className="mt--6" multiSelect={multiSelect} />
  );

  const buttonProps = { variant: "ghost" };

  return (
    <>
      <SimpleHeader name="Data Information" clean parentName="Dashboard">
        <ButtonGroup
          spacing={0}
          backgroundColor="white"
          rounded="md"
          size="sm"
          float="right"
        >
          <IconButton
            backgroundColor={multiSelect ? "#fff" : "lumenPrimary.200"}
            _hover={{ bg: multiSelect ? "#EDF2F7" : "lumenPrimary.200" }}
            icon="info-outline"
            aria-label="single-item"
            roundedRight={0}
            onClick={() => setMultiSelect(false)}
            {...buttonProps}
          />
          <IconButton
            backgroundColor={!multiSelect ? "#fff" : "lumenPrimary.200"}
            _hover={{ bg: !multiSelect ? "#EDF2F7" : "lumenPrimary.200" }}
            icon="view"
            aria-label="two-items"
            roundedLeft={0}
            onClick={() => setMultiSelect(true)}
            {...buttonProps}
          />
        </ButtonGroup>
      </SimpleHeader>
      {loading ? <LoadingContainer /> : content}
    </>
  );
};

export default DataInformation;
