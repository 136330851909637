import { GoogleSpreadsheet } from "google-spreadsheet";

const loadSheet = async (sheetId, sheetTitle) => {
  const doc = new GoogleSpreadsheet(sheetId);

  await doc.useServiceAccountAuth({
    client_email: process.env.REACT_APP_SW_GOOGLE_SERVICE_ACCOUNT_EMAIL,
    private_key: process.env.REACT_APP_SW_GOOGLE_PRIVATE_KEY,
  });

  await doc.loadInfo();
  return doc.sheetsByTitle[sheetTitle];
};

const appendCellToColumn = async (sheet, columnName, cellValue) => {
  return await sheet.addRow({ [columnName]: cellValue });
};

const isValueOnTable = async (sheet, columnName, value) => {
  const rows = await sheet.getRows();
  return rows.some((row) => row[columnName] === value);
};

export { loadSheet, appendCellToColumn, isValueOnTable };
