/* eslint-disable no-unused-expressions */
import { CSSReset, ThemeProvider } from "@chakra-ui/core";
import { IntlProvider } from "react-intl";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import NotificationWrapper from "../components/common/NotificationWrapper";
import Interceptors from "../helpers/Interceptors";
import { notifyError } from "../helpers/notifyError";
import LoginView from "./Login";
import AdminLayout from "../layouts/Admin";
import BuildTable from "../components/Versioning/Builds/BuildTable";
import DataInformation from "./DataInformation";
import { PrivateRoute } from "../components/common/PrivateRoute";
import AcpLayout from "../layouts/Acp";
import React from "react";
import devTheme from "../assets/palletes/devPallete";
import stagingTheme from "../assets/palletes/stagingPallete";
import releaseTheme from "../assets/palletes/releasePallete";

import moment from "moment";
import { ConsolePreferenceProvider } from "../context/ConsolePreferenceContext";
import { UserProvider } from "../context/UserContext";

const STAGING_ENVIRONMENT = "staging";
const STAG_ENVIRONMENT = "stag";
const PRODUCTION_ENVIRONMENT = "live";


const App = () => {

  const configureTheme = () => {
    let environment = localStorage.getItem("env")
    console.info(environment)
    if (environment === (STAGING_ENVIRONMENT) || environment === STAG_ENVIRONMENT) {
      import("assets/scss/argon-dashboard-pro-react.scss?v1.0.0");
      return stagingTheme;
    } else if (environment === PRODUCTION_ENVIRONMENT) {
      import(
        "assets/scss/argon-dashboard-pro-react-release.scss?v1.0.0"
      );
      return releaseTheme;
    } else {
      import(
        "assets/scss/argon-dashboard-pro-react-dev.scss?v1.0.0"
      );
      return devTheme;
    }
  };
  
  const configureLocale = () => {
    const locale = (navigator.language || navigator.userLanguage).toLowerCase();
    import(`moment/locale/${locale}`);
    moment.locale(locale);
  };
  
  //const environment = configureEnvironment();
  const customTheme = configureTheme();
  configureLocale();

  const notificationRef = React.createRef();

  return (
    <ThemeProvider theme={customTheme}>
      <CSSReset />
      <IntlProvider locale="en">
        <UserProvider>
        <ConsolePreferenceProvider>
          <BrowserRouter>
            <NotificationWrapper notificationRef={notificationRef} />
            <Interceptors
              notify={(error) => notifyError(notificationRef, error)}
            />
            <Switch>
              <Route path="/login">
                <LoginView/>
              </Route>
              <Route 
                path="/admintemplate"
                render={(props) => <AdminLayout {...props}/>}
                >
              </Route>
              <Route path="/buildlisting">
                <BuildTable/>
              </Route>
              <Route path="/datainformation">
                <DataInformation/>
              </Route>

              <PrivateRoute
                path="/acp/:action/:param?"
                component={(props) => <AcpLayout {...props}/>}
              />
              <PrivateRoute
                path="/"
                component={() => <Redirect to="/acp/events" />}
              />

              <Redirect from="*" to="/" />
            </Switch>
          </BrowserRouter>
        </ConsolePreferenceProvider>
        </UserProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};

export default App;
