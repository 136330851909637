import React, { useCallback, useEffect, useState } from "react";
import {
  getAllFavorites,
  PRIMARY_KEY_ENUM as CATEGORIES_PRIMARY_KEYS,
  updateFavorite,
  removeFromFavorites,
} from "../services/favorites.service";

import FavoritesComponent from "../components/Favorites/Favorites";
import FavoriteButton from "../components/Favorites/FavoriteButton";

const CATEGORIES = ["players", "snapshots"];

const DEFAULT_CATEGORY = "players";

const Favorites = () => {
  const [currentCategory, setCurrentCategory] = useState(DEFAULT_CATEGORY);
  const [favorites, setFavorites] = useState(getAllFavorites());

  const onCategoryChange = (category) => setCurrentCategory(category);

  const updateDescription = (row, description) => {
    updateFavorite(
      row[CATEGORIES_PRIMARY_KEYS[currentCategory]],
      currentCategory,
      { description }
    );
    setFavorites(getAllFavorites());
  };

  const removeFavorite = useCallback((favorite) => {
    removeFromFavorites(
      favorite[CATEGORIES_PRIMARY_KEYS[currentCategory]],
      currentCategory
    );
    setFavorites(getAllFavorites());
  }, [currentCategory]);

  const addFavoriteButton = useCallback((favorites) => {
    if (favorites) {
      const newFavorites = { ...favorites };
      // Iterate over all favorite categories and adds a favorite button
      CATEGORIES.forEach((category) => {
        newFavorites[category] = newFavorites[category].map((favorite) => ({
          ...favorite,
          favoriteButton: (
            <FavoriteButton
              removeFavorite={() => removeFavorite(favorite)}
              isFavorite
            />
          ),
        }));
      });
      setFavorites(newFavorites);
    }
  }, [removeFavorite]);

  useEffect(() => {
    addFavoriteButton(favorites);
  }, [favorites, addFavoriteButton]);

  // Columns passed to the table container holding all info necessary to render it
  const columns = {
    players: [
      {
        Header: "Player ID",
        accessor: CATEGORIES_PRIMARY_KEYS.players,
        width: "50%",
        type: "link",
        endpoint: "/acp/player/",
      },
      {
        Header: "Description",
        accessor: "description",
        width: "30%",
        type: "editable",
      },
      {
        Header: "Favorite",
        accessor: "favoriteButton",
        width: "20%",
      },
    ],
    snapshots: [
      {
        Header: "Snapshot Key",
        accessor: CATEGORIES_PRIMARY_KEYS.snapshots,
        width: "50%",
        type: "link",
        endpoint: "/acp/snapshot/",
      },
      {
        Header: "Description",
        accessor: "description",
        width: "30%",
        type: "editable",
      },
      {
        Header: "Favorite",
        accessor: "favoriteButton",
        width: "20%",
      },
    ],
  };

  return (
    <FavoritesComponent
      favorites={favorites && favorites[currentCategory]}
      columns={columns[currentCategory]}
      categories={CATEGORIES}
      defaultCategory={DEFAULT_CATEGORY}
      onCategoryChange={onCategoryChange}
      updateDescription={updateDescription}
    />
  );
};

export default Favorites;
