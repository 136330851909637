import React, { useState, useEffect, useCallback, useRef } from "react";

import authHeader from "../helpers/auth-header";

import {
  Table,
  Collapse,
  Container,
  Button,
  CardBody,
  Row,
  Col,
} from "reactstrap";
import TableCard from "../components/Layout/TableCard.jsx";

import DateTimeComponent from "../components/common/DateTimeComponent.jsx";
import LoadingContainer from "../components/Layout/LoadingContainer.jsx";
import { PersistenceInfo } from "../components/Persistence/PersistenceInfo";
import SnapshotOptionsDropdown from "../components/Snapshot/SnapshotOptionsDropdown.jsx";
import SimplePaginationComponent from "../components/common/SimplePaginationComponent.jsx";
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";
import { Link } from "react-router-dom";

import classname from "classnames";

import "./css/snapshotStyles.css";
import SearchInput from "../components/common/SearchInput.jsx";
import PaginationHeader from "../components/common/PaginationHeader.jsx";
import httpClient from "../helpers/http-client-singleton";
import NotificationAlert from "react-notification-alert";
import useWindowDimensions from "../helpers/useWindowDimensions";
import ErrorBox from "../components/common/ErrorBox";
import FavoriteButton from "../components/Favorites/FavoriteButton";
import {
  isFavorite,
  addToFavorites,
  removeFromFavorites,
} from "../services/favorites.service";

const http = httpClient.getInstance();

const SNAPSHOT_TYPES = { 0: "Manual", 1: "Reset", 2: "Migration" };

const Snapshots = () => {
  const [tableData, setTableData] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [loading, setLoading] = useState(false);
  const [loadingAccordion, setAccordionLoading] = useState(false);
  const [totalObjects, setTotalObjects] = useState(0);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [accordionPersistence, setAccordionPersistence] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedSnapshot, setSelectedSnapshot] = useState({
    key: null,
    index: null,
    playerId: null,
  });
  const notificationAlert = useRef();
  const { width } = useWindowDimensions();
  const mobile = width < 768;

  const getPage = useCallback(
    (pageNumber) => {
      let auxPage;
      if (pageNumber === "next") {
        auxPage = currentPage + 1;
      } else if (pageNumber === "prev") {
        auxPage = currentPage - 1;
      } else {
        auxPage = pageNumber;
      }
      setLoading(true);
      setCurrentPage(auxPage);
      http
        .get("/snapshots/", {
          params: {
            skip: auxPage * objectsPerPage,
            take: objectsPerPage,
            filter: searchTerm,
            desc: true,
          },
          headers: authHeader(),
        })
        .then((response) => {
          if (response.data === undefined) {
            getPage(0);
          } else {
            setTableData(response.data.data.Result);
            setTotalObjects(response.data.data.Total);
          }
        })
        .finally(() => setLoading(false));
    },
    [currentPage, objectsPerPage, searchTerm]
  );

  useEffect(() => getPage(0), []);

  const onSearch = (search) => {
    setSearchTerm(search);
  };

  const notify = () => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            Snapshot Loaded
          </span>
          <span data-notify="message">
            Snapshot loaded on Player successfully
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    notificationAlert.current.notificationAlert(options);
  };
  const onClear = () => {
    setSearchTerm("");
  };

  const onEntering = (key) => {
    setAccordionLoading(true);
    http
      .get("/persistences/snapshot_" + key, {
        headers: authHeader(),
      })
      .then((response) => {
        setAccordionPersistence(response.data.data.m_playerPersistences);
        setAccordionLoading(false);
      })
      .catch(() => {
        setAccordionLoading(false);
      });
  };

  const updatePage = () => {
    setSelectedSnapshot({
      key: null,
      index: null,
      playerId: null,
    });
    getPage(0);
  };

  const addFavorite = (e, snapshotKey) => {
    e.stopPropagation();
    addToFavorites(snapshotKey, "snapshots");
  }
  const removeFavorite = (e, snapshotKey) => {
    e.stopPropagation();
    removeFromFavorites(snapshotKey, "snapshots");
  }

  const toggleAccordion = (playerId, key, index) => {
    setSelectedSnapshot({
      playerId,
      key,
      index: selectedSnapshot.index === index ? null : index,
    });
  };

  const table = (
    <Table hover className="align-items-center table-flush " striped responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col">KEY</th>
          <th scope="col">PLAYER ID</th>
          {!mobile && <th scope="col">DESCRIPTION</th>}
          <th scope="col">DATE</th>
          <th scope="col">TYPE</th>
          <th scope="col" />
        </tr>
      </thead>
      <tbody
        className={classname("accordion", { "table-mobile-padding": mobile })}
        id="accordionPersistenceInfo"
      >
        {tableData.map((data, index) => (
          <React.Fragment key={data["Key"]}>
            <tr
              key={"row" + index}
              onClick={() => {
                toggleAccordion(data["PlayerId"], data["Key"], index);
              }}
              className={classname({
                active: selectedSnapshot.index === index,
              })}
            >
              <td key={data["PlayerId"] + "-key"}>
                <Link className="text-primary" to={"snapshot/" + data["Key"]}>
                  {data["Key"]}
                </Link>
              </td>
              <td key={data["PlayerId"] + "-did"}>
                <Link
                  className="text-primary"
                  to={"player/" + data["PlayerId"]}
                >
                  {mobile && data["PlayerId"].length > 8
                    ? data["PlayerId"].substring(0, 7) + "..."
                    : data["PlayerId"]}
                </Link>
              </td>
              {!mobile && (
                <td key={data["PlayerId"] + "-descri"}>
                  {data["Description"] != null &&
                    data["Description"].length > 34
                    ? data["Description"].substring(0, 32) + " ..."
                    : data["Description"]}
                </td>
              )}
              <td
                key={data["PlayerId"] + "-creationDate"}
                className="creationDate"
              >
                <DateTimeComponent
                  date={data["CreationDate"]}
                  tooltipId={"tooltip32528" + index}
                />
              </td>
              <td
                key={data["PlayerId"] + "-snapshotType"}
              >
                {SNAPSHOT_TYPES[data["Type"]] || "-"}
              </td>
              <td>
                <FavoriteButton
                  isFavorite={isFavorite(data["Key"], "snapshots")}
                  addFavorite={e => addFavorite(e, data["Key"])}
                  removeFavorite={e => removeFavorite(e, data["Key"])}
                />
                {!mobile && (
                  <Button
                    size="sm"
                    className="btn-icon btn-2"
                    onClick={() => {
                      toggleAccordion(data["PlayerId"], data["Key"], index);
                    }}
                    color="neutral"
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      {selectedSnapshot.index === index ? (
                        <i className="fas fa-level-up-alt" />
                      ) : (
                        <i className="fas fa-expand-arrows-alt" />
                      )}
                    </span>
                  </Button>
                )}
                <SnapshotOptionsDropdown
                  size="sm"
                  className="btn-icon-only text-light"
                  s_key={data["Key"]}
                  playerId={data["PlayerId"]}
                  s_desc={data["Description"]}
                  notifySuccess={notify}
                  update={updatePage}
                >
                  <i className="fas fa-ellipsis-v" />
                </SnapshotOptionsDropdown>
              </td>
            </tr>
            <tr>
              <td colSpan="5" className="p-0">
                <Collapse
                  isOpen={selectedSnapshot.index === index}
                  colSpan="5"
                  onEntering={() => {
                    onEntering(data["Key"]);
                  }}
                >
                  <CardBody className="snapshot-accordion-card">
                    <PersistenceInfo
                      mobile={mobile}
                      playerId={data["PlayerId"]}
                      classStyle
                      loading={loadingAccordion}
                      persistence={accordionPersistence}
                    />
                  </CardBody>
                </Collapse>
              </td>
            </tr>
          </React.Fragment>
        ))}
      </tbody>
    </Table >
  );

  const search = (
    <Row className="flex align-items-center">
      <Col xs={12} xl={6}>
        <SearchInput
          onSearch={onSearch}
          onClear={onClear}
          placeholder="Player ID or Snapshot Key"
        />
      </Col>
      <Col xs={12} xl={6}>
        <PaginationHeader
          skip={currentPage * objectsPerPage}
          take={objectsPerPage}
          total={totalObjects}
          onObjectsPerPageChange={setObjectsPerPage}
        />
      </Col>
    </Row>
  );
  const contents = loading ? (
    <LoadingContainer />
  ) : tableData.length > 0 ? (
    table
  ) : (
    <ErrorBox>Couldn't find any Snapshots to show.</ErrorBox>
  );

  return (
    <>
      <SimpleHeader name="Snapshots Listing" clean parentName="Dashboard" />
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationAlert} />
      </div>
      <Container fluid className="mt--6">
        <TableCard
          loading={loading}
          header={search}
          footer={
            (totalObjects && (
              <SimplePaginationComponent
                fetchPage={getPage}
                currentPage={currentPage}
                objectsPerPage={objectsPerPage}
                totalObjects={totalObjects}
              />
            )) ||
            null
          }
        >
          {contents}
        </TableCard>
      </Container>
    </>
  );
};

export default Snapshots;
