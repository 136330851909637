import React from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  Flex,
  Button,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";


import "../common/css/errorStyles.css";
import Swal from "sweetalert2";

const MaulMessageForm = ({
  onSubmit,
  onCancel,
  channel,
  message,
  loading,
  ...restProps
}) => {
  const { register, handleSubmit } = useForm({reValidateMode: "onBlur"});

  const onSuccess = (response) => {
    if (response.status) Swal.fire({ type: "success", title: "Success", text: "Message Sent!",});
  };

  const onError = () => Swal.fire({
    type: "error",
    title: "Error",
    text: "Please insert a valid information and try again."
  });

  const onMessageSubmit = (val) => onSubmit(val, onSuccess, onError);

  return (
    <Box fontFamily="Open Sans" {...restProps}>
      <form onSubmit={handleSubmit(onMessageSubmit)}>
      <FormControl>
        <FormLabel fontWeight="600" marginTop={2}>
          System Message
        </FormLabel>
        <Textarea
          name="message"
          ref={register}
          defaultValue={message}
        />
      </FormControl>
        <Flex justifyContent="space-between"  marginTop={4}>
          <FormControl display="flex"  flex={0.9}>
            <FormLabel fontWeight="600" alignSelf="center" paddingY={0} marginY={0} > 
              Channel
            </FormLabel>
            <Input
              name="channel"
              defaultValue={channel}
              ref={register}
            />
        </FormControl>
          <Button
            size="md"
            leftIcon="email"
            variantColor="lumenPrimary"
            type="submit"
          >
            Send it
          </Button>
          {onCancel && (
            <Button
              marginLeft={4}
              size="md"
              variantColor="gray"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </Flex>
      </form>
    </Box>
  );
};

export default MaulMessageForm;
