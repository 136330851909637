import React, { useState } from "react";
import { Row, Col } from "reactstrap";
import { Select, Input, IconButton, Button } from "@chakra-ui/core";
import ListInput from "./ListInput";

const FilterInput = ({ className, onClick, operators, onSubmit, disabled, list }) => {
  const [key, setKey] = useState("");
  const [operator, setOperator] = useState(Object.keys(operators)[0]);
  const [value, setValue] = useState("");

  const handleClick = () => {
    onClick(key, operator, value);
  };

  return (
    <>
      <Row className={className}>

        <Col xs={12} xl={3} className="py-2">
          <ListInput
            value={key}
            size="sm"
            onChange={(e) => setKey(e)}
            placeholder="Attribute Key"
            type="search"
            list="browsers"
            options={list}
          />
        </Col>
        <Col xs={12} xl={3} className="py-2">
          <Select
            type="select"
            size="sm"
            onChange={(e) => setOperator(e.target.value)}
          >
            {Object.keys(operators).map((op) => (
              <option defaultValue={operator} value={op}>
                {op}
              </option>
            ))}
          </Select>
        </Col>
        <Col xs={12} xl={3} className="py-2">
          <Input
            size="sm"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            placeholder="Attribute Value"
          />
        </Col>
        <Col xs={12} xl={3} className="py-2">
          <IconButton
            size="sm"
            aria-label="Add Filter"
            onClick={handleClick}
            variantColor="lumenPrimary"
            icon="add"
            fontSize="xs"
          />
          <Button
            marginLeft={2}
            size="sm"
            aria-label="Apply Filters"
            isDisabled={disabled}
            onClick={onSubmit}
            variantColor="lumenPrimary"
            fontSize="xs"
          >Apply Filters</Button>
        </Col>
      </Row>
    </>
  );
};

export default FilterInput;
