import React from "react";
import { Box, Tabs, TabPanels, TabList, TabPanel, Tab } from "@chakra-ui/core";
import BucketBox from "./BucketBox";
import SimpleGrid from "@chakra-ui/core/dist/SimpleGrid";
import capitalize from "../../../helpers/capitalize";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./css/BucketStyles.css";
import useWindowDimensions from "../../../helpers/useWindowDimensions";
import versionSort from "../../../helpers/version-sort";

/**
 * @typedef {{
 *  multiSelect: boolean,
 * } & import("@chakra-ui/core").BoxProps} BucketContentProps
 */

/** @type {React.NamedExoticComponent<BucketContentProps>}  */
const BucketContent = React.memo(({ multiSelect, children, ...restProps }) =>
  multiSelect ? (
    <PerfectScrollbar option={{ wheelPropagation: false }}>
      <Box {...restProps}>
        <SimpleGrid columns={{ xs: 3, lg: 4, xl: 5 }} spacing={6} height="40px">
          {children}
        </SimpleGrid>
      </Box>
    </PerfectScrollbar>
  ) : (
    <SimpleGrid columns={{ xs: 3, lg: 4, xl: 5 }} spacing={6} {...restProps}>
      {children}
    </SimpleGrid>
  )
);

/**
 * @typedef {{
 *  data: Record<string,any[]>,
 *  onClick: () => void,
 *  selectedBucket: string,
 *  multiSelect: boolean
 * }} BucketsListProps
 */

/** @type {React.NamedExoticComponent<BucketsListProps>} */
const BucketsList = React.memo(({ data, onClick, selectedBucket, multiSelect }) => {
  const { width } = useWindowDimensions();
  const mobile = width < 768;
  return (
    <Box
      bg="white"
      shadow="lg"
      rounded="md"
      alignSelf="start"
      overflow="hidden"
    >
      {/* I must set the size manually because there's a bug related at https://github.com/chakra-ui/chakra-ui/issues/598 */}
      <Tabs
        isFitted
        size={mobile ? "sm" : "md"}
        marginBottom={2}
        variant="enclosed-colored"
      >
        <TabList>
          {Object.keys(data).map((key) => (
            <Tab key={"tab_bk" + key}>{capitalize(key)}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.keys(data).map((key) => (
            <TabPanel key={"tab_v_" + key}>
              <BucketContent multiSelect={multiSelect} padding={6}>
                {versionSort(data[key], { dir: 'DESC' }).map((bucket) => (
                  <BucketBox
                    key={bucket.bucket_name}
                    selected={selectedBucket === bucket.bucket_name}
                    name={
                      !key.includes("live") && key !== "others"
                        ? capitalize(bucket.bucket_name)
                        : bucket.bucket_name
                    }
                    onClick={() => onClick({ ...bucket, type: key })}
                  />
                ))}
              </BucketContent>
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
    </Box>
  );
}
);


export default BucketsList;
