import React, { useState } from "react";
import {
  Box,
  Flex,
  Input,
  Button,
  Switch,
  FormLabel,
  Grid,
  FormControl,
  FormErrorMessage,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";

import "../common/css/errorStyles.css";
import LoadingContainer from "../Layout/LoadingContainer";

const ProfileForm = ({
  FirstName,
  LastName,
  loading,
  onSubmit,
  toggleModal,
}) => {
  const [firstName, setFirstName] = useState(FirstName);
  const [lastName, setLastName] = useState(LastName);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [willUpdatePassword, setWillUpdatePassword] = useState(false);

  const { handleSubmit, register, errors } = useForm({
    reValidateMode: "onBlur",
  });

  const updateProfile = () => {
    const body = {
      firstName,
      lastName,
      ...(willUpdatePassword && { password }),
    };
    onSubmit(body);
  };

  return loading ? (
    <LoadingContainer />
  ) : (
    <Box>
      <Grid templateColumns="repeat(2, 1fr)" gap={6}>
        <FormControl
          className="error-label"
          isInvalid={Boolean(errors.firstName)}
        >
          <FormLabel>First Name</FormLabel>
          <Input
            name="firstName"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            ref={register({
              required: true,
              pattern: /^(?![\s.]+$)[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i,
            })}
          />
          <FormErrorMessage>
            Are you sure this is your real name?
          </FormErrorMessage>
        </FormControl>
        <FormControl
          className="error-label"
          isInvalid={Boolean(errors.lastName)}
        >
          <FormLabel>Last Name</FormLabel>
          <Input
            name="lastName"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            ref={register({
              required: true,
              pattern: /^(?![\s.]+$)[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i,
            })}
          />
          <FormErrorMessage>
            Are you sure this is your real name?
          </FormErrorMessage>
        </FormControl>
        <FormControl>
          <FormLabel display="block">Update Password?</FormLabel>
          <Switch
            isChecked={willUpdatePassword}
            onChange={() => setWillUpdatePassword(!willUpdatePassword)}
          />
        </FormControl>
      </Grid>
      {willUpdatePassword && (
        <Grid templateColumns="repeat(2, 1fr)" gap={6}>
          <FormControl
            className="error-label"
            isInvalid={Boolean(errors.password)}
          >
            <FormLabel>New Password</FormLabel>
            <Input
              name="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              ref={register({
                required: true,
                validate: (value) =>
                  (value && value.length > 5) ||
                  "Your password must be at least 6 characters long",
              })}
            />
            <FormErrorMessage>
              {(errors.password && errors.password.message) ||
                "Please add your new password"}
            </FormErrorMessage>
          </FormControl>
          <FormControl
            className="error-label"
            isInvalid={Boolean(errors.confirmPassword)}
          >
            <FormLabel>Confirm New Password</FormLabel>
            <Input
              name="confirmPassword"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              ref={register({
                required: true,
                validate: (value) =>
                  (value && value === password) ||
                  "The passwords don't match. Please try again.",
              })}
            />
            <FormErrorMessage>
              The passwords don't match. Please try again.
            </FormErrorMessage>
          </FormControl>
        </Grid>
      )}
      <Flex justifyContent="flex-end" marginTop={4}>
        <Button
          onClick={handleSubmit(updateProfile)}
          variantColor="lumenPrimary"
        >
          Update
        </Button>
        <Button marginLeft={4} onClick={toggleModal}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

export default ProfileForm;
