import React from "react";
import { Flex, Heading, PseudoBox } from "@chakra-ui/core";
import "./css/BucketStyles.css";
import * as PropTypes from "prop-types";

const BucketBox = React.memo(({ name, onClick, selected }) => {
  const buttonProps = selected
    ? { bg: "lumenPrimary.400", border: "2px solid", borderColor: "lumenPrimary.400" }
    : { bg: "gray.400" };
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      onClick={onClick}
      className="pointer bucket-box"
    >
      <PseudoBox
        as="button"
        shadow="sm"
        width="100%"
        rounded="sm"
        minHeight="40px"
        {...buttonProps}
      >
        <Heading color="white" as="h6" size="xs">
          {name}
        </Heading>
      </PseudoBox>
    </Flex>
  );
});

BucketBox.propTypes = {
  multiSelect: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
};

export default BucketBox;
