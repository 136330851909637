
import React, { useEffect, useState } from "react";
// reactstrap components
import { Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";
import Swal from "sweetalert2";
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";
import LoadingContainer from "../components/Layout/LoadingContainer.jsx";
import ApiInfo from "../components/Versioning/ApiInfo.jsx";
import ApiMatchModal from "../components/Versioning/ApiMatchModal.jsx";
import authHeader from "../helpers/auth-header.js";
import httpClient from "../helpers/http-client-singleton";

const http = httpClient.getInstance();


/**
 * to be applied on a sort function
 * @param {number} a 
 * @param {number} b 
 * @returns {-1 | 0 | 1 }
 */
 const sortByVersion = (a,b) => {
  if(isNaN(a)) return 1; //send a to the end of the lsit
  if(isNaN(b)) return -1; //send b to the end of the list
  
  const parsedA = Number.parseInt(a);
  const parsedB = Number.parseInt(b);

  //b - a -> descending
  //a - b -> ascending
  return Math.max(-1, Math.min(1, parsedB - parsedA));
  
}

const fetchData = async () => {
  try {
    const result = await http.get("/apimatch/versions", { headers: authHeader() });
    const data = result.data.data;
    return data.reduce((acc,val) => {
      if(acc[val.Platform]) acc[val.Platform][val.Version] = undefined;
      else acc[val.Platform] = {[val.Version]: undefined}
      return acc;
    },{})
  } catch (err) {
    throw err;
  }
};

const fetchVersion = async (platform,version) => {
  try {
    const result = await http.get(`/apimatch/${platform}/versions/${version}`, { headers: authHeader() });
    const data = result.data.data;
    return data;
  } catch (err) {
    throw err;
  }
};

const Timeline = () => {
  const [matchList, setMatchList] = useState({});
  const [modal, setModal] = useState(false);
  const [match, setMatch] = useState({});
  const [modalType, setModalType] = useState("");
  const [loading, setLoading] = useState(true);
  const [update, setUpdate] = useState();

  useEffect(() => {
    fetchData()
      .then((matchs) => {
        
        if(!matchs['ios']) matchs['ios'] = {};
        if(!matchs['android']) matchs['android'] = {};

        const lastVersions = [
           Math.max(0,...Object.keys(matchs?.['ios']).map(val => Number.parseInt(val.trim()))),
           Math.max(0,...Object.keys(matchs?.['android']).map(val => Number.parseInt(val.trim()))),
        ]


        Promise.all([
          lastVersions[0] ? fetchVersion("ios",lastVersions[0]) : undefined,
          lastVersions[1] ? fetchVersion("android",lastVersions[1]) : undefined
        ]).then(([lastIos,lastAndroid]) => { 
           
        if(lastVersions[0]) matchs['ios'][lastVersions[0]] =  lastIos;
        if(lastVersions[1]) matchs['android'][lastVersions[1]] =  lastAndroid;
        
        setMatchList(matchs)
        setLoading(false);
      })  
    })
    .catch();
  }, [update]);


  async function fetchApiVersion(platform, version){
    if(matchList[platform]?.[version]) return;

    setMatchList({...matchList, [platform]: {...matchList[platform], [version]: "LOADING" }});
    
    const data = await fetchVersion(platform,version);
    if(!data) return;

    setMatchList({...matchList, [platform]: {...matchList[platform], [version]: data }});
  }

  const forceUpdate = () => {
    setUpdate({});
  };

  const filterVersionByPlatform = (matchs = [], platform = "") => {
    const versionsArray = matchs.map((x) => {
      return x.Platform === platform ? x.Version : null;
    });
    return [...new Set(versionsArray)].filter((x) => x !== null).sort(sortByVersion);
  };

  const filterByPlatform = (matchs = [], platform = "") => {
    return matchs.filter((x) => x.Platform === platform);
  };

  const toggleModalHandler = (el = {}, type = "") => {
    setMatch(el);
    setModal(!modal);
    setModalType(type);
  };

  const notify = (title = "", type = "", text = "") => {
    Swal.fire({
      title: title,
      type: type,
      text: text,
    }).then(() => {
      forceUpdate();
    });
  };

  return (
    <>
      {modal ? (
        <ApiMatchModal
          modal={modal}
          close={toggleModalHandler}
          a_version={match.Version}
          a_buildNumber={match.BuildNumber}
          a_platform={match.Platform}
          a_staticRoute={match.StaticRoute}
          a_contentEnvironment={match.ContentEnvironment}
          a_isStaging={match.IsStaging}
          a_debugger={match.DebuggerBuild}
          a_updateRequired={match.UpdateRequired}
          a_description={match.Description}
          type={modalType}
          notify={notify}
        />
      ) : null}
      <SimpleHeader name="API Versioning" clean={true} parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <Row>
          <Col lg="6">
            <Card>
              <CardHeader className="bg-transparent">
                <h3 className="mb-0">Android Api Information</h3>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {loading ? (
                    <LoadingContainer />
                  ) : (
                    <ApiInfo
                      onClickBadge={fetchApiVersion}
                      matchList={matchList["android"]}
                      callback={toggleModalHandler}
                      platform="android"
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
          <Col lg="6">
            <Card className="bg-gradient-default shadow">
              <CardHeader className="bg-transparent">
                <h3 className="mb-0 text-white">Ios Api Information</h3>
              </CardHeader>
              <CardBody>
                <div
                  className="timeline timeline-one-side"
                  data-timeline-axis-style="dashed"
                  data-timeline-content="axis"
                >
                  {loading ? (
                    <LoadingContainer />
                  ) : (
                    <ApiInfo
                      onClickBadge={fetchApiVersion}
                      matchList={matchList["ios"]}
                      callback={toggleModalHandler}
                      platform="ios"
                    />
                  )}
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Timeline;
