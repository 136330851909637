import React from "react";
import PropTypes from "prop-types";
import { Code, FormControl, FormHelperText } from "@chakra-ui/core";
import FileInput from "../common/FileInput";

const createPlayerListFromFile = (playerListFile) => {
  return playerListFile.text().then((players) =>
    players.split(/\r?\n/).filter(x => !x.startsWith("#")).map(x => x.replace(/(^"|"$)/g, '')));
};

const PlayerListInput = ({ onChange, disabled }) => {
  const onFileChange = (file) => {
    createPlayerListFromFile(file).then((playerList) => onChange(playerList));
  };

  return (
    <FormControl marginTop={4}>
      <Code marginY={4} display="block">
        #File example
        <br />
        be6c5075-0766-4e9d-8727-a51ed7afda08
        <br />
        32bb3e3f-35a6-4bd7-8547-f8e4b978fe3c
        <br />
        19906b0e-3e97-48b9-b50a-d7a12a84f700
        <br />
        45198d05-5bed-4c32-a457-ba920134cd12
      </Code>
      <FileInput disabled={disabled} accept=".txt, .csv" onChange={onFileChange} />
      <FormHelperText>
        File with a list of Player ID's. Only one Player ID
        is allowed per line
      </FormHelperText>
    </FormControl>
  );
};

PlayerListInput.propTypes = {
  onChange: PropTypes.func,
};

export default PlayerListInput;
