import {Box, Flex, Heading, Text } from '@chakra-ui/core';
import React, { useState,useEffect } from 'react';
import SearchInput from '../common/SearchInput';
import GenericTable from '../Table/GenericTable';
import RegattaGroupModal from './RegattaGroupModal';
import { Button } from "reactstrap";
import { userService } from '../../services/user.service';

/**
 * @typedef {{
 *    GuildId: string,
 *    GroupGuid: string,
 *    GuildName: string,
 *    AvaliableMember: string[],
 *    Tasks: string[],
 *    Score: number,
 *    AverageLevel: number,
 *    IsJoinedGroup: boolean,
 *  }} RegattaGuild
 */

/**
 * @typedef {{
 *    GroupId: string,
 *    LevelBounds: [number,number],
 *    Guilds: RegattaGuild[],
 *    MinLevel: number,
 *    MaxLevel: number,
 *    LevelOffset: number,
 *  }} RegattaGroup
 */

/** @typedef {import('./RegattaTimeline').Regatta} Regatta */
/** @template T @typedef {import('../Table/GenericTable').TableKey<T>} Key<T> */
/** @type {(onSelectGroup: (g: RegattaGroup) => void) => Key<RegattaGroup>[]} */
const makeKeys = (onSelectGroup) => {
  return ([
    {
      key: 'GroupId',
      bodyOptions:{
        render: (group) => 
        (<Text 
          as="a"
          cursor="pointer"
          className="text-primary"
          onClick={() => onSelectGroup(group)} 
          color="var(--primary)" >
            {group.GroupId}
        </Text>),
      },
      headerOptions: { title:  "Id" },
    },
    {
      key: 'LevelOffset',
      headerOptions: { title:  "Level Offset" },
    },
    {
      key: 'MinLevel',
      headerOptions: { title:  "Minimum Level" },
    },
    {
      key: 'MaxLevel',
      headerOptions: { title:  "Maximum Level" },
    },
    {
      key: 'Guilds',
      bodyOptions: {transform: (guilds) => <span>{guilds?.length || 0}</span> },
      headerOptions: { title:  "Guild Count" },
    }
  ])
}

/**
 * @typedef {{
 *  loading: boolean,
 *  groups: RegattaGroup[],
 *  regatta: Regatta,
 *  onRemoveFromGroup: (guildId: string, groupId: string) => void,
 *  onExportCsv: (guild?: string, regatta?: string) => void,
 * }} RegattaDetailsListProps
 */


/** @type {React.FC<RegattaDetailsListProps>} */
const RegattaDetailsGroupList = ({loading,groups,regatta, onRemoveFromGroup, onExportCsv}) => {
  const [search,setSearch] = useState("");
  const [selectedGroup,setSelectedGroup] = useState(undefined);

  /** @param {RegattaGroup} group */
  const handleSelectGroup = (group) => {
    setSelectedGroup(group);
  }

  useEffect(() => {
    if(groups?.find(x => x?.GroupId === selectedGroup?.groupId)) return;
    setSelectedGroup(undefined);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[groups])

  return (
    <Box backgroundColor="#fff" fontFamily="Open Sans">
    <Flex alignItems="center" justifyContent="space-around">
      <Heading
        size="sm"
        marginY={4}
        color="#525f7f"
        marginLeft={6}
        marginRight={12}
      >
        Groups
      </Heading>
      <Flex flex={1} alignItems="center">
        <SearchInput
          onSearch={(search) => setSearch(search)}
          onClear={() => setSearch("")}
          className="flex-grow-1"
          placeholder="Group ID"
        >
          <Button 
          disabled={!userService.hasPermission("EXPORT_DATA")}
          onClick={() => onExportCsv()} 
          size="sm"
          >
            Export all guilds
          </Button>
        </SearchInput>
  
      </Flex>
    </Flex>
    <GenericTable.Table 
      data={groups ? (search ? groups.filter(x => x.GroupId.includes(search)) : groups) : []} 
      keys={makeKeys(handleSelectGroup)} 
    />
    <RegattaGroupModal
      modalOpen={!!selectedGroup}
      onCancel={() => setSelectedGroup(undefined)}
      onRemoveGuild={onRemoveFromGroup}
      loading={loading}
      regatta={regatta}
      group={selectedGroup || {}}    
    />
  </Box>
  )
}

export default RegattaDetailsGroupList;