import React from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Collapse,
  Container,
  Row,
  Table,
} from "reactstrap";
import PerfectScrollbar from "react-perfect-scrollbar";
import chunkArray from "../../../helpers/chunk";
import { Link } from "react-router-dom";
import { FormattedRelative } from "react-intl";
import moment from "moment";
import PlayerOptionsDropdown from "../PlayerOptionsDropdown";
import Highlighted from "./Highlighted";
import FavoriteButton from "../../Favorites/FavoriteButton";

import {
  addToFavorites,
  removeFromFavorites,
  isFavorite,
} from "../../../services/favorites.service";

const DEVICES_PER_ROW = 3;

/**
 * @typedef {{
 *  players: any[],
 *  searchValue: string | number,
 *  updatePage: () => void,
 *  selectedPlayer: string  | number,
 *  onSort: (field: string) => void,
 *  sortKey: string,
 *  sortDir: 'asc' | 'desc',
 *  handleSelectPlayer: (player: any) => void,
 * }} PlayerTableProps
 */

/** @type {React.FC<PlayerTableProps>} */
const PlayerTable = ({
  players,
  searchValue,
  updatePage,
  selectedPlayer,
  sortKey,
  sortDir,
  onSort,
  handleSelectPlayer,
}) => {
  const createPlayerRow = (player, index) => {
    const content =
      player["Devices"] &&
      player["Devices"].map((device) => (
        <Col xs={12} lg={4} className="mb-2">
          <div className="p-2 ml-1">
            <Highlighted highlight={searchValue} text={device} />
          </div>
        </Col>
      ));
    let rows = [];
    if (content) rows = chunkArray(content, DEVICES_PER_ROW);
    return (
      <>
        <tr key={"row" + index} onClick={() => handleSelectPlayer(index)}>
          <td key={player["PlayerId"] || player["PlayerId"] + "-did"}>
            {player["isNew"] && (
              <Button
                color="primary"
                href="#addrandom"
                onClick={(e) => e.preventDefault()}
                size="sm"
              >
                NEW
              </Button>
            )}
            <Link className="text-primary" to={"player/" + player["PlayerId"]}>
              {player["PlayerId"]}
            </Link>
          </td>
            <td key={"nick-" + player["PlayerId"]}>{player["Nickname"] || <strong>{"<NULL>"}</strong>}</td>
          <td key={player["PlayerId"] + "-cin"}>
            <FormattedRelative
              value={moment.utc(player["CreationDate"]).local()}
            />
          </td>

          <td key={player["PlayerId"] + "-lang"}>{player["Language"] || <strong>{"<NULL>"}</strong>}</td>
          <td key={player["PlayerId"] + "-vtd"}>
            $
            {player["TotalSpent"] === undefined ||
            Number(player["TotalSpent"]) < 0.01
              ? "0"
              : player["TotalSpent"].toFixed(2)}
          </td>
          <td className="text-right" onClick={(e) => e.stopPropagation()}>
            <FavoriteButton
              isFavorite={isFavorite(player["PlayerId"], "players")}
              addFavorite={() => addToFavorites(player["PlayerId"], "players")}
              removeFavorite={() =>
                removeFromFavorites(player["PlayerId"], "players")
              }
            />
            <Button
              size="md"
              className="btn-icon btn-device"
              onClick={() => {
                handleSelectPlayer(index);
              }}
              color="neutral"
              type="button"
            >
              <span className="btn-inner--icon">
                {selectedPlayer === index ? (
                  <i className="fas fa-level-up-alt" />
                ) : (
                  <i className="fas fa-mobile-alt" />
                )}
              </span>
            </Button>
            <PlayerOptionsDropdown
              className="btn-icon-only text-light"
              updateFunc={updatePage}
              notify={() => {}}
              playerId={player["PlayerId"]}
            >
              <i className="fas fa-ellipsis-v" />
            </PlayerOptionsDropdown>
          </td>
        </tr>
        <tr>
          <td colSpan={5} className="p-0">
            <Collapse isOpen={selectedPlayer === index && rows.length > 0}>
              <Card className="mb-0 no-box-shadow">
                <CardBody className="px-0 py-3">
                  <Container fluid>
                    {rows &&
                      rows.map((row, index) => (
                        <>
                          <Row>{row}</Row>
                          {index < rows.length - 1 && <hr className="my-0" />}
                        </>
                      ))}
                  </Container>
                </CardBody>
              </Card>
            </Collapse>
          </td>
        </tr>
      </>
    );
  };

  return (
    <PerfectScrollbar>
      <Table
        hover
        className="align-items-center table-flush"
        striped
        responsive
      >
        <thead className="thead-light">
          <tr>
            <th 
              scope="col" 
              onClick={() => onSort("playerId")} 
              className={"cursor-pointer sort"} 
              data-sort={sortKey === "playerId" || undefined}
              data-sort-order={sortDir}
              >
              PLAYER ID
            </th>
            <th 
              scope="col" 
              onClick={() => onSort("nickname")} 
              className={"cursor-pointer sort"} 
              data-sort={sortKey === "nickname" || undefined}
              data-sort-order={sortDir}
              >
              Nickname
            </th>
            <th 
              scope="col" 
              onClick={() => onSort("creationDate")}
              className={"cursor-pointer sort"}
              data-sort={(!sortKey || sortKey === "creationDate") || undefined}
              data-sort-order={sortDir}
            >
              CREATED IN
            </th>
            <th
              scope="col"
              onClick={() => onSort("language")}
              className={"cursor-pointer sort"}
              data-sort={sortKey === "language" || undefined}
              data-sort-order={sortDir}
            >
              LANGUAGE
            </th>
            <th 
              scope="col"
              onClick={() => onSort("totalspent")}
              className={"cursor-pointer sort"}
              data-sort={sortKey === "totalspent" || undefined}
              data-sort-order={sortDir}
            >
              TOTAL SPENT
            </th>
            <th scope="col" />
          </tr>
        </thead>
        <tbody>
          {players.map((player, index) => createPlayerRow(player, index))}
        </tbody>
      </Table>
    </PerfectScrollbar>
  );
};

export default PlayerTable;
