import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const ProfileModal = ({ open, toggle, children }) => {
  return (
    <Modal isOpen={open} toggle={toggle} centered>
      <ModalHeader toggle={toggle}>Update Profile</ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  );
};

export default ProfileModal;
