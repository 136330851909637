import React from "react";
import { Flex, Heading, Box, PseudoBox, Text } from "@chakra-ui/core";
import "../css/playerDebuggerStyles.css";

const DevicesList = React.memo(({ devices, ...restProps }) => {
  return (
    <Flex direction="column">
      <Heading textAlign="center" size="sm">
        Player Device ID's
      </Heading>
      <Box bg="white" spacing={3} {...restProps}>
        {devices.map((device) => (
          <PseudoBox
            key={device.DeviceId}
            display="flex"
            paddingX={8}
            paddingY={2}
            _hover={{ bg: "#edf2f7", cursor: "default" }}
            alignItems="center"
          >
            <i className="fas fa-mobile-alt my-3" />
            <Text
              lineHeight={1}
              marginLeft={3}
              marginBottom={0}
              overflowWrap="anywhere"
            >
              {device.DeviceId}
            </Text>
          </PseudoBox>
        ))}
      </Box>
    </Flex>
  );
});

export default DevicesList;
