import React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import RegattaDefinitionForm from './RegattaDefinitionForm';

/**
 * @typedef {import('./RegattaDefinitionsList').RegattaDefinition} RegattaDefinition;
 * 
 * @typedef {{
 *   modalOpen: boolean,
 *   loading: boolean,
 *   disabled: boolean,
 *   onCancel: () => void,
 *   onSubmit: (data: RegattaDefinition) => void,
 *   regattaDefinition: RegattaDefinition
 * }} RegattaDefinitionModalProps
 */

/**
 * @type {React.FC<RegattaDefinitionModalProps>}*/
const RegattaDefinitionModal = ({modalOpen,loading,onCancel,onSubmit,disabled, regattaDefinition}) => {
  if(!modalOpen) return <></>;
  
  return (
    <Modal isOpen={modalOpen} toggle={onCancel}>
    <ModalHeader>
      {disabled ? "" : regattaDefinition?.Id ? "Clone" : "Create"} Regatta Definition
    </ModalHeader>
    <ModalBody>
      {loading ? (
        <LoadingContainer />
      ) : (
        <RegattaDefinitionForm 
          disabled={disabled} 
          regattadefinitionData={regattaDefinition}  
          onCancel={onCancel} 
          onSubmit={disabled ? undefined : onSubmit}
        />
      )}
    </ModalBody>
  </Modal>
  );
}

export default RegattaDefinitionModal;