import React, { useMemo } from "react";
import SearchInput from "../common/SearchInput";
import { Button, CardBody, Col, Row, Table } from "reactstrap";

import classnames from "classnames";

import "./css/playerPersistenceStyles.css";
import ErrorBox from "../common/ErrorBox";
import { userService } from "../../services/user.service";


/** @type {React.FC<{persistence: import("../Layout/ModalJson").JsonProp[]}>} */
const PlayerPersistence = React.memo(
  ({
    updatePlayerPersistence,
    persistence,
    searchedKey,
    onSearch,
    onClear,
    exportJson,
    toggleJsonModal,
    toggleImportModal,
    toggleDeletedKey
  }) => {
    const filtered_persistence = useMemo(() => persistence.filter((el) => el.Key.includes(searchedKey)), [persistence, searchedKey]);
    const buttonEnabled = useMemo(() => persistence.some((el) => el.Updated), [persistence]);

    const CAN_EDIT_PLAYER_PERSISTENCE = userService.hasPermission("DELETE_UPDATE_PLAYER_PERSISTENCE");
    const CAN_EXPORT = userService.hasPermission("EXPORT_DATA");

    return (
      <CardBody className="player-scroll h-100 rounded-bottom">
        <Row className="pb-2" >
          <Col xs="12" xl="6">
            <SearchInput
              placeholder="Persistence key"
              onSearch={onSearch}
              onClear={onClear}
            />
          </Col>
          <Col md="2" xs="12">
            <Row className="float-md-right">
              <div className="btnGrid">
                <Col xs="3">
                  <Button
                    color="primary"
                    outline={!buttonEnabled}
                    disabled={!buttonEnabled}
                    onClick={updatePlayerPersistence}
                    size="sm"
                  >
                    Save
                  </Button>
                </Col>
                <Col xs="3">
                  <div style={{ display: 'flex' }}>
                    <Button
                      color="primary"
                      outline={!CAN_EXPORT}
                      disabled={!CAN_EXPORT}
                      onClick={exportJson}
                      size="sm"
                    >
                      Export .json
                    </Button>
                    <Button
                      color="secondary"
                      outline={!CAN_EXPORT}
                      disabled={!CAN_EXPORT}
                      onClick={toggleImportModal}
                      size="sm"
                    >
                      Import .json
                    </Button>
                  </div>
                </Col>
              </div>
            </Row>
          </Col>
        </Row>
        <Row className="pb-4">
          <Col>
            <Button
              color="primary"
              onClick={() => toggleJsonModal("NewKey")}
              size="sm"
            >
              Add Key
            </Button>
          </Col>
        </Row>
        {filtered_persistence.length > 0 ? (
          <Row className="player-persistence-card">
            <Table hover className="align-items-center table-flush" size="sm">
              <thead className="thead-light">
                <tr>
                  <th scope="col">Edit</th>
                  <th className="sort" data-sort="name" scope="col">
                    Key
                  </th>
                  <th className="sort" data-sort="budget" scope="col">
                    Value
                  </th>
                </tr>
              </thead>
              <tbody className="list">
                {filtered_persistence.map((data, index) => (
                  <tr key={index} className="table-persistence-info">
                    <td>
                      <Button
                        className="btn-icon btn-2"
                        size="sm"
                        color="secondary"
                        outline
                        type="button"
                        disabled={!CAN_EDIT_PLAYER_PERSISTENCE}
                        onClick={() => toggleJsonModal(data.Key)}
                      >
                        <span className="btn-inner--icon">
                          <i className="ni ni-settings-gear-65" />
                        </span>
                      </Button>
                      <Button
                        className="btn-icon btn-2"
                        size="sm"
                        color="danger"
                        outline
                        type="button"
                        disabled={!CAN_EDIT_PLAYER_PERSISTENCE && !data.Deleted}
                        onClick={() => toggleDeletedKey(data.Key, !data.Deleted)}
                      >
                        <span className="btn-inner--icon">
                          <i className="fas fa-trash" />
                        </span>
                      </Button>
                    </td>
                    <td
                      className={classnames({ "font-weight-bold": data.Updated })}
                      style={{ color: data.Deleted ? "red" : undefined }}
                    >
                      {data.Key}
                    </td>
                    <td>
                      <code>{!data.Deleted && data.Value}</code>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Row>
        ) : (
          <ErrorBox className="h-100">
            This player doesn't have any persistence's.
          </ErrorBox>
        )}
      </CardBody>
    );
  }
);

export default PlayerPersistence;
