import React from "react";
import { FormattedRelative } from "react-intl";
import {
  Table,
  Col,
  FormText,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import authHeader from "../../helpers/auth-header.js";
import LoadingContainer from "../Layout/LoadingContainer.jsx";
import SimplePaginationComponent from "../common/SimplePaginationComponent.jsx";
import httpClient from "../../helpers/http-client-singleton";

const http = httpClient.getInstance();

export default class SnapshotModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      tableData: [],
      deviceIdSearched: "",
      selectedKey: null,
      loading: true,
      description: this.props.s_desc,
      objectsPerPage: 15,
      currentPage: 0,
    };
  }

  componentDidMount() {
    if (this.props.type === "load") {
      this.listUsersPage(0);
    }
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  updateSnapshot(e) {
    e.preventDefault();
    const data = {
      key: this.props.s_key,
      playerId: this.props.playerId,
      description: this.state.description,
    };
    this.setState({
      description: "",
    });
    if (this.props.loading) {
      this.props.loading(true);
    }
    http
      .put("/snapshots/update", data, {
        headers: authHeader(),
      })
      .then(() => {
        if (this.props.loading) {
          this.props.loading(false);
        }
        this.props.notifySuccess();
        this.props.update();
      });
    this.props.close();
  }

  setStatePages = (number) => {
    if (number !== this.state.totalPages) {
      this.setState({ totalPages: number });
    }
  };

  listUsersPage = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
        loading: true,
      },
      () => {
        http
          .get("/players/listing/", {
            params: {
              skip: this.state.objectsPerPage * this.state.currentPage,
              take: this.state.objectsPerPage,
              deviceIdFilter: this.state.deviceIdSearched,
            },
            headers: authHeader(),
          })
          .then((response) => {
            this.setState({
              tableData: response.data.data.Result,
              totalObjects: response.data.data.Total,
              loading: false,
            });
          });
      }
    );
  };

  loadSnapshot = (playerId) => {
    this.setState({
      loading: true,
    });
    if (this.props.loading) {
      this.props.loading(true);
    }
    const body = {
      m_snapshotKey: this.props.s_key,
      m_snapshotEnvironment: localStorage.getItem("env"),
    };
    http
      .put(`/players/${playerId}/snapshots/`, body, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          if (this.props.loading) {
            this.props.loading(false);
          }
          this.props.update();
          this.props.notifySuccess();
        }
      });
    this.props.close();
  };

  renderTable(tableData) {
    return (
      <Table hover className="align-items-center" striped responsive size="sm">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="sort col-1">
              #
            </th>
            <th scope="col" className="sort" data-sort="playerId">
              PLAYER ID
            </th>
            <th scope="col" className="sort" data-sort="creationDate">
              CREATED IN
            </th>
            <th scope="col" className="sort" data-sort="language">
              LANGUAGE
            </th>
            <th scope="col" className="sort" data-sort="valueToDate">
              VALUE TO DATE
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((data, index) => (
            <>
              <tr
                key={"row" + index}
                onClick={() => this.setState({ selectedKey: data["PlayerId"] })}
              >
                <td>
                  <input
                    checked={
                      this.state.selectedKey === data["PlayerId"]
                        ? "checked"
                        : null
                    }
                    key={index}
                    type="radio"
                    name="radio"
                  />
                </td>
                <td
                  key={data["PlayerId"] + "-did"}
                  style={
                    this.state.selectedKey === data["PlayerId"]
                      ? { fontWeight: "bold" }
                      : null
                  }
                  className="playerId"
                >
                  {data["PlayerId"]}
                </td>
                <td
                  key={data["PlayerId"] + "-cin"}
                  style={
                    this.state.selectedKey === data["PlayerId"]
                      ? { fontWeight: "bold" }
                      : null
                  }
                  className="creationDate"
                >
                  {" "}
                  <FormattedRelative
                    value={
                      new Date(data["CreationDate"]).getTime() -
                      new Date().getTimezoneOffset() * 60000
                    }
                  />{" "}
                </td>
                <td
                  key={data["PlayerId"] + "-lang"}
                  style={
                    this.state.selectedKey === data["PlayerId"]
                      ? { fontWeight: "bold" }
                      : null
                  }
                  className="language"
                >
                  {data["Language"]}
                </td>
                <td
                  key={data["PlayerId"] + "-vtd"}
                  style={
                    this.state.selectedKey === data["PlayerId"]
                      ? { fontWeight: "bold" }
                      : null
                  }
                  className="valueToDate"
                >
                  $
                  {data["ValueToDate"] === undefined
                    ? 0
                    : data["ValueToDate"].toFixed(2)}
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    );
  }

  render() {
    if (this.props.type === "update") {
      return (
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.props.close}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.close}>Update Snapshot</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Row>
                  <Col md={2}>
                    <Label for="lockedKey">Key</Label>
                    <Input
                      type="email"
                      name="email"
                      disabled="disabled"
                      id="lockedDeviceId"
                      placeholder={this.props.s_key}
                    />
                  </Col>
                  <Col md={10}>
                    <Label for="lockedDeviceId">Device Id</Label>
                    <Input
                      type="email"
                      name="email"
                      disabled="disabled"
                      id="lockedDeviceId"
                      placeholder={this.props.playerId}
                    />
                  </Col>
                </Row>
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  style={{ minHeight: "3rem" }}
                  name="description"
                  onChange={(e) => this.handleChange(e)}
                  id="description"
                  value={this.state.description}
                />
                <FormText>
                  <small
                    style={
                      this.state.description.length > 200
                        ? { color: "red" }
                        : { color: "#8898aa" }
                    }
                  >
                    {200 - this.state.description.length + " characters left"}
                  </small>
                </FormText>
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              {this.state.description.length > 200 ? (
                <h5 style={{ color: "red", display: "initial" }}>
                  {" "}
                  You exceed the limit of 200 characters in the description
                  field.
                </h5>
              ) : null}
              <Button
                color="primary"
                disabled={this.state.description.length > 200}
                onClick={(e) => this.updateSnapshot(e)}
              >
                Update
              </Button>{" "}
              <Button color="secondary" onClick={this.props.close}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    } else if (this.props.type === "load") {
      let contents = this.state.loading ? (
        <LoadingContainer />
      ) : (
        this.renderTable(this.state.tableData)
      );
      const pageNumbers = [];
      //Calculates the total number of pages, this way I can know which one is the last
      if (this.state.totalObjects !== null) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalObjects / this.state.objectsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
        this.setStatePages(pageNumbers.length);
      }
      return (
        <div>
          <div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.props.close}
              className={this.props.className}
              size="lg"
            >
              <ModalHeader style={{ paddingBottom: "0" }}>
                <h1>Load Snapshot into Device</h1>
                <Row>
                  <Col xs="6">
                    <Input
                      maxLength="40"
                      name="deviceIdSearched"
                      onChange={(e) => this.handleChange(e)}
                      innerRef={this.handleInputRef}
                      className="form-control-sm"
                      placeholder="Device ID"
                      type="text"
                      value={this.state.deviceIdSearched}
                    />
                  </Col>
                  <Col style={{ display: "inline-block" }} xs="6">
                    <Button
                      className="btn-neutral"
                      color="default"
                      onClick={() => this.listUsersPage(0)}
                      size="sm"
                    >
                      <i className="fas fa-search" /> Search
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState({ deviceIdSearched: "" }, () => {
                          this.listUsersPage(0);
                        });
                      }}
                      size="sm"
                    >
                      Clear
                    </Button>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody>
                <div style={{ height: "20rem", overflowY: "auto" }}>
                  {contents}
                </div>
                <Row className="pagination align-items-baseline">
                  <Col>
                    <SimplePaginationComponent
                      currentPage={this.state.currentPage}
                      fetchPage={this.listUsersPage}
                      totalObjects={this.state.totalObjects}
                      objectsPerPage={this.state.objectsPerPage}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={this.props.close}
                      size="sm"
                      style={{ float: "right", marginLeft: "0.8rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => this.loadSnapshot(this.state.selectedKey)}
                      color="primary"
                      outline={!this.state.selectedKey}
                      disabled={!this.state.selectedKey}
                      size="sm"
                    >
                      Load
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      );
    }
  }
}
