import React from "react";

// reactstrap components

import { Row, Card, CardBody, Col } from "reactstrap";

class LoadingContainer extends React.Component {
  render() {
    return (
      <Card>
        <CardBody>
          <Row className="justify-content-md-center">
            <Col lg={{ size: "auto" }} className="mt-5 mb-5">
              <button
                className=" btn-icon-clipboard"
                id="tooltip742747005"
                type="button"
              >
                <i className=" ni ni-5x ni-atom spin" />
                <span>Loading...</span>
              </button>
            </Col>
          </Row>
        </CardBody>
      </Card>
    );
  }
}

export default LoadingContainer;
