import React from "react";
import PageContainer from "../common/PageContainer";
import { Box } from "@chakra-ui/core";
import MaulMessageForm from "./MaulMessageForm";

const MaulMessages = React.memo(({ onSubmit,channel,message, loading }) => {
  return (
    <PageContainer pageName="Maul Message">
      <Box
        backgroundColor="white"
        position="relative"
        paddingBottom={4}
        borderRadius="calc(0.375rem - 1px) calc(0.375rem - 1px)"
      >
        <MaulMessageForm
          onSubmit={onSubmit}
          channel={channel} 
          message={message}
          loading={loading}
          padding={8}
          global
        />
      </Box>
    </PageContainer>
  );
});

export default MaulMessages;
