import React from "react";
import md5 from "md5";

export default class Gravatar extends React.Component {
  static displayName = "Gravatar";

  static defaultProps = {
    size: 50,
    rating: "g",
    default: "retro",
    protocol: "//",
  };

  render() {
    const base = "http://www.gravatar.com/avatar/";

    // Gravatar service currently trims and lowercases all registered emails
    const formattedEmail = ("" + this.props.email).trim().toLowerCase();

    let hash = md5(formattedEmail, { encoding: "binary" });

    const src = `${base}${hash}`;

    let className = "react-gravatar";
    if (this.props.className) {
      className = `${className} ${this.props.className}`;
    }

    // Clone this.props and then delete Component specific props so we can
    // spread the rest into the img.
    let { ...rest } = this.props;
    delete rest.md5;
    delete rest.email;
    delete rest.protocol;
    delete rest.rating;
    delete rest.size;
    delete rest.style;
    delete rest.className;
    delete rest.default;

    return (
      <img
        alt={`Gravatar for ${formattedEmail}`}
        style={this.props.style}
        src={src}
        {...rest}
        className={className}
      />
    );
  }
}
