import moment from 'moment';
import React, { useState,useEffect  } from 'react';
import Swal from 'sweetalert2';
import PaginationHeader from '../components/common/PaginationHeader';
import SimplePaginationComponent from '../components/common/SimplePaginationComponent';
import RegattaDefinitionModal from '../components/Regatta/RegattaDefinitionModal';
import RegattaDefinitionsList from '../components/Regatta/RegattaDefinitionsList';
import authHeader from '../helpers/auth-header';
import httpClientCreator from '../helpers/http-client-singleton';
import { csvGenerate } from '../services/export.service';

/**
 * @typedef {{
 *   Id: number ;
 *   DataKey: string ;
 *   CreationDate: string ;
 *   StartDate: number ;
 *   Duration: number ;
 *   Cooldown: number ;
 *   GuildsQuantity: number;
 *   GuildMembersQuantity: number;
 *   GuildLevelOffset: number;
 *   InactivateRegattas: boolean | 0 | 1;
 * }} IRegattaDefinition
*/

/** 
 * @template T
 * @typedef {import('../components/Table/GenericTable').TableKey<T>} TableKey 
 * */


/**
 * @param {IRegattaDefinition} def
 * @returns {IRegattaDefinition}
 */
const makeDefinition = (def) => (
  {
    DataKey: def.DataKey,
    GuildLevelOffset: def.GuildLevelOffset,
    StartDate: moment(def.StartDate).diff(new Date(), 'minutes'),
    Cooldown: def.Cooldown,
    Duration: def.Duration,
    GuildMembersQuantity: def.GuildMembersQuantity,
    GuildsQuantity: def.GuildsQuantity,
    InactivateRegattas: def.InactivateRegattas ? 1 : 0,
  }
)


const http = httpClientCreator.getInstance();

function RegattaDefinitions() {
  const [loading,setLoading] = useState(true);
  const [definitions,setDefinitions] = useState([]);
  //Pagination Props
  const [currentPage,setCurrentPage] = useState(1);
  const [total, setTotal] = useState(0);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [selectedDefinition,setSelectedDefinition] = useState(undefined);
  const [searchVal, setSearchVal] = useState('');

  useEffect(() => {
    async function fetchData(){
      const data = await http.get(`regattas/definitions/listing`, { headers: authHeader(), params:{
        skip: objectsPerPage * (currentPage - 1),
        take: objectsPerPage,
        filter: searchVal
      } })
      .then(x => x.data).catch(e => e);
      
      if(!(data instanceof Error)){
        setDefinitions(data?.data?.Result || []);
        setTotal(data?.data?.Total);
      }else{
        console.log("erro");
      }

      setLoading(false);
    }

    fetchData();
  },[currentPage, objectsPerPage, searchVal])

  /**
   * @param {Partial<IRegattaDefinition>} definition */
  const createDefinition = async (definition) => {
    const newDef = makeDefinition(definition);
    if(newDef.StartDate < 0) throw new Error("Invalid Date");
    setSelectedDefinition(undefined);
    setLoading(true);
    const result = await http.put(`regattas/definitions`,newDef, { headers: authHeader() }).then(x => x.data).catch(e => e).finally(() => setLoading(false));
    if(!result?.data)return;
    
    setDefinitions([result.data,...definitions]);
    Swal.fire({
      type: "success",
      title: "Success",
      text: "Definition Created With Success!",
    });
  }

  /**@param {IRegattaDefinition} def */
  const handleDisableDefinition =  (def) => {
    Swal.fire({
      type: "question",
      title: 'Are you sure?',
      text: "The next regattas will be deactivated.",
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () => http.delete(`regattas/definitions/${def.Id}`, { headers: authHeader() }).catch(() => ({error: true}))
    }).then(async ({value,dismiss}) => {
      if(dismiss) return;
      if(value.error) return;
      setDefinitions(definitions.map(x => x.Id === def.Id ? {...x,InactivateRegattas: true} : x));
    })
  }

  /**
   * 
   * @param {TableKey<IRegattaDefinition>[]} keys 
   */
  const exportAsCsv = (keys) => {
      console.log(csvGenerate(definitions,keys));
  }
  
  const handleObjectsPerPage = (number) => {
    setObjectsPerPage(number);
  };

  return <>
    <RegattaDefinitionsList 
      definitions={definitions} 
      loading={loading}
      onClear={() => setSearchVal('')}
      onSearch={(txt) => setSearchVal(txt)}
      onDisable={(def) => handleDisableDefinition(def)}
      onExportCsv={exportAsCsv}
      headerPanel={
          <PaginationHeader
            total={total}
            skip={currentPage * objectsPerPage}
            take={objectsPerPage}
            onObjectsPerPageChange={handleObjectsPerPage}
          />
      }

      footerPanel={
        <SimplePaginationComponent
        fetchPage={(p) => setCurrentPage(p)}
        currentPage={currentPage}
        objectsPerPage={objectsPerPage}
        totalObjects={total}
      />
      }
      onShowModal={(def) =>  setSelectedDefinition(def || {})}  
    />
    <RegattaDefinitionModal
      modalOpen={selectedDefinition}
      loading={loading}
      disabled={!!selectedDefinition?.Id}
      regattaDefinition={selectedDefinition}
      onCancel={() => setSelectedDefinition(undefined)}
      onSubmit={createDefinition}
    />
  </>;
}

export default RegattaDefinitions;
