import React, { useEffect, useState, useCallback } from "react";
import ProfileComponent from "../components/Profile/Profile";
import Swal from "sweetalert2";

import httpClient from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import ProfileForm from "../components/Profile/ProfileForm";
import ProfileModal from "../components/Profile/ProfileModal";

const http = httpClient.getInstance();

const storageUser = JSON.parse(localStorage.getItem("user"));

const Profile = () => {
  const [user, setUser] = useState(storageUser);
  const [loading, setLoading] = useState(true);
  const [modalLoading, setModalLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => setModalOpen(!modalOpen);

  const getUser = useCallback((email) => {
    setLoading(true);
    http
      .get(`/users/${email}`, { headers: authHeader() })
      .then((response) => {
        setUser(response.data.data);
      })
      .finally(() => setLoading(false));
  }, []);

  const updateProfile = (profile) => {
    setModalLoading(true);
    http
      .put(
        `/users/`,
        { id: user.Id, email: user.Email, ...profile },
        { headers: authHeader() }
      )
      .then(() => {
        Swal.fire({
          title: "Profile Updated",
          text: "Your user profile has been updated successfully",
          type: "success",
          onClose: () => getUser(storageUser.Email),
        });
        setModalOpen(false);
      })
      .finally(() => setModalLoading(false));
  };

  useEffect(() => {
    getUser(storageUser.Email);
  }, [getUser]);

  return (
    <ProfileComponent
      user={user}
      loading={loading}
      toggleModal={toggleModal}
    >
      <ProfileModal open={modalOpen} toggle={toggleModal}>
        <ProfileForm
          FirstName={user.FirstName}
          LastName={user.LastName}
          onSubmit={updateProfile}
          loading={modalLoading}
          toggleModal={toggleModal}
        />
      </ProfileModal>
    </ProfileComponent>
  );
};

export default Profile;
