import React from "react";
import {
  Row,
  Col,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";


/**
 * @type {React.FC<{fetchPage: (i: number) => void, currentPage:number, objectsPerPage: number, totalObjects:number}>}
 */
const SimplePaginationComponent = React.memo(
  ({ currentPage, objectsPerPage, totalObjects, fetchPage }) => {
    const totalPages = Math.ceil(totalObjects / objectsPerPage);

    const generatePages = (number) => {
      const pageItems = [];
      for (
        let i = Math.max(currentPage - number, 0);
        i <= currentPage + number;
        ++i
      ) {
        if (i < totalPages) {
          pageItems.push(
            <PaginationItem key={`page_${i + 1}_${totalPages}`} className={i === currentPage ? "active" : ""}>
              <PaginationLink aria-label={`page ${i + 1}`} onClick={() => fetchPage(i)}>
                {i + 1}
              </PaginationLink>
            </PaginationItem>
          );
        }
      }
      return pageItems;
    };
    if (totalPages > 1) {
      return (
        <Row className="pagination">
          <Col>
            <Pagination>
              {currentPage === 0 ? null : (
                <PaginationItem>
                  <PaginationLink
                    aria-label="First"
                    onClick={() => fetchPage(0)}
                  >
                    <i className="fas fa-angle-double-left" />
                    <span className="sr-only">First</span>
                  </PaginationLink>
                </PaginationItem>
              )}
              {generatePages(3)}
              {currentPage === totalPages - 1 ? null : (
                <PaginationItem>
                  <PaginationLink
                    aria-label="Last"
                    onClick={() => fetchPage(totalPages - 1)}
                  >
                    <i className="fas fa-angle-double-right" />
                    <span className="sr-only">Last</span>
                  </PaginationLink>
                </PaginationItem>
              )}
            </Pagination>
          </Col>
        </Row>
      );
    } else {
      return null;
    }
  }
);


export default SimplePaginationComponent;
