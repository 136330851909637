import React from "react";
import { Table } from "reactstrap";
import { Link } from "react-router-dom";
import EditableColumn from "./EditableColumn";
import ErrorBox from "./ErrorBox";

import { Skeleton } from "@chakra-ui/core";

const createLinkCell = (endpoint, content) => {
  return (
    <Link className="text-primary" to={endpoint}>
      {content}
    </Link>
  );
};

const createEditableCell = (onSubmit, content) => {
  return <EditableColumn onSubmit={onSubmit} defaultValue={content} />;
};

const TableContainer = ({
  loading,
  columns,
  data,
  onSubmit,
  errorMessage,
  className,
}) => {
  const createCell = (column, row, index) => {
    const content = row[column.accessor] || "-";
    if (column.type === "link")
      return createLinkCell(column.endpoint + row[column.accessor], content);

    if (column.type === "editable") {
      return createEditableCell((newValue) => onSubmit(row, newValue), content);
    }
    if (column.type === "component") {
      return column.component(index);
    }
    return content;
  };

  return loading || (data && data.length > 0) ? (
    <Table responsive hover striped className={className}>
      <thead className="thead-light">
        {columns.map((column) => (
          <th>{column.Header}</th>
        ))}
      </thead>
      <tbody>
        {loading ? (
          <>
            <tr>
              {columns.map((column) => {
                return (
                  <td width={column.width}>
                    <Skeleton height="20px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              {columns.map((column) => {
                return (
                  <td width={column.width}>
                    <Skeleton height="20px" />
                  </td>
                );
              })}
            </tr>
            <tr>
              {columns.map((column) => {
                return (
                  <td width={column.width}>
                    <Skeleton height="20px" />
                  </td>
                );
              })}
            </tr>
          </>
        ) : (
          data.map((row, index) => {
            return (
              <tr>
                {columns.map((column) => {
                  return (
                    <td width={column.width}>
                      {createCell(column, row, index)}
                    </td>
                  );
                })}
              </tr>
            );
          })
        )}
      </tbody>
    </Table>
  ) : (
    <ErrorBox>{errorMessage}</ErrorBox>
  );
};


export default TableContainer;
