import React from "react";
import { Button, Col, Row, Table } from "reactstrap";
import PerfectScrollBar from "react-perfect-scrollbar";
import ErrorBox from "../common/ErrorBox";
import DateTimeComponent from "../common/DateTimeComponent";
import Swal from "sweetalert2";
import {useTheme} from "@chakra-ui/core";

const PlayerEvents = ({ events, onDelete, onCreate }) => {
  const theme = useTheme();
  const handleDeleteClick = (eventId) => {
    Swal.fire({
      title: "Remove event from player history?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: () => onDelete(eventId),
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Event deleted successfully!",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "An error occurred when deleting the event.",
        });
      });
  };

  const addEvent = () => {
    Swal.fire({
      title: "Add Player to Event",
      showCancelButton: true,
      input: "text",
      inputAttributes: {
        placeholder: "Event Edition",
        autocapitalize: "off",
      },
      confirmButtonText: "Add",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: (eventId) =>
        onCreate(eventId, () => {
          Swal.showValidationMessage(
            "An error occurred when changing the event id. Are you sure this event exists?"
          );
        }),
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (result.isConfirmed) {
          Swal.fire({
            title: "Event added successfully!",
          });
        }
      })
      .catch(() => {
        Swal.fire({
          title: "An error occurred when adding the event.",
        });
      });
  };

  const content = (
    <PerfectScrollBar className="rounded-bottom">
      <Table
        hover
        className="align-items-center table-flush"
        size="sm"
        responsive
      >
        <thead className="thead-light">
          <tr>
            <th scope="col">Event Name</th>
            <th scope="col">Edition</th>
            <th scope="col">Join Date</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody className="list">
          {events.map((event, index) => (
            <tr key={event["EventId"]}>
              <td className="content">
                <b>{event["Content"] || "-"}</b>
              </td>
              <td key={event["EventId"] + "-cnt"} className="EventId">
                {event["EventId"] || "-"}
              </td>
              <td className="startDate">
                <DateTimeComponent
                  date={event["CreationDate"]}
                  tooltipId={"tooltip" + event["EventId"] + index}
                />
              </td>
              <td>
                <Button
                  size="sm"
                  className="btn-icon"
                  value={event["EventId"]}
                  onClick={(e) => handleDeleteClick(e.currentTarget.value)}
                  color="neutral"
                  type="button"
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-trash" />
                  </span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PerfectScrollBar>
  );

  return (
    <>
      <Row className="flex-column align-items-center my-3 ml-2">
        <Col xs={12}>
          <Button
            className="btn btn-round btn-icon"
            color="primary"
            onClick={addEvent}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-flag" />
            </span>
            <span className="btn-inner--text">Add Player to Event</span>
          </Button>
        </Col>
      </Row>
      {events.length === 0 ? (
        <ErrorBox className="flex-grow-1">
          This player doesn't have any event on his history.
        </ErrorBox>
      ) : (
        content
      )}
    </>
  );
};

export default PlayerEvents;
