import React from "react";
import PropTypes from "prop-types";
import { Grid, SimpleGrid } from "@chakra-ui/core";
import LeaderboardDetails from "./LeaderboardDetails";
import "../../common/css/globalStyles.css";
import "./css/tableStyles.css";

import AttributesList from "./AttributesList";

const LeaderboardGrid = React.memo(
  ({ leaderboardDefinition, eventLoading, event, loading, children }) => {
    return (
      <>
        <Grid
          templateColumns="60% 38%"
          position="relative"
          columnGap={2}
          paddingBottom={6}
        >
          <LeaderboardDetails
            loading={loading}
            leaderboardDefinition={leaderboardDefinition}
            event={event}
            eventLoading={eventLoading}
          />
          <AttributesList
            loading={loading}
            attributes={leaderboardDefinition.AttributeNames}
          />
        </Grid>
        <SimpleGrid
          columns={1}
          position="relative"
          rowGap={10}
          marginBottom={10}
        >
          {children}
        </SimpleGrid>
      </>
    );
  }
);
LeaderboardGrid.propTypes = {
  leaderboardDefinition: PropTypes.object,
  loading: PropTypes.bool,
};

export default LeaderboardGrid;
