import React from "react";
import { Box, Divider, Flex, Heading } from "@chakra-ui/core";
import SingleInfo from "./SingleInfo";
import PerfectScrollbar from "react-perfect-scrollbar";
import "./css/BucketStyles.css";
import CopyButton from "../../common/CopyButton";
import "react-perfect-scrollbar/dist/css/styles.css";
import useWindowDimensions from "../../../helpers/useWindowDimensions";


/** 
 * @typedef {{
 *  date: string;
 *  link: string;
 *  promoted_from?: string | null;
 *  sheet_key?: string;
 *  variant?: string;
 *  zip_url?: string;
 *  name?: string;
 * }} BucketContent
 */
/** @typedef {Record<string,BucketContent>} Bucket */

/** @type {(str: string) => string} */
const getFirstWord = (str) => str ? str.split(" ")[0] : ""

/** @type {(key: string) => string} */
const getIcon = (key) => ({
  "main game": "fas fa-gamepad",
  "localization": "fas fa-language",
  "plot points": "fas fa-comment-dots",
  "event": "fas fa-trophy",
  "android": "fab fa-android",
  "ios": "fab fa-apple"
}[key?.toLowerCase()])

/** @type {React.FC<{items: BucketContent[],header: React.ReactNode, prefix:string}>}*/
const BucketSection = ({ header, items, prefix }) => {
  const { width } = useWindowDimensions();
  const iconSize = width > 1366 ? "fa-2x" : "fa-lg";
  const prfx = getFirstWord(prefix);

  if (!items?.length) return <></>
  return (
    <>
      <Heading as="h5" size="sm" color="gray.400">
        {prfx ? `${prfx}/` : ""}
        {header}
      </Heading>
      <Divider borderColor="gray.400" />
      <Box>
        {items.map((item) => (
          <Flex alignItems="center" paddingY={2}>
            <i className={iconSize + " fa-fw " + getIcon(item.name)} />
            <SingleInfo
              name={item.name + (item.variant ?? "").replace("_", " ").toUpperCase()}
              date={item.date}
              promoted={item.promoted_from}
              link={item.link}
              file_url={item.zip_url}
            />
          </Flex>
        ))}
      </Box>
    </>
  );
};


/** @type {(bucket: Bucket,keys: string[],names: string[]) => BucketContent[]} */
const getContentsWithName = (bucket, keys, names) => Object.keys(bucket)
  .reduce((acc, key) => {
    const tIndex = keys.findIndex(x => key.includes(x));
    if (tIndex === -1) return acc;
    return [...acc, { ...bucket[key], name: names[tIndex] }];
  }, [])


/** 
 * @typedef {{
 *  bucket: Bucket,
 *  prefix: string
 * }} BucketInfoProps
 */

/** @type {React.NamedExoticComponent<BucketInfoProps>} */
const BucketInfo = React.memo(({ bucket, prefix }) => {
  return (
    <div className="data-information-wrapper">
      <PerfectScrollbar>
        <Box
          bg="white"
          shadow="lg"
          rounded="md"
          overflow="hidden"
          alignSelf="start"
          paddingX={4}
          paddingY={2}
        >
          <BucketSection
            header={
              <>
                <span>{bucket.bucket_name}</span>
                <CopyButton text={bucket.bucket_name} />
              </>
            }
            items={getContentsWithName(
              bucket,
              ["main_game", "game_data", "localization", "plot_point", "Event"],
              ["Main Game", "Main Game", "Localization", "Plot Points", "event"]
            )}
            prefix={prefix}
          />
          <BucketSection
            name="Asset Bundles"
            items={getContentsWithName(bucket,
              ["android_assetbundles", "ios_assetbundles"],
              ["Android", "iOS"]
            )}
          />
          <BucketSection
            prefix={prefix}
            bucket={bucket}
            name="Events"
            items={getContentsWithName(bucket, ["event"], ["Event"])}
          />
        </Box>
      </PerfectScrollbar>
    </div>
  );
});


export default BucketInfo;
