import React, { useEffect, useRef, useState } from "react";

import Scheduler, { Resource } from "devextreme-react/scheduler";
import "devextreme/dist/css/dx.common.css";
import "devextreme/dist/css/dx.material.blue.light.css";
import { locale } from "devextreme/localization";

import EventModal from "./EventModal.jsx";
import ConfirmDeleteModal from "./ConfirmDeleteModal.jsx";
import useWindowDimensions from "../../helpers/useWindowDimensions";

import random from "seedrandom";

import "./css/calendar.css";

const EventAppointmentTemplate = (event) =>
  event.appointmentData.text + "#" + event.appointmentData.eventId;

const generateColors = (events) => {
  // Get all events id's
  const ids = [...new Set(events.map((event) => event.eventId))];
  const colors = ["#7e57c2", "#2DCE89", "#ffd740", "#7986cb"];

  return ids.map((id) => ({
    id,
    color: colors[Math.floor(random(id)() * colors.length)],
  }));
};

const Calendar = ({
  day,
  handleDateChange,
  events,
  sendEvent,
  deleteEvent,
  getEventLeaderboards,
  profiles,
  defaultProfileId,
  currentView,
  onCurrentViewChange,
}) => {
  const [colors, setColors] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [modalLoading, setModalLoading] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [eventLeaderboards, setEventLeaderboards] = useState([]);

  const { deviceWidth } = useWindowDimensions();

  const calendarRef = useRef();

  useEffect(() => setColors(generateColors(events)), [events]);
  useEffect(() => {
    if (calendarRef.current)
      calendarRef.current.instance.option("dataSource", events);
  }, [events]);

  useEffect(() => locale("en-GB"), []);

  const views = [
    { type: "day", maxAppointmentsPerCells: "auto" },
    { type: "week", maxAppointmentsPerCells: "auto" },
    { type: "month", maxAppointmentsPerCells: "auto" },
  ];

  const handleAppointmentClick = (e) => {
    e.cancel = true;
    const data = e.appointmentData || e.cellData;
    if (data) {
      setSelectedEvent(data);
      if (data.eventId) {
        getEventLeaderboards(data.eventId).then((leaderboards) =>
          setEventLeaderboards(leaderboards)
        );
      } else {
        setEventLeaderboards([]);
      }
    }
    toggleModalOpen();
  };

  const handleChange = (change) => {
    if (change.name === "currentDate") handleDateChange(change.value);
    if (change.name === "currentView") onCurrentViewChange(change.value);
  };

  const handleDeleteClick = (e) => {
    e.cancel = true;
    setSelectedEvent(e.appointmentData);
    toggleDeleteModalOpen();
  };

  const handleDeleteConfirm = () => {
    setSelectedEvent(null);
    setEventLeaderboards([]);
  };

  const toggleModalOpen = () => {
    setModalOpen(!modalOpen);
  };

  const toggleDeleteModalOpen = () => {
    setDeleteModalOpen(!deleteModalOpen);
  };

  return (
    <>
      <Scheduler
        ref={calendarRef}
        dataSource={events}
        views={views}
        appointmentTemplate={EventAppointmentTemplate}
        onAppointmentFormOpening={(e) => handleAppointmentClick(e)}
        onAppointmentDblClick={(e) => handleAppointmentClick(e)}
        onAppointmentCellClick={(e) => handleAppointmentClick(e)}
        onAppointmentDeleting={(e) => handleDeleteClick(e)}
        onCellDblClick={(e) => handleAppointmentClick(e)}
        currentView={currentView}
        adaptivityEnabled={deviceWidth < 768}
        defaultCurrentDate={day}
        firstDayOfWeek={0}
        onOptionChanged={(change) => handleChange(change)}
        showCurrentTimeIndicator={false}
      >
        <Resource
          dataSource={colors}
          allowMultiple
          fieldExpr="eventId"
          label="Event"
          useColorAsDefault
        />
      </Scheduler>
      <EventModal
        defaultProfile={defaultProfileId}
        profiles={profiles}
        sendEvent={sendEvent}
        deleteEvent={deleteEvent}
        leaderboards={eventLeaderboards}
        modalOpen={modalOpen}
        loading={modalLoading}
        setModalLoading={setModalLoading}
        toggleModalOpen={toggleModalOpen}
        {...{
          ...selectedEvent,
          Content: selectedEvent && selectedEvent.text,
          Id: selectedEvent && selectedEvent.eventId,
          StartDate: selectedEvent && selectedEvent?.startDate,
        }}
      />
      <ConfirmDeleteModal
        open={deleteModalOpen}
        modalToggle={setDeleteModalOpen}
        deleteEvent={deleteEvent}
        id={selectedEvent && selectedEvent.eventId}
        callback={handleDeleteConfirm}
        text={selectedEvent && selectedEvent.text}
      />
    </>
  );
};

export default Calendar;
