import React,{useMemo,useState} from 'react';
import { Container, Button, ButtonGroup } from 'reactstrap';
import SimpleHeader from "../Headers/SimpleHeader";
import TableCard from '../Layout/TableCard';
import classNames from 'classnames';
import RegattaTimeline from './RegattaTimeline';
import RegattaCallendar from './RegattaCalendar';
import { userService } from '../../services/user.service';
import ErrorBox from '../common/ErrorBox';

/**
 * @typedef {import('../../views/Regattas').Regatta} Regatta
 */


/**
 * @typedef {{
 *  regattaGroups: Record<string,Regatta[]>
 *  loading: boolean,
 *  headerPanel?: React.ReactNode,
 *  footerPanel?: React.ReactNode,
 *  onShowModal: (entry: Regatta | {} | undefined) => void,
 *  onFetchEdition: (entry: number) => void,
 *  onCreate: () => void,
 * onExportCsv: () => void,
 *  downloading: boolean,
 * }} RegattaDefListProps
 */
/***
 * @type {React.FC<RegattaDefListProps>} */
const RegattasList = ({ regattaGroups,onFetchEdition,loading,headerPanel,footerPanel,onCreate,onExportCsv,downloading, onShowModal}) => {
    const [tab,setTab] = useState(0);

  /**
   * @type {Regatta[]}*/
  const regattas = useMemo(() => Object.values(regattaGroups || {}).reduce((acc,val) => {
      if(!val) return acc;
      acc = [...acc, ...val];
      return acc;
    },[])
  ,[regattaGroups])

  const page = ([
    (<RegattaTimeline onClickVersion={onFetchEdition} onClickBadge={onShowModal} regattaGroups={regattaGroups}/>),
    (<RegattaCallendar onAptClick={(e) => onShowModal({})} regattas={regattas || []} />)
  ])[tab];
  

  return (
    <>
      <SimpleHeader name="Regatta Timeline" clean parentName="Dashboard">
      <ButtonGroup className="float-right">
          <Button
          style={{marginRight: '1rem'}}
          className="btn-neutral btn-round float-right btn-icon"
          color="default"
          size="sm"
          onClick={() => onCreate?.()}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-trophy" />
          </span>
          <span className="btn-inner--text">Create Regatta</span>
        </Button>
        {userService.hasPermission("EXPORT_DATA") && <Button
          style={{marginRight: '1rem'}}
          className="btn-neutral btn-round float-right btn-icon"
          color="default"
          size="sm"
          disabled={downloading || !userService.hasPermission("EXPORT_DATA")}
          onClick={() => onExportCsv?.()}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-file-download" />
          </span>
          <span className="btn-inner--text">Export All Groups to .csv</span>
        </Button>}
          <Button
            className={classNames("toggle-buttons btn-secondary", {
              active: tab === 0,
            })}
            onClick={() => setTab(0)}
          >
            <i className="ni ni-bullet-list-67" />
          </Button>
          <Button
            className={classNames("toggle-buttons btn-secondary", {
              active: tab === 1,
            })}
            onClick={() => setTab(1)}
          >
            <i className="ni ni-calendar-grid-58" />
          </Button>
        </ButtonGroup>
      </SimpleHeader>
    <Container fluid className="mt--6" style={{padding: '1rem'}}>
    <TableCard loading={loading} header={headerPanel || <br/>}  footer={footerPanel || <br/>}>
        {
          (tab === 0 && !regattas.length) 
          ? (<ErrorBox>{"No Regattas Found."}</ErrorBox>)
          : page
        }
    </TableCard>
    </Container>
    </>
  );
}

export default RegattasList;