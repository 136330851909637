import { Box, Flex, Heading, Skeleton, Text } from "@chakra-ui/core";
import { Table } from "reactstrap";
import PropTypes from "prop-types";
import React, { useCallback } from "react";
import DateTimeComponent from "../../common/DateTimeComponent";
import SimplePaginationComponent from "../../common/SimplePaginationComponent";
import SearchInput from "../../common/SearchInput";

import "../../common/css/globalStyles.css";
import "./css/tableStyles.css";
import moment from "moment";

const LoadingLeaderboards = (
  <>
    <tr>
      <td width="60%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
    <tr>
      <td width="60%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
    <tr>
      <td width="60%">
        <Skeleton height="20px" />
      </td>
      <td width="30%">
        <Skeleton height="20px" />
      </td>
      <td width="10%">
        <Skeleton height="20px" />
      </td>
    </tr>
  </>
);

/** 
 * @type {React.FC<{
 *  leaderboards: any[],
 *  loading: boolean,
 *  total: number,
 *  currentPage: number,
 *  getLeaderboards: (page: number) => void,
 *  searchLeaderboardByKey: (key: string) => void,
 *  onClick: (key: string) => void, 
 * }>} 
 * */
const LeaderboardsList = React.memo(
  ({
    leaderboards,
    loading,
    total,
    currentPage,
    getLeaderboards,
    searchLeaderboardByKey,
    onClick,
  }) => {
    const onSearch = (key) => searchLeaderboardByKey(key);
    const onClear = useCallback(() => getLeaderboards(0), [getLeaderboards]);

    return (
      <Box
        minHeight={300}
        backgroundColor="#fffdfd"
        overflow="auto"
        borderRadius="md"
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Heading size="sm" p={4} color="#525f7f">
            Leaderboards
          </Heading>
          <Box flexGrow={1} paddingLeft={24}>
            <SearchInput
              onSearch={onSearch}
              onClear={onClear}
              placeholder="Leaderboard Key"
            >
       
            </SearchInput>
          </Box>
          <Text fontWeight={600} padding={4} marginBottom={0}>
            Total: {total}
          </Text>
        </Flex>
        <Table striped responsive hover>
          <thead>
            <tr>
              <th>Key</th>
              <th>Creation Date</th>
              <th>Deleted</th>
            </tr>
          </thead>
          <tbody className="cursor-pointer">
            {loading
              ? LoadingLeaderboards
              : leaderboards.sort((a,b) => moment(b.CreationDate).unix() - moment(a.CreationDate).unix()).map((leaderboard, index) => (
                  <tr
                    key={"l" + leaderboard.Key}
                    tabIndex={index}
                    onClick={() => onClick(leaderboard.Key)}
                    className="table-cell"
                  >
                    <td className="text-primary">{leaderboard.Key}</td>
                    <td>
                      <DateTimeComponent
                        tooltipId={"leaderboard-key-" + leaderboard.Key}
                        date={leaderboard.CreationDate}
                      />
                    </td>
                    <td>
                      <i
                        className={
                          leaderboard.Deleted
                            ? "fa fa-check text-success"
                            : "fas fa-times text-gray"
                        }
                      />
                    </td>
                  </tr>
                ))}
          </tbody>
        </Table>
        <Box paddingX={4}>
          <SimplePaginationComponent
            totalObjects={total}
            objectsPerPage={15}
            fetchPage={getLeaderboards}
            currentPage={currentPage}
          />
        </Box>
      </Box>
    );
  }
);

LeaderboardsList.propTypes = {
  onClick: PropTypes.func,
  total: PropTypes.number,
  currentPage: PropTypes.number,
  getLeaderboards: PropTypes.func,
  loading: PropTypes.bool,
  leaderboards: PropTypes.array,
};

export default LeaderboardsList;
