import React, { useState } from "react";
import httpClientCreator from "../helpers/http-client-singleton";
import MaulMessagesComponent from "../components/Messages/MaulMessages";
import useQuery from "../helpers/useQuery";
import { useUser } from "../context/UserContext";

const http = httpClientCreator.getInstance();

const MaulMessages = () => {
  const [loading, setLoading] = useState(false);
  const {user} = useUser();

  const query = useQuery();

  const sendMessage = (data, onSuccess, onError) => { 
    setLoading(true);   
    const dto = {
      Content: data.message,
      From: user.Id
    }

    http
      .put(`/maulmessages/${data.channel}/`, dto)
      .then(onSuccess)
      .then(() => setLoading(false))
      .catch(onError);
  };


  return (
    <MaulMessagesComponent
      onSubmit={sendMessage}
      loading={loading}
      channel={query.get("channel") ?? ""}
    />
  );
};

export default MaulMessages;
