import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.jsx";
import AdminFooter from "components/Footers/AdminFooter.jsx";
import Sidebar from "components/Sidebar/Sidebar.jsx";
import NotificationAlert from "react-notification-alert";
import makeRoutes from "../routes.js";
import {consolePreferenceContext} from '../context/ConsolePreferenceContext'
class Admin extends React.Component {
  state = {
    sidenavOpen: true,
    routes: []
  };

  componentDidMount() {
    // Close the sidebar when the app is loaded
    if (
      window.innerWidth < 1200 &&
      document.body.classList.contains("g-sidenav-pinned")
    ) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
      this.setState({ sidenavOpen: false });
    }
  }

  componentDidUpdate(e) {
    if (e.history.pathname !== e.location.pathname) {
      document.documentElement.scrollTop = 0;
      document.scrollingElement.scrollTop = 0;
      this.refs.mainContent.scrollTop = 0;
    }
  }

  setEnvironment = (ev) => {
    if (ev !== this.state.environment) {
      this.setState({ environment: ev });
    }
  };
  getEnvironment = () => {
    return this.state.environment;
  };

  /*notify = (ev) => {
        let options = {
            place: "tr",
            message: (
                <div className="alert-text">
                    <span className="alert-title" data-notify="title"> Environment Changed!</span>
                    <span data-notify="message">{ev + ' is the current environment.'}</span>
                </div>
            ),
            type: environmentTypeColor(),
            icon: "ni ni-bell-55",
            autoDismiss: 4
        };
        this.refs.notificationAlert.notificationAlert(options);
    };*/

  getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return this.getRoutes(prop.views);
      }
      if (prop.layout === "/acp") {
        return (
          <Route
            path={prop.layout + prop.path + "/:param?"}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  getBrandText = (path,routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };
  // toggles collapse between mini sidenav and normal
  toggleSidenav = () => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    this.setState({
      sidenavOpen: !this.state.sidenavOpen,
    });
  };
  getNavbarTheme = () => {
    return this.props.location.pathname.indexOf(
      "admin/alternative-dashboard"
    ) === -1
      ? "dark"
      : "light";
  };
  render() {
    const disabledFeatures = this?.context?.getPref("DISABLED_FEATURES")?.data;
    const routes = makeRoutes(Array.isArray(disabledFeatures) ? disabledFeatures : []);
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <Sidebar
          {...this.props}
          routes={routes}
          toggleSidenav={this.toggleSidenav}
          sidenavOpen={this.state.sidenavOpen}
          logo={{
            innerLink: "/",
            imgSrc: require("assets/img/brand/argon-react.png"),
            imgAlt: "...",
          }}
        />
        <div
          className="main-content"
          ref="mainContent"
          onClick={this.closeSidenav}
        >
          <AdminNavbar
            {...this.props}
            theme={this.getNavbarTheme()}
            toggleSidenav={this.toggleSidenav}
            sidenavOpen={this.state.sidenavOpen}
            brandText={this.getBrandText(this.props.location.pathname,routes)}
            showBadge={true}
            env={localStorage.getItem("env")}
          />
          <Switch>{this.getRoutes(routes)}</Switch>
          <AdminFooter />
        </div>
        {this.state.sidenavOpen ? (
          <div className="backdrop d-xl-none" onClick={this.toggleSidenav} />
        ) : null}
      </>
    );
  }
}

Admin.contextType = consolePreferenceContext;
export default Admin;
