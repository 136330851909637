import React from "react";
import PropTypes from "prop-types";
import PageContainer from "../../common/PageContainer";
import GuildMembers from "./GuildMembers";
import GuildInfo from "./GuildInfo";

import { Stack, Box } from "@chakra-ui/core";

const membersToTableFormat = (members) =>
  (members &&
    members.map((PlayerId) => ({
      PlayerId,
    }))) ||
  [];

const GuildDetails = React.memo(
  ({ guild, onKickPlayer, onAddPlayer,onTransferLeader, loading }) => {
    return (
      <PageContainer pageName="Guild Details">
        <Stack position="relative">
          <Box>
            <GuildInfo guild={guild} loading={loading} />
          </Box>
          <Box>
            <GuildMembers
              loading={loading}
              members={membersToTableFormat(guild.Members)}
              onKickPlayer={(playerId) => onKickPlayer(guild.Id, playerId, guild.LeaderId)}
              onAddPlayer={(playerId) => onAddPlayer(guild.Id, playerId)}
              onTransferLeader={(playerId => onTransferLeader(guild.Id,playerId))}
              guild={guild}
            />
          </Box>
        </Stack>
      </PageContainer>
    );
  }
);

GuildDetails.propTypes = {
  guild: PropTypes.object,
  onKickPlayer: PropTypes.func,
  onAddPlayer: PropTypes.func,
  loading: PropTypes.bool,
};

export default GuildDetails;
