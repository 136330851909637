import React from "react";

// reactstrap components
import {
  Container,
} from "reactstrap";

import PurchaseComponent from "../components/Purchases/PurchaseComponent.jsx";
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";

export default class PurchasesListing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      data_total: 0,
      searchState: false,
      idSearch: "",
    };
  }

  setTotal = (value) => {
    this.setState({ data_total: value });
  };

  setSearch = (state) => {
    this.setState({
      searchState: state,
    });
  };

  render() {
    return (
      <>
        <SimpleHeader
          name="Purchases List"
          clean={true}
          parentName="Dashboard"
        />
        <Container className="mt--6" fluid>
          <PurchaseComponent
            search={this.state.searchState}
            idSearch={this.state.idSearch}
            totalPurchases={this.setTotal}
            main
          />
        </Container>
      </>
    );
  }
}
