import React, { useCallback, useState } from "react";
import TableCard from "../Layout/TableCard";
import TableContainer from "../common/TableContainer";
import PageContainer from "../common/PageContainer";
import PropTypes from "prop-types";
import SearchInput from "../common/SearchInput";
import { Select, Flex, Box } from "@chakra-ui/core";

const Favorites = ({
  columns,
  favorites,
  categories,
  onCategoryChange,
  updateDescription,
}) => {
  const [search, setSearch] = useState("");

  const onSearch = useCallback((search) => setSearch(search), []);
  const onClear = useCallback(() => setSearch(""), []);

  const header = (
    <Flex>
      <Box flex="50%">
        <SearchInput
          onSearch={onSearch}
          onClear={onClear}
          placeholder="Description"
        />
      </Box>
      <Box flex="50%" display="inline">
        <small>Filter by category:</small>
        <Select
          textTransform="capitalize"
          size="sm"
          marginLeft={4}
          onChange={(e) => onCategoryChange(e.target.value)}
          width="50%"
          rootProps={{ display: "inline-block" }}
        >
          {categories.map((category) => (
            <option value={category} defaultValue="players">
              {category}
            </option>
          ))}
        </Select>
      </Box>
    </Flex>
  );

  const filteredFavorites =
    favorites &&
    favorites.filter((favorite) => favorite.description.includes(search));

  return (
    <PageContainer pageName="Favorites">
        <TableCard header={header}>
          <TableContainer
            columns={columns}
            data={filteredFavorites}
            onSubmit={updateDescription}
            errorMessage="You don't have any favorites yet."
          />
        </TableCard>
    </PageContainer>
  );
};

Favorites.propTypes = {
  columns: PropTypes.object.isRequired,
  favorites: PropTypes.arrayOf(PropTypes.object).isRequired,
};

export default Favorites;
