
/** 
 * @template T
 * @typedef {import('../components/Table/GenericTable').TableKey<T>} TableKey  
 * @typedef {import('../components/Table/GenericTable').ExportOptions<T>} ExportOptions  
 * @typedef {import('../components/Table/GenericTable').BodyOptions<T>} BodyOptions  
 * @typedef {import('../components/Table/GenericTable').HeaderOptions} HeaderOptions  
 * */


/**
 * @template T
 * @typedef {{ 
 *  exportOpt: ExportOptions<T>;
 *  headerOpt: HeaderOptions;
 *  bodyOpt: BodyOptions<T>;
 *  isObj: boolean;
 * }}  ParsedKey
 */

/**
 * @template T
 * @type {(data: T[], keys: TableKey<T>[]) => string}
 */
export function csvGenerate(data,keys){
  /** @type {string[]} */
  const header = keys.reduce((acc,key) => {
    const {exportOpt,headerOpt,isObj} = parseKeyOptions(key);
    const rawTitle = headerOpt?.title  || (isObj ? key.key : key);
    const headerTitle = typeof rawTitle === 'string' ? rawTitle : JSON.stringify(rawTitle);

    if(!exportOpt.ignore) acc.push((headerTitle || ""));
    return acc;
  },[])

  /** @type {string[][]} */
  const body = data.map((row,index) => keys.reduce((columns,key) => {
    const opt = parseKeyOptions(key);
    const column = transformValue(row,key,opt,index,data);
    if(!opt.exportOpt.ignore) columns.push((column));
    return columns;
  },[]));

  const tableData = [header,...body];
  console.table(tableData)
  return tableData.reduce((acc, row) => {

    return acc + "\n" + row.join(";")
  }, "")
}

/**
 * @template T
 * @param {T} row
 * @param {TableKey<T>} key
 * @param {ParsedKey<T>} options
 * @param {number} index
 * @param {T[]} total
 */
function transformValue (row,key,options,index,total){
  if(options.exportOpt?.ignore) return null;
  if(!options.isObj) return row?.[key];
  const raw =  row[key.key];
  
  if(options?.exportOpt?.transform) return options?.exportOpt?.transform(raw); 
  if(options?.exportOpt?.value) return options?.exportOpt?.value(row,index,total); 

  return  typeof raw === 'string' ? raw :  JSON.stringify(raw);
}



/**
 * @template T
 * @param {TableKey<T>} key
 * @returns {ParsedKey<T>}
 */
function parseKeyOptions(key){
  const isObj = typeof key === 'object';

    const exOptions = (isObj ? key.exportOptions || {} : {});
    const hOptions  = (isObj ? key.headerOptions || {} : {});
    /**@type {TableKey<T>["bodyOptions"]} */
    const bOptions  = (isObj ? key.bodyOptions   || {} : {});

    return {exportOpt: exOptions,headerOpt: hOptions,bodyOpt:bOptions,isObj }
}

