import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  FormHelperText,
  Flex,
  Button,
  Select,
} from "@chakra-ui/core";

import PropTypes from "prop-types";

import { useForm } from "react-hook-form";

import "../common/css/errorStyles.css";
import LoadingContainer from "../Layout/LoadingContainer";

const ACCESS_POLICIES = { 1: "Private", 0: "Public" };

const GuildForm = ({
  loading,
  onCancel,
  onSubmit,
  Id,
  Name,
  LeaderId,
  MinLevelRequirement,
  ...restProps
}) => {
  const [name, setName] = useState(Name);
  const [minLevel, setMinLevel] = useState(MinLevelRequirement);
  const [leaderId, setLeaderId] = useState(LeaderId);
  const [accessPolicy, setAccessPolicy] = useState(0);

  const { handleSubmit, errors, register } = useForm({
    reValidateMode: "onBlur",
  });

  const onGuildSubmit = () => {
    const guild = {
      guild: {
        name,
        minLevel,
        leaderId,
        accessPolicy,
      },
    };
    onSubmit(guild);
  };

  useEffect(() => {
    setName(Name);
    setMinLevel(MinLevelRequirement);
    setLeaderId(LeaderId);
  }, [Name, MinLevelRequirement, LeaderId]);

  return loading ? (
    <LoadingContainer />
  ) : (
    <Box backgroundColor="#fff" {...restProps}>
      <Grid templateColumns={{ lg: "60% 35%" }} gap="5%">
        <FormControl isInvalid={Boolean(errors["guild-name"])}>
          <FormLabel>Guild Name</FormLabel>
          <Input
            value={name}
            name="guild-name"
            onChange={(e) => setName(e.target.value)}
            placeholder="Name"
            ref={register({
              required: true,
            })}
          />
          <FormErrorMessage className="error-label">
            This field is required.
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors["min-level"])}>
          <FormLabel>Level Requirement</FormLabel>
          <Input
            name="min-level"
            type="number"
            value={minLevel}
            onChange={(e) => setMinLevel(e.target.value)}
            placeholder="Minimum Level"
            ref={register({
              validate: (value) =>
                value >= 0 || "Minimum Level should be bigger than 0.",
            })}
          />
          <FormErrorMessage className="error-label">
            {errors["min-level"] && errors["min-level"].message}
          </FormErrorMessage>
          <FormHelperText {...restProps}>
            Minimum level required to join the guild.
          </FormHelperText>
        </FormControl>
      </Grid>
      <FormControl isInvalid={Boolean(errors["leader-id"])}>
        <FormLabel>Leader ID</FormLabel>
        <Input
          name="leader-id"
          value={leaderId}
          onChange={(e) => setLeaderId(e.target.value.trim())}
          placeholder="Player ID"
          ref={register({
            required: true,
          })}
        />
        <FormHelperText {...restProps}>Guild leader Player ID.</FormHelperText>
        <FormErrorMessage className="error-label">
          This field is required.
        </FormErrorMessage>
      </FormControl>
      <FormControl marginBottom={4}>
        <FormLabel>Access Policy</FormLabel>
        <Select
          value={accessPolicy}
          onChange={(e) => setAccessPolicy(e.target.value)}
        >
          {Object.keys(ACCESS_POLICIES).map((policyKey) => (
            <option value={policyKey}>{ACCESS_POLICIES[policyKey]}</option>
          ))}
        </Select>
      </FormControl>
      <Flex justifyContent="flex-end">
        <Button
          variantColor="lumenPrimary"
          onClick={handleSubmit(onGuildSubmit)}
        >
          Create Guild
        </Button>
        <Button ml={4} onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

GuildForm.propTypes = {
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  Id: PropTypes.number,
  Name: PropTypes.string,
  LeaderId: PropTypes.string,
  MinLevelRequirement: PropTypes.string,
};

export default GuildForm;
