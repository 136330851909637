import React, { useState, useContext, useEffect, useMemo } from 'react';
import httpClientCreator from "../helpers/http-client-singleton";
import { PERMISSIONS } from '../constants/permissions.js';
import { useCallback } from 'react';
import PerfectScrollbar from "react-perfect-scrollbar";


const http = httpClientCreator.getInstance();

/**
 * @type {React.Context<{
 *    user: Record<string,any>; 
 *    isLogged: boolean;
 *    isAdmin: boolean;
 *    hasPermission: (permission: string | number) => boolean;
 *    login: (email: string, password: string) => Promise<Record<string,any>>;
 *    logout: () => Promise;
 *  }>}
 */
export const userContext = React.createContext({ user: undefined, isLogged: false });

/**@type {React.FC} */
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(undefined);

  function handleSetUser(usr) {
    httpClientCreator.setAuth(usr?.Token);
    setUser(usr);
  }


  useEffect(() => {
    const userJson = localStorage.getItem('user');
    if (!userJson || typeof userJson !== 'string') return;

    handleSetUser(JSON.parse(userJson))
  }, []);

  const logout = useCallback(() => {
    localStorage.removeItem('user');
    handleSetUser(undefined);
  }, [])

  const hasPermission = useCallback((permission) => {

    if (!user) return false;

    if (user?.Role?.Permissions) return !!user.Role.Permissions.find(x => x.Name === permission || x.Id === permission);

    const userRole = user.RoleId;
    return userRole === 2 || !!(PERMISSIONS[permission]?.includes(userRole));
  }, [user])

  const login = useCallback(async (email, password) => {
    const { user, error } = await http.post("/users/authenticate", { email, password }).then(x => ({ user: x.data.data })).catch(e => ({ error: e }))

    if (error || !user) {
      console.error({ login: error });
      return null;
    }

    localStorage.setItem('user', JSON.stringify(user));
    handleSetUser(user);
    return user;
  }, [])

  const value = useMemo(() => ({
    user,
    isLogged: !!user,
    isAdmin: user?.RoleId === 2,
    hasPermission,
    login,
    logout
  }), [hasPermission, login, logout, user]);

  return <userContext.Provider value={value}>{children}</userContext.Provider>;
}

/**
 * 
 * @returns 
 */
export const useUser = () => {
  const context = useContext(userContext);
  return context;
};