import React, { useState, useCallback } from "react";

import "assets/vendor/nucleo/css/nucleo.css";
import "../Snapshot/css/PopupSnapshot.css";
// reactstrap components
import { Col, Container, Progress, Row } from "reactstrap";

import {
  getPersistenceProperty,
  getPropertyInfo,
  getStageIncompleteGoals,
} from "../../helpers/persistence-helper";

import { formatIdleCurrency } from "../../helpers/currency-format";

import LoadingContainer from "../Layout/LoadingContainer";
import httpClient from "../../helpers/http-client-singleton";
import { useEffect } from "react";

const http = httpClient.getInstance();

/**
 * @typedef {{
 *  Id: string,
 *  Name: string,
 *  JsonPathExpression: string,
 *  PersistenceKeyName: string,
 *  Regex: string,
 *  imagePath?: string, 
 *  ValueType: string
 * }} AttributeConfig
 * 
 * @typedef {{
 *  data: { Configurations: AttributeConfig[] }
 * }} AttrConfigApiReturn
 */

/**
 * @typedef {{
 *  playerId: string,
 *  mobile: boolean,
 *  classStyle: boolean,
 *  loading: boolean,
 *  persistence: any[]
 * }} PersistenceInfoProps
 */

const hasPersistence = (persistence) => persistence && persistence.length > 0
const getCurrentGoals = (currentStage, persistence) => {
  if (hasPersistence(persistence) && currentStage) {
    return getStageIncompleteGoals(persistence, currentStage);
  }
};

const getCurrentStage = (pers, attributes) => {
  // TODO: Update this to work with the any stage attribute
  if (!hasPersistence(pers) || !attributes) return;
  const stageAttribute = attributes.find(({ Name }) => Name === 'current_stage');
  if (!stageAttribute) return;

  const property = getPersistenceProperty(
    pers,
    stageAttribute.PersistenceKeyName
  );

  return getPropertyInfo(
    property,
    stageAttribute.JsonPathExpression
  );
};

/** @type {React.FC<PersistenceInfoProps>} */
export const PersistenceInfo = ({ playerId, mobile, classStyle, loading: loadingExt, persistence, onChange, mainPage }) => {
  /** @type {ReturnType<typeof useState<AttributeConfig[]>>} */
  const [attributes, setAttributes] = useState([]);
  /** @type {ReturnType<typeof useState<any[]>>} */
  const [goals, setGoals] = useState([]);
  /** @type {ReturnType<typeof useState<any[]>>} */
  const [newGoals, setNewGoals] = useState([]);
  /** @type {ReturnType<typeof useState<boolean[]>>} */
  const [checkBtn, setCheckBtn] = useState([]);
  /** @type {ReturnType<typeof useState<boolean>>} */
  const [loadingInt, setLoading] = useState(true);

  const loading = loadingExt || loadingInt;

  const handleChange = (stageInfo = "", goalsInfo = "") => {
    onChange(stageInfo, goalsInfo);
  };

  const getAttributes = async () => {
    /**
    * @type {AttrConfigApiReturn | undefined} */
    const gameConfig = await http.get("/attributes/configurations").then((x) => x.data).catch(() => undefined);

    if (!gameConfig) {
      //TODO: handle error;
      return;
    }
    setAttributes(gameConfig.data.Configurations)
  };

  const getGameConfigurationAttributes = async () => {
    await getAttributes();
    getPlayerProgression();
  };


  const getPlayerProgression = useCallback(() => {
    const currentStage = getCurrentStage();
    const goals = getCurrentGoals(currentStage, persistence);
    setGoals(goals);
  }, [persistence]);


  useEffect(() => {
    getPlayerProgression();
  }, [getPlayerProgression, persistence])

  useEffect(() => {
    getGameConfigurationAttributes().finally(() => setLoading(false))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  /** @param {AttributeConfig} attribute      */
  const getAttributeFromGroup = (attribute) => {
    if (!hasPersistence(persistence) || !attribute) return null;

    const property = getPersistenceProperty(
      persistence,
      attribute.PersistenceKeyName
    );

    const persistenceValue = getPropertyInfo(
      property,
      attribute.JsonPathExpression
    );

    return {
      value: isNaN(persistenceValue)
        ? persistenceValue
        : formatIdleCurrency(persistenceValue),
      imageUrl: attribute.imageUrl,
      name: attribute.Name,
    };
  };

  const handleCheckbox = (target) => {
    let inputIndex = parseInt(target.id.split("customCheck")[1]);
    let auxChecked = checkBtn;
    let auxGoals = [];
    let auxOldData;

    if (target.checked) {
      auxChecked[inputIndex] = 1;

      let auxGoalsValue = JSON.parse(
        goals[inputIndex].Value
      );

      auxGoals[inputIndex] = {
        playerId: playerId,
        key: goals[inputIndex].Key,
        value: {
          _timeCreated: auxGoalsValue._timeCreated,
          _timeModified: auxGoalsValue._timeModified,
          _progression: auxGoalsValue._targetProgression,
          _targetProgression: auxGoalsValue._targetProgression,
        },
      };
      auxOldData = newGoals;
      auxOldData[inputIndex] = auxGoals[inputIndex];
      auxOldData[inputIndex].value = JSON.stringify(
        auxOldData[inputIndex].value
      );
      setCheckBtn([...auxChecked]);
      setNewGoals(auxOldData);
      handleChange("", auxOldData)
    } else {
      auxChecked[inputIndex] = 0;
      auxOldData = newGoals;
      auxOldData[inputIndex] = 0;
      setCheckBtn([...auxChecked]);
      setNewGoals(auxOldData);
      handleChange("", auxOldData)
    }
  };

  const renderProgression = () => {
    if (!goals || goals.length === 0) return <span className="d-block h5">Couldn't find any goals</span>;

    return (
      goals.map((x, index) => {
        const targetProgression = JSON.parse(x.Value)
          ._targetProgression;
        const progression =
          checkBtn[index] === 1
            ? targetProgression
            : JSON.parse(x.Value)._progression;
        const isProgressionEdited =
          JSON.parse(x.Value)._progression !== progression;
        return (
          <React.Fragment key={"pr" + x.Key}>
            <div className="progress-wrapper">
              <div className="progress-info">
                <div className="progress-label">
                  <span>{x.Key}</span>
                </div>
                <div className="progress-percentage">
                  <span>
                    {formatIdleCurrency(progression)}/{formatIdleCurrency(targetProgression)}
                  </span>
                  <div
                    className={
                      mainPage
                        ? "d-inline custom-control custom-checkbox ml-1"
                        : "d-none"
                    }
                  >
                    <input
                      className={
                        mainPage
                          ? "custom-control-input"
                          : "d-none"
                      }
                      key={index}
                      id={"customCheck" + index}
                      onChange={(e) =>
                        handleCheckbox(e.target)
                      }
                      type="checkbox"
                      checked={
                        targetProgression === progression
                          ? true
                          : null
                      }
                      disabled={
                        targetProgression === progression &&
                        !isProgressionEdited
                      }
                    />
                    <label
                      className={
                        mainPage
                          ? "custom-control-label"
                          : "d-none"
                      }
                      htmlFor={"customCheck" + index}
                    />
                  </div>
                </div>
              </div>
              <Progress
                max="1"
                value={progression / targetProgression}
              />
            </div>
          </React.Fragment>
        );
      })
    )
  }

  const hasGoal = goals && goals.length > 0;
  if (!hasPersistence(persistence)) return React.Fragment;

  return (
    <Container key={playerId} fluid>
      {loading || loading ? (
        <LoadingContainer />
      ) : (
        <Row>
          <Col lg={hasGoal ? 6 : 12} xs={12}>
            <Row>
              {attributes && attributes.map((attr) => {
                const attrData = getAttributeFromGroup(attr);
                return (
                  <Col key={"attr_cfg" + attr.Id} xs="12" md="4">
                    <span
                      className={
                        classStyle
                          ? "h6 surtitle text-muted persistenceCardBodyTitle"
                          : "h6 surtitle text-muted"
                      }
                    >
                      {attr.Name}
                    </span>
                    <Row
                      className={
                        "d-flex " +
                        (mobile
                          ? "flex-row"
                          : "flex-column")
                      }
                    >
                      <Col xs="4">
                        <div className="p-0">
                          {attrData.imageUrl &&
                            <img
                              className="max-width-2 p-1"
                              src={`data:image/*;base64,${attrData.imageUrl}`}
                              alt="Currency"
                            />}
                          <span
                            className={
                              classStyle
                                ? "d-inline-block h3 persistenceCardBodySubtitle"
                                : "d-inline-block h3"
                            }
                          >
                            {attrData.value}
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                )
              }
              )}
            </Row>
          </Col>
          {hasGoal && <Col
            lg={6}
            xs={12}
            className="progression-container"
          >
            <span
              className={
                classStyle
                  ? "h6 surtitle d-inline-block text-muted col-8 p-0 persistenceCardBodyTitle"
                  : "h6 surtitle d-inline-block text-muted col-8 p-0"
              }
            >
              Current Goals
            </span>
            <div className="pl-1">
              {renderProgression()}
            </div>
          </Col>}
        </Row>
      )}
    </Container>
  );
}


export default PersistenceInfo;