import React, {useState} from "react";
import { Button, Modal, ModalHeader } from "reactstrap";
import FileInput from "./FileInput";
import { Code } from "@chakra-ui/core";
import {tryParse} from "../../helpers/json-helpers.js"

/**
  * @typedef {{
 * Id: number, 
 * Name: string,
 * }} Permission;
 * 
 * @typedef {{
 * Id: number, 
 * Name: string,
 * Permissions?: Permission[]
 * }} Role;
 * 
 * @type {React.FC<{
 * handleModalToggle:  () => void,
 * isOpen: boolean,
 * sendRole: (role: Role) => void,
 * onClose: ()  => void,
 * header?: React.Node,
 * permissions: Permission[],
 * loading: boolean,
 * onDelete: (id: number) => void,
 * onSubmit: (file: File) => void,
 * }>}
 */
export const ImportModal = ({
  handleModalToggle,
  isOpen,
  onClose,
  header,
  onSubmit,
  loading,
  preview,
  children,
  fileType = ".json",
}) => {
  const [data,setData] = useState();
  const handleClose = () => {
    setData(undefined);
    if(onClose) onClose();
    if(handleModalToggle) handleModalToggle();
  };

  /**
   * @param {React.FormEvent<HTMLFormElement>} e
   */
  const handleSubmit = (e) => {
    e.preventDefault();
    if(onSubmit) onSubmit(e.target["file"].files[0]);
  }

  const handleFileChange = (file) => {    
    if(!preview) return;
    file.text().then(x => setData(x));
  }

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered"
    >
      <ModalHeader>{header ?? "Upload Data"}</ModalHeader>
      <div className="modal-body">
      <form onSubmit={handleSubmit}>
        {children}
        <FileInput accept={fileType} onChange={handleFileChange} name="file"/>
        <Button disabled={loading || (preview && !data)} type="submit">Submit</Button>
      </form>
      {preview && <div>
        <p>preview</p>
        <Code whiteSpace="pre-wrap" overflow="auto" w="100%" height="25rem">
          {JSON.stringify(tryParse(data),null,2)}
        </Code>
      </div>}
      </div>
    </Modal>
  );
};

export default ImportModal;