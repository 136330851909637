import React from "react";
import PerfectScrollBar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { Link } from "react-router-dom";
// reactstrap components
import { Badge, Col, Container, Row, Table } from "reactstrap";
import authHeader from "../../helpers/auth-header.js";
import "../../views/css/playerStyles.css";
import SimplePaginationComponent from "../common/SimplePaginationComponent.jsx";
import LoadingContainer from "../Layout/LoadingContainer.jsx";
import httpClient from "../../helpers/http-client-singleton";
import SearchInput from "../common/SearchInput";
import PaginationHeader from "../common/PaginationHeader";
import TableCard from "../Layout/TableCard";
import ErrorBox from "../common/ErrorBox";

import "./css/tableStyles.css";
import { userService } from "../../services/user.service.js";

const http = httpClient.getInstance();

const PURCHASE_TYPES = [
  "Consumable",
  "Non-Consumable",
  "Auto Renewed Subscription",
  "Non Renewing Subscription",
];

export default class PurchaseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      currentPage: 0,
      objectsPerPage: 15,
      data: [],
      search: "",
    };
    this.handleObjectsPerPage = this.handleObjectsPerPage.bind(this);
  }

  componentDidMount() {
    if (this.props.main) {
      this.fetchAllPurchases(0);
    } else {
      this.fetchPurchasesOfPlayer(0);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      this.props.search !== prevProps.search ||
      this.state.search !== prevState.search
    ) {
      if (this.props.main) {
        this.fetchAllPurchases(0);
      } else {
        this.fetchPurchasesOfPlayer(0);
      }
    }
  }

  handleObjectsPerPage(number) {
    this.setState({ objectsPerPage: number }, () => this.fetchAllPurchases(0));
  }

  onSearch = (search) => {
    this.setState({ search });
  };

  onClear = () => this.setState({ search: "" });

  fetchAllPurchases = (pageNumber) => {
    if(!userService.hasPermission("ACCESS_PURCHASES"))return;
    this.setState({ loading: true });
    http
      .get("/purchases/", {
        headers: authHeader(),
        params: {
          skip: pageNumber * this.state.objectsPerPage,
          take: this.state.objectsPerPage,
          filter: this.state.search || null,
        },
      })
      .then((response) => {
        this.setState({
          data: response.data.data.Result,
          totalObjects: response.data.data.Total,
          currentPage: pageNumber,
          loading: false,
        });
        this.props.totalPurchases(response.data.data.Total);
      })
      .catch((error) => {
        this.setState({ data: [], loading: false, serverResponse: error });
      });
  };

  fetchPurchasesOfPlayer = (pageNumber) => {
    if(!userService.hasPermission("ACCESS_PURCHASES"))return;
    let auxPage;
    if (pageNumber === "next") {
      auxPage = this.state.currentPage + 1;
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
    } else if (pageNumber === "prev") {
      auxPage = this.state.currentPage - 1;
      this.setState({
        currentPage: this.state.currentPage - 1,
        loading: true,
      });
    } else {
      auxPage = pageNumber;
      this.setState({
        currentPage: pageNumber,
        loading: true,
      });
    }
    http
      .get("/purchases/players/" + this.props.playerId, {
        headers: authHeader(),
        params: {
          skip: auxPage * this.state.objectsPerPage,
          take: this.state.objectsPerPage,
          filter: this.props.idSearch || null,
        },
      })
      .then((response) => {
        this.setState({
          data: response.data.data.Result,
          totalObjects: response.data.data.Total,
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ data: [], loading: false, serverResponse: error });
      });
  };

  formatDate = (m_date) => {
    let date = new Date(m_date);
    return (
      date.getDate() + "/" + (date.getMonth() + 1) + "/" + date.getFullYear()
    );
  };

  renderPurchasesOfAll = () => {
    if (this.state.data.length === 0) {
      return <ErrorBox>There isn't any purchases :'(</ErrorBox>;
    } else {
      return (
        <Table hover className="table-flush" size="sm" responsive>
          <thead className="thead-light">
            <tr>
              <th className="sort pr-0" data-sort="date" scope="col">
                Date
              </th>
              <th className="sort" data-sort="device" scope="col">
                Device
              </th>
              <th className="pr-0" scope="col">
                SKU
              </th>
              <th scope="col">Order ID</th>
              <th>Type</th>
              <th className="sort pl-0" data-sort="itemId" scope="col">
                Item
              </th>
              <th className="sort pl-0 pr-0" data-sort="value" scope="col">
                Value
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.data.map((data, index) => (
              <tr key={index}>
                <td className="pr-0 purchase-table-cell">
                  {this.formatDate(data["Date"])}
                </td>
                <td className="pr-0 purchase-table-cell">
                  {data["Platform"] === "android" ||
                  data["Platform"] === "Android" ? (
                    <i className="fab fa-android" />
                  ) : (
                    <i className="fab fa-apple" />
                  )}{" "}
                  <Link
                    className="text-primary"
                    to={"player/" + data["PlayerId"]}
                  >
                    {data["PlayerId"]}
                  </Link>
                </td>
                <td className="purchase-table-cell">{data["SKU"]}</td>
                <td className="pl-0">
                  {data["Valid"] ? (
                    <Badge color="" className="badge-dot">
                      <i className="bg-success" />
                      <span className="purchase-table-cell">
                        {data["OrderId"].length > 40
                          ? data["OrderId"].substring(0, 38) + " ..."
                          : data["OrderId"]}
                      </span>
                    </Badge>
                  ) : (
                    <Badge color="" className="badge-dot">
                      <i className="bg-danger" />
                      <span className="purchase-table-cell">
                        {data["OrderId"].length > 40
                          ? data["OrderId"].substring(0, 38) + " ..."
                          : data["OrderId"]}
                      </span>
                    </Badge>
                  )}
                </td>
                <td width="5%" className="pl-0 purchase-table-cell">
                  {PURCHASE_TYPES[Number(data["Type"])]}
                </td>
                <td className="pl-0 purchase-table-cell">
                  {data["ItemId"] + "  x " + data["Amount"]}
                </td>
                <td className="px-0 purchase-table-cell">
                  {"$" + (data["Value"] && data["Value"].toFixed(2))}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      );
    }
  };

  renderPurchasesOfPlayer = () => {
    if (this.state.data.length === 0) {
      return (
        <ErrorBox className="h-100">
          This player doesn't have any purchases.
        </ErrorBox>
      );
    } else {
      const pageNumbers = [];
      if (this.state.totalObjects !== null) {
        for (
          let i = 1;
          i <= Math.ceil(this.state.totalObjects / this.state.objectsPerPage);
          i++
        ) {
          pageNumbers.push(i);
        }
        if (pageNumbers.length !== this.state.totalPages) {
          this.setState({ totalPages: pageNumbers.length });
        }
      }
      return (
        <PerfectScrollBar className="rounded-bottom">
          <Table
            hover
            className="align-items-center table-flush"
            size="sm"
            responsive
          >
            <thead className="thead-light">
              <tr>
                <th className="sort" data-sort="date" scope="col">
                  Date
                </th>
                <th className="sort" data-sort="sku" scope="col">
                  SKU
                </th>
                <th className="sort" data-sort="orderId" scope="col">
                  OrderID
                </th>
                <th>Type</th>
                <th className="sort pl-0" data-sort="platform" scope="col">
                  Platform
                </th>
                <th className="sort pl-0" data-sort="itemId" scope="col">
                  Item
                </th>
                <th className="sort" data-sort="value" scope="col">
                  Value
                </th>
              </tr>
            </thead>
            <tbody className="list">
              {this.state.data.map((data, index) => (
                <tr key={index}>
                  <td className="purchase-table-cell">
                    {this.formatDate(data["Date"]) +
                      " at " +
                      data["Date"].split("T")[1].split(":")[0] +
                      ":" +
                      data["Date"].split("T")[1].split(":")[1]}
                  </td>
                  <td>{data["SKU"]}</td>
                  <td>
                    {data["Valid"] ? (
                      <Badge color="" className="badge-dot">
                        <i className="bg-success" />
                        <span className="purchase-table-cell">
                          {data["OrderId"].length > 40
                            ? data["OrderId"].substring(0, 38) + " ..."
                            : data["OrderId"]}
                        </span>
                      </Badge>
                    ) : (
                      <Badge color="" className="badge-dot">
                        <i className="bg-danger" />
                        <span className="purchase-table-cell">
                          {data["OrderId"].length > 40
                            ? data["OrderId"].substring(0, 38) + " ..."
                            : data["OrderId"]}
                        </span>
                      </Badge>
                    )}
                  </td>
                  <td className="pl-0 purchase-table-cell">
                    {PURCHASE_TYPES[Number(data["Type"])]}
                  </td>
                  <td className="pl-0 purchase-table-cell">
                    {data["Platform"] === "" ? "-" : data["Platform"]}
                  </td>
                  <td className="pl-0 purchase-table-cell">
                    {data["ItemId"] + " - " + data["Amount"]}
                  </td>
                  <td>{"$" + (data["Value"] && data["Value"].toFixed(2))}</td>
                </tr>
              ))}
            </tbody>
          </Table>
          <Container fluid>
            {this.state.totalObjects !== null ? (
              <SimplePaginationComponent
                fetchPage={this.fetchPurchasesOfPlayer}
                currentPage={this.state.currentPage}
                objectsPerPage={this.state.objectsPerPage}
                totalObjects={this.state.totalObjects}
              />
            ) : null}
          </Container>
        </PerfectScrollBar>
      );
    }
  };

  render() {
    const header = (
      <Row className="flex align-items-center">
        <Col xs={12} xl={6}>
          <SearchInput
            placeholder="Player ID or Order ID"
            onSearch={this.onSearch}
            onClear={this.onClear}
          />
        </Col>
        <Col xs={12} xl={6}>
          <PaginationHeader
            total={this.state.totalObjects}
            skip={this.state.currentPage * this.state.objectsPerPage}
            take={this.state.objectsPerPage}
            onObjectsPerPageChange={this.handleObjectsPerPage}
          />
        </Col>
      </Row>
    );

    const footer =
      this.state.totalObjects !== null ? (
        <SimplePaginationComponent
          fetchPage={this.fetchAllPurchases}
          currentPage={this.state.currentPage}
          objectsPerPage={this.state.objectsPerPage}
          totalObjects={this.state.totalObjects}
        />
      ) : null;

    if (!userService.hasPermission("ACCESS_PURCHASES")){
      return (
        <>
        <TableCard
          header={header}
        >
          <center style={{padding: '5rem'}} >You're not authorized to see this page.</center>          
        </TableCard>
      </>
      )
    }

    if (this.props.main) {
      return (
        <>
          {this.state.loading ? (
            <LoadingContainer />
          ) : (
            <>
              <TableCard
                header={header}
                loading={this.state.loading}
                footer={this.state.data.length > 0 && footer}
              >
                {this.renderPurchasesOfAll()}
              </TableCard>
            </>
          )}
        </>
      );
    } else {
      return (
        <>
          {this.state.loading ? (
            <LoadingContainer />
          ) : (
            this.renderPurchasesOfPlayer()
          )}
        </>
      );
    }
  }
}
