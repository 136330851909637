import React from "react";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import Swal from "sweetalert2";
import authHeader from "../../helpers/auth-header.js";
import httpClient from "../../helpers/http-client-singleton";

const http = httpClient.getInstance();

export default class UserOptionsDropdown extends React.Component {
  deleteUser() {
    Swal.fire({
      title: "Are you sure?",
      text:
        this.props.user.FirstName +
        " " +
        this.props.user.LastName +
        " will be deleted.",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        http
          .delete("/users/" + this.props.user.Email, {
            headers: authHeader(),
          })
          .then((response) => {
            this.props.getUsers();

            if (response.status === 200) {
              this.props.getUsers();
              Swal.fire(
                "Done!",
                this.props.user.FirstName +
                  " " +
                  this.props.user.LastName +
                  " has been deleted."
              );
            } else {
              Swal.fire(
                "Error!",
                "The system was not able to delete this user."
              );
            }
          });
      }
    });
  }

  render() {
    return (
      <>
        <UncontrolledDropdown>
          <DropdownToggle
            size={this.props.size}
            color="neutral"
            className="this.props.className"
          >
            {this.props.children}
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem onClick={(e) => this.props.modalUpdate()}>
              Update User
            </DropdownItem>
            <div className="dropdown-divider"></div>

            <DropdownItem
              onClick={() => this.deleteUser()}
              disabled={
                this.props.user.Email ===
                JSON.parse(localStorage.getItem("user")).Email
              }
            >
              Delete User
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </>
    );
  }
}
