import React from 'react';
import { Table } from 'reactstrap';

// import { Container } from './styles';

function RoleTable({ roles, onOpen }) {
  return (
    <div >
      <Table
        hover
        className="align-items-center table-flush"
        style={{ maxWidth: "auto", msOverflowX: "scroll" }}
      >
        <thead className="thead-light">
          <tr>
            <th className="sort" data-sort="id" scope="col">
              id
            </th>
            <th className="sort" data-sort="role" scope="col">
              role
            </th>
            <th style={{ width: "3%" }} scope="col" />
          </tr>
        </thead>
        <tbody className="list">
          {roles?.sort((a, b) => a.Id - b.Id).map((role, index) => (
            <tr key={role?.name + index}>
              <td className="id">
                {role?.Id || "--"}
              </td>
              <td className="role">{role?.Name}</td>
              <td>
                {
                  role.Name !== "Admin" &&
                  <button
                    size="sm"
                    className="btn-icon-only"
                    onClick={() => onOpen(role)}
                  >
                    <i className="fas fa-pen" />
                  </button>
                }
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  )
}

export default RoleTable;