import React from 'react';
import { Container, Button } from 'reactstrap';
import TableCard from '../Layout/TableCard';
import GT from '../Table/GenericTable';
import SimpleHeader from "../Headers/SimpleHeader";
import { Flex, Text } from '@chakra-ui/core';
import { parseDate, getHumanizedDuration} from '../../helpers/date-helper';
import DateTimeComponent from '../common/DateTimeComponent';

/**
 * @typedef {import('../../views/RegattaDefinitions').IRegattaDefinition} RegattaDefinition
 */




  const humanizedDuration = (minutes)  => getHumanizedDuration(minutes, 'minute');

  /**
  * @template T
  * @typedef {import('../Table/GenericTable').TableKey<T>} Key<T>
  * */
  /**
   * @type {(onEdit: (def: RegattaDefinition) => void, onDisable: (def: RegattaDefinition) => void) => Key<RegattaDefinition>[]}
   */
  const TABLE_KEYS = (onDisplay, onDisable) => [
    {
      key: "Id"
    },
    {
      key: "DataKey",
      bodyOptions:{
        render: (def) => 
        (<Text 
          as="a"
          cursor="pointer"
          className="text-primary"
          onClick={() => onDisplay(def)} 
          color="var(--primary)" >
            {def.DataKey}
        </Text>),
      },
      headerOptions: { title:  "Data Key" },
    },
    {
      key: "CreationDate",
      headerOptions: { title:  "Created At" },  
      exportOptions: { transform: (CreationDate) => parseDate(CreationDate).dateTime() },
      bodyOptions: {render:(({CreationDate,Id}) => <DateTimeComponent date={CreationDate} tooltipId={"T" + Id + "CATRD"}/>)}
    },
    {
      key: "StartDate",
      headerOptions: { title:  "Start Date" },
      exportOptions: {value: ({CreationDate,StartDate}) => parseDate(CreationDate).addMin(StartDate).dateTime() },
      bodyOptions: {render: ({CreationDate,StartDate,Id}) => <DateTimeComponent date={parseDate(CreationDate).addMin(StartDate)} tooltipId={"T" + Id + "STRDT"}/> } },
    {
      key: "Duration",
      exportOptions: {transform: humanizedDuration  },
      bodyOptions: {transform: humanizedDuration } },
    {
      key: "Cooldown",
      exportOptions: {transform: humanizedDuration  },
      bodyOptions: {transform: humanizedDuration }
    },
    {
      key: "GuildsQuantity",
      headerOptions: {title: "Guild Count"},
      exportOptions: {transform: (val) => val || "infinity" },
      bodyOptions: {transform: (val) => val || <i className="fas fa-infinity" /> }
    },
    {
      key: "GuildMembersQuantity",
      headerOptions: {title: "Members/Guild"},
      exportOptions: {transform: (val) => val || "infinity" },
      bodyOptions: {transform: (val) => val || <i className="fas fa-infinity" /> }
      
    },
    {
      key: "InactivateRegattas",
      headerOptions: {title: "Inactive Next Regatta"},
      bodyOptions: {render:  (def) => <i className={def.InactivateRegattas ? "fa fa-check text-success": "fas fa-times text-gray"} />}
    },
    {
      key: "",
      exportOptions: { ignore: true },
      bodyOptions: {
        render:  (def,i) => (
        <Flex>
        { i === 0 && !def.InactivateRegattas && <Button className="btn-icon btn-neutral" size="sm" color="danger" onClick={() => onDisable?.(def)} >
          <i className="fas fa-stop" />
        </Button>}
        </Flex>
      )}
    },
  ];

/**
 * @typedef {{
 *  definitions: RegattaDefinition[]
 *  loading: boolean,
 *  headerPanel?: React.ReactNode,
 *  footerPanel?: React.ReactNode,
 *  onShowModal: (entry: RegattaDefinition | {} | undefined) => void,
 *  onSearch: (data: string) => void,
 *  onClear: () => void,
 *  onDisable: (def: RegattaDefinition) => void,
 *  onExportCsv: (options: Key<RegattaDefinition>[]) => void,
 * }} RegattaDefListProps
 */
/***
 * @type {React.FC<RegattaDefListProps>} */
const RegattaDefinitionsList = ({definitions,loading,headerPanel,footerPanel, onShowModal,onDisable, onSearch, onClear,onExportCsv}) => {
  const keys = TABLE_KEYS(onShowModal,onDisable);

  return (
    <>
      <SimpleHeader name="Regatta Definitions" clean parentName="Dashboard">
        <Button
          className="btn-neutral btn-round float-right btn-icon"
          color="default"
          size="sm"
          onClick={() => onShowModal?.({})}
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-trophy" />
          </span>
          <span className="btn-inner--text">Create Regatta Definition</span>
        </Button>
      </SimpleHeader>
    <Container fluid className="mt--6">
      <TableCard loading={loading} 
        header={
          <GT.Header 
            onClear={onClear} 
            onSearch={onSearch} 
            inputPlaceholder="Regatta Definition Id"
            >
              {headerPanel}
            </GT.Header>
        } 
        footer={footerPanel}>
        <GT.Table data={definitions}  onEmpty="No Regattas Definition Found." keys={keys} />
      </TableCard>
    </Container>
    </>
  );
}

export default RegattaDefinitionsList;