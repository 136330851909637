import React, { useCallback, useEffect, useState } from "react";
import EventsCalendar from "../components/Events/EventsCalendar.jsx";
import EventsListing from "../components/Events/EventsListing.jsx";
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";
import { Button, ButtonGroup } from "reactstrap";

import authHeader from "../helpers/auth-header";
import Swal from "sweetalert2";

import "./css/eventsStyles.css";

import classnames from "classnames";
import EventsTable from "../components/Events/EventsTable.jsx";

import moment from "moment";
import httpClient from "../helpers/http-client-singleton";

const http = httpClient.getInstance();

const getEventLeaderboards = (eventId) => {
  return http
    .get(`/leaderboards/definitions/events/${eventId}`, {
      headers: authHeader(),
    })
    .then((response) => {
      return response.data.data;
    });
};

const Events = ({ history, match }) => {
  const { eventId } = match.params;
  const [tab, setTab] = useState(1);
  const [calendarEvents, setCalendarEvents] = useState([]);
  const [tableEvents, setTableEvents] = useState([]);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [error, setError] = useState({ value: false, errorMsg: "" });
  const [totalObjects, setTotalObjects] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);
  const [tableLoading, setTableLoading] = useState(true);
  const [calendarLoading, setCalendarLoading] = useState(true);
  const [profiles, setProfiles] = useState([]);
  const [defaultProfileId, setDefaultProfileId] = useState(null);
  const [month, setMonth] = useState(new Date());
  const [showDeleted, setShowDeleted] = useState(true);
  const [loadingMock, setLoadingMock] = useState(false);

  const setSelectedEvent = (id) => history.push(`/${id}`);

  const getMonthEvents = useCallback(() => {
    setCalendarLoading(true);
    const startMonth = moment(month).startOf("month").toISOString();
    const endMonth = moment(month).endOf("month").add(10, "days").toISOString();
    http
      .get("/events/range", {
        headers: authHeader(),
        params: {
          startDate: startMonth,
          endDate: endMonth,
          deleted: false,
        },
      })
      .then((response) => {
        setCalendarEvents(response.data.data);
        setCalendarLoading(false);
      });
  }, [month]);

  const getPage = useCallback((pageNumber) => {
    let auxPage;
    if (pageNumber === "next") auxPage = currentPage + 1;
    if (pageNumber === "prev") auxPage = currentPage - 1;
    else auxPage = pageNumber;

    setCurrentPage(auxPage);
    setTableLoading(true);
    http.get("/events/", {
      headers: authHeader(),
      params: {
        desc: true,
        skip: objectsPerPage * auxPage,
        take: objectsPerPage,
        deleted: showDeleted,
      },
    })
      .then(({ data }) => {
        setTableEvents(data.data.Result);
        setTotalObjects(data.data.Total);
        setTableLoading(false);
      })
      .catch((error) => setError({ value: true, errorMsg: error.body }));
  }, [currentPage, objectsPerPage, showDeleted]);


  useEffect(() => {
    getMonthEvents();
  }, [getMonthEvents]);

  useEffect(() => {
    getPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    http.get("/events/profiles/", { headers: authHeader(), params: { getDeleted: true } })
      .then(({ data }) => {
        setProfiles(data.data);
        setDefaultProfileId(data.data.find((profile) => profile.Name === "default")?.Id ?? 0);
      });
  }, []);

  function getMockAttributes(leaderboards) {
    const attributes = {};
    leaderboards.forEach((leaderboard) => {
      Object.keys(leaderboard.attributes).forEach((attributeKey) => {
        if (!(attributeKey in attributes)) {
          const min = leaderboard.attributes[attributeKey][0];
          const max = leaderboard.attributes[attributeKey][1];
          attributes[attributeKey] = { item1: min, item2: max };
        }
      });
    });
    return attributes;
  }

  const mockLeaderboards = (
    numberOfPlayerToMock,
    eventId,
    leaderboards,
    onSuccess,
    onError
  ) => {
    setLoadingMock(true);
    const attributesRange = getMockAttributes(leaderboards);
    const body = { numberOfPlayerToMock, eventId, attributesRange };
    http
      .put("/helpers", body, { headers: authHeader() })
      .then(onSuccess)
      .catch(onError)
      .finally(() => setLoadingMock(false));
  };

  const handleObjectsPerPage = (number) => {
    setObjectsPerPage(number);
  };

  const handleToggleChange = () => {
    setShowDeleted(!showDeleted);
  };


  const addEventPrefix = (leaderboards, event) =>
    // Adds the event content and event edition as prefix to the attribute name
    leaderboards.map((leaderboard) => ({
      ...leaderboard,
      AttributeNames: leaderboard.AttributeNames.map(
        (attribute) => `${event.Content}.${event.Id}.${attribute}`
      ),
    }));

  const sendEvent = ({ body, beforeCb, thenCb, finallyCb }, leaderboards) => {
    if (beforeCb) beforeCb();

    http
      .put("/events/" + (body?.Id || ""), body, {
        headers: authHeader("application/json"),
      })
      .then((response) => {
        const formattedLeaderboards = addEventPrefix(
          leaderboards,
          response.data.data
        );
        sendLeaderboards(formattedLeaderboards, response.data.data.Id);
        Swal.fire({
          type: "success",
          text:
            "Event " +
            response.data.data.Content +
            " has been successfully " +
            (body.id ? "updated" : "created") +
            " ...",
        }).then(() => {
          getPage(0);
          getMonthEvents();
        });
        if (thenCb) thenCb();
      })
      .catch((error) => {
        Swal.fire({
          type: "error",
          text: error?.response?.data.data.message ?? error?.message,
        });
      })
      .finally(() => {
        if (finallyCb) finallyCb();
      });
  };
  
  const sendBatch = (events) => {
    if(typeof events == "string"){
      console.error(events);
      Swal.fire({
        type: "error",
        text: events,
      });
      return;
    }
    console.log(events);
    setTableLoading(true);
    http.post(
        "/events/batch",
        events,
        { headers: authHeader() }
    ).then(() => {
      setTableLoading(false);
      Swal.fire({
        type: "success",
        text:
            "Success!",
      }).then(() => {
        getPage(0);
        getMonthEvents();
      });
    });
  }

  const sendBatchDelete = (events) => {
    if(typeof events == "string"){
      console.error(events);
      Swal.fire({
        type: "error",
        text: events,
      });
      return;
    }
    console.log(events);
    setTableLoading(true);
    http.post(
        "/events/batch-delete",
        events,
        { headers: authHeader() }
    ).then(() => {
      setTableLoading(false);
      Swal.fire({
        type: "success",
        text:
            "Success!",
      }).then(() => {
        getPage(0);
        getMonthEvents();
      });
    });
  }
  const sendLeaderboards = (leaderboards, id) => {
    leaderboards.forEach((leaderboard) => {
      http.put(
        "/leaderboards/definitions",
        { ...leaderboard, eventId: id },
        { headers: authHeader() }
      );
    });
  };

  const deleteEvent = ({ id, beforeCb, thenCb, finallyCb }) => {
    if (beforeCb) beforeCb();
    http
      .delete("/events/" + id, {
        headers: authHeader("application/json"),
      })
      .then(() => {
        Swal.fire({
          type: "success",
          text: "Event has been successfully deleted...",
          onClose: () => {
            getPage(0);
            getMonthEvents();
            if (thenCb) thenCb();
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          type: "error",
          text: error,
        });
      })
      .finally(() => {
        if (finallyCb) finallyCb();
      });
  };

  return (
    <>
      <SimpleHeader name="Events" clean parentName="Dashboard">
        <ButtonGroup className="float-right">
          <Button
            className={classnames("toggle-buttons btn-secondary", {
              active: tab === 1,
            })}
            onClick={() => setTab(1)}
          >
            <i className="ni ni-bullet-list-67" />
          </Button>
          <Button
            className={classnames("toggle-buttons btn-secondary", {
              active: tab === 2,
            })}
            onClick={() => setTab(2)}
          >
            <i className="ni ni-calendar-grid-58" />
          </Button>
        </ButtonGroup>
      </SimpleHeader>
      <div className="events-tab-wrapper">
        <EventsListing
          className={classnames("tab", { "active-tab": tab === 1 })}
          error={error}
        >
          <EventsTable
            selectedEventId={eventId ? Number.parseInt(eventId) : null}
            setSelectedEventId={setSelectedEvent}
            events={tableEvents}
            loading={tableLoading}
            totalObjects={totalObjects}
            objectsPerPage={objectsPerPage}
            mockLeaderboards={mockLeaderboards}
            loadingMock={loadingMock}
            getPage={getPage}
            currentPage={currentPage}
            handleObjectsPerPage={handleObjectsPerPage}
            getEventLeaderboards={getEventLeaderboards}
            sendEvent={sendEvent}
            deleteEvent={deleteEvent}
            profiles={profiles}
            defaultProfileId={defaultProfileId}
            showDeleted={showDeleted}
            handleToggleChange={handleToggleChange}
            sendBatch={sendBatch}
            sendBatchDelete={sendBatchDelete}
          />
        </EventsListing>
        <EventsCalendar
          className={classnames("tab", { "active-tab": tab === 2 })}
          events={calendarEvents}
          getMonthEvents={getMonthEvents}
          loading={calendarLoading}
          sendEvent={sendEvent}
          deleteEvent={deleteEvent}
          getEventLeaderboards={getEventLeaderboards}
          month={month}
          setMonth={setMonth}
          profiles={profiles}
          defaultProfileId={defaultProfileId}
        />
      </div>
    </>
  );
};

export default Events;
