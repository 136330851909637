import React from "react";
import { Button } from 'reactstrap';
import TableCard from '../../Layout/TableCard';
import GT from '../../Table/GenericTable';

/** @typedef {import('../../../context/ConsolePreferenceContext').ConsolePreference} ConsolePreference*/

/**
* @template T
* @typedef {import('../../Table/GenericTable').TableKey<T>} Key<T>
* */

  /**
   * @type {(onEdit: (def: ConsolePreference) => void, onDelete: (def: ConsolePreference) => void) => Key<ConsolePreference>[]}
   */
   const TABLE_KEYS = (onDisplay, onDelete) => [
    {
      headerOptions: {render: () => "EDIT"},
      bodyOptions: {render: (val) => 
     (
     <>
     <Button
        className="btn-icon btn-2"
        size="sm"
        color="secondary"
        outline
        type="button"
        onClick={() => onDisplay(val)}
      >
        <span className="btn-inner--icon">
          <i className="ni ni-settings-gear-65" />
        </span>
      </Button>
      {!val.Required && onDelete && <Button
        className="btn-icon btn-2"
        size="sm"
        color="warning"
        outline
        type="button"
        onClick={() => onDelete(val)}
      >
        <span className="btn-inner--icon">
          <i className="fas fa-trash" />
        </span>
      </Button>}
      </>
      )}
    },
    {
      key: "Key",
      bodyOptions: {transform: (val) => <span style={{color: 'var(--primary', fontWeight: 'bolder'}}>{val}</span>}
    },
    {
      key: "Type",
      bodyOptions: {transform: (pref) => ({0: "JSON", 1: "Raw String", 2: "Boolean" ,3: "Number"})[pref] }
    },
    {
      key: "Value",
      bodyOptions: {render: (pref) => (<code>{JSON.stringify(pref.Value)}</code>), rowStyle: {width:"100%"} }
    }
  ];



/**@type {React.FC<{consolePreferences: ConsolePreference[], loading}>} */
export const ConsolePreferences = ({consolePreferences,loading, onEdit, onDelete}) => {
  return(
    <TableCard loading={loading} 
      footer={<></>}>
      <GT.Table data={consolePreferences} keys={TABLE_KEYS(onEdit,onDelete)}>
      </GT.Table>
    </TableCard>
  )
}