export const  PERMISSIONS = {
  "CREATE_EVENTS": [2,3,4],
  "DELETE_UPDATE_EVENTS": [2,3],
  "CHANGE_API_MATCH": [2,3,4],
  "RESET_PLAYER_PROGRESS": [4,5,3,2],
  "DELETE_UPDATE_PLAYER_PERSISTENCE": [5,3,2],
  "CONTROL_TIMED_ATTRIBUTES": [4,5,3,2],
  "CREATE_ATTRIBUTES": [4,3,2],
  "CREATE_UPDATE_LEADERBOARD_DEFS": [3,2],
  "DELETE_LEADERBOARD_DEFS": [3,2],
  "SEND_MESSAGES": [5,3,2],
  "CREATE_SNAPSHOT": [4,5,3,2],
  "DELETE_UPDATE_SCREENSHOT": [3,2],
  "REPORT_BAD_NAMES": [5,3,2],
  "CREATE_GUILD": [5,3,2],
  "DELETE_UPDATE_GUILD": [5,3,2],
  "ACCESS_PURCHASES": [5,3,2],
  "ACCESS_OFFERS": [5,3,2],
  "MANAGE_ROLE_PERMISSIONS": [2],
  "MANAGE_SIDIOUS_USERS": [2],
  "MANAGE_SYSTEM_PREFS": [2],
  "EXPORT_DATA": [],
}

