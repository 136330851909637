import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  FormLabel,
  Input,
  Button,
  FormControl,
  SimpleGrid,
  FormErrorMessage,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import moment from "moment";
import LoadingContainer from "../Layout/LoadingContainer";

import "./css/playerDebuggerStyles.css";

const DebuggerModal = ({ open, toggle, onSubmit, loading }) => {
  const { register, errors, handleSubmit, getValues } = useForm({ reValidateMode: "onBlur" });

  const onConfirm = () => {
    const { date, time } = getValues();
    const datetime = moment(date + " " + time);
    const timeToLive = datetime.diff(moment(), "seconds");
    onSubmit(timeToLive);
  };

  const content = loading ? (
    <LoadingContainer />
  ) : (
    <>
      <ModalHeader>Set the debugger key</ModalHeader>
      <ModalBody>
        <form>
          <SimpleGrid columns={2} spacingX={2} spacingY={1}>
            <FormControl isInvalid={Boolean(errors.date)}>
              <FormLabel fontWeight={600}>Date</FormLabel>
              <Input
                type="date"
                name="date"
                ref={register({
                  required: true,
                  validate: () => {
                    const { date, time } = getValues();
                    return (
                      !moment(date + " " + time).isBefore(moment()) ||
                      "The datetime must be in the future"
                    );
                  },
                })}
              />
              <FormErrorMessage className="no-margin-bottom">
                {errors.date &&
                  (errors.date.message || "This field is required")}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={Boolean(errors.time)}>
              <FormLabel fontWeight={600}>Time</FormLabel>
              <Input
                type="time"
                name="time"
                ref={register({
                  required: true,
                  validate: () => {
                    const { date, time } = getValues();
                    return (
                      !moment(date + " " + time).isBefore(moment()) ||
                      "The datetime must be in the future"
                    );
                  },
                })}
              />
              <FormErrorMessage className="no-margin-bottom">
                {errors.time &&
                  (errors.time.message || "This field is required")}
              </FormErrorMessage>
            </FormControl>
          </SimpleGrid>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button
          onClick={handleSubmit(onConfirm)}
          marginRight={4}
          variantColor="lumenPrimary"
        >
          Confirm
        </Button>
        <Button variant="outline" onClick={toggle}>
          Cancel
        </Button>
      </ModalFooter>
    </>
  );

  return (
    <Modal isOpen={open} size="md" isCentered>
      <ModalOverlay />
      <ModalContent padding={4} rounded="md">
        {content}
      </ModalContent>
    </Modal>
  );
};

export default DebuggerModal;
