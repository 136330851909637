
/**
 * @param {string} json
 */
export function tryParse(json){
  if(typeof json === 'object') return json;

  try{
    return JSON.parse(json.toString());
  }catch{
    return json;
  }
}

export function stringfyKeepOrder(obj, space)
{
    const allKeys = new Set();
    JSON.stringify(obj, (key, value) => (allKeys.add(key), value));
    return JSON.stringify(obj, Array.from(allKeys).sort(), space);
}