import React, { useCallback, useEffect, useState } from "react";
import authHeader from "../helpers/auth-header";
import httpClientCreator from "../helpers/http-client-singleton";
import LeaderboardDefinition from "../components/Leaderboards/Leaderboard/LeaderboardDefinition";
import LeaderboardGrid from "../components/Leaderboards/Leaderboard/LeaderboardGrid";
import LeaderboardsList from "../components/Leaderboards/Leaderboard/LeaderboardsList";
import LeaderboardPlayers from "../components/Leaderboards/Leaderboard/LeaderboardPlayers";
import moment from "moment";
import { useDisclosure} from "@chakra-ui/core";
import Swal from "sweetalert2";

const http = httpClientCreator.getInstance();

const Leaderboard = ({ match }) => {
  const definitionId = match.params.param;
  const [loading, setLoading] = useState(true);
  const [loadingLeaderboardPlayers, setLoadingLeaderboardPlayers] = useState(
    false
  );
  const [loadingLeaderboards, setLoadingLeaderboards] = useState(true);
  const [leaderboardDefinition, setLeaderboardDefinition] = useState({});
  const [eventLoading, setEventLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);
  const [total, setTotal] = useState(0);
  const [error, setError] = useState(false);
  const [leaderboards, setLeaderboards] = useState([]);
  const [leaderboardPlayers, setLeaderboardPlayers] = useState(null);
  const { isOpen: isModalOpen, onOpen: onOpenModal, onClose: onCloseModal } = useDisclosure();
  const [leaderboardKey,  setLeaderboardKey] = useState(undefined);
  const [event, setEvent] = useState(null);

  const getLeaderboards = useCallback(
    (page) => {
      setLoadingLeaderboards(true);
      http
        .get(`/leaderboards/definitions/${definitionId}/groups`, {
          headers: authHeader(),
          params: { skip: page * 15, take: 15 },
        })
        .then((response) => {
          setLeaderboards(response.data.data.Result);
          setTotal(response.data.data.Total);
          setCurrentPage(page);
          setLoadingLeaderboards(false);
        });
    },
    [definitionId]
  );

  /** @type {(id: string|number) => void} */
  const getEvent = useCallback((id) => {
    setEventLoading(true);
    http
      .get(`/events/${id?.toString().trim()}`, { headers: authHeader() })
      .then((response) => {
        setEvent(response.data.data);
      })
      .finally(() => {
        setEventLoading(false);
      });
  }, []);

  //TODO: Use the attributes names instead of the array position.
  const calculateGoldbergsLeaderboardScore = useCallback(
    (
      currencyAmount,
      boostMillisecondsRemaining,
      currencyPerSecond,
      saveGameMillisecondsTime
    ) => {
      const eventEndTime = moment(event.StartDate).add(event.Duration, "hours");
      const eventEndTimestamp = moment(eventEndTime);
      const nowTimestamp = moment();

      // Milliseconds since 01/01/1970 until this moment or when the event ended (whatever comes first)
      const timeToCalculate = eventEndTimestamp.isAfter(nowTimestamp)
        ? nowTimestamp.valueOf()
        : eventEndTimestamp.valueOf();

      const secondsSinceSave = Math.max(
        (timeToCalculate - saveGameMillisecondsTime) / 1000,
        0
      );
      const bonusSeconds = Math.max(
        Math.min(boostMillisecondsRemaining / 1000, secondsSinceSave),
        0
      );

      return (
        currencyAmount + currencyPerSecond * (secondsSinceSave + bonusSeconds)
      );
    },
    [event]
  );

  const calculatePlayersScore = useCallback(
    (players) => {
      return players.map((player) => {

        return {
          ...player,
          Score: calculateGoldbergsLeaderboardScore(
            ...Object.values(player.Attributes)
          ),
        };
      });
    },
    [calculateGoldbergsLeaderboardScore]
  );

  const getLeaderboardPlayers = useCallback(
    (key) => {
      setLoadingLeaderboardPlayers(true);      
      onOpenModal();
      http
      .get(`/leaderboards/${key}`, { headers: authHeader() })
      .then((response) => {
        const players = calculatePlayersScore(response.data.data.Scores);
        setLeaderboardPlayers(players);
        setLeaderboardKey(key);
        onOpenModal();
      })
      .finally(() => setLoadingLeaderboardPlayers(false));
    },
    [calculatePlayersScore, onOpenModal]
  );

  const searchLeaderboardByKey = useCallback((key) => {
    setLoadingLeaderboards(true);
    http
      .get(`/leaderboards/${key}`, { headers: authHeader() })
      .then((response) => {
        setLeaderboards([response.data.data]);
      })
      .finally(() => setLoadingLeaderboards(false));
  }, []);

  const removePlayer = useCallback((playerId) => {
    
    Swal.fire({
      type: "warning",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, remove them!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        setLoadingLeaderboardPlayers(true);

        return http
          .delete(`/leaderboards/definitions/${definitionId}/players/${playerId}`, { headers: authHeader()})
          .catch((e) => ({error: e instanceof Error ? e.message : {message: ""}}))
          .finally(() => setLoadingLeaderboardPlayers(false))
      }, 
    }).then((result) => {
      if (result.dismiss) return;
      
      if(!result?.value?.error){
        setLeaderboardPlayers(leaderboardPlayers?.filter(x => x.PlayerId !== playerId))
        Swal.fire({ type: "success", title: "Success", text: "Player Removed From Leaderboard!"})
      }else{
        Swal.fire({ type: "error", title: "Oops", text: "Something Went Wrong! \n" + result.value.error.message})
      }

    });

  }, [definitionId, leaderboardPlayers]);


  const getDefinition = useCallback(() => {
    http
      .get((`/leaderboards/definitions/${definitionId}`), {
        headers: authHeader(),
      })
      .then((response) => {
        setLeaderboardDefinition(response.data.data);
        getLeaderboards(0);
        if (response.data.data.EventId) getEvent(response.data.data.EventId);
      })
      .catch((e) => {console.log(e); setError(true)})
      .finally(() => setLoading(false));
  }, [definitionId, getLeaderboards, getEvent]);

  useEffect(() => {
    getDefinition();
  }, [getDefinition]);

  const exportAsCsv = async () => {
    setLoadingLeaderboardPlayers(true);
    const response = await http.get(`/leaderboards/${leaderboardKey}/dump`, {
        responseType: 'blob',
        headers: authHeader(),
    }).finally(() => setLoadingLeaderboardPlayers(false));


    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `leaderboard_${leaderboardKey}_${(new Date()).valueOf()}.csv`);
    document.body.appendChild(link);
    link.click();
  }

  return (
    <LeaderboardDefinition loading={loading} error={error}>
      <LeaderboardGrid
        leaderboardDefinition={leaderboardDefinition}
        loading={loading}
        event={event}
        eventLoading={eventLoading}
      >
        <LeaderboardsList
          leaderboards={leaderboards}
          loading={loadingLeaderboards}
          total={total}
          currentPage={currentPage}
          getLeaderboards={getLeaderboards}
          searchLeaderboardByKey={searchLeaderboardByKey}
          onClick={getLeaderboardPlayers}
        />
        <LeaderboardPlayers
          onRemovePlayer={removePlayer}
          isModalOpen={isModalOpen}
          onModalClose={onCloseModal}
          leaderboardPlayers={leaderboardPlayers}
          loading={loadingLeaderboardPlayers}
          attributeNames={leaderboardDefinition?.AttributeNames || []}
          exportCsv={exportAsCsv}
          calculateGoldbergsLeaderboardScore={
            calculateGoldbergsLeaderboardScore
          }
          hasEvent={Boolean(event)}
        />
      </LeaderboardGrid>
    </LeaderboardDefinition>
  );
};

export default Leaderboard;
