import React from "react";
import EditableColumn from "../../common/EditableColumn";

/** @type {React.FC<{name:string, onSubmit:  (val: string) => void, inputSize: number, maxWidth: number, textSize: number }>} */
const EditableTitle = ({ name, onSubmit,maxWidth,inputSize, textSize }) => {
  return (
    <EditableColumn
      onSubmit={onSubmit}
      defaultValue={name}
      maxWidth={maxWidth || "40%"}
      inputSize={inputSize || "sm"}
      textSize={textSize || 14}
      color="gray"
      textWeight={700}
      margin={4}
    />
  );
};

export default EditableTitle;
