import React from "react";

// reactstrap components
import { Card, CardBody, Container, Row } from "reactstrap";
// core components
import SimpleHeader from "components/Headers/SimpleHeader.jsx";

class Vector extends React.Component {
  render() {
    return (
      <>
        <SimpleHeader name="Vector maps" parentName="Maps" />
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardBody className="pt-0">Vector map was removed</CardBody>
              </Card>
            </div>
          </Row>
        </Container>
      </>
    );
  }
}

export default Vector;
