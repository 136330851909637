import { PERMISSIONS } from '../constants/permissions.js';
import authHeader from '../helpers/auth-header.js';
import httpClientSingleton from "../helpers/http-client-singleton.js";

export const userService = {
    login,
    logout,
    getAll,
    getUserRole,
    hasPermission,
    isAdmin
};

const http = httpClientSingleton.getInstance();

function login(email, password) {

    return http.post("/users/authenticate", { email, password })
        .then(user => {
            // login successful if there's a user in the response
            if (user.data.data) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                user.data.data.authdata = window.btoa(email + ':' + password);
                localStorage.setItem('user', JSON.stringify(user.data.data));
            }

            return user.data.data;
        });
}

function logout(shouldRedirect = true) {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
    if (shouldRedirect) {
        window.location.reload();
    }
}

function getAll() {
    return http.get("/users/", { headers: authHeader() }).then(handleResponse.data);
}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }

        return data;
    });
}

/**
 * @returns {string | undefined} 
 */
function getUserRole() {
    return JSON.parse(localStorage.getItem("user"))?.Role?.Name;
}

/**
 * @returns {{Id: number, Name:string}[] | undefined}
 */
function getUserPermissions() {
  return JSON.parse(localStorage.getItem("user"))?.Role?.Permissions;
}

function getUserRoleId() {
    return JSON.parse(localStorage.getItem("user"))?.RoleId;
}

/**
 * 
 * @param {keyof PERMISSIONS} permission 
 * @returns {boolean}
 */
function hasPermission(permission){
  if(getUserRole()?.toLowerCase() === 'admin') return true;
  const userPerms =  getUserPermissions();
  if(userPerms) return !!userPerms.find(x => x.Name === permission || x.Id === permission);
  
  const userRole = getUserRoleId();  
  return userRole === 2 || !!(PERMISSIONS[permission]?.includes(userRole));
}

/**
 *  @returns {boolean}
 */
function isAdmin(){
  const userRole = getUserRoleId();  
  return userRole === 2;
}