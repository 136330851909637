import React from "react";
import { Col, Row, Label, Input } from "reactstrap";
import PropTypes from "prop-types";
import useWindowDimensions from "../../helpers/useWindowDimensions";

/** @type {React.FC<{className: string, take: number, total: number, skip: number,onObjectsPerPageChange: (p: number) => void}>} */
const PaginationHeader = (props) => {
  const { width } = useWindowDimensions();
  return (
    <Row className={"flex m-0 align-items-center " + props.className}>
      <Col
        className={
          "d-flex col-form-label " +
          (width > 1200 ? "justify-content-end" : "px-0")
        }
      >
        <Label
          className="text-right text-muted table-pagination-text pr-2"
          for="itemsNumber"
        >
          Items per page:
        </Label>
        <Input
          className="table-header-pagination d-inline"
          type="select"
          name="select"
          id="itemsNumber"
          defaultValue={props.take}
          onChange={(e) => props.onObjectsPerPageChange(Number.parseInt(e.target.value.toString()))}
        >
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={30}>30</option>
          <option value={50}>50</option>
        </Input>
        {props.skip || (props.take && props.total) ? (
          <div className="mx-2 mx-lg-5 table-pagination-text text-muted">
            Showing {props.skip + 1} -{" "}
            {Math.min(Number(props.skip) + Number(props.take), props.total)} of{" "}
            {props.total} results
          </div>
        ) : null}
      </Col>
    </Row>
  );
};

PaginationHeader.propTypes = {
  className: PropTypes.string,
  take: PropTypes.number,
  total: PropTypes.number,
  skip: PropTypes.number,
  onObjectsPerPageChange: PropTypes.func,
};

export default PaginationHeader;
