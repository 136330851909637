import React, { useCallback, useEffect, useState } from "react";
import httpClientCreator from "../helpers/http-client-singleton";
import ReportedPlayersList from "../components/Player/ReportedPlayers/ReportedPlayers";
import authHeader from "../helpers/auth-header";
import {
  appendCellToColumn,
  isValueOnTable,
  loadSheet,
} from "../services/googlesheets.service";
import PageContainer from "../components/common/PageContainer";
import { Box, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/core";
import HackersList from "../components/Player/ReportedPlayers/HackersList";

const http = httpClientCreator.getInstance();

/**
 * @template T 
 * @typedef {import("../types/models").PaginationData<T>} PaginationData<T> */
/** @typedef {import("../types/models").Player} Player */

const PlayerNicknames = () => {


  const [loading, setLoading] = useState(false);
  /** @type {ReturnType<typeof useState<PaginationData<Player>>>}*/
  const [nickNamesData, setNicknamesData] = useState({ currentPage: 0, data: [], objectsPerPage: 15, total: 0 });
  const [hackersData, setHackersData] = useState({ currentPage: 0, data: [], objectsPerPage: 15, total: 0 });

  const sheets = undefined;

  const fetchReportedPlayers = useCallback(
    (page, count) => {
      console.log({ page, count })
      setLoading(true);
      http
        .get("players/reportnamelist", {
          params: { skip: count * page, take: count },
        })
        .then(({ data: { data } }) =>
          setNicknamesData(old => ({
            ...old,
            data: data.Result,
            total: data.Total,
            currentPage: page,
            objectsPerPage: count
          }))
        )
        .finally(() => setLoading(false));
    },
    []
  );

  const fetchHackerPlayers = useCallback(
    (page, count) => {
      console.log({ page, count })
      setLoading(true);
      http
        .get("players/listing", {
          params: {
            filter: "true",
            filterType: "isHacker",
            skip: count * page,
            take: count
          },
        })
        .then(({ data: { data } }) =>
          setHackersData(old => ({
            ...old,
            data: data.Result,
            total: data.Total,
            currentPage: page,
            objectsPerPage: count
          }))
        )
        .finally(() => setLoading(false));
    },
    []
  );

  const deleteFromReportList = (playerId, resetNickname) => {
    return http.delete(`players/${playerId}/reportname`, {
      headers: authHeader(),
      params: { setNicknameAsNull: resetNickname },
    });
  };

  const onResetClick = (index) => {
    setLoading(true);
    const nickname = nickNamesData.data[index].Name;
    const playerId = nickNamesData.data[index].PlayerId;
    deleteFromReportList(playerId, true)
      .then(() => {
        addNicknameToForbiddenNames(nickname);
        fetchReportedPlayers(0, nickNamesData.objectsPerPage);
      })
      .finally(() => setLoading(false));
  };

  const onRemoveFromListClick = (index) => {
    setLoading(true);
    const playerId = nickNamesData.data[index].PlayerId;
    const nickname = nickNamesData.data[index].Name;
    deleteFromReportList(playerId, false)
      .then(() => {
        addNicknameToAllowedNames(nickname);
        fetchReportedPlayers(0, nickNamesData.objectsPerPage);
      })
      .finally(() => setLoading(false));
  };

  const loadForbiddenNameSheet = () => {
    if (sheets?.nicknamesSheetId && sheets?.nicknamesSheetForbidden)
      return loadSheet(
        sheets?.nicknamesSheetId,
        sheets?.nicknamesSheetForbidden
      );
    return null;
  };

  const loadAllowedNameSheet = () => {
    if (sheets?.nicknamesSheetId && sheets?.nicknamesSheetAllowed)
      return loadSheet(sheets?.nicknamesSheetId, sheets?.nicknamesSheetAllowed);
    return null;
  };

  const addNicknameToForbiddenNames = (nameToReport) => {
    const sheet = loadForbiddenNameSheet();
    if (sheet) {
      sheet.then((sheet) =>
        isValueOnTable(
          sheet,
          sheets?.nicknamesSheetForbiddenColumnName,
          nameToReport
        ).then((isOnSheet) => {
          if (!isOnSheet) {
            return appendCellToColumn(
              sheet,
              sheets?.nicknamesSheetForbiddenColumnName,
              nameToReport
            );
          }
        })
      );
    }
  };

  const addNicknameToAllowedNames = (nameToAllow) => {
    const sheet = loadAllowedNameSheet();
    if (sheet) {
      sheet.then((sheet) =>
        isValueOnTable(
          sheet,
          sheets?.nicknamesSheetAllowedColumnName,
          nameToAllow
        ).then((isOnSheet) => {
          if (!isOnSheet) {
            return appendCellToColumn(
              sheet,
              sheets?.nicknamesSheetAllowedColumnName,
              nameToAllow
            );
          }
        })
      );
    }
  };

  const updateNickname = async (playerId, nickname) => {
    const response = await http
      .put(
        `/players/${playerId}/nickname`,
        { Nickname: nickname }, { headers: authHeader() }
      );
    return response.data;
  };

  const onNicknameChange = async (index, newNickname) => {
    const playerId = nickNamesData.data[index].PlayerId;
    const nickname = nickNamesData.data[index].Name;
    await deleteFromReportList(playerId, false);
    addNicknameToForbiddenNames(nickname);
    await updateNickname(playerId, newNickname);
    return fetchReportedPlayers(0, nickNamesData.objectsPerPage);
  };

  useEffect(() => {
    fetchReportedPlayers(0, nickNamesData.objectsPerPage);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchReportedPlayers]);



  return (
    <PageContainer pageName="Hackers and Reported Players">
      <Box position="relative" rounded="lg" backgroundColor="white">
        <Tabs variant="line">
          <TabList>
            <Tab>Reported Names</Tab>
            <Tab>Hackers</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <ReportedPlayersList
                data={nickNamesData}
                fetchPage={fetchReportedPlayers}
                loading={loading}
                onNicknameChange={onNicknameChange}
                addNicknameToForbiddenNames={addNicknameToForbiddenNames}
                onRemoveFromListClick={onRemoveFromListClick}
                onResetClick={onResetClick}
              />
            </TabPanel>
            <TabPanel>
              <HackersList
                data={hackersData}
                fetchPage={fetchHackerPlayers}
                loading={loading}
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageContainer >

  );
};

export default PlayerNicknames;
