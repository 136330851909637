import React, { useCallback } from "react";
import { Row, Col } from "reactstrap";
import DateTimeComponent from "../../common/DateTimeComponent";
import classnames from "classnames";
import moment from "moment";
import localeCode from "locale-code";
import { FormattedNumber } from "react-intl";
import { SOCIAL_MEDIAS } from "../../../constants/social-medias";

const PlayerInfo = React.memo(({ playerData, debuggerInfo, socialMedias }) => {
  const getSocialMediaByName = useCallback(
    (socialMediaName) => {
      return socialMedias.find(
        (socialMedia) => socialMedia.Platform === SOCIAL_MEDIAS[socialMediaName]
      );
    },
    [socialMedias]
  );

  return (
    <>
      <Row>
        <Col xs="12" lg="6">
          <span className="h6 surtitle text-muted">
            {playerData["PlayerId"] ? "Player ID" : "Device ID"}
          </span>
          <span className="d-block h3">
            {playerData["PlayerId"] || playerData["DeviceId"]}
          </span>
        </Col>
        <Col xs="6" md="3">
          <span className="h6 surtitle text-muted">Account created in</span>
          <span className="d-block h3">
            <DateTimeComponent
              tooltipId="tooltip878209"
              date={playerData["CreationDate"]}
            />
          </span>
        </Col>
        <Col xs="6" md="3">
          <span className="h6 surtitle text-muted">Last game saved</span>
          <span className="d-block h3">
            <DateTimeComponent
              tooltipId="tooltip876780"
              date={playerData["LastSaveGame"]}
            />
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <span
            className={classnames("h6 surtitle text-muted", {
              "d-none": !JSON.stringify(playerData["LastDevice"]),
            })}
          >
            Last device used
          </span>
          <span className="d-block h3">
            <pre style={{ whiteSpace: "normal" }}>
              {JSON.stringify(playerData["LastDevice"])}
            </pre>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">
            Debugger Expiration Date
          </span>
          <span className="d-block h3">
            {debuggerInfo ? (
              <DateTimeComponent
                tooltipId="tooltip57547"
                date={moment().add(debuggerInfo, "seconds")}
              />
            ) : (
              "-"
            )}
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Language</span>
          <span className="d-block h3">
            {playerData["Language"] &&
            localeCode.validate(playerData["Language"])
              ? localeCode.getLanguageName(playerData["Language"]) +
                ", " +
                localeCode.getCountryName(playerData["Language"])
              : playerData["Language"] || "-"}
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Total Spent</span>
          <span className="d-block h3">
            <strong>
              {"$ "}
              <FormattedNumber
                value={playerData["TotalSpent"]}
                currency="USD"
              />
              {playerData["PurchasesCount"]
                ? " in " + playerData["PurchasesCount"] + " purchases"
                : ""}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">
            Local persistence outdated
          </span>
          <span className="d-block h3">
            <strong>
              {(playerData["IsLocalPersistenceOutdated"] &&
                playerData["IsLocalPersistenceOutdated"].toString()) ||
                "false"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Device Cache Outdated</span>
          <span className="d-block h3">
            <strong>
              {(playerData["DeviceCacheInvalid"] &&
                playerData["DeviceCacheInvalid"].toString()) ||
              "false"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Is Hacker</span>
          <span className="d-block h3">
            <strong>
              {(playerData["IsHacker"] && playerData["IsHacker"].toString()) ||
                "false"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Last Event Id</span>
          <span className="d-block h3">
            <strong>
              {(playerData["LastEventId"] &&
                playerData["LastEventId"].toString()) ||
              "-"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Facebook ID</span>
          <span className="d-block h3">
            <strong>{getSocialMediaByName("FACEBOOK")?.Id || "-"}</strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Apple ID</span>
          <span className="d-block h3">
            <strong>{getSocialMediaByName("APPLE_SIGN_IN")?.Id || "-"}</strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Apple Game Center</span>
          <span className="d-block h3">
            <strong>
              {getSocialMediaByName("APPLE_GAME_CENTER")?.Id || "-"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Google Play Games</span>
          <span className="d-block h3">
            <strong>
              {getSocialMediaByName("GOOGLE_PLAY_GAMES")?.Id || "-"}
            </strong>
          </span>
        </Col>
        <Col xs="6" md="4">
          <span className="h6 surtitle text-muted">Nickname</span>
          <span className="d-block h3">
            <strong>
              {playerData["Nickname"] || "-"}
            </strong>
          </span>
        </Col>
      </Row>
    </>
  );
});

export default PlayerInfo;
