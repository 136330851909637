import "assets/vendor/nucleo/css/nucleo.css";
import classnames from "classnames";
import AcpHeader from "../components/Headers/AcpHeader.jsx";
import LoadingContainer from "../components/Layout/LoadingContainer.jsx";
import PersistenceInfo from "../components/Persistence/PersistenceInfo";
import React from "react";
import NotificationAlert from "react-notification-alert";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  Table,
  TabPane,
} from "reactstrap";
import ModalJson from "../components/Layout/ModalJson.jsx";
import SnapshotOptionsDropdown from "../components/Snapshot/SnapshotOptionsDropdown.jsx";
import authHeader from "../helpers/auth-header.js";
import "./css/stylePopup.css";

import httpClient from "../helpers/http-client-singleton";

const http = httpClient.getInstance();

export default class SnapshotPage extends React.Component {
  constructor(props) {
    super(props);
    this.snapshotKey = this.props.match.params.param;
    this.state = {
      activeTab: "1",
      loading: true,
      loadingInfo: true,
      loadingPersistence: true,
      goals: [],
      serverResponse: false,
      showPopup: false,
      popupKey: null,
      popupInfo: [],
      indexChange: null,
      keyOfChanged: [],
      toUpdate: false,
      snapshotPersistence: [],
      snapshotInfo: {},
      width: 0,
      height: 0
    };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.getSnapshotInfo(this.snapshotKey);
    this.getSnapshotPersistence(this.snapshotKey);
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }

  notifyUpdateSuccess = () => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Success
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-like-2",
      autoDismiss: 3,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  loadingPage = (status = true) => {
    this.setState({
      loadingInfo: status,
      loadingPersistence: status,
    });
  };

  getSnapshotPersistence = (Key) => {
    this.setState({ loadingPersistence: true });
    http
      .get("/persistences/snapshot_" + Key, {
        headers: authHeader() ,
      })
      .then((response) => {
        this.setState({
          snapshotPersistence: response.data.data.m_playerPersistences,
          loadingPersistence: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, serverResponse: error });
      });
  };

  getSnapshotInfo = (Key) => {
    this.setState({ loadingInfo: true });
    http
      .get("/snapshots/" + Key, { headers: authHeader() })
      .then((response) => {
        this.setState({
          snapshotInfo: response.data.data.Snapshot,
          playerId: response.data.data.Snapshot.playerId,
          loadingInfo: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, serverResponse: error });
      });
  };

  updatePage = () => {
    this.getSnapshotInfo(this.snapshotKey);
    this.getSnapshotPersistence(this.snapshotKey);
  };

  openPopupHandler = () => {
    this.setState({
      showPopup: true,
    });
  };

  closePopupHandler = () => {
    this.setState({
      showPopup: false,
    });
  };

  togglePopup(key, data, indexChange) {
    this.setState({
      showPopup: true,
      popupInfo: data,
      popupKey: "" + key,
      indexChange: indexChange,
    });
  }

  togglePopupOff() {
    this.setState({
      showPopup: false,
    });
  }

  renderSnapshot(snapshotData) {
    //snapshotInfo
    return (
      <Card key={this.state.toUpdate}>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="9">
              <h5 className="h3 mb-0">Snapshot Information</h5>
            </Col>
            <Col className="text-right" xs="3">
              <SnapshotOptionsDropdown
                size="sm"
                className="btn-icon-only text-light"
                s_key={snapshotData["Key"]}
                playerId={snapshotData["PlayerId"]}
                s_desc={snapshotData["Description"]}
                notifySuccess={this.notifyUpdateSuccess}
                update={this.updatePage}
                loading={this.loadingPage}
              >
                <i className="fas fa-ellipsis-v" />
              </SnapshotOptionsDropdown>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          <Row>
            <div className="col-12">
              <span className="h6 surtitle text-muted">Original Device ID</span>
              <span className="d-block h3">
                <Link to={"/acp/player/" + snapshotData["PlayerId"]}>
                  {snapshotData["PlayerId"]}
                </Link>
              </span>
            </div>
            <div className="col-6">
              <span className="h6 surtitle text-muted">Snapshot Key</span>
              <span className="d-block h3">{snapshotData["Key"]}</span>
            </div>
            <div className="col-6">
              <span className="h6 surtitle text-muted">Created in</span>
              <span className="d-block h3">
                {new Date(snapshotData["CreationDate"]).toLocaleString()}
              </span>
            </div>
            <div className="col-12">
              <span className="h6 surtitle text-muted">Description</span>
              <span className="d-block h4">{snapshotData["Description"]}</span>
            </div>
            <div className="col-6">
              <span className="h6 surtitle text-muted">
                Snapshot Load Counter
              </span>
              <span className="d-block h3">
                {snapshotData["LoadCount"] +
                  " Load" +
                  (snapshotData["LoadCount"] !== 1 ? "s" : "")}
              </span>
            </div>
          </Row>
        </CardBody>
      </Card>
    );
  }

  renderSnapshotGameInfo() {
    return (
      <Card>
        <CardHeader>
          <Row className="align-items-center">
            <Col xs="9">
              <h5 className="h3 mb-0">In-Game Information</h5>
            </Col>
          </Row>
        </CardHeader>

        <CardBody>
          {this.state.loadingPersistence ? (
            <LoadingContainer />
          ) : (
            <PersistenceInfo
              mainPage={false}
              mobile={this.state.width < 768}
              deviceId={this.state.playerId}
              refresh={this.updatePage}
              persistence={this.state.snapshotPersistence}
            />
          )}
        </CardBody>
      </Card>
    );
  }

  renderPlayerPersistence(persistence) {
    return (
      <div>
        <Row>
          <PerfectScrollbar>
            {
              <Table
                hover
                className="align-items-center table-flush"
                size="sm"
                style={{ maxWidth: "auto", overflowX: "hidden" }}
              >
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Edit</th>
                    <th className="sort" data-sort="name" scope="col">
                      Key
                    </th>
                    <th className="sort" data-sort="budget" scope="col">
                      Value
                    </th>
                  </tr>
                </thead>
                <tbody className="list">
                  {persistence.map((data, index) => (
                    <tr key={index} style={{ maxHeight: "10px" }}>
                      <td>
                        <Button
                          className="btn-icon btn-2"
                          size="sm"
                          color="secondary"
                          outline
                          type="button"
                          onClick={() =>
                            this.togglePopup(data["Key"], data["Value"], index)
                          }
                        >
                          <span className="btn-inner--icon">
                            <i className="ni ni-settings-gear-65" />
                          </span>
                        </Button>
                      </td>
                      <td
                        style={
                          this.state.keyOfChanged.indexOf(data["Key"]) > -1
                            ? { fontWeight: "bold" }
                            : null
                        }
                      >
                        {data["Key"]}
                      </td>

                      <td>
                        <code>{data["Value"]}</code>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            }
          </PerfectScrollbar>
        </Row>
      </div>
    );
  }
  renderPopupJson() {
    return (
      <ModalJson
        edit={false}
        modal={this.state.showPopup}
        close={this.closePopupHandler}
        src={this.state.snapshotPersistence[this.state.indexChange]}
      />
    );
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  renderTabPanel() {
    return (
      <div>
        <Card>
          <CardHeader className="pb-0">
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ borderTopRightRadius: "0", cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === "1",
                  })}
                  onClick={() => {
                    this.toggle("1");
                  }}
                >
                  Snapshot Persistence
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ borderTopLeftRadius: "0", cursor: "pointer" }}
                  className={classnames({
                    active: this.state.activeTab === "2",
                  })}
                  onClick={() => {
                    this.toggle("2");
                  }}
                >
                  Snapshot Inventory
                </NavLink>
              </NavItem>
            </Nav>
          </CardHeader>
          <CardBody className="pt-0">
            <TabContent
              activeTab={this.state.activeTab}
              style={{
                height: "25rem",
                overflowY: "auto",
                boxShadow: "0 1px 3px 0 rgba(0,0,0,.15)",
              }}
            >
              <TabPane tabId="1" style={{ overflowX: "hidden" }}>
                {this.state.loadingPersistence ? (
                  <LoadingContainer />
                ) : (
                  this.renderPlayerPersistence(this.state.snapshotPersistence)
                )}
              </TabPane>
              <TabPane tabId="2">{this.renderInventory()}</TabPane>
            </TabContent>
          </CardBody>
        </Card>
      </div>
    );
  }

  renderInventory() {
    return (
      <h2 style={{ padding: "0.5rem" }}>Functionality not implemented yet.</h2>
    );
  }

  render() {
    return (
      <div>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <AcpHeader
          cards
          parentName="Snapshots"
          parentLink="snapshots"
          name="Snapshot details"
        />
        <Container className="mt--6" fluid>
          <Row>
            <Col xs="12" lg="6">
              {this.state.loadingInfo ? (
                <LoadingContainer />
              ) : (
                this.renderSnapshot(this.state.snapshotInfo)
              )}
            </Col>
            <Col xs="12" lg="6">
              {this.state.loadingPersistence ? (
                <LoadingContainer />
              ) : (
                this.renderSnapshotGameInfo()
              )}
            </Col>
          </Row>
          <Row>
            <Col className="col-12">{this.renderTabPanel()}</Col>
          </Row>
        </Container>

        {this.state.showPopup ? this.renderPopupJson() : null}
      </div>
    );
  }
}
