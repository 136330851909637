import React, { useState } from "react";

import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
} from "@chakra-ui/core";
import LeaderboardMockForm from "./LeaderboardMockForm";
import { Button } from "reactstrap";
import { useForm } from "react-hook-form";

import "../common/css/errorStyles.css";
import { useCallback } from "react";

const MOCK_LIMIT = 50;

const EventLeaderboardsMock = ({
  leaderboards,
  onSubmit,
  onCancel,
  notify,
}) => {
  const [formLeaderboards, setFormLeaderboards] = useState([]);
  const [players, setPlayers] = useState(0);

  const onFormChange = useCallback((content, index) => {
    const newFormLeaderboards = formLeaderboards.slice();
    newFormLeaderboards[index] = content;
    setFormLeaderboards(newFormLeaderboards);
  },[formLeaderboards]);

  const eventId = leaderboards[0]?.EventId;

  const { handleSubmit, register, errors } = useForm({ mode: "onBlur" });

  const onSuccess = () => {
    onCancel();
    notify();
  };

  const onError = () => {};


  return (
    <Box>
      <FormControl isInvalid={Boolean(errors["players"])}>
        <FormLabel fontWeight={600}>Number of Players</FormLabel>
        <Input
          name="players"
          placeholder={0}
          value={players}
          onChange={(e) => setPlayers(Number(e.target.value))}
          ref={register({
            validate: (value) =>
              (value > 0 && value <= MOCK_LIMIT) ||
              `Mocked players should be greater than 0 and less or equal than ${MOCK_LIMIT}.`,
          })}
        />
        <FormHelperText>
          Number of players that should be greater than 0 and less or equal than {MOCK_LIMIT}
        </FormHelperText>
        <FormErrorMessage className="error-label">
          {errors["players"] && errors["players"].message}
        </FormErrorMessage>
      </FormControl>
      <Tabs variant="soft-rounded">
        <TabList>
          {leaderboards.map((leaderboard, index) => (
            <Tab key={index}>{leaderboard.Name}</Tab>
          ))}
        </TabList>
        <TabPanels marginTop={4}>
          {leaderboards.map((leaderboard, index) => (
            <TabPanel key={index}>
              <LeaderboardMockForm
                name={`leaderboard-form-${index}`}
                errors={errors}
                register={register}
                leaderboard={leaderboard}
                onChange={(content) => onFormChange(content, index)}
              />
            </TabPanel>
          ))}
        </TabPanels>
      </Tabs>
      <Flex justifyContent="flex-end">
        <Button
          color="primary"
          onClick={handleSubmit(() =>
            onSubmit(players, eventId, formLeaderboards, onSuccess, onError)
          )}
        >
          Mock it!
        </Button>
        <Button outline color="primary" onClick={onCancel}>
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

export default EventLeaderboardsMock;
