import React from "react";
import SystemPreferencesComponent from "../components/SystemPreferences/SystemPreferences";
import {useConsolePreferences} from "../context/ConsolePreferenceContext";
import { useEffect,useCallback } from "react";
import Swal from 'sweetalert2';


/** @type {React.FC} */
const SystemPreferences = () => {
  const {consolePreferences, refresh,loading,saveOne,deleteOne,importData} = useConsolePreferences();

  useEffect(() =>{ refresh(); },[refresh])

  function handleDelete(pref){
    Swal.fire({
      title: "Delete Preference",
      text: `Are you sure you want to delete ${pref.Key}? This action can't be undone!`,
      showCancelButton: true,
      confirmButtonText: "Yes",
      showLoaderOnConfirm: true,
      preConfirm: () => deleteOne(pref).catch(x => ({error: true})),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (result.dismiss) return;
      
      if(!result.value.error) Swal.fire({title:  `Preference deleted successfully!`, icon: "success",});
      else Swal.fire({ title:  "Couldn't delete the preference.", icon: "error" });
    });
  }

  const handleExport = useCallback(() => {
    
    const project = localStorage.getItem("project") || consolePreferences.find(x => x.Key === "PROJECT").Value;
    const env = localStorage.getItem("env") || consolePreferences.find(x => x.Key === "ENV").Value;

    const jsonData = JSON.stringify(consolePreferences, null, 2);
    const url = window.URL.createObjectURL(new Blob([jsonData], {type: "application/json"}));
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `console_preferences_${project}-${env}_${(new Date()).valueOf()}.json`);
    document.body.appendChild(link);
    link.click();
  },[consolePreferences]);

  const handleImport = useCallback(async (file) => {
    const data = JSON.parse(await file.text());
    await importData(data);
  },[importData]);

  return (
    <>
      <SystemPreferencesComponent
        consolePreferences={consolePreferences}
        loading={loading}
        onSave={saveOne}
        onDelete={handleDelete}
        onImport={handleImport}
        onExport={handleExport}
      />
    </>
  );
};

export default SystemPreferences;
