import PlayersListing from "./views/PlayerListing.jsx";
import Player from "./views/Player.jsx";
import SnapshotPage from "./views/SnapshotPage.jsx";
import Docs from "./views/Docs.jsx";
import Snapshots from "./views/Snapshots.jsx";
import EventsProfiles from "./views/EventsProfiles.jsx";
import GlobalGift from "./views/GlobalGift.jsx";
import PurchasesListing from "./views/PurchasesListing.jsx";
import Events from "./views/Events.jsx";
import Profile from "./views/Profile.jsx";
import Settings from "./views/Settings.jsx";
import Timeline from "./views/Timeline.jsx";
import BuildListing from "./views/BuildListing.jsx";
import DataInformation from "./views/DataInformation";
import AttributeConfig from "./views/AttributesConfig";
import Leaderboard from "./views/Leaderboard";
import Leaderboards from "./views/Leaderboards";
import Favorites from "./views/Favorites";
import SystemPreferences from "./views/SystemPreferences";
import PlayerNicknames from "./views/PlayerNicknames";
import Guilds from "./views/Guilds";
import GuildDetails from "./views/GuildDetails";
import Regattas from "./views/Regattas.jsx";
import RegattaDefinitions from "./views/RegattaDefinitions.jsx";
import RegattaDetails from "./views/RegattaDetails.jsx";
import PlayerDeleteRequestsPage from "./views/PlayerDeleteRequests.jsx";
import MaulMessages from "./views/MaulMessages.jsx";
import BatchPlayerUpdate from "./views/BatchPlayerUpdate.jsx";

/** @type {(disabled: string[]) => any[]} */
const makeRoute = (disabled) => {
  const routes = [
    {
      collapse: true,
      name: "Players",
      icon: "ni ni-single-02 text-warning",
      state: "playersCollapse",
      views: [
        {
          path: "/players-listing",
          name: "All Players",
          icon: "ni ni-circle-08 text-warning",
          component: PlayersListing,
          layout: "/acp",
        },
        {
          path: "/player-update",
          name: "Batch Update",
          icon: "fas fa-wrench text-red",
          component: BatchPlayerUpdate,
          layout: "/acp",
        },
        {
          path: "/players-nicknames",
          name: "Hackers and Reports",
          icon: "ni ni-bullet-list-67 text-purple",
          component: PlayerNicknames,
          layout: "/acp",
        },
        {
          path: "/players-delete",
          name: "Delete Requests",
          icon: "fas fa-trash text-red",
          component: PlayerDeleteRequestsPage,
          layout: "/acp",
        },
      ],
    },
    {
      path: "/attributes-config",
      name: "Attributes Configuration",
      icon: "ni ni-settings text-info",
      component: AttributeConfig,
      layout: "/acp",
    },
    {
      path: "/favorites",
      name: "Favorites",
      icon: "fas fa-star text-yellow",
      component: Favorites,
      layout: "/acp",
    },
    {
      collapse: true,
      name: "Events",
      icon: "fas fa-flag text-purple",
      state: "dashboardsCollapse",
      views: [
        {
          path: "/events/:eventId?",
          name: "Manage Events",
          icon: "fas fa-sliders-h text-gray",
          component: Events,
          layout: "/acp",
        },
        {
          path: "/eventsprofiles",
          name: "Events Profiles",
          icon: "ni ni-sound-wave text-primary",
          component: EventsProfiles,
          layout: "/acp",
        },
      ],
    },
    {
      collapse: true,
      name: "Regatta",
      icon: "fas fa-users text-warning",
      state: "regattaCollapse",
      views: [
        {
          path: "/regattas",
          name: "Regattas",
          icon: "fas fa-sliders-h text-gray",
          component: Regattas,
          layout: "/acp",
        },
        {
          path: "/regattadefinitions",
          name: "Regatta Definitions",
          icon: "ni ni-bullet-list-67 text-primary",
          component: RegattaDefinitions,
          layout: "/acp",
        },
      ],
    },
    {
      path: "/leaderboards",
      name: "Leaderboard Definitions",
      icon: "fas fa-trophy text-black-50",
      component: Leaderboards,
      layout: "/acp",
    },
    {
      path: "/guilds",
      name: "Guilds",
      icon: "fas fa-home text-pink",
      component: Guilds,
      layout: "/acp",
    },
    {
      path: "/snapshots",
      name: "Snapshots",
      icon: "ni ni-camera-compact text-primary",
      component: Snapshots,
      layout: "/acp",
    },
    {
      /*
          collapse: true,
          name: "Global stuff",
          icon: "ni ni-planet text-purple",
          state: "dashboardsCollapse",
          views: [
              {
                  path: "/message",
                  name: "Snapshots",
                  component: Snapshots,
                  layout: "/acp"
              },
              {
                  path: "/message",
                  name: "Global Message",
                  component: GlobalGift,
                  layout: "/acp"
              }
          ]
      */
    },
    {
      path: "/purchases",
      name: "Purchases",
      icon: "ni ni-diamond text-green",
      component: PurchasesListing,
      layout: "/acp",
    },
    {
      collapse: true,
      name: "Messages",
      icon: "ni ni-email-83 text-purple",
      state: "msgCollapse",
      views: [
        {
          path: "/message",
          name: "Global Gifts",
          icon: "ni ni-box-2 text-pink",
          component: GlobalGift,
          layout: "/acp",
        },
        // {
        //   path: "/maulmessage",
        //   name: "Chat Message",
        //   icon: "ni ni-email-83 text-red",
        //   component: MaulMessages,
        //   layout: "/acp",
        // },
      ],
    },
    {
      collapse: true,
      name: "API Versioning",
      icon: "ni ni-world-2 text-purple",
      state: "apiCollapse",
      views: [
        {
          path: "/apiversioning",
          name: "API Timeline",
          icon: "ni ni-time-alarm text-purple",
          component: Timeline,
          layout: "/acp",
        },
        {
          path: "/buildlisting",
          name: "Build List",
          icon: "ni ni-bullet-list-67 text-info",
          component: BuildListing,
          layout: "/acp",
        },
      ],
    },
    {
      path: "/datainformation",
      name: "Data Information",
      icon: "ni ni-archive-2 text-warning",
      component: DataInformation,
      layout: "/acp",
    },
    {
      name: "--",
    },
    localStorage.getItem("env") === "dev" && {
      path: "/docs/index.html",
      name: "Swagger Docs",
      icon: "ni ni-books",
      component: Docs,
      layout: "/acp",
    },
    {
      path: "/myprofile",
      component: Profile,
      layout: "/acp",
    },
    {
      path: "/settings",
      component: Settings,
      layout: "/acp",
    },
    {
      path: "/player",
      component: Player,
      layout: "/acp",
    },
    {
      path: "/snapshot",
      component: SnapshotPage,
      layout: "/acp",
    },
    {
      path: "/leaderboard",
      component: Leaderboard,
      layout: "/acp",
    },
    {
      path: "/systempreferences",
      component: SystemPreferences,
      layout: "/acp",
    },
    {
      path: "/guild",
      component: GuildDetails,
      layout: "/acp",
    },
    {
      path: "/regatta",
      component: RegattaDetails,
      layout: "/acp",
    },
  ].filter(x => x);

  return routes.filter(x => !x.name || !disabled.find(feature => feature.toLowerCase() === x.name?.toLowerCase()));
};

export default makeRoute;
