import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Button,
  Flex,
  Switch,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";

/** @typedef {import("./RoleModal").Role} Role*/
/** @typedef {import("./RoleModal").Permission} Permission*/

/** 
 * @type {React.FC<{
 *  onSubmit: (role: Role) => void,
 *  onCancel: () => void,
 *  roleData:Role,
 *  permissions: Permission[],
 *  loading: boolean,
 *  onDelete: (id: number) => void
 * }>} 
 * */
const RoleForm = ({
  onSubmit,
  onCancel,
  roleData,
  onDelete,
  permissions: allPermissions,
  loading,
}) => {
  const [playerPermissions,setPlayerPermissions] = useState([]);

  useEffect(() => {
    setPlayerPermissions(roleData?.Permissions?.map(x => x.Id) || [])
  },[roleData]);


  const handleTogglePermission = (data) => {
    const newArr = playerPermissions.includes(data) ? playerPermissions.filter(x => x !== data) : [...playerPermissions, data];
    setPlayerPermissions(newArr);
  }

  const submitRole = (role) => {
    const rolePayload = {
      ...role,
      Id: roleData?.Id,
      PermissionIds: playerPermissions?.map((val) => Number.parseInt(val.toString()))
    }

    onSubmit(rolePayload);
  };

  const { register, errors, handleSubmit, reset } = useForm({
    reValidateMode: "onBlur",
    defaultValues:{
      Id: 0,
      Permissions: [],
      Name: ""
    }
  });

   useEffect(() => {
    reset(!roleData ? undefined : roleData);
   }, [reset, roleData]);

  return (
    <Box backgroundColor="whiteAlpha.400" textAlign="left">
      <form onSubmit={handleSubmit(submitRole)}>
      <Flex
        justifyContent="space-between"
        mb="1rem"
      >
      <FormControl width="25%" mr="5" isInvalid={Boolean(errors.Id)}>
        <FormLabel fontWeight={600}>Id</FormLabel>
        <Input
          name="Id"
          disabled={true}
          ref={register({
            required: true,
          })}
        />
      </FormControl>
      <FormControl flex={1} isInvalid={Boolean(errors.Name)}>
        <FormLabel fontWeight={600}>Role Name</FormLabel>
        <Input
          name="Name"
          ref={register({
            required: true,
          })}
        />
        <FormErrorMessage className="no-margin-bottom">
          Please use a valid name.
        </FormErrorMessage>
      </FormControl>
      </Flex>
      <FormControl isInvalid={Boolean(errors.Permissions)}>
        <Box maxH="30vh" paddingY="0.5rem" overflowY="scroll">
          {roleData?.Id && allPermissions?.map((permission) => (
            <Flex key={permission.Id + "kperm"} justifyContent="space-between">
              <Box>
                {permission.Name}
              </Box>
             <Switch
              onChange={() => handleTogglePermission(permission.Id)}
              isChecked={playerPermissions.includes(permission.Id )}
              size="lg" />
             </Flex>
          ))}
        </Box>
        </FormControl>
      <br/>
      <Flex justifyContent="space-between">
        <Flex>
        {
          roleData?.Id && roleData.Name !== 'Admin' && <Button
            onClick={() => onDelete(roleData?.Id)}
            disabled={!roleData?.Id}
            textAlign="left"
            display="inline"
            variant="solid"
            variantColor="red"
          >
            Delete
          </Button>
        }
        </Flex>
        <Flex>
        <Button
          onClick={handleSubmit(submitRole)}
          textAlign="left"
          display="inline"
          mr={5}
          variantColor="lumenPrimary"
        >
          Confirm
        </Button>
        <Button
          variant="outline"
          variantColor="lumenPrimary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        </Flex>
      </Flex>
      </form>
    </Box>
  );
};


export default RoleForm;
