import React from "react";
import AlertContainer from "../components/Layout/AlertContainer.jsx";

// reactstrap components
import { CardBody, Input, Col, Row, Card, Button, Container } from "reactstrap";

// core components
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";
import SimpleCard from "../components/Layout/SimpleCard.jsx";
import BuildTable from "../components/Versioning/Builds/BuildTable.jsx";

export default class BuildListing extends React.Component {
  constructor(props) {
    super(props);
    this.searchInput = React.createRef();

    this.state = {
      loading: true,
      serverResponse: false,
      buildNumberSearched: null,
      errorMessage: false,
      timeZoneSub: new Date().getTimezoneOffset() * 60000,
    };
  }

  setAlertNotification = (
    alert = false,
    type = "success",
    title = "Success!",
    subtitle = ""
  ) => {
    if (alert) {
      this.setState(
        {
          alert: true,
          alertType: type,
          alertTitle: title,
          alertSubtitle: subtitle,
        },
        () => {
          return true;
        }
      );
    } else {
      this.setState(
        { alert: false, alertType: "", alertTitle: "", alertSubtitle: "" },
        () => {
          return false;
        }
      );
    }
  };

  alertNotificationHandler = () => {
    if (this.state.alert) {
      return this.alertNotification(
        this.state.alertType,
        this.state.alertTitle,
        this.state.alertSubtitle
      );
    } else {
      return null;
    }
  };

  alertNotification = (type = "success", title = "Success!", subtitle = "") => {
    return (
      <AlertContainer
        type={type}
        title={title}
        close={this.props.close}
        text={subtitle}
      />
    );
  };

  searchBuildByBuildNumber = () => {
    this.setState({ buildNumberSearched: this.searchInput.current.value });
  };

  render() {
    return (
      <>
        <SimpleHeader clean name="Build List" parentName="Dashboard" />
        {this.alertNotificationHandler()}
        <Container className="mt--6" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardBody>
                  <Row>
                    <Col xs="6">
                      <Input
                        maxLength="80"
                        innerRef={this.searchInput}
                        className="form-control-sm"
                        placeholder="Build Number"
                        type="text"
                      />
                    </Col>
                    <Col xs="3">
                      <Button
                        className="btn-neutral"
                        color="default"
                        onClick={this.searchBuildByBuildNumber}
                        size="sm"
                      >
                        <i className="fas fa-search" /> Search
                      </Button>
                      <Button
                        onClick={() =>
                          this.setState({ buildNumberSearched: null })
                        }
                        size="sm"
                      >
                        Clear
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
          </Row>

          <BuildTable search={this.state.buildNumberSearched} />
          {this.state.errorMessage && (
            <SimpleCard title="An error occured when searching for API's">
              {this.state.errorMessage}
            </SimpleCard>
          )}
        </Container>
      </>
    );
  }
}
