import React from "react";
import {
  Button,
  Col,
  Container,
  Row,
  Table,
  Input,
} from "reactstrap";
import SearchInput from "../common/SearchInput";
import PaginationHeader from "../common/PaginationHeader";
import SimplePaginationComponent from "../common/SimplePaginationComponent";
import SimpleHeader from "../Headers/SimpleHeader";
import TableCard from "../Layout/TableCard";
import PropTypes from "prop-types";

import "../common/css/errorStyles.css";
import ErrorBox from "../common/ErrorBox";
import { userService } from "../../services/user.service";

const AttributesConfig = React.memo(
  ({
    page,
    objectsPerPage,
    total,
    handleObjectsPerPage,
    attributesConfig,
    handleDelete,
    handlePageChange,
    loading,
    toggleModalOpen,
    onSearch,
    onClear,
    children,
    onExportJson,
    onImportJson
  }) => {
    const CAN_EXPORT = userService.hasPermission("EXPORT_DATA");

    const header = (
      <Row className="flex align-items-center">
        <Col xs={12} xl={6}>
          <SearchInput
            placeholder="Attribute ID"
            onSearch={onSearch}
            onClear={onClear}
          >
            <Button
              color="primary"
              outline={!CAN_EXPORT}
              disabled={!CAN_EXPORT}
              onClick={onExportJson}
              size="sm"
            >
              Export .json
            </Button>
            <Button
              color="secondary"
              outline={!CAN_EXPORT}
              disabled={!CAN_EXPORT}
              onClick={onImportJson}
              size="sm"
            >
              Import Data
            </Button>
          </SearchInput>
        </Col>
        <Col xs={6} xl={6}>
          <PaginationHeader
            total={total}
            skip={page * objectsPerPage}
            take={objectsPerPage}
            onObjectsPerPageChange={handleObjectsPerPage}
          />
        </Col>
      </Row>
    );

    const table = (
      <Table responsive>
        <thead className="thead-light">
          <tr>
            <th>ID</th>
            <th>Name</th>
            <th>Json Path Expression</th>
            <th>Persistence Key Name</th>
            <th>Regex</th>
            <th>Value Type</th>
            <th>Mark as Main Attribute</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {attributesConfig.length > 0 &&
            attributesConfig.map((attributeConfig, index) => (
              <tr>
                <td>{attributeConfig.Id}</td>
                <td>{attributeConfig.Name}</td>
                <td>
                  <code>{attributeConfig.JsonPathExpression}</code>
                </td>
                <td>
                  <code>{attributeConfig.PersistenceKeyName}</code>
                </td>
                <td>
                  <code className="pl-2">{attributeConfig.Regex || "-"}</code>
                </td>
                <td>
                  <code className="pl-2">{attributeConfig.ValueType}</code>
                </td>
                <td className="text-center">
                  <Input type="checkbox" id={`attribute-box-${index}`} />
                </td>
                <td>
                  <Button
                    size="sm"
                    value={attributeConfig.Id}
                    className="btn-icon"
                    onClick={() => {
                      toggleModalOpen(attributeConfig);
                    }}
                    color="neutral"
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-edit" />
                    </span>
                  </Button>
                  <Button
                    size="sm"
                    className="btn-icon"
                    value={attributeConfig.Id}
                    onClick={() => handleDelete(attributeConfig.Id)}
                    color="neutral"
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-trash" />
                    </span>
                  </Button>
                </td>
              </tr>
            ))}
        </tbody>
      </Table>
    );

    const footer = total ? (
      <SimplePaginationComponent
        fetchPage={handlePageChange}
        currentPage={page}
        objectsPerPage={objectsPerPage}
        totalObjects={total}
      />
    ) : null;

    const isEmpty = attributesConfig.length === 0;

    return (
      <>
        <SimpleHeader
          name="Attributes Configuration"
          clean
          parentName="Dashboard"
        >
          <Button
            className="btn-neutral btn-round float-right btn-icon"
            color="default"
            disabled={!userService.hasPermission("CREATE_ATTRIBUTES")}
            onClick={toggleModalOpen}
            size="sm"
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-wrench" />
            </span>
            <span className="btn-inner--text">
              Create Attribute Configuration
            </span>
          </Button>
        </SimpleHeader>
        <Container fluid className="mt--6">
          <TableCard
            loading={loading}
            header={header}
            footer={total > objectsPerPage && footer}
          >
            {!isEmpty ? (
              table
            ) : (
              <ErrorBox>
                There is no Attributes Configurations to show.
              </ErrorBox>
            )}
          </TableCard>
        </Container>
        {children}
      </>
    );
  }
);

AttributesConfig.propTypes = {
  page: PropTypes.number,
  total: PropTypes.number,
  loading: PropTypes.bool,
  attributesConfig: PropTypes.arrayOf(PropTypes.object),
  objectsPerPage: PropTypes.number,
  handleDelete: PropTypes.func,
  handleObjectsPerPage: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default AttributesConfig;
