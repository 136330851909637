import React from "react";
import SimpleHeader from "../../Headers/SimpleHeader";
import { Button, Container } from "reactstrap";
import TableCard from "../../Layout/TableCard";
import Header from "./Header";
import ErrorBox from "../../common/ErrorBox";
import LoadingContainer from "../../Layout/LoadingContainer";
import SimplePaginationComponent from "../../common/SimplePaginationComponent";
import PlayerTable from "./PlayerTable";

import "./css/playersStyles.css";


/**
 * @typedef {{
 *  onSort: (field: string) => void,
 *  sortKey: string,
 *  sortDir: 'asc' | 'desc',
 * }} PlayerListingProps
 */
/**@type {React.FC<PlayerListingProps>} */
const PlayerListing = ({
  addRandomPlayer,
  listPlayers,
  filters,
  onSearch,
  onClear,
  onSort,
  sortDir,
  sortKey,
  objectsPerPage,
  totalObjects,
  currentPage,
  handleObjectsPerPage,
  handleFiltersChange,
  updatePage,
  searchValue,
  loading,
  players,
  selectedPlayer,
  handleSelectPlayer,
  handleSearch,
  attributeList
}) => {
  const contents = loading ? (
    <LoadingContainer />
  ) : (
    <PlayerTable
      players={players}
      selectedPlayer={selectedPlayer}
      searchValue={searchValue}
      updatePage={updatePage}
      handleSelectPlayer={handleSelectPlayer}
      onSort={onSort}
      sortDir={sortDir}
      sortKey={sortKey}
    />
  );

  const footer =
    totalObjects !== null ? (
      <SimplePaginationComponent
        fetchPage={listPlayers}
        currentPage={currentPage}
        objectsPerPage={objectsPerPage}
        totalObjects={totalObjects}
      />
    ) : null;

  return (
    <>
      <SimpleHeader name="Player List" clean parentName="Dashboard">
        <Button
          className="btn-neutral btn-round float-right btn-icon"
          color="default"
          href="#addrandom"
          id="tooltip969372949"
          onClick={addRandomPlayer}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-child" />
          </span>
          <span className="btn-inner--text">Generate random player</span>
        </Button>
      </SimpleHeader>
      <Container className="mt--6" fluid>
        <TableCard
          header={
            <Header
              filters={filters}
              onSearch={onSearch}
              onClear={onClear}
              objectsPerPage={objectsPerPage}
              totalObjects={totalObjects}
              currentPage={currentPage}
              handleObjectsPerPage={handleObjectsPerPage}
              handleFiltersChange={handleFiltersChange}
              handleSearch={handleSearch}
              attributeList={attributeList}
            />
          }
          loading={loading}
          footer={totalObjects > objectsPerPage && footer}
        >
          {players.length > 0 ? (
            contents
          ) : (
            <ErrorBox>There is no Players to show.</ErrorBox>
          )}
        </TableCard>
      </Container>
    </>
  );
};

export default PlayerListing;
