import classnames from "classnames";
import React from "react";
import {
  Button,
  ButtonGroup,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  UncontrolledTooltip,
} from "reactstrap";
import authHeader from "../../helpers/auth-header.js";
import httpClient from "../../helpers/http-client-singleton.js";
import "../common/css/tooltipStyles.css";

const http = httpClient.getInstance();

export default class ApiMatchModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      success: false,
      dropdownOpen: false,
      a_version: this.props.a_version,
      a_buildNumber: this.props.a_buildNumber,
      a_platform: this.props.a_platform,
      a_staticRoute: this.props.a_staticRoute,
      a_contentEnvironment: this.props.a_contentEnvironment,
      a_isStaging: this.props.a_isStaging,
      a_updateRequired: this.props.a_updateRequired,
      a_debugger: this.props.a_debugger,
      a_description: this.props.a_description,
      enableInput: this.props.type === "updateBuild",
    };
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  toggleOff() {
    this.setState({
      modal: false,
    });
    this.props.close();
  }

  handleChange(e) {
    this.setState(
      {
        [e.name]: e.value,
      },
      () => {
        if (this.state.a_buildNumber !== "" && this.state.a_version !== "") {
          this.setState({ enableInput: true });
        }
      }
    );
  }

  handleChangeToggle(e) {
    this.setState({
      [e.name]: e.checked,
    });
  }

  handleSubmit = () => {
    const bodyData = {
      buildNumber: parseInt(this.state.a_buildNumber),
      version: this.state.a_version,
      platform: this.state.a_platform,
      staticRoute: this.state.a_staticRoute,
      contentEnvironment: this.state.a_contentEnvironment,
      isStaging: this.state.a_isStaging,
      updateRequired: this.state.a_updateRequired,
      debuggerBuild: this.state.a_debugger,
      description: this.state.a_description
    };

    http
      .put("/apimatch/", bodyData, {
        headers: authHeader("application/json"),
      })
      .then((response) => {
        if (response.status) {
          this.props.notify(
            "Success",
            "success",
            "Versioning created successfully!"
          );
        } else {
          this.props.notify("Error", "error", response.message);
        }
      })
      .catch((err) => {
        this.props.notify("Error", "error", err.message);
      });
    this.props.close();
  };

  handleDelete = () => {
    http
      .delete(
        "/apimatch/" + this.state.a_platform + "/" + this.state.a_buildNumber,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status) {
          this.props.notify(
            "Success",
            "success",
            "Versioning created successfully!"
          );
        } else {
          this.props.notify("Error", "error", response.message);
        }
      })
      .catch((err) => {
        this.props.notify("Error", "error", err.message);
      });
    this.props.close();
  };

  toggleDropdown = () => {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.modal}
          toggle={this.props.close}
          className={this.props.className}
        >
          <ModalHeader toggle={this.props.close}>
            <ButtonGroup
              className="btn-group-toggle"
              data-toggle="buttons"
              style={{ top: "8%" }}
            >
              <Button
                size="sm"
                disabled
                className={classnames({
                  active: this.state.a_platform === "android",
                })}
                color="secondary"
                onClick={() => this.setState({ a_platform: "android" })}
              >
                <input
                  autoComplete="off"
                  name="options"
                  type="radio"
                  value={this.state.a_platform === "android"}
                />
                <i
                  className="fab fa-android"
                  style={{ margin: "0 .125rem 0 .125rem" }}
                />
              </Button>
              <Button
                size="sm"
                disabled
                className={classnames({
                  active: this.state.a_platform === "ios",
                })}
                color="secondary"
                onClick={() => this.setState({ a_platform: "ios" })}
              >
                <input
                  autoComplete="off"
                  name="options"
                  type="radio"
                  value={this.state.a_platform === "ios"}
                />
                <i
                  className="fab fa-apple"
                  style={{ margin: "0 .125rem 0 .125rem" }}
                />
              </Button>
            </ButtonGroup>
            <strong> Add a new build</strong>
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Row>
                <Col md={2}>
                  <Label for="lockedKey">
                    Version
                    <i
                      id="api-match-version-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip
                    placement="right"
                    target="api-match-version-info"
                  >
                    The StarWars API version that the build will use.
                  </UncontrolledTooltip>
                  {this.props.type === "addVersion" ? (
                    <Input
                      size="sm"
                      type="text"
                      name="a_version"
                      id="lockedDeviceId"
                      placeholder={this.state.a_version}
                      onChange={(e) => this.handleChange(e.target)}
                    />
                  ) : (
                    <Input
                      size="sm"
                      type="text"
                      name="a_version"
                      disabled="disabled"
                      id="lockedDeviceId"
                      placeholder={this.state.a_version}
                      onChange={(e) => this.handleChange(e.target)}
                    />
                  )}
                </Col>
                <Col md={4}>
                  <Label for="lockedDeviceId">
                    Build Number
                    <i
                      id="api-match-build-number-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip target="api-match-build-number-info">
                    The build number that will be saved.
                  </UncontrolledTooltip>
                  <Input
                    size="sm"
                    type="number"
                    min={0}
                    name="a_buildNumber"
                    id="lockedDeviceId"
                    placeholder={this.props.a_buildNumber}
                    onChange={(e) => this.handleChange(e.target)}
                  />
                </Col>
                <Col md={6}>
                  <Label for="lockedDeviceId">
                    Content Bucket
                    <i
                      id="api-match-content-bucket-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip target="api-match-content-bucket-info">
                    The bucket that has in-game information.
                  </UncontrolledTooltip>
                  <Input
                    className="form-control-sm"
                    name="a_contentEnvironment"
                    onChange={(e) => this.handleChange(e.target)}
                    value={this.state.a_contentEnvironment}
                    type="text"
                  />
                </Col>
              </Row>
              <Row className="py-1">
                <Col xs={12}>
                  <Label for="lockedDeviceId">
                    Description
                    <i
                      id="api-match-description-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip target="api-match-description-info">
                    A description to this build.
                  </UncontrolledTooltip>
                  <Input
                    className="form-control-sm"
                    name="a_description"
                    onChange={(e) => this.handleChange(e.target)}
                    value={this.state.a_description}
                    type="text"
                  />
                </Col>
              </Row>
              <Row style={{ paddingTop: "1rem" }}>
                <Col md={6}>
                  <label
                    className="custom-toggle"
                    style={{ top: "10%", display: "inline-block" }}
                  >
                    <input
                      type="checkbox"
                      name="a_debugger"
                      onChange={(e) => this.handleChangeToggle(e.target)}
                      checked={this.state.a_debugger}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <Label className="px-2" for="lockedDebuggerBuild">
                    Debugger Build
                    <i
                      id="api-match-debugger-build-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip target="api-match-debugger-build-info">
                    Only players with a SRDebugger key will access this build.
                  </UncontrolledTooltip>
                </Col>
                <Col md={6}>
                  <label
                    className="custom-toggle"
                    style={{ top: "10%", display: "inline-block" }}
                  >
                    <input
                      type="checkbox"
                      name="a_isStaging"
                      onChange={(e) => this.handleChangeToggle(e.target)}
                      checked={this.state.a_isStaging}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <Label className="px-2" for="lockedDeviceId">
                    Staging Build
                    <i
                      id="api-match-staging-build-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip
                    placement="left"
                    target="api-match-staging-build-info"
                  >
                    This build will use the staging environment.
                  </UncontrolledTooltip>
                </Col>
              </Row>
              <Row>
                <Col md={6}>
                  <label
                    className="custom-toggle"
                    style={{ top: "10%", display: "inline-block" }}
                  >
                    <input
                      type="checkbox"
                      name="a_staticRoute"
                      onChange={(e) => this.handleChangeToggle(e.target)}
                      checked={this.state.a_staticRoute}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <Label className="px-2" for="lockedDeviceId">
                    Unique Access
                    <i
                      id="api-match-unique-access-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip target="api-match-unique-access-info">
                    This build can only be accessed using it's specific number.
                  </UncontrolledTooltip>
                </Col>
                <Col md={6}>
                  <label
                    className="custom-toggle"
                    style={{ top: "10%", display: "inline-block" }}
                  >
                    <input
                      type="checkbox"
                      name="a_updateRequired"
                      onChange={(e) => this.handleChangeToggle(e.target)}
                      checked={this.state.a_updateRequired}
                    />
                    <span
                      className="custom-toggle-slider rounded-circle"
                      data-label-off="No"
                      data-label-on="Yes"
                    />
                  </label>
                  <Label className="px-2" for="lockedDeviceId">
                    Update Required
                    <i
                      id="api-match-update-required-info"
                      className="fas fa-info-circle info-tooltip d-inline"
                    />
                  </Label>
                  <UncontrolledTooltip
                    placement="left"
                    target="api-match-update-required-info"
                  >
                    All players in this build will receive a notification to
                    update the game to a newer build.
                  </UncontrolledTooltip>
                </Col>
              </Row>
            </FormGroup>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={this.handleSubmit}>
              {this.props.type === "updateBuild" ? "Update" : "Create"}
            </Button>{" "}
            {this.props.type === "updateBuild" ? (
              <Button color="warning" onClick={() => this.handleDelete()}>
                Delete
              </Button>
            ) : (
              ""
            )}
            <Button color="secondary" onClick={this.props.close}>
              Cancel
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
