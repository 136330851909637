import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import GiftMessageForm from "./GiftMessageForm";

const MessageModal = ({ open, toggle, onSubmit, playerId }) => {
  return (
    <Modal backdrop="static" isOpen={open} toggle={toggle} size="lg">
      <ModalHeader className="p-4" toggle={toggle}>
        {playerId} Message
      </ModalHeader>
      <ModalBody>
        <GiftMessageForm
          onSubmit={(message) => {
            onSubmit(message);
            toggle();
          }}
          onCancel={toggle}
          message={{ PlayerId: playerId }}
        />
      </ModalBody>
    </Modal>
  );
};

export default MessageModal;
