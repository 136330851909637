import React, { useCallback, useEffect, useState } from "react";
import GuildsComponent from "../components/Guilds/Guilds";

import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";

const http = httpClientCreator.getInstance();

const Guilds = () => {
  const [search, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(0);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [loading, setLoading] = useState(false);
  const [formLoading, setFormLoading] = useState(false);
  const [guilds, setGuilds] = useState([]);
  const [sortDir, setSortDir] = useState('desc');
  const [sortKey, setSortKey] = useState('id');

  const getPage = useCallback(
    (page) => {
      setLoading(true);
      http
        .get("/guilds/listing", {
          params: {
            skip: page * objectsPerPage,
            take: objectsPerPage,
            filter: search,
            sortDir: sortDir,
            sortKey: sortKey
          },
          headers: authHeader(),
        })
        .then((response) => {
          setGuilds(response.data.data.Guilds);
          setTotal(response.data.data.Total);
          setCurrentPage(page);
        })
        .finally(() => setLoading(false));
    },
    [objectsPerPage, search, sortDir, sortKey]
  );

  const createGuild = (guild) => {
    setFormLoading(true);
    return http
      .post("/guilds/", guild, { headers: authHeader() })
      .then(() => getPage(0))
      .finally(() => setFormLoading(false));
  };

  const updateGuild = (guild) => {
    setFormLoading(true);
    return http
      .put("/guilds/", guild, { headers: authHeader() })
      .then(() => getPage(0))
      .finally(() => setFormLoading(false));
  };

  const onGuildSubmit = (guild) => {
    if (guild.Id) {
      return updateGuild(guild);
    } else {
      return createGuild(guild);
    }
  };

  const onGuildDelete = (guildId) => {
    return http.delete(`/guilds/${guildId}/delete`, { headers: authHeader() });
  };

  useEffect(() => getPage(0), [getPage]);

  function handleSort(key){
    const newSortDir = sortKey === key ? sortDir === 'asc' ? 'desc' : 'asc' : 'desc';

    setSortKey(key);
    setSortDir(newSortDir);
  }

  return (
    <GuildsComponent
      currentPage={currentPage}
      objectsPerPage={objectsPerPage}
      total={total}
      guilds={guilds}
      onObjectsPerPageChange={setObjectsPerPage}
      getPage={getPage}
      onSearch={setSearch}
      loading={loading}
      onSubmit={onGuildSubmit}
      onDelete={onGuildDelete}
      formLoading={formLoading}
      onSort={handleSort}
      sortDir={sortDir}
      sortKey={sortKey}
    />
  );
};

export default Guilds;
