import React from 'react';
import { Table as StrapTable, Row, Col, Button } from "reactstrap";
import { Link } from "react-router-dom";
import SearchInput from "../common/SearchInput";
import ErrorBox from '../common/ErrorBox';
import { LoadingIndicator } from 'devextreme-react/bar-gauge';


/**
 * @template {Record<string,any>} T
 * @typedef {{type: "BUTTON"; onClickEntry: (data: T, event:React.MouseEvent) => void;} 
 *  | {type: "LINK"; to: (data: T,event:React.MouseEvent)  => string; }
 *  | {type: "EXPORT_ONLY"; } 
 * } RenderType
 */

/**
 * @template {Record<string,any>} T

 * @typedef {{ 
 * transform?: (data: any) => React.ReactNode;
 * render?: (entity: T, index?: number, arr?: T[])  => React.ReactNode;
 * nodeProps?: Partial<React.TdHTMLAttributes<HTMLTableDataCellElement>>
 * } 
 * & RenderType<T>
 * } BodyOptions 
 * */


/** @typedef {{ rowStyle: React.CSSProperties,transform?: (data: any) => React.ReactNode, render?: (entity: T, index?: number, arr?: T[])  => React.ReactNode} & ({type: "BUTTON" onClickEntry: (data: T, event:React.MouseEvent) => void;} | {type: "LINK" to: string | (data: T)  => string}) } BodyOptions */

/** 
 * @typedef {{
 *  title?: React.ReactNode;
 *  sortStatus?: { dir: 'asc' | 'desc', isSorted?: boolean }
 * } & Partial<React.HTMLAttributes<HTMLTableCellElement>>} HeaderOptions 
 * */


/** 
 * @template {Record<string,any>} T
 * @typedef {{
 *  ignore?: boolean;
 *  transform?: (data: any) => string;
 *  value?: (entity: T, index?: number, arr?: T[])  => string;
 * }} ExportOptions 
 * */

/** 
 * @template {Record<string,any>} T
 * @typedef {{
 * key: keyof T,
 * headerOptions?: HeaderOptions,
 * bodyOptions?: BodyOptions<T>,
 * exportOptions: ExportOptions<T>
 * }} TableKey 
 * */



/** 
 * @template {Record<string,any>} T 
 * @typedef {{
 *  data: T[];
 *  keys: (keyof T | TableKey<T>) [];
 *  order: 'ASC' | 'DESC',
 *  onEmpty?: React.ReactNode,
 *  loading?: boolean,
 *  rowKeyfn?: (entry:T, i:number, arr:T[]) => string | number,
 * }} TableProps
 */

/**
 * @template T
 * @type {React.FC<TableProps<T>>} */
const Table = ({ children, data = [], keys, order, onEmpty, loading, rowKeyfn }) => {

  if (loading) return (<LoadingIndicator />)
  if (!data.length) return (<ErrorBox>{onEmpty || "The table is Empty"}</ErrorBox>)
  return (
    <StrapTable hover className="align-items-center table-flush" striped responsive>
      <thead className="thead-light">
        <tr>
          {keys.map(key => {
            const { sortStatus, title, ...options } = (typeof key === 'object' ? key.headerOptions || {} : {});

            const cellValue = typeof key === 'object' ? title || key.key : key;

            //Ignore the Export Only Rows
            if (typeof key === 'object' && key.bodyOptions?.type === "EXPORT_ONLY")
              return React.Fragment;

            const sortOptions = sortStatus ? {
              className: "cursor-pointer sort" + (options.className || ""),
              "data-sort": sortStatus.isSorted || undefined,
              "data-sort-order": sortStatus.dir,
              onClick: sortStatus.action
            } : {};

            return (
              <th key={"thkey" + cellValue} scope="col" {...sortOptions} {...options}>
                {cellValue}
              </th>)
          })}
        </tr>
      </thead>
      <tbody>
        {
          ((order === 'ASC' ? data : [...data]).flatMap((entry, i, arr) => {
            const identifier = rowKeyfn ? rowKeyfn(entry, i, arr) : entry?.["Id"] || i;
            return (
              <tr key={`entry_${identifier}`}>
                {keys.map(keyData => {
                  const options = typeof keyData === 'object' ? keyData?.bodyOptions || {} : {};
                  const key = typeof keyData === 'object' ? keyData.key : keyData;

                  if (options.type === 'EXPORT_ONLY') return React.Fragment;

                  const value = options?.render
                    ? options.render(entry, i, arr)
                    : options.transform
                      ? options.transform(entry[key])
                      : entry[key];

                  const valDisplay = identifier === value ? <b>{value}</b> : <span>{value}</span>;
                  const props = options?.nodeProps || {};

                  switch (options?.type) {
                    case "BUTTON": return (
                      <td {...props} key={`${key}rw${identifier}`} onClick={(e) => options.onClickEntry(entry, e)}>
                        {valDisplay}
                      </td>
                    );
                    case "LINK": return (
                      <td {...props} key={`${key}rw${identifier}`}>
                        <Link className="text-primary" to={typeof options.to === 'string' ? options.to : options.to(entry)}>
                          {valDisplay}
                        </Link>
                      </td>
                    );
                    default: return (
                      <td {...props} key={`${key}rw${identifier}`} style={options.rowStyle}>
                        {valDisplay}
                      </td>
                    );
                  }

                })}
              </tr>
            )
          }))}
        {children}
      </tbody>
    </StrapTable>
  )
}

/**
 * @type {React.FC<{
 * onSearch: (data: string)=> void,
 * onClear: () => void,
 * inputPlaceholder?: string,
 * onExportCsv: () => void
 * }>} 
 * */
export const Header = ({ children, onClear, onSearch, inputPlaceholder, onExportCsv }) => (
  <>
    <Row className="no-gutter align-items-center">
      <Col>
        <SearchInput
          onSearch={onSearch}
          onClear={onClear}
          placeholder={inputPlaceholder}
        />
      </Col>
      <Col>
        <div>
          {onExportCsv &&
            <Button onClick={onExportCsv} size="sm">
              Export .csv
            </Button>
          }
        </div>
      </Col>
      <Col>{children}</Col>
    </Row>
  </>
);

const GenericTable = {
  Table,
  Header
}

export default GenericTable;