import React, { useState } from "react";
import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import GlobalGiftComponent from "../components/Messages/GlobalGift";

const http = httpClientCreator.getInstance();

const GlobalGift = () => {
  const [globalMessages, setGlobalMessages] = useState([]);
  const [playerMessages, setPlayerMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  const getGlobalMessages = () => {
    setLoading(true);
    http
      .get("/messages/global", { headers: authHeader(), })
      .then((response) => setGlobalMessages(response.data.data))
      .finally(() => setLoading(false));
  };

  const getPlayerMessages = () => {
    setLoading(true);
    http
      .get("/messages/players", { headers: authHeader(), })
      .then((response) => setPlayerMessages(response.data.data))
      .finally(() => setLoading(false));
  };

  const sendMessage = (msg, onSuccess, onError) => {
    http
      .put("/messages/global/", msg, { headers: authHeader(), })
      .then(onSuccess)
      .catch(onError);
  };

  const sendMessageToList = (msg, onSuccess, onError) => {
    http
      .put("/messages/multiplayers", msg, {
        headers: authHeader(),
      })
      .then(onSuccess)
      .catch(onError);
  };

  const onMessageSubmit = (msg, onSuccess, onError) => {
    if (msg.PlayerIds) {
      sendMessageToList(msg, onSuccess, onError);
    } else {
      sendMessage(msg, onSuccess, onError);
    }
  };

  return (
    <GlobalGiftComponent
      playerMessages={playerMessages}
      globalMessages={globalMessages}
      onSubmit={onMessageSubmit}
      getGlobalMessages={getGlobalMessages}
      getPlayerMessages={getPlayerMessages}
      loading={loading}
    />
  );
};

export default GlobalGift;
