import moment from "moment"


const parseMoment = (date, parseAsUtc) => {
  if(!parseAsUtc) return moment.utc(date).local();
  else return moment(date);
}

/**
 * 
 * @param {moment.MomentInput | {raw: moment.Moment}} date
 * @param {boolean} [utc]
 */
export function parseDate(date, utc = false){
  const mDate = typeof date === 'object' && !!date?.['raw'] ? parseMoment(date['raw'],utc) :  parseMoment(date,utc); 
    
  return {
    raw: mDate,
    isUTC: () => mDate.isUTC(),
    toUTC: () => parseDate(mDate.clone().utc(),true),
    addMin: (minutes) => parseDate(mDate.clone().add(minutes,'minutes'),utc),
    addHour: (hours) => parseDate(mDate.clone().add(hours,'hours'),utc),
    toDate: () => mDate.toDate(),
    isBefore: (date) => mDate.isBefore(date), 
    isAfter: (date) => mDate.isAfter(date), 
    iso: () => mDate.toISOString(),
    date: () => mDate.format('L'),
    inputFull: () => mDate.format('YYYY-MM-DDTHH:mm'),
    inputDate: () => mDate.format('YYYY-MM-DD'),
    time: () => mDate.format('HH:mm'),
    dateTime: () => mDate.format('L HH:mm'),
    setTime: (time) => parseDate(mDate.clone().startOf('day').add(moment.duration(time)),utc)
  };
}

/**
 *  @param {moment.DurationInputArg1} timeSpan 
 *  @param {moment.unitOfTime.DurationConstructor} [unit="minutes"]  - defaults to minutes
 * 
*/
export function getHumanizedDuration(timeSpan, unit = "minutes"){
  return moment.duration(timeSpan,unit).humanize()
}

/**
 * @typedef { ReturnType<parseDate> } SwDate
 */
