import {  Flex, FormControl, FormLabel,Input,Button } from '@chakra-ui/core';
import React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import GenericTable from '../Table/GenericTable';
import { isRegattaFinished } from './utils';

/** @typedef {import('./RegattaDetailsGroupList').RegattaGroup} RegattaGroup */
/** @typedef {import('./RegattaDetailsGroupList').RegattaGuild} RegattaGuild */
/** @typedef {import('./RegattaDetailsComponent').Regatta} Regatta */

/** @template T @typedef {import('../Table/GenericTable').TableKey<T>} Key<T> */
/** @type {(onRemove: (g: RegattaGuild) => void, reg: Regatta) => Key<RegattaGuild>[]} */
const GUILD_KEYS = (onRemove,reg) => [
  {
    key: 'GuildId',
    headerOptions: {title: "Id"},
    bodyOptions:{
      type: "LINK",
      to: (guild) => `/acp/guild/${guild.GuildId}` 
    }
  },
  {
    key: 'GuildName',
    headerOptions: {title: "Name"}
  },
  {
    key: 'AverageLevel',
  },
  {
    key: 'Score',
  },
  {
    key: "Remove Guild",
    bodyOptions: {render:  (def,i,arr) => (
      <Flex>
      <Button 
      disabled={reg && isRegattaFinished(reg)}
      className="btn-icon btn-neutral" 
      size="sm" 
      color="default" 
      onClick={() => onRemove?.(def)} >
        <i className="fas fa-trash" />
      </Button>
      </Flex>
    )}
  }
]


/**
 * 
 * @typedef {{
 *   modalOpen: boolean,
 *   loading: boolean,
 *   onCancel: () => void,
 *   onRemoveGuild: (guildId: string, groupId: string) => void,
 *   group: RegattaGroup,
 *   regatta: 
 * }} RegattaGroupModalProps
 */

/**
 * @type {React.FC<RegattaGroupModalProps>}*/
const RegattaGroupModal = ({modalOpen,loading,regatta,onCancel, group, onRemoveGuild}) => {
  if(!modalOpen) return <></>;



  return (
    <Modal style={{maxWidth: "max-content"}} isOpen={modalOpen} toggle={onCancel}>
    <ModalHeader >
      <span style={{color: group.IsDeleted ? "warning" : undefined}}>Regatta Group</span>
    </ModalHeader>
    <ModalBody>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            flexDir="column"
            >
            <Flex flex={1} flexDir="row" justifyContent="space-between" alignItems="flex-end">
            <FormControl width="100%" mr="5" >
              <FormLabel>Group Id</FormLabel>
              <Input
                name="Id"
                disabled={true}
                value={group.GroupId}
              />
            </FormControl>
            <FormControl>
  
            </FormControl>
            </Flex>
            <Flex flex={1} flexDir="row" justifyContent="space-between">
            <FormControl  mr="5" >
              <FormLabel >Minimum Level</FormLabel>
              <Input
                disabled={true}
                name="MinLevel"
                value={group.MinLevel}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Maximum Level</FormLabel>
              <Input
                disabled={true}
                name="MaxLevel"
                value={group.MaxLevel}
              />
            </FormControl>
            </Flex>
          </Flex>
          <FormControl>
            <FormLabel>Guilds</FormLabel>
            <GenericTable.Table data={group.Guilds?.sort((a,b) => b.Score - a.Score)} keys={GUILD_KEYS(onRemoveGuild,regatta)}/>
          </FormControl>
          <br/>
          <Flex justifyContent="space-between">
          <Flex>
          <Button
            mr="1rem"
            variant="outline"
            variantColor="lumenPrimary"
            onClick={onCancel}
          >
            Close
          </Button>
          </Flex>
          </Flex>
        </>
      )}
    </ModalBody>
  </Modal>
  );
}

export default RegattaGroupModal;