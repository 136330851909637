import React from "react";
import { Redirect } from "react-router-dom";

// nodejs library that concatenates classes
import classnames from "classnames";
import NotificationAlert from "react-notification-alert";
import authHeader from "../helpers/auth-header.js";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { userService } from "../services/user.service.js";
import Swal from "sweetalert2";

import httpClient from "../helpers/http-client-singleton.js";
import { userContext } from "../context/UserContext.jsx";

const http = httpClient.getInstance();

class Login extends React.Component {
  constructor(props) {
    super(props);

    userService.logout(false);

    this.state = {
      email: "",
      password: "",
      valid: true,
      submitted: false,
      serverInfo: null,
      modalEmail: false,
    };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  handleSubmit(e) {
    e.preventDefault();

    this.setState({ valid: true, submitted: true });
    if (this.state.email && this.state.password) {
      this.authenticate();
    }
  }

  componentDidMount() {
    document.body.classList.add("bg-default");
  }
  componentWillUnmount() {
    document.body.classList.remove("bg-default");
  }

  notifyEmail(type, msg) {
    let options = {
      place: "tc",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            {type === "success" ? "Success" : type === "danger" ? "Error" : ""}
          </span>
          <span data-notify="message">{msg}</span>
        </div>
      ),
      type: type,
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  authenticate() {
    this.context.login(this.state.email, this.state.password).then((user) => {
      if (!user) {
        this.setState({ submitted: false, valid: false });
        return;
      }

      this.setState({ submitted: false, redirect: true });
      const { from } = this.props.location?.state || { from: { pathname: "/" } };

      if (this?.props?.history)
        this.props.history.push(from);
    });

  }

  toggleModalEmail = () => {
    Swal.fire({
      type: "question",
      title: "Reset Password",
      html:
        "<p>Please confirm that you want to reset the password for this user: <b>" +
        this.state.email +
        "</b></p>",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value)
        http
          .post("/users/reset/" + this.state.email, null, {
            headers: authHeader(),
          })
          .then((res) => {
            if (res.status === 200)
              this.notifyEmail(
                "success",
                "Email Successfully reseted. Take a look at your email."
              );
          })
          .catch((error) => {
            if (error.response.status === 400)
              this.notifyEmail("danger", error.response.data);
          });
    });
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to="/" />;
    }
    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <div className="main-content" ref="mainContent">
          <div
            className="header py-7 pb-lg-8 pt-lg-4"
            style={{
              background: "linear-gradient(87deg, #e7ff5f 0, #ff9b7c 100%)",
            }}
          >
            <Container className="text-center">
              <img
                className="img-responsive"
                src={require("assets/img/brand/lumen_games.png")}
                style={{ width: "200px" }}
                alt="logo"
              />
            </Container>
            <div className="separator separator-bottom separator-skew zindex-100">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                preserveAspectRatio="none"
                version="1.1"
                viewBox="0 0 2560 100"
                x="0"
                y="0"
              >
                <polygon
                  className="fill-default"
                  points="2560 0 2560 100 0 100"
                />
              </svg>
            </div>
          </div>

          <Container className="mt--6 pb-5">
            <Row className="justify-content-center">
              <Col lg="5" md="7">
                <Card
                  className="border-0 mb-0"
                  style={{ backgroundColor: "#f4f5f7" }}
                >
                  <CardBody className="px-lg-5 py-lg-5">
                    <div className="text-center text-muted mb-4">
                      <small>Sign in with your credentials</small>
                    </div>
                    <Form role="form" onSubmit={this.handleSubmit}>
                      <FormGroup
                        className={classnames("mb-3", {
                          focused: this.state.focusedEmail,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-email-83" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="email"
                            required
                            name="email"
                            value={this.state.email}
                            onChange={this.handleChange}
                            valid={this.state.valid}
                            invalid={!this.state.valid}
                          />
                        </InputGroup>
                      </FormGroup>
                      <FormGroup
                        className={classnames({
                          focused: this.state.focusedPassword,
                        })}
                      >
                        <InputGroup className="input-group-merge input-group-alternative">
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="ni ni-lock-circle-open" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type="password"
                            required
                            name="password"
                            value={this.state.password}
                            onChange={this.handleChange}
                            valid={this.state.valid}
                            invalid={!this.state.valid}
                          />
                        </InputGroup>
                      </FormGroup>
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id=" customCheckLogin"
                          type="checkbox"
                        />
                        <label
                          className="custom-control-label"
                          htmlFor=" customCheckLogin"
                        >
                          <span className="text-muted">Remember me</span>
                        </label>
                      </div>
                      <div className="text-center">
                        <Button
                          className="my-4"
                          outline={this.state.submitted}
                          color="info"
                          type="submit"
                        >
                          {this.state.submitted ? (
                            <>
                              <i className="fa fa-circle-notch fa-spin" />{" "}
                            </>
                          ) : null}
                          Sign in
                        </Button>
                      </div>
                      <a
                        onClick={() => {
                          this.toggleModalEmail();
                        }}
                      >
                        <small>
                          <u>Forgot your password?</u>
                        </small>
                      </a>
                    </Form>
                  </CardBody>
                </Card>
                <Row className="mt-3">
                  <Col>
                    <small>
                      Commit{" "}
                      <strong>
                        {process.env.REACT_APP_COMMIT}
                      </strong>
                      , branch{" "}
                      <strong>
                        {process.env.REACT_APP_BRANCH}
                      </strong>
                      .
                    </small>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <small>
                      {this.state.serverInfo &&
                        this.state.serverInfo.commitDate}
                    </small>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
      </>
    );
  }
}

Login.contextType = userContext;

export default Login;
