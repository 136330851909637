import React, { useCallback, useEffect, useState } from "react";
import authHeader from "../helpers/auth-header";
import PlayerListingComponent from "../components/Player/PlayerListing/PlayerListing";

import httpClient from "../helpers/http-client-singleton";
import qs from "qs";

const http = httpClient.getInstance();

const NO_PLAYER_SELECTED = -1;
const DEFAULT_SORT_KEY = "creationDate";
const DEFAULT_SORT_DIR = 'desc';
const REVERSE_SORT_DIR = 'asc';
const reverseSortDir = dir => dir === DEFAULT_SORT_DIR ? REVERSE_SORT_DIR : DEFAULT_SORT_DIR;
/**
 * @typedef {{value: string, type: 'playerId' | 'deviceId' | 'nickname', sortKey: string, sortDir: 'asc' | 'desc'}} SearchPayload
 * 
 */

const PlayerListing = () => {
  const [players, setPlayers] = useState([]);
  const [loading, setLoading] = useState(false);
  /**
   * @type {[SearchPayload, (val: SearchPayload | (val: SearchPayload) => SearchPayload) => void ]}*/
  const [search, setSearch] = useState({ value: "", type: "playerId", sortKey: DEFAULT_SORT_KEY, sortDir: DEFAULT_SORT_DIR });
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [filters, setFilters] = useState([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalObjects, setTotalObjects] = useState(0);
  const [selectedPlayer, setSelectedPlayer] = useState(NO_PLAYER_SELECTED);
  const [attributesList, setAttributesList] = useState([]);

  useEffect(() => {
    http.get("/attributes/configurations")
      .then((response) => {
        setAttributesList(response.data.data.Configurations.map(a => a.Name));
      });
  }, [])


  const makePlayerListRequest = useCallback(
    (page) => {
      http
        .get("/players/listing", {
          headers: authHeader(),
          params: {
            filter: search.value,
            filterType: search.type,
            sortKey: search.sortKey,
            sortDir: search.sortDir,
            skip: page * objectsPerPage,
            take: objectsPerPage,
            ...filters,
          },
          paramsSerializer: (param) => qs.stringify(param, { allowDots: true }),
        })
        .then((response) => {
          setCurrentPage(page);
          setPlayers(response.data.data.Result || []);
          setTotalObjects(response.data.data.Total || 0);
          setSelectedPlayer(NO_PLAYER_SELECTED);
        })
        .finally(() => setLoading(false));
    },
    [objectsPerPage, search, filters]
  );

  const handleObjectsPerPage = (number) => {
    setObjectsPerPage(number);
  };

  const listPlayers = useCallback(
    (pageNumber) => {
      setLoading(true);
      makePlayerListRequest(pageNumber);
    },
    [makePlayerListRequest]
  );

  const updatePage = useCallback(() => {
    listPlayers(0);
  }, [listPlayers]);

  useEffect(() => {
    updatePage();
  }, [filters, objectsPerPage, updatePage]);

  const handleFiltersChange = (filters) => {
    setFilters(filters);
  };

  const onSearch = (value) => {
    handleSearch({ value });
  };

  /**
   * @type  {(searchObject: Partial<SearchPayload>) => void} */
  const handleSearch = useCallback((searchObject) => {
    setSearch((search) => ({ ...search, ...searchObject }));
  }, []);

  const onSort = (key) => {
    const sortDir = search.sortKey === key ? reverseSortDir(search.sortDir) : DEFAULT_SORT_DIR;

    setSearch((search) => ({ ...search, sortKey: key, sortDir }));
  }

  const onClear = useCallback(() => handleSearch({ value: "" }), [handleSearch]);

  const handleSelectPlayer = (index) => {
    if (selectedPlayer === index) {
      setSelectedPlayer(NO_PLAYER_SELECTED);
    } else {
      setSelectedPlayer(index);
    }
  };

  const addRandomPlayer = () => {
    http.put("/players/random", null, { headers: authHeader() }).then(() => {
      updatePage();
    });
  };

  return (
    <PlayerListingComponent
      addRandomPlayer={addRandomPlayer}
      listPlayers={listPlayers}
      filters={filters}
      onSearch={onSearch}
      onClear={onClear}
      objectsPerPage={objectsPerPage}
      totalObjects={totalObjects}
      currentPage={currentPage}
      handleObjectsPerPage={handleObjectsPerPage}
      handleFiltersChange={handleFiltersChange}
      loading={loading}
      players={players}
      searchValue={search.value}
      sortKey={search.sortKey}
      sortDir={search.sortDir}
      updatePage={updatePage}
      selectedPlayer={selectedPlayer}
      handleSelectPlayer={handleSelectPlayer}
      handleSearch={handleSearch}
      onSort={onSort}
      attributeList={attributesList}
    />
  );
};

export default PlayerListing;
