import React from "react";
import {
  Box,
  SimpleGrid,
  Heading,
  Text,
  Divider,
  Skeleton,
} from "@chakra-ui/core";
import PropTypes from "prop-types";

const titleStyles = {
  display: "block",
  fontWeight: "bold",
  fontSize: "0.625rem",
  className: "surtitle text-muted",
  fontFamily: "Open Sans",
};

const ACCESS_POLICIES = { 1: "Private", 0: "Public" };

const GuildInfo = React.memo(({ guild, loading }) => {
  return (
    <Box backgroundColor="#fff" borderRadius="md">
      <Heading size="sm" padding={4} color="#525f7f" paddingLeft={6}>
        Guild Information
      </Heading>
      <Divider />
      <SimpleGrid columns={2} spacingX={1} spacingY={1} margin={8}>
        <Box>
          <Heading {...titleStyles}>Guild ID</Heading>
          <Skeleton height="20px" width="80%" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>{guild.Id || "-"}</Text>
          </Skeleton>
        </Box>
        <Box>
          <Heading {...titleStyles}>Leader ID</Heading>
          <Skeleton height="20px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>{guild.LeaderId || '-'}</Text>
          </Skeleton>
        </Box>
        <Box>
          <Heading {...titleStyles}>Guild Name</Heading>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>{guild.Name || "-"}</Text>
          </Skeleton>
        </Box>
        <Box>
          <Heading {...titleStyles}>Minimum Level</Heading>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>{guild.MinLevel || 0}</Text>
          </Skeleton>
        </Box>
        <Box>
          <Heading {...titleStyles}>Members</Heading>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>
              {guild.Members && guild.Members.length}
            </Text>
          </Skeleton>
        </Box>
        <Box>
          <Heading {...titleStyles}>Guild Type</Heading>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>
              {ACCESS_POLICIES[guild.AccessPolicy] || "-"}
            </Text>
          </Skeleton>
        </Box>
      </SimpleGrid>
      <Box padding={8} paddingTop={0}>
        <Heading {...titleStyles}>Description</Heading>
        <Skeleton height="20px" marginY={2} isLoaded={!loading}>
          <Text
            fontFamily="Open Sans"
            fontWeight={600}
            fontSize="0.9rem"
            textAlign="justify"
          >
            {guild.Description || "-"}
          </Text>
        </Skeleton>
      </Box>
    </Box>
  );
});

GuildInfo.propTypes = {
  guild: PropTypes.object,
  loading: PropTypes.bool,
};

export default GuildInfo;
