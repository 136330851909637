import React from "react";
import { Badge } from "reactstrap";
import { userService } from "../../services/user.service";
import "../common/css/globalStyles.css";
import LoadingContainer from "../Layout/LoadingContainer";

/**
 * @typedef {("android" | "ios")} Platform
 * 
 * @typedef {{
 * BuildNumber: number,
 * ContentEnvironment: string,
 * DebuggerBuild: boolean,
 * Description: string | null,
 * IsStaging: boolean,
 * Platform: Platform,
 * StaticRoute: boolean,
 * UpdateRequired: boolean,
 * Version: number
 * }} Match
 * 
 * @typedef {{
 * Platform: Platform,
 * Version: number
 * }} ApiVersionDTO
 *  
 * @typedef {{
 * matchList: Record<number,Match[]>,
 * onClickBadge: (platform: Platform, version: number) => void,
 * callback: (build: Object, key: string) => void,
 * platform: Platform,
 * }} ApiInfoProps 
 */



  /**
   * 
   * @param {Match} build 
   * @param {number} index 
   * @param {number | null} nxtBuildNum 
   * @returns {React.ReactNode}
   */

  /** @type {React.FC<{build: Match, index: number, nextBuildNumber: number, callback: (a:Match, b: string) => void, color: string}>} */
   const BuildBadge = (
  {  build = {},
    index = 0,
    callback,
    color,
    nextBuildNumber: nxtBuildNum = null}
  ) => {
    return (
      <Badge
        className="cursor-pointer"
        color={color}
        onClick={() => userService.hasPermission("CHANGE_API_MATCH") && callback(build, "updateBuild")}
        pill={!build.StaticRoute}
      >
        {build.StaticRoute === true
          ? build.BuildNumber
          : build.BuildNumber +
            " >> " +
            (nxtBuildNum !== null ? nxtBuildNum : "")}
        {nxtBuildNum == null && !build.StaticRoute ? (
          <i className="fas fa-infinity" />
        ) : null}
      </Badge>
    );
  };

/**
 * 
 * @param {ApiInfoProps} props 
 * @returns 
 */
const ApiInfo = ({matchList = {},callback, platform, onClickBadge}) => {
  console.log({matchList,callback, platform, onClickBadge})
  const getColorByBuildType = (build) => {
    const colors = {
      updateRequired: "danger",
      stagingBuild: "info",
      debuggerBuild: "warning",
      default: "success",
    };
    if (build.UpdateRequired) return colors.updateRequired;
    if (build.IsStaging) return colors.stagingBuild;
    if (build.DebuggerBuild) return colors.debuggerBuild;
    return colors.default;
  };

  /**
   * 
   * @param {Match[] | undefined | "LOADING" } matchList 
   * @param {number} versionIndex 
   * @returns {React.ReactNode}
   */
  const generateListOfBadges = (matchList, versionIndex) => {
    if(!matchList)return <></>
    else if(matchList === "LOADING") return <LoadingContainer/>
    else if(matchList.length === 0) return <small>empty version</small>

    const bdg = matchList.sort((a,b) => a.BuildNumber - b.BuildNumber).reduce((badges, build, index,arr) => {
        //if !StaticRoute -> find next one to arrange it like A >>> B;
        let nextBuildNum = null;  
        
        if(!build.StaticRoute){
          //Check the next empty space between the current build and the next non staticRoute build (non-inclusive)
          const arrFromStart  = arr.slice(index + 1);
          const nextBuild = !build.StaticRoute ? arrFromStart.findIndex(b => !b.StaticRoute) : -1;   
          
          for(let i = nextBuild; i >= 0 ; --i){
            const current = arrFromStart[i];
            const previous = arrFromStart[i-1];
            //if it is continuous -> ignore
            if(previous?.BuildNumber === current.BuildNumber - 1) continue;
            //get the first non continuous point and assign the missing number
            nextBuildNum = arrFromStart[i].BuildNumber - 1;
            break;
          }
        }
        
        badges.push(<BuildBadge  build={build}  index={versionIndex} 
          callback={callback} 
          color={getColorByBuildType(build)} 
          nextBuildNumber={nextBuildNum}
          />
        );
        return badges;
    },[]);

    return bdg;
  };



  return (
    <>
      <div className="timeline-block pb-5" style={{ marginBottom: "1.3rem" }}>
        <span
          style={{
            justifyContent: "center",
            fontSize: "x-large",
            cursor: "pointer",
          }}
          onClick={() =>
            userService.hasPermission("CHANGE_API_MATCH") && callback({ Platform: platform }, "addVersion")
          }
          className="timeline-step badge-danger"
        >
          +
        </span>
      </div>
      {Object.keys(matchList).sort((a,b) => b-a).map((version, i) => {
        return (
          <div className="timeline-block">
            <span
              className={`timeline-step ${matchList[version] ? "badge-primary" : "badge-info"}`}
              style={{ justifyContent: "center", cursor: matchList[version] ? undefined : "pointer" }}
              onClick={() => !matchList[version] && onClickBadge(platform,version)}
            >
              V{version}
            </span>
            <div className="timeline-content">
              <small
                className="text-muted font-weight-bold"
                style={i === 0 ? null : { display: "none !important" }}
              >
                Builds
              </small>
              <div className="mt-3">
                {generateListOfBadges(matchList[version], i)}
                {matchList[version] && <Badge
                  className="cursor-pointer"
                  color="default"
                  onClick={() =>
                    userService.hasPermission("CHANGE_API_MATCH") &&
                    callback(
                      {
                        Platform: platform,
                        Version: version,
                      },
                      "addBuild"
                    )
                  }
                  pill
                >
                  <span>
                    <i className="fas fa-plus" />
                  </span>
                </Badge>
                }
              </div>
          </div>
        </div>
      )})}
    </>
  );
};

export default ApiInfo;
