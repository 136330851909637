import React, {useCallback} from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import EventLeaderboardsMock from "./EventLeaderboardsMock";

const LeaderboardsMockModal = ({
  open,
  toggle,
  loading,
  onConfirm,
  leaderboards,
  notificationRef
}) => {

  const notify = useCallback(() => {
    const options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            <b>Success!</b>
          </span>
          <span data-notify="message"><b>Players mock created successfully!</b></span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    notificationRef.current.notificationAlert(options);
  },[notificationRef]);

  return (
    <Modal centered isOpen={open} toggle={toggle} size="lg">
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <ModalHeader toggle={toggle}>Mock Leaderboards</ModalHeader>
          <ModalBody>
            <EventLeaderboardsMock
              leaderboards={leaderboards}
              onCancel={toggle}
              onSubmit={onConfirm}
              notify={notify}
            />
          </ModalBody>
        </>
      )}
    </Modal>
  );
};

export default LeaderboardsMockModal;
