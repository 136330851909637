import React from "react";
import MessageList from "./MessageList";
import PageContainer from "../common/PageContainer";

import { Box, Tabs, TabList, Tab, TabPanels, TabPanel } from "@chakra-ui/core";
import GiftMessageForm from "./GiftMessageForm";
import LoadingContainer from "../Layout/LoadingContainer";
import { userService } from "../../services/user.service";

const GlobalGift = React.memo(({
  globalMessages,
  playerMessages,
  onSubmit,
  getGlobalMessages,
  getPlayerMessages,
  loading
}) => {

  return (
    <PageContainer pageName="Global Gift">
      <Box
        backgroundColor="white"
        position="relative"
        paddingBottom={4}
        borderRadius="calc(0.375rem - 1px) calc(0.375rem - 1px)"
      >
        <Tabs variant="line">
          <TabList>
            <Tab>All Global Messages</Tab>
            <Tab>All Player Messages</Tab>
            <Tab disabled={!userService.hasPermission("SEND_MESSAGES")}>Send Global Message</Tab>
          </TabList>

          <TabPanels>
            <TabPanel>
              <MessageList
                global
                loading={loading}
                messages={globalMessages}
                getMessages={getGlobalMessages}
              />
            </TabPanel>
            <TabPanel>
              <MessageList
                loading={loading}
                messages={playerMessages}
                getMessages={getPlayerMessages}
              />
            </TabPanel>
            <TabPanel>
              <GiftMessageForm
                onSubmit={onSubmit}
                padding={8}
                getMessages={getGlobalMessages}
                global
              />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
    </PageContainer>
  );
});

export default GlobalGift;
