import React from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input
} from "@chakra-ui/core";
import { FormText } from "reactstrap";
/**
 * @typedef {import("react").InputHTMLAttributes<SelectProps>} htmlProps */

/** 
 * @typedef {import("@chakra-ui/core").InputProps  &
 * htmlProps & {
 *   label: string;
 *   name: string;
 *   error?: Error;
 *   subText?: string;
 * }} InputFieldProps
 * */

/**
 * @type {(payload:  InputFieldProps, ref: React.Ref<any>) => ReturnType<React.FC>} */
export const TextInput = React.forwardRef(
  (props, ref) => {
    const { label, error, isRequired, subText } = props;

    return (
      <FormControl isInvalid={!!error}>
        <FormLabel htmlFor={props.name}>{label}{isRequired ? "*" : ""}</FormLabel>
        <Input {...props} id={props.name} ref={ref} />
        {error ? <FormErrorMessage>{error.message}</FormErrorMessage> : null}
        {subText ? <FormText>{subText}</FormText> : null}
      </FormControl>
    )
  }
);


export default TextInput;