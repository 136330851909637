import React from "react";
import { IconButton, Tooltip, useClipboard } from "@chakra-ui/core";

const CopyButton = React.memo(({ text }) => {
  const { onCopy, hasCopied } = useClipboard(text);
  const icon = (
    <IconButton
      variantColor="gray"
      aria-label="Copy Text"
      size="sm"
      variant="ghost"
      marginLeft={1}
      icon="copy"
      onClick={onCopy}
    />
  );
  return hasCopied ? (
    <Tooltip aria-label="Text copied" hasArrow placement="bottom-end" label="Text has been copied to clipboard">
      {icon}
    </Tooltip>
  ) : (
    icon
  );
});

export default CopyButton;
