import React from "react";

// reactstrap components

import { Card, CardHeader, CardFooter, Row } from "reactstrap";

import PropTypes from "prop-types";

import LoadingContainer from "./LoadingContainer.jsx";

import "./css/tableStyle.css";

const TableCard = React.memo((props) => {
  const contents = props.children;

  return (
    <Row>
      <div className="col">
        <Card className={props.className}>
          {props.header && (
            <CardHeader
              className={
                "border-0 " +
                (props.headerProps ? props.headerProps.className : "")
              }
            >
              {props.header}
            </CardHeader>
          )}
          {props.loading ? <LoadingContainer /> : contents}
          {props.footer && (
            <CardFooter className="py-1">{props.footer}</CardFooter>
          )}
        </Card>
      </div>
    </Row>
  );
});

TableCard.propTypes = {
  header: PropTypes.element,
  loading: PropTypes.bool,
  footer: PropTypes.element,
  className: PropTypes.string,
  headerProps: PropTypes.object
};

export default TableCard;
