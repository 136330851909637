import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  Form,
  Col,
  Row,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Modal,
  Button,
} from "reactstrap";

import { useForm } from "react-hook-form";

import moment from "moment";

const PlayerAttributeModal = ({ open, toggleModal, onSubmit }) => {
  const { handleSubmit, register, errors } = useForm({ reValidateMode: "onBlur" });
  const [attributeName, setAttributeName] = useState("");
  const [value, setvalue] = useState(0);
  const [expirationDate, setExpirationDate] = useState(null);

  return (
    <Modal size="sm" isOpen={open} toggle={toggleModal}>
      <Card className="shadow border-0 m-0">
        <CardHeader>Attribute Configuration</CardHeader>
        <CardBody>
          <Form role="form">
            <Row>
              <Col xs="12" lg="6">
                <FormGroup>
                  <Label className="text-muted pl-1" for="name">
                    <small>
                      <b>Attribute Name</b>
                    </small>
                  </Label>
                  <Input
                    id="name"
                    name="name"
                    placeholder="Attribute Name"
                    type="text"
                    invalid={errors.name}
                    value={attributeName}
                    onChange={(e) => setAttributeName(e.target.value)}
                    innerRef={register({
                      required: true,
                    })}
                  />
                  {errors.name && (
                    <FormFeedback>This field is required</FormFeedback>
                  )}
                </FormGroup>
              </Col>
              <Col xs="12" lg="6">
                <FormGroup>
                  <Label className="text-muted pl-1" for="value">
                    <small>
                      <b>Value</b>
                    </small>
                  </Label>
                  <Input
                    id="value"
                    name="value"
                    placeholder="Value"
                    type="number"
                    invalid={errors.value}
                    value={value}
                    onChange={(e) => setvalue(Number(e.target.value))}
                    innerRef={register({
                      required: true,
                      validate: (value) => {
                        return (
                          value !== null || value !== "" || "Must be a integer"
                        );
                      },
                    })}
                  />
                  {errors.value && (
                    <FormFeedback>{errors.value.message}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="text-muted pl-1" for="expirationDate">
                    <small>
                      <b>Expiration Date (optional)</b>
                    </small>
                  </Label>
                  <Input
                    id="expirationDate"
                    name="expirationDate"
                    type="date"
                    invalid={errors.expirationDate}
                    value={expirationDate}
                    onChange={(e) => setExpirationDate(e.target.value)}
                    innerRef={register({
                      validate: (value) =>
                        !value ||
                        moment(value) > moment() ||
                        "We can't go back in time yet",
                    })}
                  />
                  {errors.expirationDate && (
                    <FormFeedback>{errors.expirationDate.message}</FormFeedback>
                  )}
                </FormGroup>
              </Col>
            </Row>
            <div className="text-center">
              <Button
                className="my-4"
                color="primary"
                type="button"
                onClick={handleSubmit(onSubmit)}
              >
                Send
              </Button>
              <Button
                className="my-4"
                color="primary"
                outline
                type="button"
                onClick={toggleModal}
              >
                Cancel
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </Modal>
  );
};

export default PlayerAttributeModal;
