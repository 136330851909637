import { SimpleGrid } from "@chakra-ui/core";
import BucketsList from "./BucketsList";
import BucketInfo from "./BucketInfo";
import * as PropTypes from "prop-types";
import React from "react";

const DataGrid = React.memo(
  ({ data, multiSelect, className, onClick, selectedData }) => {
    return (
      <SimpleGrid
        gridTemplateRows="auto"
        columns={{ xs: 1, lg: multiSelect ? 1 : 2 }}
        gridTemplateColumns={{ xs: "1fr", lg: multiSelect ? "1fr" : "40% 1fr" }}
        spacing={10}
        marginX={8}
        marginTop={5}
        marginBottom={5}
        position="relative"
        className={className}
      >
        <BucketsList
          data={data}
          selectedBucket={selectedData.bucket_name}
          onClick={onClick}
          multiSelect={multiSelect}
        />
        {selectedData && (
          <BucketInfo
            bucket={selectedData}
            prefix={selectedData.type !== "others" ? selectedData.type : ""}
          />
        )}
      </SimpleGrid>
    );
  }
);

DataGrid.propTypes = {
  multiSelect: PropTypes.any,
  className: PropTypes.any,
  data: PropTypes.shape({}),
  selectedData: PropTypes.shape({}),
  onClick: PropTypes.func,
};
export default DataGrid;
