
import Scheduler, { Resource } from 'devextreme-react/scheduler';
import React, { useRef,useMemo } from 'react';
import { locale } from "devextreme/localization";
import { CALENDAR_COLORS } from '../../constants/style';
import useWindowDimensions from '../../helpers/useWindowDimensions';
import moment from 'moment';
import { parseDate } from '../../helpers/date-helper';

locale("en-GB");

const views = [
  // { type: "day", maxAppointmentsPerCells: "auto" },
  // { type: "week", maxAppointmentsPerCells: "auto" },
  { type: "month", maxAppointmentsPerCells: "auto" },
];

const appointmentTemplate = (data) => data.appointmentData.text + "#" + data.appointmentData.id;

/**
 * @typedef {{
 *    component?: dxScheduler;
 *    element?: dxElement;
 *    model?: any;
 *    name?: string;
 *    fullName?: string;
 *    value?: any;
 * }} ChangeParam
 */


/**
 * @typedef {{
 *  regattas: import('./RegattaList').Regatta[],
 *  currentView: "day" | "week" | "month",
 *  day: Date,
 *  onAptClick: () => void,
 *  onAptDelete: () => void,
 *  onAptDoubleClick: () => void,
 *  onAptFormOpen: () => void,
 *  onChange: (prop: ChangeParam) => void,
 * }} RegattaCalendarProps
 */

/**
 * @type {React.FC<RegattaCalendarProps>} */
const RegattaCallendar = ({regattas,currentView,day, onAptClick,onAptDelete,onAptDoubleClick, onAptFormOpen, onChange}) => {
  const calendarRef = useRef();
  const { deviceWidth } = useWindowDimensions();

  const colors = useMemo(() => regattas.map(({Id,IsDeleted}) => ({
    id: Id,
    color: IsDeleted ? "#f5365c" : CALENDAR_COLORS[Id % CALENDAR_COLORS.length] 
  })),[regattas])

  const regData = regattas.map((reg) => ({
    id: reg["Id"],
    text: `Regatta`,
    duration: reg.Duration,
  startDate: parseDate(reg.CreationDate).addMin(reg?.StartDate || 0).toDate(),
    endDate: moment
      .utc(reg.CreationDate)
      .local()
      .add(reg?.StartDate || 0, 'minutes')
      .add(reg?.Duration  || 0, 'minutes')
      .add(reg?.Cooldown  || 0, 'minutes')
      .toDate(),
  }))

  return (
    <Scheduler
    ref={calendarRef}
    dataSource={regData}
    views={views} 
    appointmentTemplate={appointmentTemplate}
    onAppointmentFormOpening={onAptFormOpen}
    onAppointmentDeleting={onAptDelete}
    onAppointmentCellClick={onAptClick}
    onAppointmentDblClick={onAptDoubleClick !== undefined ? onAptDoubleClick || undefined : onAptClick}
    onCellDblClick={onAptDoubleClick !== undefined ? onAptDoubleClick || undefined : onAptClick}
    currentView={currentView || "month"}
    adaptivityEnabled={deviceWidth < 768}
    defaultCurrentDate={day || new Date()}
    firstDayOfWeek={0}
    onOptionChanged={(change) => onChange?.(change)}
    showCurrentTimeIndicator={false}

  >
    <Resource
      dataSource={colors}
      allowMultiple
      fieldExpr="id"
      label="Regatta"
      useColorAsDefault={true}
    />
  </Scheduler>
  );
}

export default RegattaCallendar;