import React, { useState } from "react";
import {
  Box,
  Heading,
  Flex,
  IconButton,
  useTheme,
} from "@chakra-ui/core";

import { UncontrolledTooltip } from "reactstrap";
import SearchInput from "../../common/SearchInput";
import { Button } from "reactstrap";
import Swal from "sweetalert2";
import { userService } from "../../../services/user.service";
import { Link } from "react-router-dom";
import GT from '../../Table/GenericTable';


/**
 *  @typedef {{
 * AccessPolicy: number,
 * Description: string,
 * Id: string,
 * LeaderId: string,
 * Members: string[],
 * MinLevel: number,
 * Name: string,
 * VisualIdentification: string
 * }} Guild 
 * */
/** @typedef {{PlayerId: string}} GuildMember */
/** @typedef {(playerId: string) => void} MemberCb */

/**
* @template T
* @typedef {import('../../Table/GenericTable').TableKey<T>} Key<T>
* */

/** @type {(g: Guild,p: bool,onKick: MemberCb, onTransfer: MemberCb ) => Key<GuildMember>[]}*/
const makeColumns = (guild,canManage,onKick,onTransfer) => [
  {
    key: "PlayerId",
    headerOptions: {title: "Player ID"},
    bodyOptions: {
      type: "LINK",
      to: ({PlayerId}) => `/acp/player/${PlayerId}`,
      transform: (PlayerId) =>{
        const leader = guild.LeaderId === PlayerId;
        return<b>{PlayerId} {leader && <i className="fas fa-crown"/>}</b>
      }
    }
  },
  {
    key:"",
    headerOptions: {title: "Actions"},
    bodyOptions: { 
     nodeProps: {width: "15%"},
     render: ({PlayerId}) => {
      const isLeader = guild.LeaderId === PlayerId;
      return(
        <>
          <IconButton
            aria-label="Kick Member"
            icon={"not-allowed"}
            variant="ghost"
            variantColor={"red"}
            size="sm"
            disabled={!canManage || isLeader}
            onClick={() => onKick(PlayerId)}
            id={`kick-member-${PlayerId}`}
          />
          {!isLeader && <IconButton
            aria-label="Transfer Leadership"
            icon={() => <i className="fas fa-crown"/>}
            variant="ghost"
            variantColor={"yellow"}
            size="sm"
            disabled={!canManage || isLeader}
            onClick={() => onTransfer(PlayerId)}
            id={`kick-member-${PlayerId}`}
          />}
        </>)
      }
    }
  },
];


const GuildMembers = ({loading, members, onKickPlayer, onAddPlayer,onTransferLeader,guild }) => {
  const [search, setSearch] = useState("");
  const theme = useTheme();

  const CAN_MANAGE_GUILD = userService.hasPermission("DELETE_UPDATE_GUILD");

  const onTransferLeadershipClick = (playerId) => {
    Swal.fire({
      title: "Transfer Leadership",
      text: "Are you sure you want to make this player the guild leader?",
      showCancelButton: true,
      confirmButtonText: "Confirm",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: () => onTransferLeader(playerId),
    });
  };

  const onKickPlayerClick = (playerId) => {
    Swal.fire({
      title: "Remove Member",
      text: "Are you sure you want to remove this player from the guild?",
      showCancelButton: true,
      confirmButtonText: "Remove",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: () => onKickPlayer(playerId),
    });
  };

  const onAddPlayerClick = () => {
    Swal.fire({
      title: "Add Player to the Guild",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
        placeholder: "Player ID",
      },
      showCancelButton: true,
      confirmButtonText: "Add Player",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: (playerId) => onAddPlayer(playerId),
    });
  };

  return (
    <Box backgroundColor="#fff" fontFamily="Open Sans">
      <Flex alignItems="center" justifyContent="space-evenly">
        <Heading
          size="sm"
          marginY={4}
          color="#525f7f"
          marginLeft={6}
          marginRight={12}
        >
          Members
        </Heading>
        <SearchInput
          onSearch={(search) => setSearch(search)}
          onClear={() => setSearch("")}
          className="flex-grow-1"
          placeholder="Player ID"
        />
        <UncontrolledTooltip
          placement="top"
          aria-label="Add a player to the guild."
          target="add-player"
        >
          Add a player to the guild.
        </UncontrolledTooltip>
        <Flex mr="5">
          <Button
            className="btn btn-round btn-icon"
            color="primary"
            size="sm"
            disabled={!CAN_MANAGE_GUILD}
            id="add-player"
            onClick={onAddPlayerClick}
          >
            <span className="btn-inner--icon mr-1">
              <i className="fas fa-user" />
            </span>
            <span className="btn-inner--text">Add Player</span>
          </Button>
          <Link to={`/acp/maulmessage?channel=${guild.Id}`} >
            <Button
              className="btn btn-round btn-icon"
              color="primary"
              size="sm"
              id="send-message"
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-envelope" />
              </span>
              <span className="btn-inner--text">Send Message</span>
            </Button>
          </Link>
        </Flex>
      </Flex>
      <GT.Table 
        loading={loading}
        data={members.filter((member) => member.PlayerId.includes(search))}  
        onEmpty="Couldn't find any member." 
        keys={makeColumns(guild,CAN_MANAGE_GUILD,onKickPlayerClick,onTransferLeadershipClick)} 
        rowKeyfn={(entry) => entry.PlayerId}
      />
    </Box>
  );
};


export default GuildMembers;
