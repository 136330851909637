import React from 'react';
import { Badge } from "reactstrap";
import LoadingContainer from '../Layout/LoadingContainer';
import { isRegattaFinished } from './utils';


/**
 * @typedef { import('./RegattaList').Regatta} Regatta
 */

/** @type {React.FC<{regattaGroups: Record<string,Regatta[]>, onClickBadge: (reg: Regatta) => void}>, onClickVersion: (version: number | string) => void} */
const RegattaTimeline = ({regattaGroups, onClickBadge,onClickVersion}) => {
  return (
    <div className="timeline timeline-one-side ml-4" data-timeline-axis-style="dashed" data-timeline-content="axis" >
    {[...Object.keys(regattaGroups)].reverse().map((key) => 
    {

      return(<div key={"rdef" + key} className="timeline-block">
        <span
          className={`timeline-step ${regattaGroups[key] ? "badge-primary" : "badge-info"}`}
          style={{ justifyContent: "center", cursor: regattaGroups[key] ? undefined : "pointer" }}
          onClick={() => !regattaGroups[key] && onClickVersion(key)}
        >
          #{key}
        </span>
        <div className="timeline-content" style={{maxWidth: "50%"}}>
          <small className="text-muted font-weight-bold">
            Regattas
          </small>
          <div className="mt-3">
          {
            !regattaGroups[key] 
            ? <></>
            : regattaGroups[key] === 'LOADING'
            ? <LoadingContainer/>
            : regattaGroups[key]?.length === 0 
            ? <span style={{fontSize: '0.7rem'}}>Empty edition</span>
            : regattaGroups[key]?.map((rg) => (
            <Badge 
              color={rg.IsDeleted ? "warning" : isRegattaFinished(rg) ? "info" :  "success"}
              onClick={() => onClickBadge(rg)} 
              key={"rbdg" + key + ":"+ rg.Id} 
              className="cursor-pointer"
            >
              {rg.Id} 
            </Badge>))
          }
          </div>
        </div>
      </div>)})}
      </div>
  );
}



export default RegattaTimeline;