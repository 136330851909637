import {
  Box,
  Divider,
  Heading,
  SimpleGrid,
  Text,
  Skeleton,
} from "@chakra-ui/core";
import PropTypes from "prop-types";
import React from "react";

const titleStyles = {
  display: "block",
  fontWeight: "bold",
  fontSize: "0.625rem",
  className: "surtitle text-muted",
  fontFamily: "Open Sans",
};

const infoStyles = {
  display: "block",
  color: "#525f7f",
  fontWeight: 600,
};

const LeaderboardDetails = React.memo(
  ({ leaderboardDefinition, event, eventLoading, loading }) => {
    return (
      <Box backgroundColor="#fffdfd" borderRadius="md" height={300}>
        <Heading
          as="h2"
          size="sm"
          p={4}
          color="#525f7f"
          fontWeight="bold"
          pl={6}
        >
          Leaderboard Definition
        </Heading>
        <Divider />
        <SimpleGrid columns={2} spacingX={1} spacingY={1}>
          <Box p={2} pl={6}>
            <Text {...titleStyles}>Leaderboard Definition Name</Text>
            <Skeleton width="60%" isLoaded={!loading}>
              <Text {...infoStyles}>{leaderboardDefinition.Name || "-"}</Text>
            </Skeleton>
          </Box>
          <Box p={2} pl={6}>
            <Text {...titleStyles}>Capacity</Text>
            <Skeleton width="20%" isLoaded={!loading}>
              <Text {...infoStyles}>
                {leaderboardDefinition.Capacity || "-"}
              </Text>
            </Skeleton>
          </Box>
          <Box p={2} pl={6}>
            <Text {...titleStyles}>Leaderboard Definition ID</Text>
            <Skeleton width="20%" isLoaded={!loading}>
              <Text {...infoStyles}>{leaderboardDefinition.Id || "-"}</Text>
            </Skeleton>
          </Box>
          <Box p={2} pl={6}>
            <Text {...titleStyles}>Associated Event ID</Text>
            <Skeleton width="20%" isLoaded={!loading}>
              <Text {...infoStyles}>
                {leaderboardDefinition.EventId || "-"}
              </Text>
            </Skeleton>
          </Box>
          <Box p={2} pl={6}>
            <Text {...titleStyles}>Event Name</Text>
            <Skeleton width="20%" isLoaded={!eventLoading}>
              <Text {...infoStyles}>{(event && event.Content) || "-"}</Text>
            </Skeleton>
          </Box>
        </SimpleGrid>
      </Box>
    );
  }
);

LeaderboardDetails.propTypes = {
  leaderboardDefinition: PropTypes.any,
  loading: PropTypes.bool,
};

export default LeaderboardDetails;
