import React from "react";
import { Col, Input, Row } from "reactstrap";
import SearchInput from "../../common/SearchInput";
import PaginationHeader from "../../common/PaginationHeader";
import FilterContainer from "../../common/FilterContainer";

const Header = ({
  filters,
  onSearch,
  onClear,
  objectsPerPage,
  totalObjects,
  currentPage,
  handleObjectsPerPage,
  handleFiltersChange,
  handleSearch,
  attributeList
}) => {
  return (
    <>
      <Row className="flex align-items-center">
        <Col xs={12} xl={5}>
          <SearchInput
            placeholder="Player ID, Device ID or Nickname"
            onSearch={onSearch}
            onClear={onClear}
            disabled={
              filters &&
              filters.attributeFilters &&
              filters.attributeFilters.length > 0
            }
          />
        </Col>
        <Col className="d-flex align-items-center" xs={12} xl={3}>
          <small className="d-inline text-muted">Search by:</small>
          <Input
            className="search-player-select d-inline"
            type="select"
            onChange={(e) => handleSearch({ type: e.target.value })}
            bsSize="sm"
          >
            <option defaultValue="playerId" value="playerId">
              Player ID
            </option>
            <option value="deviceId">Device ID</option>
            <option value="nickname">Nickname</option>
          </Input>
        </Col>
        <Col xs={12} xl={4}>
          <PaginationHeader
            total={totalObjects}
            skip={currentPage * objectsPerPage}
            take={objectsPerPage}
            onObjectsPerPageChange={handleObjectsPerPage}
          />
        </Col>
      </Row>
      <FilterContainer onSubmit={handleFiltersChange} list={attributeList} />
    </>
  );
};

export default Header;
