import React from "react";
import NotificationAlert from "react-notification-alert";
import "./css/StyleAlert.css";

//This is following the same documentation as the Argon and Reactstrap.
class AlertContainer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }
  /*componentDidMount() {
        this.setState({ visible: true });
        this.alertTime = setTimeout(() => {
            this.setState({ visible: false });
            this.props.close();
        }, 3000);
         
    }

    componentWillUnmount() {
        if (this.alertTime) {                                   
            clearTimeout(this.alertTime);      
            this.alertTime = 0;
            this.props.close();
        }
    }*/

  componentDidMount() {
    /*this.props.type
        this.props.title
        this.props.text*/
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {this.props.title}
          </span>
          <span data-notify="message">{this.props.text}</span>
        </div>
      ),
      type: this.props.type === "" ? "success" : this.props.type,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.refs.notificationAlert.notificationAlert(options);
  }

  /*render() {
        return (
            <UncontrolledAlert className="alertC" isOpen={this.state.visible} color={this.props.type}>
                <span className="alert-inner--icon">
                    {this.props.type === 'success' ? <i className="ni ni-like-2" /> : <i class="fab fa-galactic-republic"/> }
                    
                </span>{" "}
                <span className="alert-inner--text">
                    <strong>{this.props.title}</strong> {this.props.text}
                </span>
            </UncontrolledAlert>
        );
    }*/

  render() {
    return (
      <div className="rna-wrapper">
        <NotificationAlert ref="notificationAlert" />
      </div>
    );
  }
}

export default AlertContainer;
