import React from "react";

// reactstrap components

import { Card, CardHeader, CardFooter, Container, Row, Col } from "reactstrap";

class SimpleCard extends React.Component {
  render() {
    return (
      <Row>
        <div className="col">
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6">
                  <h3 className="mb-0">{this.props.title}</h3>
                </Col>
                <Col className="text-right" xs="6">
                  {this.props.titleRight}
                </Col>
              </Row>
            </CardHeader>
            <Container>
              <Row className="justify-content-md-center">
                <Col lg={{ size: "auto" }} className="mt-5 mb-5">
                  {this.props.children}
                </Col>
              </Row>
            </Container>

            <CardFooter className="py-4"></CardFooter>
          </Card>
        </div>
      </Row>
    );
  }
}

export default SimpleCard;
