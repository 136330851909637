import React from "react";
import { Table } from "reactstrap";
import PerfectScrollBar from "react-perfect-scrollbar";
import ErrorBox from "../common/ErrorBox";

const PlayerLeaderboards = ({ leaderboards }) => {
  const content = (
    <PerfectScrollBar className="rounded-bottom">
      <Table
        hover
        className="align-items-center table-flush"
        size="sm"
        responsive
      >
        <thead className="thead-light">
          <tr>
            <th className="sort" scope="col">
              Leaderboard Key
            </th>
            <th className="sort" scope="col">
              Leaderboard Definition ID
            </th>
          </tr>
        </thead>
        <tbody className="list">
          {leaderboards.map((leaderboard, index) => (
            <tr className="py-2" key={index}>
              <td>{leaderboard.Key}</td>
              <td>{leaderboard.LeaderboardDefinitionId}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </PerfectScrollBar>
  );

  if (leaderboards.length === 0) {
    return (
      <ErrorBox className="h-100">
        This player isn't on a leaderboard.
      </ErrorBox>
    );
  } else {
    return content;
  }
};

export default PlayerLeaderboards;
