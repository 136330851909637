import React from "react";
import {
  Collapse,
  CardBody,
  Container,
  Table,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import authHeader from "../../helpers/auth-header.js";
import AlertContainer from "../Layout/AlertContainer.jsx";
import Swal from "sweetalert2";
import LoadingContainer from "../Layout/LoadingContainer.jsx";
import PersistenceInfo from "../Persistence/PersistenceInfo";
import "./css/PopupSnapshot.css";
import classnames from "classnames";
import DateTimeComponent from "../common/DateTimeComponent.jsx";
import SimplePaginationComponent from "../common/SimplePaginationComponent.jsx";
import httpClient from "../../helpers/http-client-singleton";

const http = httpClient.getInstance();

const envs = {
  Development: "dev",
  Staging: "staging",
  Production: "live",
};

export default class SnapshotCreator extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      success: false,
      alertWarn: "",
      loading: false,
      tableData: [],
      objectsPerPage: 15,
      currentPage: 0,
      totalObjects: null,
      deviceIdSearch: "",
      search: "",
      keySearch: "",
      selectedKey: null,
      selectedDeviceId: null,
      alert: false,
      playerId: this.props.playerId,
      description: "",
      totalPages: 0,
      accordionPersistence: [],
      loadingAccordion: false,
      accordionIndex: -1,
    };

    this.toggle = this.toggle.bind(this);
    this.createSnapshot = this.createSnapshot.bind(this);

    this.handleDeviceIdChange = this.handleDeviceIdChange.bind(this);
    this.handleKeyChange = this.handleKeyChange.bind(this);
    this.searchSnapshotById = this.searchSnapshotById.bind(this);
    this.searchSnapshotByKey = this.searchSnapshotByKey.bind(this);
    this.onEntering = this.onEntering.bind(this);
    this.listSnapshotsPage = this.listSnapshotsPage.bind(this);
  }

  componentDidMount() {
    this.listSnapshotsPage(0);
  }

  listSnapshotsPage = (pageNumber) => {
    this.setState(
      {
        currentPage: pageNumber,
        loading: true,
      },
      () => {
        http
          .get("/snapshots/", {
            headers: authHeader(),
            params: {
              skip: this.state.objectsPerPage * this.state.currentPage,
              take: this.state.objectsPerPage,
              filter: this.state.deviceIdSearch || null,
              desc: true,
            },
          })
          .then((response) => {
            this.setState({
              tableData: response.data.data.Result,
              objectsPerPage: 15,
              totalObjects: response.data.data.Total,
              search: "",
              loading: false,
            });
          });
      }
    );
  };

  handleDeviceIdChange(e) {
    this.setState({ deviceIdSearch: e.target.value });
  }

  handleKeyChange(e) {
    this.setState({ keySearch: e.target.value });
  }

  searchSnapshotById = () => {
    this.setState({ search: "Id" }, () => {
      this.listSnapshotsPage(0);
    });
  };

  searchSnapshotByKey = () => {
    this.setState({ search: "key" }, () => {
      this.listSnapshotsPage(0);
    });
  };

  loadSnapshotFromEnvironment = () => {
    Swal.fire({
      title: "Load Snapshot",
      html:
        '<div class="input-group">' +
        '<input class="form-control" placeholder="Snapshot Key" id="swal-input1">' +
        `<select class="form-control" id="swal-input2">${Object.keys(envs).map(
          (env) => `<option value=${env}>${env}</option>`
        )}</select>` +
        "</div>",
      focusConfirm: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Load",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.loadSnapshot(
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value
        );
      },
    });
  };

  loadSnapshot = (
    key = this.state.selectedKey,
    environment = localStorage.getItem("env")
  ) => {
    const body = {
      m_snapshotKey: key,
      m_snapshotEnvironment: localStorage.getItem("env"),
    };
    this.setState({
      loading: true,
    });
    http
      .put(`/players/${this.props.playerId}/snapshots`, body, { headers: authHeader()})
      .then((response) => {
        if (response.data.success) {
          this.setState({ alert: true, alertWarn: "success" });
          Swal.fire({
            type: "success",
            title: "Success",
            text: "Your Snapshot has been loaded!",
          });
          this.props.notifySnapshot();
          this.props.updateFunc();
        } else {
          Swal.fire({
            type: "error",
            title: "Error",
            text: "The system was not able to load Snapshot.",
          });
          this.setState({
            selectedDeviceId: null,
            loading: false,
            modal: false,
            alert: true,
            alertWarn: "danger",
          });
        }
      });
    this.setState({ alert: true });
    this.props.close();
  };

  notify = (type = "success", title = "Success!", subtitle = "") => {
    return (
      <AlertContainer
        type={type}
        title={title}
        close={this.props.close}
        text={subtitle}
      />
    );
  };

  onEntering(Key) {
    this.setState({ loadingAccordion: true });
    http
      .get("/persistences/snapshot_" + Key, {
        headers: authHeader(),
      })
      .then((response) => {
        this.setState({
          accordionPersistence: response.data.data.m_playerPersistences,
          loadingAccordion: false,
        });
      })
      .catch((error) => {
        this.setState({ loadingAccordion: false, serverResponse: error });
      });
  }

  toggleAccordion(playerId, key, index) {
    this.setState({
      selectedDeviceId: playerId,
      selectedKey: key,
      accordionIndex: this.state.accordionIndex === index ? null : index,
    });
  }

  renderTable(tableData) {
    return (
      <Table hover className="align-items-center" striped responsive size="sm">
        <thead className="thead-light">
          <tr>
            <th scope="col" className="sort col-1">
              #
            </th>
            <th scope="col" className="sort col-1" data-sort="key">
              KEY
            </th>
            <th scope="col" className="sort col-4" data-sort="playerId">
              PLAYER ID
            </th>
            <th scope="col" className="sort col-4" data-sort="description">
              DESCRIPTION
            </th>
            <th scope="col" className="sort col-2" data-sort="creationDate">
              DATE
            </th>
          </tr>
        </thead>
        <tbody className="accordion" id="accordionPersistenceInfo">
          {tableData.map((data, index) => (
            <>
              <tr
                key={"row" + index}
                onClick={() => {
                  this.toggleAccordion(data["PlayerId"], data["Key"], index);
                }}
              >
                <td>
                  <input
                    checked={
                      this.state.selectedKey === data["Key"] ? "checked" : null
                    }
                    key={index}
                    type="radio"
                    name="radio"
                  />
                </td>
                <td
                  className={classnames({
                    "font-weight-bold": this.state.selectedKey === data.Key,
                  })}
                  key={data["PlayerId"] + "-key"}
                >
                  {data["Key"]}
                </td>
                <td
                  className={classnames({
                    "font-weight-bold": this.state.selectedKey === data.Key,
                  })}
                  key={data["PlayerId"] + "-did"}
                >
                  <a>{data["PlayerId"]}</a>
                </td>
                <td
                  className={classnames({
                    "font-weight-bold": this.state.selectedKey === data.Key,
                  })}
                  key={data["PlayerId"] + "-descri"}
                >
                  {data["Description"] != null &&
                  data["Description"].length > 34
                    ? data["Description"].substring(0, 32) + " ..."
                    : data["Description"]}
                </td>
                <td
                  className={classnames({
                    "font-weight-bold": this.state.selectedKey === data.Key,
                  })}
                  key={data["PlayerId"] + "-creationDate"}
                >
                  <DateTimeComponent
                    date={new Date(data["CreationDate"])}
                    tooltipId={"tooltip850098708" + index}
                  />
                </td>
              </tr>
              <tr>
                <td colSpan="5" className="p-0">
                  <Collapse
                    isOpen={this.state.accordionIndex === index}
                    colSpan="5"
                    onEntering={() => {
                      this.onEntering(data["Key"]);
                    }}
                  >
                    <CardBody>
                      <PersistenceInfo
                        playerId={data["PlayerId"]}
                        classStyle
                        loading={this.state.loadingAccordion}
                        persistence={this.state.accordionPersistence}
                      />
                    </CardBody>
                  </Collapse>
                </td>
              </tr>
            </>
          ))}
        </tbody>
      </Table>
    );
  }

  toggle() {
    this.setState((prevState) => ({
      modal: !prevState.modal,
    }));
  }

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  createSnapshot(e) {
    e.preventDefault();
    const data = {
      m_description: this.state.description,
    };
    this.setState({
      loading: true,
    });

    http.post(`/players/${this.props.playerId}/snapshots`, data, {
        headers: authHeader(),
      })
      .then((response) => {
        this.setState({
          description: "",
          loading: false,
          alert: true,
          success: true,
        });
        Swal.fire({
          type: "success",
          title: "Success",
          text:
            "Snapshot created! Snapshot Key: " +
            response.data.data.m_snapshotKey,
        });
      });
    this.props.close();
  }

  setStatePages = (number) => {
    if (number !== this.state.totalPages) {
      this.setState({ totalPages: number });
    }
  };

  render() {
    let contents = this.state.loading ? (
      <LoadingContainer />
    ) : (
      this.renderTable(this.state.tableData)
    );

    const pageNumbers = [];
    //Calculates the total number of pages, this way I can know which one is the last
    if (this.state.totalObjects !== null) {
      for (
        let i = 1;
        i <= Math.ceil(this.state.totalObjects / this.state.objectsPerPage);
        i++
      ) {
        pageNumbers.push(i);
      }
      this.setStatePages(pageNumbers.length);
    }

    if (this.props.snapshotType === "create") {
      return (
        <div>
          {this.state.alert ? (
            <AlertContainer
              type={this.state.alertWarn}
              title="Success!"
              close={this.props.close}
              text="Created a new Snapshot!"
            />
          ) : null}
          <Modal
            isOpen={this.state.modal}
            toggle={this.props.close}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.close}>Create Snapshot</ModalHeader>
            <ModalBody>
              <FormGroup>
                <Label for="lockedDeviceId">Device Id</Label>
                <Input
                  type="email"
                  name="email"
                  disabled="disabled"
                  id="lockedDeviceId"
                  placeholder={this.props.playerId}
                />
              </FormGroup>
              <FormGroup>
                <Label for="description">Description</Label>
                <Input
                  type="textarea"
                  style={{ minHeight: "3rem", height: "4rem" }}
                  name="description"
                  onChange={(e) => this.handleChange(e)}
                  id="description"
                  value={this.state.description}
                />
              </FormGroup>
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={(e) => this.createSnapshot(e)}>
                Create
              </Button>{" "}
              <Button color="secondary" onClick={this.props.close}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    } else if (this.props.snapshotType === "load") {
      return (
        <div>
          <div>
            <Modal
              isOpen={this.state.modal}
              toggle={this.props.close}
              className={this.props.className}
              size="lg"
            >
              <ModalHeader>
                <Row>
                  <Col xs="12">
                    <h1>Load Snapshot</h1>
                  </Col>
                  <Col xs="8">
                    <Input
                      maxLength="40"
                      onChange={this.handleDeviceIdChange}
                      innerRef={this.handleInputRef}
                      className="form-control-sm"
                      placeholder="Device ID"
                      type="text"
                      value={this.state.deviceIdSearch}
                    />
                  </Col>
                  <Col style={{ display: "inline-block" }} xs="4">
                    <Button
                      className="btn-neutral"
                      color="default"
                      onClick={this.searchSnapshotById}
                      size="sm"
                    >
                      <i className="fas fa-search" /> Search
                    </Button>
                    <Button
                      onClick={() => {
                        this.setState(
                          { deviceIdSearch: "", search: "" },
                          () => {
                            this.searchSnapshotById();
                          }
                        );
                      }}
                      size="sm"
                    >
                      Clear
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      onClick={this.loadSnapshotFromEnvironment}
                      color="primary"
                      size="sm"
                      className="mt-4"
                    >
                      Load from other environment
                    </Button>
                  </Col>
                </Row>
              </ModalHeader>
              <ModalBody className="px-0 pt-0">
                <Container className=" px-0 snapshot-modal-container" fluid>
                  {contents}
                </Container>
                <Row className="pagination px-3 align-items-baseline">
                  <Col>
                    <SimplePaginationComponent
                      currentPage={this.state.currentPage}
                      fetchPage={this.listSnapshotsPage}
                      totalObjects={this.state.totalObjects}
                      objectsPerPage={this.state.objectsPerPage}
                    />
                  </Col>
                  <Col>
                    <Button
                      onClick={this.props.close}
                      size="sm"
                      style={{ float: "right", marginLeft: "0.8rem" }}
                    >
                      Cancel
                    </Button>
                    <Button
                      style={{ float: "right" }}
                      onClick={() => this.loadSnapshot()}
                      color="primary"
                      outline={!this.state.selectedKey}
                      disabled={!this.state.selectedKey}
                      size="sm"
                    >
                      Load
                    </Button>
                  </Col>
                </Row>
              </ModalBody>
            </Modal>
          </div>
        </div>
      );
    } else {
      return (
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.props.close}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.close}>
              Sorry, we couldn't load this.
            </ModalHeader>
            <ModalBody>
              <Label for="lockedDeviceId">Device Id</Label>
              <Input
                type="email"
                name="email"
                disabled="disabled"
                id="lockedDeviceId"
                placeholder={this.props.playerId}
              />
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur. Excepteur sint occaecat cupidatat non proident,
              sunt in culpa qui officia deserunt mollit anim id est laborum.
            </ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={this.props.close}>
                Do Something
              </Button>{" "}
              <Button color="secondary" onClick={this.props.close}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }
}
