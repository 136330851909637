import React from "react";
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LeaderboardDefinitionForm from "./LeaderboardDefinitionForm";
import LoadingContainer from "../Layout/LoadingContainer";

const LeaderboardDefinitionModal = ({
  onSubmit,
  onCancel,
  loading,
  modalOpen,
  toggle,
  ...leaderboardDefinition
}) => {
  return (
    <Modal isOpen={modalOpen} toggle={onCancel}>
      <ModalHeader>
        {leaderboardDefinition.Id ? "Update" : "Create"} Leaderboard Definition
      </ModalHeader>
      <ModalBody>
        {loading ? (
          <LoadingContainer />
        ) : (
          <LeaderboardDefinitionForm
            onSubmit={onSubmit}
            onCancel={onCancel}
            {...leaderboardDefinition}
          />
        )}
      </ModalBody>
    </Modal>
  );
};

export default LeaderboardDefinitionModal;
