import React from "react";
import {
  Col,
  FormText,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  FormGroup,
} from "reactstrap";
import authHeader from "../../helpers/auth-header.js";
import httpClient from "../../helpers/http-client-singleton";
import LoadingContainer from "../Layout/LoadingContainer.jsx";

const http = httpClient.getInstance();

export default class EventProfileModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: this.props.modal,
      tableData: [],
      loading: false,
      name: this.props.eventProfileName,
      id: this.props.eventProfileId,
      taskcount: this.props.eventProfileTaskcount,
      offset: this.props.eventProfileOffset,
    };
  }

  componentDidMount() {}

  handleChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }

  updateEventProfile(e) {
    e.preventDefault();
    //if (this.state.taskcount < 1 || this.state.offset < 1) {
    //    this.props.notificationEmitter('danger');
    //    return;
    //}
    var data = null;
    if (this.state.id === null) {
      data = {
        name: this.state.name,
        taskcount: this.state.taskcount,
        offset: this.state.offset,
      };
    } else {
      data = {
        id: this.state.id,
        name: this.state.name,
        taskcount: this.state.taskcount,
        offset: this.state.offset,
      };
    }
    this.setState({
      loading: true
    });

    http
      .put("/events/profiles/" + (data.id || ""), data, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.status === 200) {
          this.props.notifySuccess();
          this.props.update();
        } else {
          this.props.notificationEmitter("danger");
        }
      }).finally(() => {
        this.setState({
          name: "",
          loading: false
        });
        this.props.close();
      });
  }

  render() {

    if (this.props.type === "update") {
      return (
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.props.close}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.close}>
              Update Event Profile
            </ModalHeader>
            {(this.state.modal && (this.props.loading || this.state.loading))
              ?(<LoadingContainer/>) 
              :( <>
                <ModalBody>
                  <FormGroup>
                    <Row>
                      <Col md={2}>
                        <Label for="lockedKey">Key</Label>
                        <Input
                          type="email"
                          name="id"
                          disabled="disabled"
                          id="lockedDeviceId"
                          placeholder={this.state.id}
                        />
                      </Col>
                      <Col md={10}>
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            type="text"
                            style={{ minHeight: "2rem" }}
                            name="name"
                            onChange={(e) => this.handleChange(e)}
                            id="name"
                            value={this.state.name}
                          />
                          <FormText>
                            <small
                              style={
                                this.state.name.length > 150
                                  ? { color: "red" }
                                  : { color: "#8898aa" }
                              }
                            >
                              {" "}
                              {150 - this.state.name.length + " characters left"}
                            </small>
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Label>Task count</Label>
                        <Input
                          type="number"
                          min="1"
                          max="50"
                          name="taskcount"
                          id="taskcount"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.taskcount}
                        />
                      </Col>
                      <Col md={3}>
                        <Label>Offset</Label>
                        <Input
                          type="number"
                          min="1"
                          name="offset"
                          id="offset"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.offset}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  <div>The changes will be applied to the next created events.</div>
                  {this.state.name.length > 150 ? (
                    <h5 style={{ color: "red", display: "initial" }}>
                      {" "}
                      You exceed the limit of 150 characters in the name field.
                    </h5>
                  ) : null}
                  <Button
                    color="primary"
                    disabled={this.state.name.length > 150}
                    onClick={(e) => this.updateEventProfile(e)}
                  >
                    Update
                  </Button>{" "}
                  <Button color="secondary" onClick={this.props.close}>
                    Cancel
                  </Button>
                </ModalFooter>
              </>)
            }
          </Modal>
        </div>
      );
    } else if (this.props.type === "create") {
      return (
        <div>
          <Modal
            isOpen={this.state.modal}
            toggle={this.props.close}
            className={this.props.className}
          >
            <ModalHeader toggle={this.props.close}>
              Create Event Profile
            </ModalHeader>
            {(this.state.modal && (this.props.loading || this.state.loading))
              ?(<LoadingContainer/>) 
              :(<>
                <ModalBody>
                  <FormGroup>
                    <Row>
                      <Col md={10}>
                        <FormGroup>
                          <Label for="name">Name</Label>
                          <Input
                            type="text"
                            style={{ minHeight: "2rem" }}
                            name="name"
                            onChange={(e) => this.handleChange(e)}
                            id="name"
                            value={this.state.name}
                          />
                          <FormText>
                            <small
                              style={
                                this.state.name.length > 150
                                  ? { color: "red" }
                                  : { color: "#8898aa" }
                              }
                            >
                              {" "}
                              {150 - this.state.name.length + " characters left"}
                            </small>
                          </FormText>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <Label>Task count</Label>
                        <Input
                          type="number"
                          min="1"
                          max="50"
                          name="taskcount"
                          id="taskcount"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.taskcount}
                        />
                      </Col>
                      <Col md={3}>
                        <Label>Offset</Label>
                        <Input
                          type="number"
                          min="1"
                          name="offset"
                          id="offset"
                          onChange={(e) => this.handleChange(e)}
                          value={this.state.offset}
                        />
                      </Col>
                    </Row>
                  </FormGroup>
                </ModalBody>
                <ModalFooter>
                  {this.state.name.length > 150 ? (
                    <h5 style={{ color: "red", display: "initial" }}>
                      {" "}
                      You exceed the limit of 150 characters in the name field.
                    </h5>
                  ) : null}
                  <Button
                    color="primary"
                    disabled={this.state.name.length > 150}
                    onClick={(e) => this.updateEventProfile(e)}
                  >
                    Create
                  </Button>{" "}
                  <Button color="secondary" onClick={this.props.close}>
                    Cancel
                  </Button>
                </ModalFooter>
              </>
          )}
          </Modal>
        </div>
      );
    }
  }
}
