import { Box, Button, Flex, Textarea } from "@chakra-ui/core";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactJson from "react-json-view";
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import EditableTitle from "../SystemPreferences/MainAttributes/EditableTitle";



/** @type {(str: string) => [false, string] | [true, object]} */
const validateJson = (str) => {
  if (!str) return [false, str]
  try {
    var jsonObj = JSON.parse(str);
    return [typeof jsonObj === "object", jsonObj]
  } catch {
    return [false, str]
  }
}

/** @typedef {{Key: string, Value: string, Updated?: boolean, Deleted?: boolean,NewKey?: boolean }} JsonProp*/

/**
 * @typedef {{
 *  isOpen: boolean,
 *  onClose: () => void,
 *  onChange: (newSrc: JsonProp) => void,
 *  className: string,
 *  data: JsonProp,
 *  edit?: boolean
 * }} ModelJsonProps
 */

/** @type {React.FC<ModelJsonProps>} */
export const ModalJson = ({ isOpen, onClose, onChange, className, data: src, edit = false }) => {
  /** @type {[JsonProp, React.Dispatch<JsonProp>] } */
  const [data, setData] = useState(src);
  const [isJson, value] = useMemo(() => validateJson(data?.Value), [data])

  useEffect(() => {
    setData(src);
  }, [src])

  /** @type {(newData: Partial<JsonProp>) => void} */
  const handleEditData = useCallback((newData) => {
    if (newData.Value === undefined) delete newData.Value;
    if (newData.Key === undefined) delete newData.Key;

    setData({ ...data, ...newData });
  }, [data]);

  if (!data) {
    console.error("Invalid Propery [data] on ModalJson");
    return React.Fragment;
  }

  const onUpdateJson = edit
    ? ((newVal) => handleEditData({ Value: JSON.stringify(newVal.updated_src) }))
    : undefined;

  return (
    <Modal
      size="lg"
      isOpen={isOpen}
      toggle={onClose}
      className={className}
    >
      <ModalHeader toggle={onClose}>
        <Flex align="center" justify="space-between">
          {!edit
            ? data.Key
            : (<EditableTitle
              textSize={14}
              maxWidth="100%"
              margin={0}
              inputSize="sm"
              name={data.Key}
              onSubmit={e => handleEditData({ Key: e })}
            />)
          }
          <Box>
            <Button
              variantColor="lumenPrimary"
              type="button"
              isDisabled={data.Key === src.Key && data.Value === src.Value}
              onClick={() => { onChange(data); onClose() }}
            >
              Confirmar
            </Button>
          </Box>
        </Flex>
      </ModalHeader>
      <hr style={{ margin: "0" }} />
      <ModalBody style={{ maxHeight: "30rem", overflowY: "auto" }}>
        <PerfectScrollbar>
          {isJson ? (
            <ReactJson
              name={false}
              onAdd={onUpdateJson}
              onDelete={onUpdateJson}
              onEdit={onUpdateJson}
              src={value}
            />
          ) : (
            <Textarea
              value={value}
              onChange={e => handleEditData({ Value: e.target.value })}
            />
          )}
        </PerfectScrollbar>
      </ModalBody>
    </Modal>
  );
}

export default ModalJson;