import React from 'react';
import { useState,useEffect } from 'react';
import ReactJson from "react-json-view";
import PerfectScrollbar from "react-perfect-scrollbar";
// reactstrap components
import { Modal, ModalBody, ModalHeader,ModalFooter } from "reactstrap";
import {Button,Input,Select,NumberInput,Text} from '@chakra-ui/core';
import EditableTitle from '../MainAttributes/EditableTitle';
/** @typedef {import('./ConsolePreferences').ConsolePreference} ConsolePreference*/


/** @type {React.FC<{isOpen: boolean, toggle: () => void, preference?: ConsolePreference}>} */
export const EditPreferenceModal = ({isOpen,toggle, preference, onSubmit }) => {
  const [value,setValue] = useState(preference?.Value || "");
  const [type,setType] = useState(preference?.Type || 1);
  const [key,setKey] = useState(preference?.Key);

  useEffect(() => {
    const val = preference?.Value === 'object' 
    ? Array.isArray(preference.Value) ? [...preference.Value] : {...preference.Value}
    : preference?.Value
    
    setType(preference?.Type || 0);
    setKey(preference?.Key);
    setValue(val);
  } ,[preference])

  function handleSubmit(){
    onSubmit({
      Id: preference?.Id,
      Key: key,
      Type: type,
      Value: value,
      _original: preference,
    });
  }

  if(!isOpen) return <></>
  return (
  <Modal
    size="lg"
    isOpen={isOpen}
    toggle={toggle}
  >
    <ModalHeader toggle={toggle} style={{paddingBottom: 0}}>
      {preference?.Id 
        ? <Text marginRight={4} marginBottom={0} lineHeight={2.5} fontSize={16} fontWeight={700} fontFamily="Open Sans">{key || ""}</Text> 
        : <EditableTitle textSize={16} maxWidth="100%" inputSize="md" name={key || ""} onSubmit={e => setKey(e.toUpperCase())}/>
      }
    </ModalHeader>
    <hr style={{ margin: "0" }} />
    <ModalBody style={{ maxHeight: "30rem", overflowY: "auto" }}>
      <PerfectScrollbar style={{display: 'grid'}}>
        <label>
          Type
          <Select value={type} onChange={e => setType(Number.parseInt(e.target.value.toString())) }>
            <option value={0}>JSON</option>
            <option value={1}>Raw String</option>
            <option value={2}>Boolean</option>
            <option value={3}>Number</option>
          </Select>
        </label>
        <label>
          Value:
        {
          {
            0: () => (<ReactJson name={false} onEdit={(edit) => setValue(edit.updated_src)} onAdd={(edit) => setValue(edit.updated_src)} onDelete={(edit) => setValue(edit.updated_src)} src={value || {}}/>),
            1: () => (<Input value={value} onChange={e => setValue(e.target.value)}/>),
            2: () => (<Select value={value?.toString()} onChange={e => setValue(e.target.value.toString() === 'true') }>
                    <option value={'true'}>true</option>
                    <option value={'false'}>false</option>
                  </Select>),
            3: () => (<NumberInput type='number' value={typeof value === 'number' ? value : 0} onChange={e => setValue(e)}></NumberInput>),
          
          }[type]()
        }
        </label>
      </PerfectScrollbar>
    </ModalBody>
    <ModalFooter>
      <Button
        mr="1rem"
        variant="outline"
        variantColor="lumenPrimary"
        onClick={toggle}
      >
        Cancel
      </Button>
      <Button
          variantColor="lumenPrimary"
          onClick={handleSubmit}
          isDisabled={!key || value === undefined}
        >
          Save
      </Button>
    </ModalFooter>
  </Modal>
  );
}

export default EditPreferenceModal;