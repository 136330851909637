import React from "react";

import httpClient from "./http-client-singleton";
import { userService } from "../services/user.service.js";
const http = httpClient.getInstance();

export default class Interceptors extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isForbidden: false,
    };
    this.setupInterceptors();
    this.setupInterceptors = this.setupInterceptors.bind(this);
  }

  setupInterceptors() {
    http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status !== 200) {
          if (error.response.status === 401) {
            userService.logout();
          } else {
            this.props.notify(error);
          }
          if (error.response.status === 403 && !this.state.isForbidden) {
            this.setState({
              isForbidden: true,
            });
          }
        }
        return Promise.reject(error);
      }
    );
  }

  render() {
    return <></>;
  }
}
