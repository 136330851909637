import React, { useEffect, useState, useCallback } from "react";
import LeaderboardListing from "../components/Leaderboards/LeaderboardListing";
import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import Swal from "sweetalert2";
import SimplePaginationComponent from "../components/common/SimplePaginationComponent";
import PaginationHeader from "../components/common/PaginationHeader";

const http = httpClientCreator.getInstance();

const addEventPrefix = (leaderboard, event) => {
  // Adds the event content and event edition as prefix to the attribute name
  return {
    ...leaderboard,
    attributeNames: leaderboard.attributeNames.map((attribute) =>
      // Prevent adding the prefix twice.
      /(\w+\.\d+\.\w+)/.test(attribute)
        ? attribute
        : `${event.Content}.${event.Id}.${attribute}`
    ),
  };
};

const Leaderboards = () => {
  const [deleteModalLoading, setDeleteModalLoading] = useState(false);
  const [leaderboardDefinitions, setLeaderboardDefinitions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [downloading, setDownloading] = useState(false);
  const [isSendingDefinition, setIsSendingDefinition] = useState(false);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");


  const getPage = useCallback(
    (page) => {
      setLoading(true);
      http
        .get("/leaderboards/definitions/listing", {
          headers: authHeader(),
          params: {
            skip: page * objectsPerPage,
            take: objectsPerPage,
            filter: search,
          },
        })
        .then((response) => {
          setLeaderboardDefinitions(response.data.data.Result);
          setTotal(response.data.data.Total);
          setCurrentPage(page);
        })
        .finally(() => setLoading(false));
    },
    [objectsPerPage, search]
  );

  useEffect(() => {
    getPage(0);
  }, [objectsPerPage, getPage]);

  const onSearch = useCallback((search) => setSearch(search), []);

  const onClear = useCallback(() => setSearch(""), []);

  const getEventInfo = (id) => {
    return http
      .get(`/events/${id}`, { headers: authHeader() })
      .then((response) => response.data.data);
  };

  const sendLeaderboardDefinition = (definition, callback) => {
    setIsSendingDefinition(true);
    if (definition.eventId) {
      getEventInfo(definition.eventId).then((event) => {
        const leaderboard = addEventPrefix(definition, event);
        updateLeaderboardDefinition(leaderboard, callback).finally(() =>
          setIsSendingDefinition(false)
        );
      });
    } else
      updateLeaderboardDefinition(definition, callback).finally(() =>
        setIsSendingDefinition(false)
      );
  };

  const updateLeaderboardDefinition = (definition, callback) => {
    return http
      .put("/leaderboards/definitions", definition, { headers: authHeader() })
      .then(() => {
        callback();
        Swal.fire({
          type: "success",
          text: "The Leaderboard Definition has been successfully sent!",
          onClose: () => {
            getPage(0);
          },
        });
      })
      .catch(() => {
        Swal.fire({
          type: "error",
          text: "An error occurred when creating the leaderboard definition!",
        });
      });
  };

  /** @type {(id: string|number) => void} */
  const deleteLeaderboardDefinition = (id) => {
    setDeleteModalLoading(true);
    http
      .delete(`/leaderboards/definitions/${id?.toString()?.trim()}`, { headers: authHeader() })
      .then(() => {
        Swal.fire({
          type: "success",
          text: "The Event Definition has been successfully deleted...",
          onClose: () => {
            getPage(0);
          },
        });
      })
      .catch((error) => {
        Swal.fire({
          type: "error",
          text: error,
        });
      })
      .finally(() => {
        setDeleteModalLoading(false);
      });
  };

  const header = (
    <PaginationHeader
      total={total}
      skip={currentPage * objectsPerPage}
      take={objectsPerPage}
      onObjectsPerPageChange={(value) => setObjectsPerPage(value)}
    />
  );

  const footer = (
    <SimplePaginationComponent
      currentPage={currentPage}
      fetchPage={getPage}
      objectsPerPage={objectsPerPage}
      totalObjects={total}
    />
  );

  const onExportCsv = async (guild) => {
    setDownloading(true);
    const response = await http.get(`/leaderboards/definitions/dump`, {
        responseType: 'blob',
        headers: authHeader(),
    }).finally(() => setDownloading(false));

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `leaderboard_definitions_all_${(new Date()).valueOf()}.csv`);
    document.body.appendChild(link);
    link.click();
}

  return (
    <LeaderboardListing
      leaderboards={leaderboardDefinitions}
      onDelete={deleteLeaderboardDefinition}
      onEdit={sendLeaderboardDefinition}
      deleteModalLoading={deleteModalLoading}
      modalLoading={isSendingDefinition}
      header={header}
      footer={footer}
      onSearch={onSearch}
      onClear={onClear}
      loading={loading}
      onExportCsv={onExportCsv}
      downloading={downloading}
    />
  );
};

export default Leaderboards;
