import {Box, Divider, Heading, SimpleGrid, Skeleton, Text } from '@chakra-ui/core';
import React from 'react';
import { parseDate } from '../../helpers/date-helper';
import DateTimeComponent from '../common/DateTimeComponent';
import { getRegattaRealEndDate, isRegattaFinished } from './utils';


/** @type {React.FC} */
const PropTitle = ({children}) => (
  <Heading 
    fontSize="0.625rem" 
    className="surtitle text-muted" 
    fontFamily="Open Sans" 
    display="block" 
    fontWeight="bold"
  >
    {children}
  </Heading>
)
/** @typedef {import('../../views/Regattas').Regatta} Regatta */

/**
 * @typedef {{
 *  regatta: Regatta,
 *  loading: boolean
 * }} RegattaDetailsHeaderProps
 */

/** @type {React.FC<RegattaDetailsHeaderProps>} */
const RegattaDetailsHeader = ({regatta, loading}) => {
  return (
    <Box backgroundColor="#fff" borderRadius="md">
      <Heading size="sm" padding={4} color="#525f7f" paddingLeft={6}>
        Regatta Information
      </Heading>
      <Divider />
      <SimpleGrid columns={2} spacingX={1} spacingY={1} margin={8}>
        <Box>
          <PropTitle >Regatta ID</PropTitle>
          <Skeleton height="20px" width="80%" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>{regatta.Id || "-"}</Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Regatta Definition ID</PropTitle>
          <Skeleton height="20px" width="60%" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>{regatta.RegattaDefinitionId || '-'}</Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Regatta Key</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>{regatta.DataKey || "-"}</Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Status:</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={600}>
              {
                regatta.IsDeleted
                  ? "Deleted"
                  : parseDate(regatta.EndDateLastRegatta).isBefore(Date.UTC) 
                  ? "Not Started" 
                  : isRegattaFinished(regatta) 
                  ? "Finished"
                  : "Active"
              }
            </Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Members Per Guild</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
              {regatta.GuildMembersQuantity || "--"} 
            </Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Guilds Per Group</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
              {regatta.GuildsQuantity || "--"}
            </Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Expected Competition Start</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
            <DateTimeComponent 
                tooltipId="ExpectStartT"
                date={regatta.EndDateLastRegatta}
              />
               </Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Competition End</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
              <DateTimeComponent
                tooltipId="competitionEndT"  
                date={parseDate(regatta.EndDateLastRegatta).addMin(regatta.Duration)}
              /> 
            </Text>
          </Skeleton>
        </Box>
        <Box>
          <PropTitle >Creation Date</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
              <DateTimeComponent 
                tooltipId="creationDateT"
                date={regatta.CreationDate}
              /> 
            </Text>
          </Skeleton>
          <br/>
        </Box>
        <Box>
          <PropTitle >Regatta End</PropTitle>
          <Skeleton height="20px" width="200px" marginY={2} isLoaded={!loading}>
            <Text fontWeight={400}>
              <DateTimeComponent
                tooltipId="regattaEndT"  
                date={getRegattaRealEndDate(regatta)}
              /> 
            </Text>
          </Skeleton>
        </Box>
      </SimpleGrid>
    </Box>
  );
}

export default RegattaDetailsHeader;