import React, { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  FormHelperText,
  FormErrorMessage,
  Button,
  Flex,
  Textarea,
  Switch
} from "@chakra-ui/core";

import { useForm } from "react-hook-form";

import "../common/css/errorStyles.css";

const LeaderboardDefinitionForm = ({
  onSubmit,
  onCancel,
  Id,
  Name,
  EventId,
  Capacity,
  AttributeNames,
  AutoJoin
}) => {
  const [name, setName] = useState(Name);
  const [capacity, setCapacity] = useState(Capacity);
  const [eventId, setEventId] = useState(EventId);
  const [attributeNames, setAttributeNames] = useState(AttributeNames);
  const [autoJoin, setAutoJoin] = useState(AutoJoin !== false);
  const { handleSubmit, errors, register } = useForm({ reValidateMode: "onBlur" });

  const submitDefinition = () => {
    const formattedAttributeNames = Array.isArray(attributeNames)
      ? attributeNames
      : attributeNames
          .replace(/[\s\n\r]+/g, "")
          .split(",")
          .filter((attribute) => attribute !== "");

    const leaderboardDefinition = {
      ...(Id && { id: Id }),
      name,
      capacity,
      attributeNames: formattedAttributeNames,
      eventId,
      autoJoin
    };
    onSubmit(leaderboardDefinition);
  };

  useEffect(() => {
    setName(Name);
    setCapacity(Capacity);
    setAttributeNames(AttributeNames);
    setEventId(EventId);
    setAutoJoin(AutoJoin);
  }, [Name, Capacity, AttributeNames, EventId, AutoJoin]);

  return (
    <Box>
      <FormControl isInvalid={Boolean(errors["leaderboard-name"])}>
        <FormLabel>Definition Name</FormLabel>
        <Input
          name="leaderboard-name"
          placeholder="Leaderboard Definition Name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          ref={register({
            required: true,
          })}
        />
        <FormErrorMessage className="no-margin-bottom">
          This field can't be empty
        </FormErrorMessage>
        <FormHelperText>The Leaderboard Definition Name.</FormHelperText>
      </FormControl>
      <FormControl isInvalid={Boolean(errors["eventId"])}>
        <FormLabel>Event Edition</FormLabel>
        <Input
          placeholder="Event Edition (Optional)"
          name="eventId"
          value={eventId}
          isDisabled={Id}
          onChange={(e) => setEventId(e.target.value?.trim() || null)}
          ref={register({
            validate: (value) => {
              return (
                value === null ||
                value === "" ||
                !isNaN(value) ||
                "This field must be a valid event edition"
              );
            },
          })}
        />
        <FormErrorMessage className="error-label">
          {errors.eventId && errors.eventId.message}
        </FormErrorMessage>
        <FormHelperText>
          The event edition associated to this leaderboard definition.
        </FormHelperText>
      </FormControl>
      <FormControl isInvalid={Boolean(errors["attribute-names"])}>
        <FormLabel>Attribute Names</FormLabel>
        <Textarea
          placeholder="Attribute Names"
          name="attribute-names"
          value={attributeNames}
          onChange={(e) => setAttributeNames(e.target.value)}
          ref={register({
            required: true,
          })}
        />
        <FormErrorMessage className="no-margin-bottom">
          This field can't be empty
        </FormErrorMessage>
        <FormHelperText>
          A list of attributes separated by comma.
          {
            " Each attribute will be saved using the following format: {event_text}.{event_edition}.{attribute_name}"
          }
        </FormHelperText>
      </FormControl>
      <FormControl isInvalid={Boolean(errors["capacity"])}>
        <FormLabel>Capacity</FormLabel>
        <Input
          placeholder="Capacity (Optional)"
          name="capacity"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value || null)}
          ref={register({
            validate: (value) => {
              return (
                value === null ||
                value === "" ||
                value > 0 ||
                "This field must be a number greater than 0"
              );
            },
          })}
        />
        <FormErrorMessage className="error-label">
          {errors.capacity && errors.capacity.message}
        </FormErrorMessage>
        <FormHelperText>How many players can join a group.</FormHelperText>
      </FormControl>
      <FormControl>
        <FormLabel>Auto Join</FormLabel>
        <Switch
          name="autoJoin"
          value={autoJoin}
          isChecked={autoJoin}
          onChange={(e) => setAutoJoin(Boolean(e.target.checked))}
        />
        <FormHelperText>Turn off this option if the player shouldn't join automatically a leaderboard</FormHelperText>
      </FormControl>
      <Flex justifyContent="flex-end">
        <Button
          onClick={handleSubmit(submitDefinition)}
          textAlign="left"
          display="inline"
          ml={10}
          mr={10}
          variantColor="lumenPrimary"
        >
          Confirm
        </Button>
        <Button
          variant="outline"
          variantColor="lumenPrimary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

export default LeaderboardDefinitionForm;
