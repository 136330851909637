import React,{ useState, useEffect }  from 'react';
import Swal from 'sweetalert2';
import RegattasList from '../components/Regatta/RegattaList';
import RegattaModal from '../components/Regatta/RegattaModal';
import authHeader from '../helpers/auth-header';
import httpClientCreator from '../helpers/http-client-singleton';
/**
 * @typedef {{
 *  Id: number,
 *  CreationDate: string,
 *  EndDateLastRegatta: string,
 *  RegattaDefinitionId: number,
 *  DataKey: string,
 *  StartDate: number,
 *  Duration: number,
 *  Cooldown: number,
 *  GuildsQuantity: number,
 *  GuildMembersQuantity: number,
 *  GuildMembersQuantity: number,
 *  IsDeleted: boolean,
 * }} Regatta
 * 
 */


 const http = httpClientCreator.getInstance();

 async function getAllRegattasByDefinition(id){
  const data = await http.get(`regattas/definitions/${id}/regattas`, { headers: authHeader()})
  .then(x => x.data)
  .catch(e => e)

  if(data instanceof Error) return;
  return data.data;
}


function Regattas() {
  const [regattas,setRegattas] = useState({});
  const [selectedRegatta, setSelectedRegatta] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [downloading, setDownloading] = useState(false);

  async function fetchData(){
    /** @type {{data: int[]} | Error} */
    const data = await http.get(`regattas/definitions/versions`, { headers: authHeader()})
    .then(x => x.data)
    .catch(e => e)

    if(!(data instanceof Error)){
      var lastDefId = data.data[data.data.length - 1];
      var lastDef = await getAllRegattasByDefinition(lastDefId)
      setLoading(false);
      setRegattas((data.data || []).reduce((acc,val) => ({...acc, [val]: val === lastDefId ? lastDef : undefined}),{}));
    }
  }

  async function fetchRegattas(id){
    if(regattas[id]) return;
    setRegattas({...regattas, [id]: "LOADING"});
    
    const data = await getAllRegattasByDefinition(id);
    if(!data) return;

    setRegattas({...regattas, [id]: data});
  }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => { 
    fetchData(); 
  },[])

  const handleOnCreate = async () => {
    Swal.fire({
      type: "question",
      showLoaderOnConfirm: true,
      title: 'Are you sure?',
      showCancelButton: true,
      preConfirm: () => {
        return http.put(`regattas`,{}, { headers: authHeader()}).then(x => x.data.data).catch(() => undefined)
      }
    }).then(async ({value,dismiss}) => {
      if(dismiss) return;      
      if(!value) return;

      console.log(value);
      setRegattas([...regattas,value])
    } )
  }

  /**
   * @param {Regatta} reg */
  const handleOnDelete = async (reg) => {
    Swal.fire({
      type: "question",
      title: 'Are you sure?',
      showCancelButton: true,
      showLoaderOnConfirm: true,
      preConfirm: () =>{
        setSelectedRegatta(undefined)
        return http.delete(`regattas/${reg.Id}`, { headers: authHeader()}).catch(() => ({error: true}));
      }
    }).then(async ({value,dismiss}) => {
      if(dismiss) return;      
      if(value?.error) return;

      setRegattas(regattas.map(x => (x.Id === reg.Id ? {...x, IsDeleted: true} : x)))
    } )
  }

  const onExportCsv = async () => {
    setDownloading(true);
    const response = await http.get(`/regattagroups/dump`, {
        responseType: 'blob',
        headers: authHeader(),
    }).finally(() => setDownloading(false));

    const url = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `regattagroups_all_${(new Date()).valueOf()}.csv`);
    document.body.appendChild(link);
    link.click();
  }


  return (<>
    <RegattasList 
      regattaGroups={regattas}  
      loading={loading} 
      onShowModal={setSelectedRegatta}
      onFetchEdition={fetchRegattas}
      onExportCsv={onExportCsv}
      downloading={downloading}
      onCreate={handleOnCreate}
    />
    <RegattaModal
      modalOpen={!!selectedRegatta}
      regatta={selectedRegatta}
      onDelete={handleOnDelete}
      onCancel={() => setSelectedRegatta(undefined)}
      onSubmit={() => setSelectedRegatta(undefined)}
    />
  </>);
}

export default Regattas;