import React, { useCallback, useState } from "react";
import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Textarea,
  SimpleGrid,
  FormErrorMessage,
  FormHelperText,
  Checkbox,
  Flex,
  Button,
  IconButton,
  Stack,
  Heading,
  Switch,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import GiftInput from "./GiftInput";
import validator from "validator";

import moment from "moment";

import "../common/css/errorStyles.css";
import Swal from "sweetalert2";
import PlayerListInput from "./PlayerListInput";
import DateTimeComponent from "../common/DateTimeComponent";
import { parseDate } from "../../helpers/date-helper";

const getHours = (time) => {
  if (time) {
    const endHour = time.indexOf(":");
    return Number(time.substring(0, endHour));
  }
  return "";
};

const hoursFromTicks = ticks => (ticks || 0) / 36000000000;

/** @type {React.FC<{message: Message}>} */
const GiftMessageForm = ({
  onSubmit,
  onCancel,
  global,
  readonly,
  getMessages,
  message = {},
  ...restProps
}) => {
  const [title, setTitle] = useState(message.Title || "");
  const [messageText, setMessageText] = useState(message.Message || "");
  const [externalLink, setExternalLink] = useState(message.ExternalLink || "");
  const [imageURL, setImageUrl] = useState(message.ImageURL || "");
  const [expiration, setExpiration] = useState(hoursFromTicks(message.Expiration) || 48);
  const [automaticDisplay, setAutomaticDisplay] = useState(message.AutomaticDisplay || true);
  const [availableDate, setAvailableDate] = useState(parseDate(message.AvailableDate).inputDate());
  const [availableTime, setAvailableTime] = useState(parseDate(message.AvailableDate).time());
  const [isMultipleGifts, setIsMultipleGifts] = useState(false);
  const [gifts, setGifts] = useState(message.Gifts || []);
  const [playerIds, setPlayerIds] = useState(message.PlayerIds || []);

  const { register, handleSubmit, errors, getValues } = useForm({
    reValidateMode: "onBlur",
  });

  const validateExternalLink = (url) => {
    if (url) {
      return validator.isURL(url);
    }
    return true;
  };

  const validateImageUrl = (url) => {
    if (url) {
      let isImageUrl = validator.isURL(url) || url.includes("assetbundle://");
      isImageUrl =
        isImageUrl &&
        validator.matches(url, /.*(\.png|\.jpg|\.jpeg|\.gif|\.bmp)/i);
      return isImageUrl;
    }
    return true;
  };

  const removeGift = (index) => {
    const newGifts = gifts.slice();
    newGifts.splice(index, 1);
    setGifts(newGifts);
  };

  const handleAddGift = () => {
    setGifts([
      ...gifts,
      {
        m_giftableId: "",
        m_amount: 0,
        m_type: 1,
      },
    ]);
  };

  const onSuccess = (response) => {
    if (response.status) {
      Swal.fire({
        type: "success",
        title: "Success",
        text: "Message Sent!",
      }).then(getMessages);
    }
  };

  const onError = () => {
    Swal.fire({
      type: "error",
      title: "Error",
      text: "Please insert a valid information and try again.",
    });
  };

  const createMessage = () => {
    const m_availableDate = moment.utc(
      moment(availableDate + " " + availableTime)
    );
    const formattedGifts = gifts.map((gift) => ({
      ...gift,
      m_giftableId: gift.m_giftableId?.toString().trim(),
    }));

    const isValidDate = m_availableDate !== null && m_availableDate.isValid();

    return {
      Message: messageText,
      Expiration: expiration,
      Gifts: formattedGifts,
      AutomaticDisplay: automaticDisplay,
      ExternalLink: externalLink,
      ImageURL: imageURL,
      ...(isValidDate ? { AvailableDate: m_availableDate.format() } : {}),
      Title: title,
    };
  };

  const onMessageSubmit = () => {
    const msg = createMessage();
    if (isMultipleGifts && playerIds) {
      onSubmit({ ...msg, PlayerIds: playerIds }, onSuccess, onError);
    } else {
      onSubmit(msg, onSuccess, onError);
    }
  };

  const handleGiftChange = useCallback(
    (giftInfo, index) => {
      const newGifts = gifts.slice();
      newGifts[index] = { ...newGifts[index], ...giftInfo };
      setGifts(newGifts);
    },
    [gifts]
  );

  const onIsMultipleGiftChange = (isMultipleGift) => {
    setIsMultipleGifts(isMultipleGift);
    if (!isMultipleGift) {
      setPlayerIds([]);
    }
  };

  return (
    <Box fontFamily="Open Sans" {...restProps}>
      {readonly && (
        <Stack isInline justify="space-evenly">
          <div>
            <span
              className={message.Collected ? "text-success" : "text-danger"}
            >
              ●
            </span>
            <span className="h6 surtitle text-muted ml-2">
              {message.Collected
                ? "Message collected"
                : "Message not collected"}
            </span>
          </div>
          <div>
            <span className="h6 surtitle text-muted">
              Created at:
              <DateTimeComponent
                tooltipId="message-creation-date"
                date={message.CreationDate}
              />
            </span>
          </div>
        </Stack>
      )}
      <FormControl>
        <FormLabel fontWeight="600" marginTop={2}>
          Title
        </FormLabel>
        <Input
          isDisabled={readonly}
          value={title}
          onChange={(e) => setTitle(e.target.value)}
          ref={register}
        />
      </FormControl>
      <FormControl>
        <FormLabel fontWeight="600" marginTop={2}>
          Message
        </FormLabel>
        <Textarea
          isDisabled={readonly}
          ref={register}
          value={messageText}
          onChange={(e) => setMessageText(e.target.value)}
        />
      </FormControl>
      <SimpleGrid columns={2} spacingX={2} marginTop={2}>
        <FormControl isInvalid={Boolean(errors["message-external-link"])}>
          <FormLabel fontWeight="600" marginTop={2}>
            External Link
          </FormLabel>
          <Input
            isDisabled={readonly}
            name="message-external-link"
            value={externalLink}
            ref={register({
              validate: (value) =>
                validateExternalLink(value) ||
                "Please ensure to insert valid information on the External Link field.",
            })}
            onChange={(e) => setExternalLink(e.target.value)}
          />
          <FormErrorMessage className="error-label">
            {errors["message-external-link"] &&
              errors["message-external-link"].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors["message-image-url"])}>
          <FormLabel fontWeight="600" marginTop={2}>
            Image URL
          </FormLabel>
          <Input
            isDisabled={readonly}
            name="message-image-url"
            value={imageURL}
            ref={register({
              validate: (value) =>
                validateImageUrl(value) ||
                "Please ensure to insert valid information on the Image URL field.",
            })}
            onChange={(e) => setImageUrl(e.target.value)}
          />
          <FormErrorMessage className="error-label">
            {errors["message-image-url"] && errors["message-image-url"].message}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <SimpleGrid columns={3} spacing={2}>
        <FormControl
          isInvalid={Boolean(errors["message-available-date"])}
          isDisabled={readonly}
        >
          <FormLabel fontWeight="600" marginTop={2}>
            Available Date
          </FormLabel>
          <Input
            name="message-available-date"
            type="date"
            value={availableDate}
            ref={register({
              validate: (value) =>
                value === null ||
                value === undefined ||
                value === "" ||
                moment(
                  value + " " + getValues("message-available-time")
                ).isAfter(moment()) ||
                "Please ensure to insert a date in the future.",
            })}
            onChange={(e) => setAvailableDate(e.target.value)}
          />
          <FormHelperText>
            Date when the message will be available to the players
          </FormHelperText>
          <FormErrorMessage className="error-label">
            {errors["message-available-date"] &&
              errors["message-available-date"].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl
          isInvalid={Boolean(errors["message-available-time"])}
          isDisabled={readonly}
        >
          <FormLabel fontWeight="600" marginTop={2}>
            Available Time
          </FormLabel>
          <Input
            name="message-available-time"
            type="time"
            value={availableTime}
            ref={register({
              validate: (value) =>
                value === null ||
                value === undefined ||
                value === "" ||
                moment(
                  getValues("message-available-date") + " " + value
                ).isAfter(moment()) ||
                "Please ensure to insert a date in the future.",
            })}
            onChange={(e) => setAvailableTime(e.target.value)}
          />
          <FormHelperText>
            Time when the message will be available to the players
          </FormHelperText>
          <FormErrorMessage className="error-label">
            {errors["message-available-time"] &&
              errors["message-available-time"].message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors["message-expiration"])}>
          <FormLabel fontWeight="600" marginTop={2}>
            Expiration
          </FormLabel>
          <Input
            isDisabled={readonly}
            name="message-expiration"
            type="number"
            value={expiration}
            ref={register({
              required: true,
              validate: (value) =>
                value > 0 || "Please ensure to insert a value greater than 0.",
            })}
            onChange={(e) => setExpiration(e.target.value)}
          />
          <FormHelperText>
            Expiration in X hours. Timer starts counting at Available Time
          </FormHelperText>
          <FormErrorMessage className="error-label">
            {(errors["message-expiration"] &&
              errors["message-expiration"].message) ||
              "Please add an expiration to the message."}
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      <Checkbox
        marginTop={2}
        isChecked={automaticDisplay}
        onChange={(e) => setAutomaticDisplay(Boolean(e.target.checked))}
      >
        Automatic Display
      </Checkbox>
      {global && (
        <>
          <FormControl>
            <FormLabel fontWeight="600" marginTop={4}>
              Send Message to a List
            </FormLabel>
            <Switch
              display="block"
              isDisabled={readonly}
              isChecked={isMultipleGifts}
              onChange={(e) => onIsMultipleGiftChange(e.target.checked)}
            />
          </FormControl>
          {isMultipleGifts && <PlayerListInput onChange={setPlayerIds} />}
        </>
      )}
      <Heading size="sm" marginTop={2}>
        Gifts
      </Heading>
      {gifts.map((gift, index) => {
        return (
          <GiftInput
            key={index}
            gift={gift}
            readonly={readonly}
            handleGiftChange={(gift) => handleGiftChange(gift, index)}
            removeGift={() => removeGift(index)}
            register={register}
            errors={errors}
            name={`gift-input-${index}`}
          />
        );
      })}
      {!readonly && (
        <Flex justifyContent="center">
          <IconButton
            aria-label="Add Gift"
            size="sm"
            isRound
            icon="add"
            variant="outline"
            variantColor="lumenPrimary"
            onClick={handleAddGift}
          />
        </Flex>
      )}
      {!readonly && (
        <Flex justifyContent="flex-end" marginTop={4}>
          <Button
            size="md"
            leftIcon="email"
            variantColor="lumenPrimary"
            isDisabled={isMultipleGifts && !playerIds.length}
            onClick={handleSubmit(onMessageSubmit)}
          >
            Send it
          </Button>
          {onCancel && (
            <Button
              marginLeft={4}
              size="md"
              variantColor="gray"
              onClick={onCancel}
            >
              Cancel
            </Button>
          )}
        </Flex>
      )}
    </Box>
  );
};

export default GiftMessageForm;
