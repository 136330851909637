import React from "react";
// react plugin that creates an input with badges
import TagsInput from "react-tagsinput";

const TagsContainer = ({ tags, handleTagsInput }) => {
  return (
    <TagsInput
      className="bootstrap-tagsinput"
      onChange={handleTagsInput}
      tagProps={{ className: "tag bg-primary badge mr-1" }}
      value={tags}
      inputProps={{
        className: "d-none",
      }}
    />
  );
};

export default TagsContainer;
