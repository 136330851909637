// nodejs library that concatenates classes
import classnames from "classnames";
// nodejs library to set properties for components
import PropTypes from "prop-types";
import React from "react";
import { Link, Redirect } from "react-router-dom";

import { userService } from "../../services/user.service";
// reactstrap components
import {
  Badge,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavItem,
  UncontrolledDropdown,
} from "reactstrap";
import Gravatar from "../common/Gravatar.jsx";

class AdminNavbar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      environment: this.props.env,
    };
  }

  environmentBadge = () => {
    return (
      <>
        <Badge className="badge-default" pill>
          {this.state.environment}
        </Badge>
      </>
    );
  };

  logout() {
    // remove user from local storage to log user out
    localStorage.removeItem("user");
  }

  render() {
    if (!localStorage.getItem("user")) {
      return <Redirect to="/login" />;
    }
    let user = JSON.parse(localStorage.getItem("user"));
    let navBar = "bg-secondary";
    if (this.state.environment === "Development") navBar = "bg-green";
    if (this.state.environment === "Staging") navBar = "navbar-dark bg-info";
    if (this.state.environment === "Production") navBar = "bg-red";
    return (
      <>
        <Navbar
          className={classnames(
            "navbar-top navbar-expand border-bottom navbar-light py-0 " +
              navBar,
            {
              /* "navbar-dark bg-info": this.props.theme === "dark" */
            },
            {
              /* "navbar-light bg-secondary": this.props.theme === "light" */
            }
          )}
        >
          <Container fluid>
            <Collapse navbar isOpen={true}>
              <Nav className="align-items-center" navbar>
                <NavItem className="d-xl-none">
                  <div
                    className={classnames(
                      "pr-3 sidenav-toggler",
                      { active: this.props.sidenavOpen },
                      { "sidenav-toggler-dark": this.props.theme === "dark" }
                    )}
                    onClick={this.props.toggleSidenav}
                  >
                    <div className="sidenav-toggler-inner">
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                      <i className="sidenav-toggler-line" />
                    </div>
                  </div>
                </NavItem>
                <NavItem>
                  {this.props.showBadge ? this.environmentBadge() : null}
                </NavItem>
              </Nav>
              <Nav className="align-items-center ml-auto" navbar>
                <UncontrolledDropdown nav>
                  <DropdownToggle
                    style={{ cursor: "pointer" }}
                    className="nav-link pr-0"
                    color=""
                    tag="a"
                  >
                    <Media className="align-items-center">
                      <span className="avatar avatar-sm rounded-circle">
                        <Gravatar email={user.email} />
                      </span>
                      <Media className="ml-2 d-none d-lg-block">
                        <span className="mb-0 text-sm font-weight-bold">
                          {user.firstName} {user.lastName}
                        </span>
                      </Media>
                    </Media>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <Link to={"/acp/myprofile"}>
                      <DropdownItem
                        style={{ cursor: "pointer" }}
                        onClick={() => <Link to={"/acp/myprofile"} />}
                      >
                        <i className="ni ni-single-02" />
                        <span>My profile</span>
                      </DropdownItem>
                    </Link>
                    {userService.hasPermission("MANAGE_SIDIOUS_USERS") && <Link to={"/acp/settings"}>
                      <DropdownItem
                        href="#pablo"
                        onClick={() => <Link to={"/acp/settings"} />}
                      >
                        <i className="ni ni-settings-gear-65" />
                        <span>Settings</span>
                      </DropdownItem>
                    </Link>}
                    {userService.hasPermission("CONTROL_CONSOLE_PREFERENCES") && (
                      <Link to={"/acp/systempreferences"}>
                        <DropdownItem>
                          <i className="ni ni-ui-04" />
                          <span>Console Preferences</span>
                        </DropdownItem>
                      </Link>
                    )}

                    <DropdownItem divider />
                    <DropdownItem href="#pablo" onClick={this.logout}>
                      <i className="fas fa-sign-out-alt" />
                      <span>Logout</span>
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              </Nav>
            </Collapse>
          </Container>
        </Navbar>
      </>
    );
  }
}
AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
