import NotificationAlert from "react-notification-alert";
import { Button, Card, CardBody, CardHeader, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import * as PropTypes from "prop-types";
import React, { useState } from "react";
import classNames from "classnames";
import UserTable from "./UserTable";
import RoleTable from "./RoleTable";

const Settings = ({
  notificationRef,
  tableRef,
  onClick,
  onOpenRoleModal,
  loading,
  getUsers,
  handleOpenUpdateModal,
  onUpdateRole,
  roles,
  users,
}) => {
  const [activeTab, setActiveTab] = useState('1');
  const toggle = tab => activeTab !== tab && setActiveTab(tab);
  

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
      <Card className="shadow">
        <CardBody>
          <Card>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '1' })}
                onClick={() => { toggle('1'); }}
              >
                Users
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classNames({ active: activeTab === '2' })}
                onClick={() => { toggle('2'); }}
              >
                Roles
              </NavLink>
            </NavItem>
          </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId="1">
          <CardHeader>
            <h4>User List 
              <Button className="float-right" color="primary" onClick={onClick} size="sm">
                Create User
              </Button>
            </h4>
          </CardHeader>
            <CardBody className="p-0">
              {loading ? 
                <LoadingContainer /> 
                : 
                <UserTable 
                  users={users} 
                  getUsers={getUsers} 
                  handleOpenUpdateModal={handleOpenUpdateModal} 
                  tableRef={tableRef}/>
                }
            </CardBody>
          </TabPane>
          <TabPane tabId="2">
            <CardHeader>
              <h4>Role List 
                <Button className="float-right" color="primary" onClick={() => onOpenRoleModal(null)} size="sm">
                  Create Role
                </Button>
              </h4>
            </CardHeader>
            <CardBody className="p-0">
              {loading 
                ? <LoadingContainer /> 
                : <RoleTable roles={roles} onOpen={onOpenRoleModal}  onEdit={onUpdateRole}/>
              }
            </CardBody>
          </TabPane>
      </TabContent>

      </Card>
      </CardBody>
      </Card>
    </>
  );
};

export default Settings;

Settings.propTypes = {
  notificationRef: PropTypes.any,
  tableRef: PropTypes.any,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  getUsers: PropTypes.func,
  handleOpenUpdateModal: PropTypes.func,
  users: PropTypes.array,
  children: PropTypes.element,
};
