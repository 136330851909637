import React, { useEffect, useState } from "react";

import {
  Card,
  CardBody,
  CardHeader,
  Row,
  Col,
  Modal,
  Button,
  Form,
  Input,
  FormGroup,
  Label,
  UncontrolledTooltip,
  FormFeedback,
} from "reactstrap";

import { useForm } from "react-hook-form";

import LoadingContainer from "../Layout/LoadingContainer";

const isValidRegex = (input) => {
  let isValid = true;
  try {
    new RegExp(input);
  } catch (e) {
    isValid = false;
  }
  return isValid;
};

const AttributeConfigModal = ({
  attribute,
  loading,
  open,
  toggleModal,
  onSubmit,
}) => {
  const [id, setId] = useState(attribute.Id);
  const [attributeName, setAttributeName] = useState(attribute.Name);
  const [jsonPath, setJsonPath] = useState(attribute.JsonPathExpression);
  const [regex, setRegex] = useState(attribute.Regex);
  const [persistenceKey, setPersistenceKey] = useState(
    attribute.PersistenceKeyName
  );

  const { handleSubmit, register, errors } = useForm({ reValidateMode: "onBlur" });

  useEffect(() => {
    setId(attribute.Id);
    setAttributeName(attribute.Name);
    setJsonPath(attribute.JsonPathExpression);
    setRegex(attribute.Regex);
    setPersistenceKey(attribute.PersistenceKeyName);
  }, [attribute]);

  const sendAttribute = () => {
    const body = {
      name: attributeName,
      jsonPathExpression: jsonPath,
      persistenceKeyName: persistenceKey,
      valueType: "int",
      ...(regex && { regex }),
      ...(id && { id }),
    };

    onSubmit(body);
  };

  const content = loading ? (
    <LoadingContainer />
  ) : (
    <Card className="bg-white shadow border-0 m-0">
      <CardHeader>Attribute Configuration</CardHeader>
      <CardBody>
        <Form role="form">
          <Row className="py-1">
            <Col xs="12" lg="6">
              <FormGroup>
                <Label className="text-muted pl-1" for="attr_name">
                  <small>
                    <b>Attribute Name</b>
                  </small>
                </Label>
                <Input
                  id="attr_name"
                  name="attr_name"
                  placeholder="Attribute Name"
                  type="text"
                  invalid={errors.attr_name}
                  value={attributeName}
                  onChange={(e) => setAttributeName(e.target.value)}
                  innerRef={register({
                    required: true,
                  })}
                />
                <UncontrolledTooltip placement="top" target="attr_name">
                  The Attribute name used to create the configuration
                </UncontrolledTooltip>
                {errors.attr_name && (
                  <FormFeedback>This field is required</FormFeedback>
                )}
              </FormGroup>
            </Col>
            <Col xs="12" lg="6">
              <FormGroup>
                <Label className="text-muted pl-1" for="persistence_name">
                  <small>
                    <b>Persistence Key Name</b>
                  </small>
                </Label>
                <Input
                  id="persistence_name"
                  name="persistence_name"
                  placeholder="Persistence Key Name"
                  type="text"
                  value={persistenceKey}
                  onChange={(e) => setPersistenceKey(e.target.value)}
                  innerRef={register({ required: true })}
                  invalid={errors.persistence_name}
                />
                <UncontrolledTooltip placement="top" target="persistence_name">
                  The persistence json key where the attributes are.
                </UncontrolledTooltip>
                {errors.persistence_name && (
                  <FormFeedback>This field is required</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-1">
            <Col xs="12">
              <FormGroup>
                <Label className="text-muted pl-1" for="json_path">
                  <small>
                    <b>JSON Path Expression</b>
                  </small>
                </Label>
                <Input
                  id="json_path"
                  name="json_path"
                  placeholder="JSON Path Expression"
                  type="text"
                  value={jsonPath}
                  onChange={(e) => setJsonPath(e.target.value)}
                  innerRef={register({ required: true })}
                  invalid={errors.json_path}
                />
                <UncontrolledTooltip placement="top" target="json_path">
                  A JSON Path Expression from the root of the persistence key to
                  the path where the attributes are.
                </UncontrolledTooltip>
                {errors.json_path && (
                  <FormFeedback>This field is required</FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <Row className="py-1">
            <Col xs="12">
              <FormGroup>
                <Label className="text-muted pl-1" for="regex">
                  <small>
                    <b>Regex</b>
                  </small>
                </Label>
                <Input
                  id="regex"
                  name="regex"
                  placeholder="Regex (optional)"
                  type="text"
                  value={regex}
                  onChange={(e) => setRegex(e.target.value)}
                  innerRef={register({
                    validate: (value) =>
                      isValidRegex(value) || "Must be a valid regex pattern",
                  })}
                  invalid={errors.regex}
                />
                <UncontrolledTooltip placement="top" target="regex">
                  Only the keys that match the regex will be created.
                </UncontrolledTooltip>
                {errors.regex && (
                  <FormFeedback>
                    This is not a valid regex expression.
                  </FormFeedback>
                )}
              </FormGroup>
            </Col>
          </Row>
          <div className="text-center">
            <Button
              className="my-4"
              color="primary"
              type="button"
              onClick={handleSubmit(sendAttribute)}
            >
              Send
            </Button>
            <Button
              className="my-4"
              color="primary"
              outline
              type="button"
              onClick={toggleModal}
            >
              Cancel
            </Button>
          </div>
        </Form>
      </CardBody>
    </Card>
  );

  return (
    <Modal
      className="modal-dialog-centered"
      size="md"
      isOpen={open}
      toggle={toggleModal}
    >
      <div className="modal-body p-0">{content}</div>
    </Modal>
  );
};

export default AttributeConfigModal;
