import React from "react";
import ReactDOM from "react-dom";

// plugins styles from node_modules
import "react-notification-alert/dist/animate.css";
import "react-perfect-scrollbar/dist/css/styles.css";
// plugins styles downloaded
import "assets/vendor/fullcalendar/dist/fullcalendar.min.css";
import "assets/vendor/sweetalert2/dist/sweetalert2.min.css";
import "assets/vendor/select2/dist/css/select2.min.css";
import "assets/vendor/quill/dist/quill.core.css";
import "assets/vendor/nucleo/css/nucleo.css";
import "assets/vendor/@fortawesome/fontawesome-free/css/all.min.css";
import "./assets/css/overwrite.css"
import "assets/scss/argon-dashboard-pro-react.scss?v1.0.0";
import App from "./views/App";
import httpClientCreator from "./helpers/http-client-singleton";

const config = () => {
fetch("/environment")
    .then(response => response.json())
    .then(function(response) {
        let apiUrl = response["apiUrl"];
        let environment = response["environment"];
        let cdnUrl = response["cdnUrl"];
        let project = response["project"];
        let displayName = response["displayName"];
        let isIdle = response["isIdle"];

        localStorage.setItem("env", environment);
        localStorage.setItem("cdnUrl", cdnUrl);
        localStorage.setItem("displayName", displayName);
        localStorage.setItem("project", project);
        localStorage.setItem("displayName", displayName);
        localStorage.setItem("isIdle", isIdle);

        httpClientCreator.setEnvHeaders(environment);
        httpClientCreator.setVersionHeaders("latest");
        httpClientCreator.setBaseUrl(apiUrl);
        ReactDOM.render(
            <App/>,
            document.getElementById("root")
        );
    })
    .catch(function(error) {
        throw error;
    });
}

config();