import React, { useState } from "react";
import { Box, Flex, Image, Stack, Heading, Button, Textarea } from "@chakra-ui/core";

import PlayerGiftModal from "../Player/PlayerGiftModal";
import { parseDate } from "../../helpers/date-helper";
import { Link } from "react-router-dom";

/**
 * @typedef {{
 *  message: Message;
 *  getMessages: () => Promise<void>;
 *  global: boolean;
 *  playerId?: string;
 * }}MessageProps
 */
/** @type {React.FC<MessageProps>} */
const Message = ({ message, getMessages, global, playerId }) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <Flex backgroundColor="#fff" marginY={4} fontFamily="Open Sans">
      <Image
        height="80px"
        width="80px"
        rounded="full"
        ml={4}
        mr={4}
        src={require("assets/img/icons/common/game_icon.png")}
      />
      <Flex justifyContent="space-between" flex={1} alignItems="center">
        <Stack flex={0.9}>
          <Heading as="h5" size="sm">
            {message.Title || "No Title."}
          </Heading>
          <Textarea isReadOnly value={message.Message || "No message"} />
          <Box>
            <small style={{ display: "block" }}>Player: <Link to={"/acp/player/" + message.PlayerId}>{message.PlayerId}</Link></small>
            <small style={{ display: "block" }}>Creation Date: {parseDate(message.CreationDate).dateTime()}</small>
          </Box>
        </Stack>
        <Flex flexDir="column-reverse" alignItems="center">
          {!global && (
            <div>
              <span
                className={message.Collected ? "text-success" : "text-danger"}
              >
                ●
              </span>
              <small>
                {message.Collected ? " Collected" : " Not Collected"}
              </small>
            </div>
          )}
          <Button
            variantColor="lumenPrimary"
            size="xs"
            paddingX={3}
            paddingY={4}
            onClick={toggleModal}
          >
            More Information
          </Button>
        </Flex>

      </Flex>
      <PlayerGiftModal
        modal={modalOpen}
        update={getMessages}
        className="modal-dialog-centered"
        content={message}
        close={toggleModal}
        global={global}
        playerId={playerId}
      />
    </Flex >
  );
};

export default Message;
