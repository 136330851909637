import React, { useCallback, useEffect, useState } from "react";
import GuildDetailsComponent from "../components/Guilds/Guild/GuildDetails";
import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";

const http = httpClientCreator.getInstance();

const GuildDetails = (props) => {
  const guildId = props.match.params.param;
  const [loading, setLoading] = useState(false);
  const [guild, setGuild] = useState({});

  const onKickPlayer = (guildId, playerId) => {
    return http.delete(`/guilds/${guildId}/members/${playerId}/kick`, { headers: authHeader()}).then(() => {
      setGuild({...guild, Members: guild.Members.filter(x => x !== playerId)});
    }).catch(e => e);
  };

  const onAddPlayer = (guildId, playerId) => {
    return http.put(`/guilds/${guildId}/members/${playerId}`, null, {
      headers: authHeader(),
    }).then(() => {
      setGuild({...guild, Members: [...guild.Members,playerId]});
    }).catch((e) => e);
  };
  
  const onTransferLeader = (guildId, playerId) => {
    return http.put(`guilds/${guildId}/transfer-leader/${playerId}`, null, {
      headers: authHeader(),
    }).then(() => {
      setGuild({...guild,LeaderId: playerId});
    }).catch((e) => e);
  };

  const getGuild = useCallback((guildId) => {
    setLoading(true);
    http
      .get(`/guilds/${guildId}`, { headers: authHeader() })
      .then((response) => {
        setGuild(response.data.data);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    getGuild(guildId);
  }, [guildId, getGuild]);

  return (
    <GuildDetailsComponent
      guild={guild}
      onKickPlayer={onKickPlayer}
      onAddPlayer={onAddPlayer}
      onTransferLeader={onTransferLeader}
      loading={loading}
    />
  );
};

export default GuildDetails;
