import React from "react";
import NotificationAlert from "react-notification-alert";

const NotificationWrapper = ({notificationRef}) => {

  return (
    <div className="rna-wrapper">
      <NotificationAlert ref={notificationRef} />
    </div>
  );
};

export default NotificationWrapper;