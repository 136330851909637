export default function slugify(text) {
  return text
    .toLowerCase()
    .replace(/[^\w^. ]+/g, "")
    .replace(/ +/g, "_");
}

export function stringifySlug(slug) {
  const words = slug
    .toLowerCase()
    .split("_")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1));
  return words.join(" ");
}
