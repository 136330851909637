import React from "react";
import {
  FormControl,
  FormErrorMessage,
  Input,
  Stack,
  Text,
} from "@chakra-ui/core";

import "../common/css/globalStyles.css";

const LeaderboardAttributeInput = ({
  attribute,
  onChange,
  name,
  values,
  errors,
  register,
}) => {
  return (
    <Stack isInline alignItems="center" marginY={4}>
      <Text
        fontFamily="Open Sans"
        fontSize="sm"
        fontWeight={600}
        marginBottom={0}
      >
        {attribute.substring(attribute.lastIndexOf(".") + 1) + ":"}
      </Text>
      <Input
        placeholder="Minimum Value"
        type="number"
        value={values[0]}
        onChange={(e) => onChange({min: Number.parseFloat(e.target.value)})}
        isFullWidth={false}
      />
      <FormControl isInvalid={Boolean(errors[`${name}-max`])}>
        <Input
          name={`${name}-max`}
          placeholder="Maximum Value"
          type="number"
          isFullWidth={false}
          value={values[1]}
          onChange={(e) => onChange({max: Number.parseFloat(e.target.value)})}
          ref={register({
            validate: (value) =>
              Number(value) >= Number(values[0]) ||
              "Max value should be greater or equal than minimum value.",
          })}
        />
        <FormErrorMessage className="error-label">
          {errors[`${name}-max`] && errors[`${name}-max`].message}
        </FormErrorMessage>
      </FormControl>
    </Stack>
  );
};

export default LeaderboardAttributeInput;
