import React from "react";
import {MenuButton, Menu, MenuItem, MenuList, Button, useTheme} from "@chakra-ui/core";
import Swal from "sweetalert2";

const PlayerNicknameDropdown = ({
  onResetClick,
  onRemoveClick,
  onChangeClick,
}) => {
  const theme = useTheme();
  const onChangeNicknameClick = () => {
    Swal.fire({
      title: "Type a new nickname",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: (newNickname) => onChangeClick(newNickname),
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (!result.dismiss)
          Swal.fire({
            title: `Nickname changed successfully!`,
            icon: "success",
          });
      })
      .catch(() => {
        Swal.fire({
          title: "An error occurred when updating the nickname",
          icon: "error",
        });
      });
  };

  return (
    <Menu>
      <MenuButton
        as={Button}
        variantColor="lumenPrimary"
        variant="ghost"
        size="sm"
        boxShadow="md"
      >
        <i className="fas fa-ellipsis-v" />
      </MenuButton>
      <MenuList>
        <MenuItem fontSize="sm" onClick={onResetClick}>
          Reset Player Nickname
        </MenuItem>
        <MenuItem fontSize="sm" onClick={onChangeNicknameClick}>
          Change Player Nickname
        </MenuItem>
        <MenuItem fontSize="sm" onClick={onRemoveClick}>
          Remove from list
        </MenuItem>
      </MenuList>
    </Menu>
  );
};

export default PlayerNicknameDropdown;
