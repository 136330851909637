import React from "react";
import { CardBody, Row, Col, Button, Table } from "reactstrap";
import PersistenceInfo from "../Persistence/PersistenceInfo";

import PropTypes from "prop-types";
import useWindowDimensions from "../../helpers/useWindowDimensions";
import PerfectScrollbar from "react-perfect-scrollbar";
import PlayerAttributeModal from "./PlayerAttributeModal";
import { userService } from "../../services/user.service";

const PlayerAttributes = ({
  playerId,
  handlePersistenceInfoChange,
  persistence,
  attributes,
  buttonEnabled,
  onSubmit,
  updatePlayerInformations,
  loading,
  modalOpen,
  toggleModalOpen,
}) => {
  const { width } = useWindowDimensions();

  return (
    <>
      <PerfectScrollbar className="rounded-bottom" option={{ suppressScrollX: true }}>
        <CardBody className="player-tab-pane px-0 b-0">
          <PersistenceInfo
            mainPage
            playerId={playerId}
            mobile={width < 1200}
            onChange={handlePersistenceInfoChange}
            persistence={persistence}
          />
          <Row className="pt-4 pb-2">
            <Col className="text-right px-5" xs={{ size: 5, offset: 7 }}>
              <Button
                color="primary"
                onClick={updatePlayerInformations}
                outline={!buttonEnabled}
                disabled={!buttonEnabled}
                size="sm"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
          <hr className="divider" />
          <>
            <Row className="py-2">
              <h3 className="px-5">Player Attributes</h3>
              <Button
                size="sm"
                className="ml-auto mr-5"
                color="primary"
                disabled={!userService.hasPermission("CREATE_ATTRIBUTES")}
                onClick={toggleModalOpen}
              >
                Create
              </Button>
            </Row>
            {attributes.length > 0 && (
              <Row>
                <Col xs="12 pr-0">
                  <Table>
                    <thead className="thead-light">
                      <tr>
                        <th>Name</th>
                        <th>Value</th>
                        <th>Expiration Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {attributes.length > 0 ? (
                        attributes.map((attribute) => (
                          <tr key={attribute.Name}>
                            <td>{attribute.Name}</td>
                            <td>{attribute.Value}</td>
                            <td>{attribute.ExpirationDate || "-"}</td>
                          </tr>
                        ))
                      ) : (
                        <div className="player-persistence-empty-card pl-4 py-2">
                          <p>This player doesn't have any attributes to show</p>
                        </div>
                      )}
                    </tbody>
                  </Table>
                </Col>
              </Row>
            )}
            <PlayerAttributeModal
              open={modalOpen}
              toggleModal={toggleModalOpen}
              onSubmit={onSubmit}
              loading={loading}
            />
          </>
        </CardBody>
      </PerfectScrollbar>
    </>
  );
};

PlayerAttributes.propTypes = {
  playerId: PropTypes.string,
  handlePersistenceInfoChange: PropTypes.func,
  persistence: PropTypes.array,
};

export default PlayerAttributes;
