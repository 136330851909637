
/**
 * @template {string[] | number[] | Record<string,string>[]} T 
 * @param {T} arr 
 * @param {{key: keyof T, dir: 'ASC' | 'DESC'}} options 
 */
export function versionSort(arr, {key = "bucket_name", dir = 'ASC'}){
  const dirVal = dir === 'ASC' ? 1 : -1;

  return [...arr].sort((a,b) => {
    if(typeof a === 'number' && typeof b === 'number') return a - b;

    const aData = (typeof a === 'object' ? a[key] : a).toString().replace(/[^.0-9]/g,"");
    const bData = (typeof b === 'object' ? b[key] : b).toString().replace(/[^.0-9]/g,"");

    const parts = [aData.split("."), bData.split(".")];

    for(let i = 0; i < Math.max(parts[0].length, parts[1].length); i++){
      if(isNaN(parts[0][i])) return dirVal * -1;
      if(isNaN(parts[1][i])) return dirVal *  1;

      const numA = Number.parseInt(parts[0][i]);
      const numB = Number.parseInt(parts[1][i]);

      if(numA > numB) return  dirVal *  1;
      if(numA < numB) return  dirVal * -1;
    }

    return 0;
  })

}

export default versionSort;