const MAX_DECIMALS = 2;
const ASCII_OFFSET = 97;
const SUFFIXES = "KMBT";

const getSuffix = (exp) => {
  if (exp < 5) return SUFFIXES.substring(exp - 1, 1);
  else {
    const index = exp - 5;
    let suffixes = "";
    suffixes += String.fromCharCode(ASCII_OFFSET + index / 26);
    suffixes += String.fromCharCode(ASCII_OFFSET + (index % 26));
    return suffixes;
  }
};

const getFormat = (value) => {
  const offset = value.length - MAX_DECIMALS;
  return `${value.substring(0, offset)}.${value.substring(offset)}`;
};

const formatIdleCurrency = (currency) => {
  if (currency < 1000) return currency;
  const e = Math.floor(Math.log(Math.abs(currency)) / Math.log(1000));
  currency = currency / Math.pow(1000, e);
  const t = Math.pow(10, MAX_DECIMALS);
  currency = Math.floor(currency * t);
  return getFormat(currency.toString()) + getSuffix(e);
};

export { formatIdleCurrency };
