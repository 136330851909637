import React from "react";
import { SimpleGrid, Box, Link, IconButton } from "@chakra-ui/core";
import DateTimeComponent from "../../common/DateTimeComponent";
import CopyButton from "../../common/CopyButton";
import moment from "moment";
import * as PropTypes from "prop-types";
import "./css/BucketStyles.css";

const SingleInfo = ({ name, date, link, promoted, file_url }) => {
  const key = link.substring(link.lastIndexOf("/") + 1);
  return (
    <SimpleGrid width="100%" bg="gray.100" marginX={6} padding={2} columns={1}>
      <SimpleGrid columns={{ xs: 1, lg: 2 }} justifyContent="space-between">
        <small>
          <b>{name}</b>
          {file_url && (
            <IconButton
              variantColor="gray"
              aria-label="Copy Text"
              size="sm"
              variant="ghost"
              icon="attachment"
              as="a"
              top={-3}
              _focus={{ boxShadow: "none" }}
              href={file_url}
              target="_blank"
            />
          )}
        </small>
        <Box justifySelf={{ lg: "end" }}>
          <small>
            {(date && (
              <DateTimeComponent
                date={moment(date)}
                tooltipId={"bucket" + name.replace(/[^0-9a-z_]/gi, "").replace(/[_]{2,}/,'_')}
              />
            )) ||
              "-"}
          </small>
        </Box>
      </SimpleGrid>
      <SimpleGrid gridTemplateColumns={{ xs: "1fr", lg: "60% 40%" }}>
        <div>
          <small>{link.includes("spreadsheet") ? "Sheet" : "Commit"}: </small>
          {(link && (
            <>
              <Link color="lumenPrimary.400" target="_blank" href={link}>
                <small className="break-word">{key}</small>
              </Link>
              <CopyButton text={key} />
            </>
          )) ||
            "-"}
        </div>
        {promoted && promoted !== "null" && (
          <Box color="#ffcc00" textAlign={{ lg: "end" }}>
            <small className="break-word">
              <i className="fas fa-exclamation-triangle" /> Promoted from{" "}
              {promoted}
            </small>
          </Box>
        )}
      </SimpleGrid>
    </SimpleGrid>
  );
};

SingleInfo.propTypes = {
    name: PropTypes.string,
    link: PropTypes.string,
    file_url: PropTypes.string,
    promoted: PropTypes.string,
    date: PropTypes.string,
};

export default SingleInfo;
