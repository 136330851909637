import React, { useCallback, useEffect, useState } from "react";
import httpClient from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import Swal from "sweetalert2";
import Attributes from "../components/Attributes/AttributesConfig";
import AttributeConfigModal from "../components/Attributes/AttributeConfigModal";
import { useTheme } from "@chakra-ui/core";
import ImportModal from "../components/common/ImportModal";

const http = httpClient.getInstance();

const AttributeConfig = () => {
  const [page, setPage] = useState(0);
  const [objectsPerPage, setObjectsPerPage] = useState(15);
  const [loading, setLoading] = useState(false);
  const [total, setTotal] = useState(0);
  const [attributesConfig, setAttributesConfig] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [importModalOpen, setImportModalOpen] = useState(false);
  const [selectedAttributeConfig, setSelectedAttributeConfig] = useState({});
  const [modalLoading, setModalLoading] = useState(false);

  const theme = useTheme();

  const getPage = useCallback(
    (number) => {
      setLoading(true);
      http
        .get("/attributes/configurations/paging", {
          headers: authHeader(),
          params: { skip: number * objectsPerPage, take: objectsPerPage },
        })
        .then((response) => {
          setAttributesConfig(response.data.data.Result);
          setTotal(response.data.data.Total);
          setPage(number);
        })
        .finally(() => setLoading(false));
    },
    [objectsPerPage]
  );

  useEffect(() => {
    getPage(0);
  }, [objectsPerPage, getPage]);


  /** @type {(id: string|number) => void} */
  const onSearch = (id) => {
    setLoading(true);
    http
      .get(`/attributes/configurations/${id?.toString().trim()}`, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.data) setAttributesConfig([response.data.data]);
        else setAttributesConfig([]);
        setTotal(1);
      })
      .finally(() => setLoading(false));
  };

  const onClear = useCallback(() => getPage(0), [getPage]);

  const toggleModalOpen = (attributeConfig) => {
    setModalOpen(!modalOpen);
    if (modalOpen) setSelectedAttributeConfig({});
    else {
      if (attributeConfig) setSelectedAttributeConfig(attributeConfig);
    }
  };

  /** @type {(id: string|number) => void} */
  const deleteAttribute = (id) => {
    http
      .delete(`/attributes/configurations/${id?.toString().trim()}`, { headers: authHeader() })
      .then((response) => {
        if (response.status !== 200) {
          throw new Error(response.statusText);
        }
        setAttributesConfig(attributesConfig.filter(x => x.Id !== id))
        return response.data;
      })
      .catch((error) => {
        Swal.showValidationMessage(`Request failed: ${error}`);
      });
  };

  const sendAttribute = (body) => {
    setModalLoading(true);
    http
      .put("/attributes/configurations", body, { headers: authHeader() })
      .then(() => {
        setModalOpen(false);
        Swal.fire(
          "",
          "The attribute configuration has been sent successfully.",
          "success"
        ).then(() => {
          getPage(0);
        });
      })
      .catch((error) => {
        Swal.fire(
          "",
          `An error occurred\n 
          Status: ${error.status}. ${error.response}`,
          "error"
        ).then(() => getPage(0));
      })
      .finally(() => {
        setModalLoading(false);
      });
  };

  const handlePageChange = (number) => setPage(number);

  const handleObjectsPerPage = (number) => {
    setObjectsPerPage(number);
    setPage(0);
  };

  const handleDelete = (id) => {
    Swal.fire({
      type: "question",
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      confirmButtonText: "Yes, delete it!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return deleteAttribute(id);
      },
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          "Deleted!",
          "The attribute configuration has been deleted.",
          "success"
        ).then(() => getPage(0));
      }
    });
  };

  const onExportJson = async () => {
    const {data} = await http.get("/attributes/configurations")
    const jsonData = JSON.stringify(data.data.Configurations, null, 2);
    const url = window.URL.createObjectURL(new Blob([jsonData], {type: "application/json"}));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', `attributes_config_${(new Date()).valueOf()}.json`);
    document.body.appendChild(link);
    link.click();
  }

  const onImportJson = async (file) => {
    const data = JSON.parse(await file.text());

    const result =  await http
      .put(
        `/attributes/configurations/override`,
        data, {
        headers: { ...authHeader() },
      }).then(x => x.data.data)

    console.log({result});
    setAttributesConfig(result);
    setImportModalOpen(false);
  };


  return (
    <Attributes
      page={page}
      handleObjectsPerPage={handleObjectsPerPage}
      loading={loading}
      attributesConfig={attributesConfig}
      handleDelete={handleDelete}
      handlePageChange={handlePageChange}
      objectsPerPage={objectsPerPage}
      total={total}
      toggleModalOpen={toggleModalOpen}
      onSearch={onSearch}
      onClear={onClear}
      onExportJson={onExportJson}
      onImportJson={() => setImportModalOpen(true)}
    >
      <AttributeConfigModal
        attribute={selectedAttributeConfig}
        open={modalOpen}
        toggleModal={toggleModalOpen}
        onSubmit={sendAttribute}
        loading={modalLoading}
      />
      <ImportModal
        isOpen={importModalOpen}
        handleModalToggle={() => setImportModalOpen(!importModalOpen)}
        onSubmit={onImportJson}
        preview
      >
        <small style={{color: 'red'}}>CAUTION: ALL EXISTING DATA WILL BE OVERWRITTEN</small>
      </ImportModal>
    </Attributes>
  );
};

export default AttributeConfig;
