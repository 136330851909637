import React from "react";
import { UncontrolledTooltip } from "reactstrap";
import { parseDate } from "../../helpers/date-helper";
import "./css/tooltipStyles.css";

/** @typedef {import("../../helpers/date-helper").SwDate} SwDate */

/**
 * @type {React.FC<{date: moment.MomentInput | SwDate, tooltipId: string}>}
 */
const DateTimeComponent = ({ date, tooltipId }) => {

  const getDate = (dateTime, zone = "UTC") => {
    const parsedDate = parseDate(dateTime,zone === "UTC");
    const time = parsedDate.time();
    const date = parsedDate.date();
    const tz_abbr = zone === "UTC" ? "UTC" : "";
    
    return `${date} ${time} ${tz_abbr}`;
  };
  
  return (
    <>
      <span>{getDate(date, "local")}</span>
      <i id={tooltipId} className="fas fa-info-circle info-tooltip " />
      <UncontrolledTooltip delay={0} placement="top" target={tooltipId}>
        {getDate(date)}
      </UncontrolledTooltip>
    </>
  );
};

export default DateTimeComponent;
