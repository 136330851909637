import jsonPath from "jsonpath";

// Returns the persistence key json
const getPersistenceProperty = (persistence, key) => {
  const el = persistence.find((x) => x.Key === key);
  return el !== undefined ? JSON.parse(el.Value) : {};
};

const getPropertyInfo = (propertyJson, path) => {
  return jsonPath.value(propertyJson, `$${path.trim()}`);
};

const setPropertyInfo = (propertyJson, path, newPropertyValue) => {
  const newProperty = {...propertyJson};
  jsonPath.value(newProperty, `$${path.trim()}`, newPropertyValue);
  return newProperty;
};

const getStageGoals = (persistence, stageId) => {
  return persistence.filter((persistenceKey) =>
    persistenceKey.Key.includes(stageId + "_goal_")
  );
};

const getStageIncompleteGoals = (persistence, stageId) => {
  const goals = getStageGoals(persistence, stageId);
  return goals.filter(
    (goal) =>
      JSON.parse(goal.Value)._progression <=
        JSON.parse(goal.Value)._targetProgression &&
      JSON.parse(goal.Value)._targetProgression !== 0
  );
};

export {
  getPersistenceProperty,
  getPropertyInfo,
  getStageIncompleteGoals,
  setPropertyInfo,
};
