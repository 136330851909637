import React from "react";
import SimpleHeader from "../Headers/SimpleHeader";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

const PageContainer = ({ pageName, header, children }) => {
  return (
    <>
      <SimpleHeader name={pageName} clean parentName="Dashboard">
        {header}
      </SimpleHeader>
      <Container className="mt--6" fluid>{children}</Container>
    </>
  );
};

PageContainer.propTypes = {
  pageName: PropTypes.string.isRequired,
  header: PropTypes.any,
  data: PropTypes.any,
};

export default PageContainer;
