// The favorite service has categories associated to it.
// The current categories are players and snapshots.

// This object maps the category to the primary key associated to it.
const PRIMARY_KEY_ENUM = {
  players: "PlayerId",
  snapshots: "Key",
};

const LOCAL_STORAGE_FAVORITE_KEY = "favorites";

const createFavorites = () => {
  const favorites = {
    players: [],
    snapshots: [],
  };
  localStorage.setItem("favorites", JSON.stringify(favorites));
};

const getAllFavorites = () => {
  const storageFavorites = localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY);
  return JSON.parse(storageFavorites);
};

const _removeFromFavorites = (
  primaryKey,
  primaryKeyField,
  category,
  favorites
) => {
  const favoriteIndex = favorites[category].findIndex(
    (favorite) => favorite[primaryKeyField] === primaryKey
  );
  if (favoriteIndex !== -1) {
    favorites[category].splice(favoriteIndex, 1);
    localStorage.setItem(LOCAL_STORAGE_FAVORITE_KEY, JSON.stringify(favorites));
  }
};

const _searchFavorite = (search, primaryKeyField, category, favorites) => {
  favorites[category].filter(
    (favorite) =>
      favorite[primaryKeyField].includes(search) ||
      favorite.description.includes(search)
  );
};

const _findFavorite = (primaryKey, primaryKeyField, category, favorites) => {
  return favorites[category].find(
    (favorite) => favorite[primaryKeyField] === primaryKey
  );
};

const _isFavorite = (primaryKey, primaryKeyField, category, favorites) => {
  return favorites[category].some(
    (favorite) => favorite[primaryKeyField] === primaryKey
  );
};

const _addToFavorites = (primaryKey, primaryKeyField, category, favorites) => {
  const favorite = { [primaryKeyField]: primaryKey, description: "" };
  if (!_isFavorite(primaryKey, primaryKeyField, category, favorites))
    favorites[category].push(favorite);
  localStorage.setItem(LOCAL_STORAGE_FAVORITE_KEY, JSON.stringify(favorites));
};

const _updateFavorite = (
  primaryKey,
  updatedFields,
  primaryKeyField,
  category,
  favorites
) => {
  const favoriteIndex = favorites[category].findIndex(
    (favorite) => favorite[primaryKeyField] === primaryKey
  );

  const newFavorite = {
    ...favorites[category][favoriteIndex],
    ...updatedFields,
  };
  favorites[category][favoriteIndex] = newFavorite;

  localStorage.setItem(LOCAL_STORAGE_FAVORITE_KEY, JSON.stringify(favorites));

  return newFavorite;
};

const addToFavorites = (favorite, category) => {
  let storageFavorites = localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY);

  if (!storageFavorites) {
    createFavorites();
    storageFavorites = localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY);
  }
  const favorites = JSON.parse(storageFavorites);

  _addToFavorites(favorite, PRIMARY_KEY_ENUM[category], category, favorites);
};

const findFavorite = (favorite, category) => {
  const favorites = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY)
  );
  if (favorites)
    return _findFavorite(
      favorite,
      PRIMARY_KEY_ENUM[category],
      category,
      favorites
    );
  return false;
};

const isFavorite = (favorite, category) => {
  const favorites = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY)
  );
  if (favorites)
    return _isFavorite(
      favorite,
      PRIMARY_KEY_ENUM[category],
      category,
      favorites
    );
  return false;
};

const updateFavorite = (favorite, category, fields) => {
  const favorites = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY)
  );
  if (favorites)
    return _updateFavorite(
      favorite,
      fields,
      PRIMARY_KEY_ENUM[category],
      category,
      favorites
    );
};

const removeFromFavorites = (favorite, category) => {
  const favorites = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY)
  );
  if (favorites)
    _removeFromFavorites(
      favorite,
      PRIMARY_KEY_ENUM[category],
      category,
      favorites
    );
};

const searchFavorite = (search, category) => {
  const favorites = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_FAVORITE_KEY)
  );
  if (favorites)
    return _searchFavorite(
      search,
      PRIMARY_KEY_ENUM[category],
      category,
      favorites
    );
  return false;
};

export {
  addToFavorites,
  removeFromFavorites,
  findFavorite,
  isFavorite,
  getAllFavorites,
  updateFavorite,
  searchFavorite,
  PRIMARY_KEY_ENUM,
};
