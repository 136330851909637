import React, { useCallback, useEffect, useState } from "react";
import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import RegattaDetailsComponent from "../components/Regatta/RegattaDetailsComponent";

const http = httpClientCreator.getInstance();

/**@typedef {import("./Regattas").Regatta} Regatta */
/**@typedef {import("../components/Regatta/RegattaGroupModal").RegattaGuild} RegattaGuild */

const RegattaDetails = (props) => {
  const regattaId = props.match.params.param;
  const [loading, setLoading] = useState(false);
  const [regatta, setRegatta] = useState({});
  /** @type {[RegattaGuild[], (newState: RegattaGuild[]) => void]} */
  const [groups, setGroups] = useState([]);

  /** @param {RegattaGuild} reg */
  const onRemoveGuild = async (reg) => {
    const result = await http.delete(`/regattagroups/${regattaId}/${reg.GuildId}`, { headers: authHeader() }).catch(() => undefined);
    if(!result) throw new Error("Failed to Remove Regatta");
    getGroups();
  };

  /** @type {(guild?: string) => void} */
  const onExportCsv = async (guild) => {
      setLoading(true);
      const response = await http.get(`/regattagroups/dump`, {
          params:{
            guild, 
            regatta: regattaId
          },
          responseType: 'blob',
          headers: authHeader(),
      }).finally(() => setLoading(false));
  
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `regattagroups_${regattaId}_${(new Date()).valueOf()}.csv`);
      document.body.appendChild(link);
      link.click();
  }

  const getRegatta = useCallback(async (regattaId) => {
    const reg = await http.get(`/regattas/${regattaId}/details`, { headers: authHeader() })
      .then((response) => response.data.data)
      .catch(() => undefined);
    if(!reg) throw new Error("Couldn't Find a Valid Regatta");
    setRegatta(reg);
  }, []);
  
  const getGroups = useCallback(async (regattaId) => {
    const grp = await http.get(`/regattagroups/${regattaId}`, { headers: authHeader() })
      .then((response) => response.data.data.RegattaGroup)
      .catch(() => undefined);
    if(!grp) throw new Error("Couldn't Find a Groups for the desired Regatta");
    setGroups(grp);
  }, []);

  useEffect(() => {
    async function fetch(){
      setLoading(true);
      await Promise.allSettled([getRegatta(regattaId), getGroups(regattaId)]);
      setLoading(false);
    }

    fetch();
  }, [regattaId, getRegatta, getGroups]);

  return (
    <RegattaDetailsComponent 
      regatta={regatta} 
      onRemoveGuild={onRemoveGuild}
      groups={groups}
      loading={loading}
      onExportCsv={onExportCsv}
    />
  );
};

export default RegattaDetails;
