   
import React, { useState } from "react";
import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Select,
  Flex
} from "@chakra-ui/core";
import moment from "moment";
/**
 * @typedef {InputHTMLAttributes<SelectProps>} htmlProps */

/** 
 * @typedef {SelectProps &
 * htmlProps & {
 *   label: string;
 *   name: string;
 *   error?: Error;
 * }} InputFieldProps
 * */

/**
 * @type {React.FC<InputFieldProps>} */
export const TimeSpanInput = ({label,disabled,error,name,value,onChange}) => {
    const [mode,setMode] = useState('minutes');
    const duration = moment.duration(value, 'minutes');

    function handleChange(val){
      if(onChange)onChange(moment.duration(val,mode).asMinutes());
    }


    return(
    <FormControl isInvalid={!!error}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Flex >
      <Input disabled={disabled} onChange={(e) => handleChange(e.target.value)} value={duration.as(mode)} type="number"  w="50%"/>
      <Select onChange={(e) => setMode(e.target.value)} value={mode} ml="2%"  w="50%" id={name}>
        <option value={'minutes'}>Minutes</option>
        <option value={'hours'}>Hours</option>
        <option value={'days'}>Days</option>
        <option value={'weeks'}>Weeks</option>
      </Select>
      </Flex>
      {error ? <FormErrorMessage>{error}</FormErrorMessage> : null}
    </FormControl>
  )};

