import React from "react";

// reactstrap components
import { Container, Card } from "reactstrap";

// core components
import ErrorBox from "../common/ErrorBox";

const EventsListing = React.memo(({ className, error, children }) => {
  return (
    <div className={className}>
      <Container className="mt--6" fluid>
        {error.value ? (
          <Card>
            <ErrorBox>Couldn't find any events to show.</ErrorBox>
          </Card>
        ) : (
          children
        )}
      </Container>
    </div>
  );
});

export default EventsListing;
