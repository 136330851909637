import React from "react";

const notifyError = (notificationRef, error) => {
  const options = {
    place: "tr",
    message: (
      <div className="alert-text">
        <span className="alert-title" data-notify="title">
          Request Error
        </span>
        <span data-notify="message">
          Request returned status{" "}
          <b>{`${error.response.status} - ${error.response.statusText}`}</b>
        </span>
        <br/>
        {error.response.data.data && error.response.data.data.message && (
          <span>
            <b>{error.response.data.data.message}</b>
          </span>
        )}
      </div>
    ),
    type: "danger",
    icon: "ni ni-bell-55",
    autoDismiss: 5,
  };
  if(notificationRef?.current?.notificationAlert) notificationRef.current.notificationAlert(options);
};

export { notifyError };
