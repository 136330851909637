import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";

const DeleteLeaderboardModal = ({
  loading,
  deleteLeaderboard,
  open,
  modalToggle,
}) => {
  const confirmDelete = () => {
    deleteLeaderboard();
  };

  return (
    <Modal centered isOpen={open} toggle={modalToggle}>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <ModalHeader toggle={modalToggle}>Delete Leaderboard Definition</ModalHeader>
          <ModalBody>
            <p>
              Are you sure you want to delete this leaderboard definition? Any player's associated to
              this leaderboard definition will not receive any rewards.
            </p>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmDelete}>
              Delete
            </Button>
            <Button outline color="primary" onClick={modalToggle}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default DeleteLeaderboardModal;
