import React, { useState } from "react";
import { Box, Flex, FormControl, FormLabel, Select } from "@chakra-ui/core";
import Message from "./Message";
import ErrorBox from "../common/ErrorBox";
import { useEffect } from "react";
import LoadingContainer from "../Layout/LoadingContainer";
import GenericTable from "../Table/GenericTable";
import { parseDate } from "../../helpers/date-helper";

/**
 * @typedef {{
 *  messages: Message[];
 *  loading?: boolean;
 *  global?: boolean;
 *  getMessages: () => Promise<void>;
 *  playerId: string;
 * }} MessageListProps 
 * */

/**@type {React.FC<MessageListProps>} */
const MessageList = ({ messages, loading, global, getMessages, playerId }) => {
  const [filter, setFilter] = useState("");
  const [orderBy, setOrderBy] = useState("CreationDate");
  useEffect(() => {
    if (getMessages) getMessages();
  }, []);

  if (loading)
    return <LoadingContainer />


  return (
    <Box mt={4} padding={1} marginX={6}>
      <GenericTable.Header
        onClear={() => setFilter("")}
        onSearch={(x) => setFilter(x)}
        inputPlaceholder="Message Id, Title or Player Id"
      >
        <FormControl display="flex" flexDir="row" paddingX={3}>
          <small>Order By:</small>
          <Select onChange={x => setOrderBy(x.target.value)} size="sm">
            <option value="CreationDate">Creation Date</option>
            <option value="Title">Title</option>
            <option value="PlayerId">PlayerId</option>
          </Select>
        </FormControl>
      </GenericTable.Header>
      {Array.isArray(messages) && messages.length > 0 ? (
        <Box>
          {messages
            .filter(x => !filter || x.MsgId.includes(filter) || x.Title.includes(filter) || x.PlayerId?.includes(filter))
            .sort((a, b) => {
              const [val1, val2] = [a[orderBy], b[orderBy]];
              if (orderBy === "CreationDate")
                return parseDate(val1).toDate().valueOf() - parseDate(val2).toDate().valueOf();
              else
                return val1.localeCompare(val2);
            })
            .map((message) => (
              <Message
                key={message.MsgId + message.PlayerId}
                message={message}
                global={global}
                getMessages={getMessages}
                playerId={playerId}
              />
            ))}
        </Box>
      ) : (
        <ErrorBox>There is no Messages to show.</ErrorBox>
      )}
    </Box>)
};

export default MessageList;
