import React, {useState} from "react";

import { Button } from "@chakra-ui/core";

const FavoriteButton = ({ isFavorite, addFavorite, removeFavorite }) => {
  const [_isFavorite, _setIsFavorite] = useState(isFavorite);

  const toggleFavorite = (e) => {
    if(_isFavorite) removeFavorite(e);
    else addFavorite(e);
    _setIsFavorite(!_isFavorite);
  };

  return (
    <Button
      paddingX={0}
      aria-label="Add to Favorites"
      variant="link"
      variantColor="yellow"
      onClick={e => toggleFavorite(e)}
    >
      {_isFavorite ? (
        <i className="fas fa-star" />
      ) : (
        <i className="far fa-star" />
      )}
    </Button>
  );
};

export default FavoriteButton;
