import React from "react";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Grid,
  Input,
  Select,
} from "@chakra-ui/core";

import "../common/css/errorStyles.css";

const GiftInput = ({
  gift,
  removeGift,
  handleGiftChange,
  register,
  readonly,
  errors,
  name,
}) => {
  return (
    <Grid templateColumns="20% 40% 20% 5%" gap="5%" marginTop={4}>
      <FormControl>
        <FormLabel fontWeight="600">Type</FormLabel>
        <Select
          isDisabled={readonly}
          icon="chevron-down"
          size="sm"
          value={gift.m_type}
          onChange={(e) => handleGiftChange({ m_type: e.target.value })}
        >
          <option value={1}>Currency</option>
          <option value={0}>Reward</option>
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel fontWeight="600">ID</FormLabel>
        <Input
          isDisabled={readonly}
          size="sm"
          value={gift.m_giftableId}
          onChange={(e) => handleGiftChange({ m_giftableId: e.target.value })}
        />
      </FormControl>
      <FormControl isInvalid={errors[name]}>
        <FormLabel fontWeight="600">Amount</FormLabel>
        <Input
          isDisabled={readonly}
          name={name}
          size="sm"
          value={gift.m_amount}
          type="number"
          onChange={(e) => handleGiftChange({ m_amount: e.target.value })}
          ref={register({
            validate: (value) => value > 0 || "Amount must be greater than 0",
          })}
        />
        <FormErrorMessage className="error-label">
          {errors[name] && errors[name].message}
        </FormErrorMessage>
      </FormControl>
      {!readonly && (
        <button
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            removeGift();
          }}
        >
          <i className="fas fa-minus border rounded-circle py-1 minus-icon btn-outline-primary" />
        </button>
      )}
    </Grid>
  );
};

export default GiftInput;
