import React, { useCallback, useState } from "react";
import PageContainer from "../common/PageContainer";

import { Box, SimpleGrid, useTheme } from "@chakra-ui/core";

import TableCard from "../Layout/TableCard";

import PropTypes from "prop-types";
import PaginationHeader from "../common/PaginationHeader";
import SimplePaginationComponent from "../common/SimplePaginationComponent";
import SearchInput from "../common/SearchInput";
import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import GuildForm from "./GuildForm";

import Swal from "sweetalert2";
import { userService } from "../../services/user.service";
import GenericTable from "../Table/GenericTable";

const ACCESS_POLICIES = { 1: "Private", 0: "Public" };

/**
 * @typedef {{
 *  Id: string,
 *  Name: string,
 *  VisualIdentification: string,
 *  MinLevel: string,
 *  AccessPolicy: string,
 *  MembersCount: string
 * }} Guild
 */

const Guilds = ({
  guilds,
  currentPage,
  loading,
  total,
  formLoading,
  onDelete,
  onSearch,
  onObjectsPerPageChange,
  objectsPerPage,
  getPage,
  onSubmit,
  onSort,
  sortKey,
  sortDir
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedGuild, setSelectedGuild] = useState({});

  const theme = useTheme();

  const handleEditClick = (index) => {
    setSelectedGuild(guilds[index]);
    toggleModal();
  };

  const onDeleteClick = (index) => {
    Swal.fire({
      title: "Delete Guild",
      text: "Are you sure you want to delete this guild?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      confirmButtonColor: theme.colors.lumenPrimary["500"],
      showLoaderOnConfirm: true,
      preConfirm: () => onDelete(guilds[index].Id),
    });
  };

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onGuildCreate = (guild) => {
    onSubmit(guild).then(toggleModal);
  }

  const onClear = useCallback(() => onSearch(""), [onSearch]);

  const header = (
    <SimpleGrid columns={2} alignItems="center">
      <SearchInput
        onSearch={(search) => onSearch(search)}
        onClear={onClear}
        placeholder="Guild ID or Name"
      />
      <PaginationHeader
        skip={currentPage * objectsPerPage}
        take={objectsPerPage}
        total={total}
        onObjectsPerPageChange={onObjectsPerPageChange}
      />
    </SimpleGrid>
  );

  const footer = (
    <SimplePaginationComponent
      totalObjects={total}
      objectsPerPage={objectsPerPage}
      fetchPage={getPage}
      currentPage={currentPage}
    />
  );

  const guildButton = (
    <Button
      className="btn-neutral btn-round float-right btn-icon"
      color="default"
      href="#addrandom"
      disabled={!userService.hasPermission("CREATE_GUILD")}
      onClick={toggleModal}
      id="create-new-guild"
      size="sm"
    >
      <span className="btn-inner--icon mr-1">
        <i className="fas fa-home" />
      </span>
      <span className="btn-inner--text">Create New Guild</span>
    </Button>
  );


  /** @type {import("../Table/GenericTable").TableKey<Guild>[]} */
  const columns = [
    {
      key: 'Id',
      headerOptions: {
        onClick: () => onSort("id"),
        sortStatus: {
          isSorted: (sortKey === "id"),
          dir: sortDir
        },
        title: "Guild ID",
      },
      bodyOptions: {
        type: "LINK",
        to: (data) =>  `/acp/guild/${data.Id}`
      }
    },
    { 
      key: "Name",
      headerOptions: {
        onClick: () => onSort("name"),
        sortStatus: {
          isSorted: (sortKey === "name"),
          dir: sortDir
        }
      }
    },
    {
      key: 'AccessPolicy',
      headerOptions:{ 
        title: "Guild Access",
        onClick: () => onSort("AccessPolicy"),
        sortStatus: {
          isSorted: (sortKey === "AccessPolicy"),
          dir: sortDir
        }
      },
      bodyOptions: { transform: (val) => ACCESS_POLICIES[val] }
    },
    { 
      key: "MembersCount", 
      headerOptions: { 
        title: "Members",
        onClick: () => onSort("MembersCount"),
        sortStatus: {
          isSorted: (sortKey === "MembersCount"),
          dir: sortDir
        }
      }, 
    },
    { 
      key: "MinLevel", 
      headerOptions: { 
        title: "Minimum Level",
        onClick: () => onSort("MinLevel"),
        sortStatus: {
          isSorted: (sortKey === "MinLevel"),
          dir: sortDir
        }
      }, 
    },
  ];

  return (
    <PageContainer pageName="Guilds" header={guildButton}>
      <Box position="relative" backgroundColor="white" borderRadius="md">
        <TableCard
          className="mb-0"
          header={header}
          footer={footer}
          loading={loading}
        >
          <GenericTable.Table
            keys={columns}
            data={guilds}
            onEmpty="Couldn't find any guilds."
          />
        </TableCard>
      </Box>
      <Modal isOpen={modalOpen} toggle={toggleModal} centered backdrop="static">
        <ModalHeader>Create New Guild</ModalHeader>
        <ModalBody>
          <GuildForm
            onSubmit={onGuildCreate}
            onCancel={toggleModal}
            loading={formLoading}
            fontFamily="Open Sans"
            {...selectedGuild}
          />
        </ModalBody>
      </Modal>
    </PageContainer>
  );
};

Guilds.propTypes = {
  guilds: PropTypes.array,
  currentPage: PropTypes.number,
  loading: PropTypes.bool,
  total: PropTypes.number,
  onDelete: PropTypes.func,
  onSearch: PropTypes.func,
  onObjectsPerPageChange: PropTypes.func,
  objectsPerPage: PropTypes.number,
  getPage: PropTypes.func,
  onSubmit: PropTypes.func,
};

export default Guilds;
