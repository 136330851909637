import React, { useState } from "react";
import { Input, Button, FormControl, List, Flex } from "@chakra-ui/core";

/** 
 * @typedef {React.FC<import("@chakra-ui/core").InputProps} InputProps
 * @typedef {{
 *    options?: string[]
*    onChange?: (val: string) => void,
*    onFocus?: () => void,
*    onBlur?: () => void
*  }} ListInputProps 
* */

/** @type {React.FC<ListInputProps & InputProps>} */
export const ListInput = ({ options, onChange, onFocus, onBlur, value, ...props }) => {
  const [isFocused, setIsFocused] = useState(false);

  const handleFocus = () => {
    setIsFocused(true);
    if (onFocus) onFocus()

  }
  const handleBlur = (e) => {
    if (e?.relatedTarget?.name == "list-button") return;
    setIsFocused(false);
    if (onBlur) onBlur()

  }

  const handleMenuClick = (value) => {
    if (onChange) onChange(value)
    handleBlur();
  }

  return (
    <FormControl position="relative" onFocus={handleFocus} onBlur={handleBlur} >
      <Input {...props} value={value} onChange={e => onChange(e.target.value)} />
      {isFocused &&
        <Flex
          padding={3}
          bg="white"
          border="1px"
          mt={1}
          borderRadius="5px"
          borderColor="gray.200"
          position="absolute"
          width="100%"
          flexDir="column"
          zIndex={1}
        >
          {Array.isArray(options) &&
            options
              .filter(s => !value || s.toLowerCase().includes(value.toLowerCase()))
              .map(option => (
                <Button name="list-button" variant="outline" key={option} onClick={e => handleMenuClick(option)}>
                  {option}
                </Button>
              ))}
        </Flex>}
    </FormControl>
  )
}


export default ListInput;