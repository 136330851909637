import React from "react";
import PropTypes from "prop-types";
import PlayerInfo from "./PlayerOverview/PlayerInfo";
import DevicesList from "./PlayerOverview/DevicesList";
import { Box, Flex, SimpleGrid } from "@chakra-ui/core";
import PlayerOptionsDropdown from "./PlayerOptionsDropdown";
import {
  addToFavorites,
  isFavorite,
  removeFromFavorites,
} from "../../services/favorites.service";
import FavoriteButton from "../Favorites/FavoriteButton";

const PlayerOverview = React.memo(
  ({
    playerData,
    devices,
    size,
    debuggerInfo,
    getDebuggerInfo,
    updatePage,
    socialMedias,
  }) => {
    return (
      // hide all unnecessary information
      <Flex height="100%" flexDirection="column">
        <Box textAlign="right" paddingX={8} paddingY={2}>
          <FavoriteButton
            isFavorite={isFavorite(playerData["PlayerId"], "players")}
            addFavorite={() =>
              addToFavorites(playerData["PlayerId"], "players")
            }
            removeFavorite={() =>
              removeFromFavorites(playerData["PlayerId"], "players")
            }
          />
          <PlayerOptionsDropdown
            size={size || "sm"}
            className="mr-0"
            updateFunc={updatePage}
            player={playerData}
            debugger={debuggerInfo}
            callback={getDebuggerInfo}
            socialMedias={socialMedias}
          >
            <i className="fas fa-ellipsis-h" />
          </PlayerOptionsDropdown>
        </Box>

        <SimpleGrid
          flex={1}
          paddingLeft={8}
          gridTemplateColumns={{ sm: "100% 100%", lg: "80% 20%" }}
        >
          <Box>
            <PlayerInfo
              playerData={playerData}
              debuggerInfo={debuggerInfo}
              socialMedias={socialMedias}
            />
          </Box>
          <Box
            paddingY={2}
            borderLeft={{ lg: "1px solid #dee2e6" }}
            borderTop={{ xs: "1px solid #dee2e6", lg: "none" }}
          >
            <DevicesList devices={devices} paddingY={4} />
          </Box>
        </SimpleGrid>
      </Flex>
    );
  }
);

PlayerOverview.propTypes = {
  playerData: PropTypes.object,
  size: PropTypes.string,
  debuggerInfo: PropTypes.string,
  getDebuggerInfo: PropTypes.func,
  socialMedias: PropTypes.array,
};

export default PlayerOverview;
