import React,{useState,useContext,useEffect,useCallback} from 'react';
import httpClientCreator from "../helpers/http-client-singleton";
import authHeader from "../helpers/auth-header";
import {useUser} from './UserContext';

const http = httpClientCreator.getInstance();

/**
 * @type {React.Context<{
 *    consolePreferences: ConsolePreference[],
 *    setConsolePreference: (gc: ConsolePreference[]) => void,
 *    refresh: () => Promise,
 *    loading: boolean,
 *    saveOne: (pref: ConsolePreference) => Promise,
 *    deleteOne: (pref: ConsolePreference) => Promise,
 *    getPref: (key: string) => any,
 *    importData: (data:string) => Promise, 
 *  }>
 *  }
 */
export const consolePreferenceContext = React.createContext({consolePreferences: [] });

async function fetchConsolePrefs(){
  const { data } = await http.get("/consolepreferences", { headers: authHeader() }).then(x => x.data).catch(() => ({}));
  if(!data) return;
  return data;
}

/** 
 * @typedef {{Id: number, Key: string, _original?: ConsolePreference} & ({Type: 0, Value: object } | {Type: 1, Value: string} | {Type: 2, Value: boolean } | {Type: 3, Value: number })} ConsolePreference  
 * */


/**@type {React.FC} */
export const ConsolePreferenceProvider = ({children}) => {
  
  /** @type {[ ConsolePreference[], React.Dispatch<React.SetStateAction<ConsolePreference[]>>]} */
  const [consolePreferences,setConsolePreferences] = useState([]);
  const [loading,setLoading] = useState(true);
  const {isLogged} = useUser();
  
  useEffect(() => {
    if(isLogged) {
      setLoading(true);
      fetchConsolePrefs().then(x => setConsolePreferences(x)).finally(() => setLoading(false));
    }
  },[isLogged])

  const refresh = useCallback(() => { 
      setLoading(true); 
      return fetchConsolePrefs().then(x => setConsolePreferences(x)).finally(() => setLoading(false));
    },[])

  /** @type {(pref: ConsolePreference) => Promise} */
  const saveOne = useCallback(async (pref) => {
    setLoading(true);
    
    const newPref = pref.Id 
    ?  await http.post(`/consolepreferences/${pref?._original?.Key || pref.Key}`, pref).then(x => x.data.data).catch(() => undefined) //UPDATE
    :  await http.put(`/consolepreferences`, pref).then(x => x.data.data).catch(() => undefined) //CREATE
    
   

    if(newPref){
      const newList = [...consolePreferences];
      
      if(pref.Id) newList[consolePreferences.findIndex(x => x.Id === newPref.Id)] = newPref;
      else newList.push(newPref);
      
      setConsolePreferences(newList);
    }
    
    setLoading(false);
  },[consolePreferences])

   /** @type {(pref: ConsolePreference) => Promise} */
   const deleteOne = useCallback(async (pref) => {
     if(!pref.Id) return;
    
    const result = await http.delete(`/consolepreferences/${pref.Key}`).then(x => true)
    
    if(result){
      const newList = consolePreferences.filter(x => x.Key !== pref.Key);
      setConsolePreferences(newList);
    }
    
  },[consolePreferences])

     /** @type {(json: string) => Promise} */
  const importData = useCallback(async (json) => {   
    
    setLoading(true);

    var result = await http.put(`/consolepreferences/override`,json,).then(x => x?.data?.data).catch(() => null)
    if(result === null) return;

    if(result){
      setConsolePreferences(result);
      setLoading(false);
    }else{
      await refresh();
    }

 },[refresh])

  /** @type {(key: string) => any} */
  const getPref = useCallback((key) => {
    return consolePreferences?.find(x => x.Key === key)?.Value;
 },[consolePreferences])

  const value = {
    consolePreferences,
    setConsolePreferences,
    refresh,
    loading,
    saveOne,
    deleteOne,
    importData,
    getPref
  };

  return <consolePreferenceContext.Provider value={value}>{children}</consolePreferenceContext.Provider>;
}

/**
 * 
 * @returns 
 */
export const useConsolePreferences = () => {
  const context = useContext(consolePreferenceContext);
  return context;
};