import React from "react";

import "./css/errorStyles.css";

const ErrorBox = React.memo(({ className, children }) => {
  return (
    <div className={"error-box p-5 w-100 " + className}>
      <p>{children}</p>
    </div>
  );
});

export default ErrorBox;
