import { Box, Button, Stack } from '@chakra-ui/core';
import React, { useState } from 'react';
import PageContainer from '../../common/PageContainer';
import PlayerListInput from '../../Messages/PlayerListInput';
/**
 * @typedef {{
 *  onSubmit: (data: string[], toHacker: boolean) => void,
 *  loading?: boolean
 * }} BatchUpdateProps
 */

/** @type {React.FC<BatchUpdateProps>} */
const BatchUpdateComponent = ({ onSubmit, loading }) => {
  const [players, setPlayers] = useState([]);

  const handleSubmit = (toHacker) => {
    onSubmit(players, toHacker);
  }

  return (
    <PageContainer pageName="Batch Update Player">
      <Box
        backgroundColor="white"
        position="relative"
        paddingBottom={4}
        paddingX={6}
        borderRadius="calc(0.375rem - 1px) calc(0.375rem - 1px)"
      >
        <Stack padding={1}>
          <PlayerListInput disabled={loading} onChange={setPlayers} />
        </Stack>
        <Stack isInline spacing={8} align="center">
          <Button isDisabled={loading} onClick={() => handleSubmit(true)} variantColor="lumenPrimary">
            Set as Hacker
          </Button>
          <Button isDisabled={loading} onClick={() => handleSubmit(false)}>
            Unset as Hacker
          </Button>
        </Stack>
      </Box>
    </PageContainer >
  )
}

export default BatchUpdateComponent;