import React, { useState } from "react";
import Calendar from "./Calendar.jsx";
import { Container, Row, Card, CardBody } from "reactstrap";

import LoadingContainer from "../Layout/LoadingContainer.jsx";

import moment from "moment";

const EventToData = (event) => {
  return {
    eventId: event["Id"],
    text: event["Content"],
    Duration: event["Duration"],
    ProfileId: event["ProfileId"],
    startDate: moment.utc(event["StartDate"]).local().toDate(),
    endDate: moment
      .utc(event["StartDate"])
      .local()
      .add(event["Duration"], "hours")
      .toDate(),
  };
};

const EventsCalendar = ({
  className,
  month,
  setMonth,
  events,
  loading,
  sendEvent,
  deleteEvent,
  getEventLeaderboards,
  profiles,
  defaultProfileId,
}) => {
  const [day, setDay] = useState(Date());
  const [currentView, setCurrentView] = useState("month");

  const handleDateChange = (date) => {
    setDay(date);
    if (!moment(month).isSame(moment(date), "month")) {
      setMonth(date);
    }
  };

  const data = events.map((event) => EventToData(event));

  return (
    <div className={className}>
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card className="card-calendar">
              <CardBody>
                {loading ? (
                  <LoadingContainer />
                ) : (
                  <Calendar
                    day={day}
                    handleDateChange={handleDateChange}
                    events={data}
                    deleteEvent={deleteEvent}
                    sendEvent={sendEvent}
                    getEventLeaderboards={getEventLeaderboards}
                    profiles={profiles}
                    defaultProfileId={defaultProfileId}
                    currentView={currentView}
                    onCurrentViewChange={setCurrentView}
                  />
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default EventsCalendar;
