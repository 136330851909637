import { Button } from '@chakra-ui/core';
import React from 'react';
import { useCallback } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Container } from 'reactstrap';
import TableCard from '../components/Layout/TableCard';
import GT from '../components/Table/GenericTable';
import authHeader from '../helpers/auth-header';
import httpClientCreator from '../helpers/http-client-singleton';
import SimpleHeader from "../components/Headers/SimpleHeader";
import Swal from 'sweetalert2';
import PaginationHeader from '../components/common/PaginationHeader';
import SimplePaginationComponent from '../components/common/SimplePaginationComponent';
import { parseDate } from '../helpers/date-helper';

const http = httpClientCreator.getInstance();

/** 
 * @typedef {import("./../types/models").Player} Player
 *  */

/** 
 * @typedef {{
 * PlayerId: string,
 * PlayerCreationDate:string,
 * CreationDate: string,
 * }} PlayerDeleteRequestDTO
 *  */
/** @template t @typedef {ReturnType<typeof React.useState<t>> ReactState} */
/** @type {React.FC} */
const PlayerDeleteRequestsPage = () => {
  /** @type {ReactState<PlayerDeleteRequestDTO[]>} */
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [total, setTotal] = useState(0);
  const [page, setPage] = useState(0);
  const [objectsPerPage, setObjectsPerPage] = useState(15);

  useEffect(() => {
    async function fetchUsers() {
      const pls = await http
        .get("/playerdeleterequests", { headers: authHeader(), params: { skip: objectsPerPage * page, take: objectsPerPage } });

      const data = Array.isArray(pls.data.data?.Result) ? pls.data.data.Result : []
      setRequests(data);
      setTotal(pls.data.data?.Total || 0);
    }
    fetchUsers().finally(() => setLoading(false))
  }, [objectsPerPage, page])

  /** @type {(id: string) => Promise} */
  const deletePlayer = (id) => http
    .delete(`/playerdeleterequests/${id}`, { headers: authHeader() })
    .then(() => setRequests(ps => ps.filter(x => x.PlayerId !== id)))


  /** @type {(id: string) => void} */
  const onDelete = useCallback((id) => {
    Swal.fire({
      title: "Delete Request",
      text:
        "Are you sure? This action can't be undone!",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: () => deletePlayer(id),
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (!result.dismiss)
          Swal.fire({
            title: `Request deleted successfully!`,
            icon: "success",
          });
      })
      .catch(() => Swal.fire({
        title: "Couldn't delete the Request.",
        icon: "error",
      }));
  }, [])



  return (
    <>
      <SimpleHeader name="Player Delete Requests" clean parentName="Dashboard" />
      <Container className="mt--6" fluid>
        <TableCard loading={loading}
          header={<PaginationHeader
            total={total}
            skip={page * objectsPerPage}
            take={objectsPerPage}
            onObjectsPerPageChange={v => setObjectsPerPage(v)}
          />}
          footer={
            <SimplePaginationComponent
              fetchPage={(p) => setPage(p)}
              currentPage={page}
              objectsPerPage={objectsPerPage}
              totalObjects={total}
            />
          }
        >
          <GT.Table data={requests} onEmpty="All the delete requests were already answered." keys={makeColumns(onDelete)} />
        </TableCard>
      </Container>
    </>
  );
}

export default PlayerDeleteRequestsPage;


/** @type {(onDelete: () => void) => import("../components/Table/GenericTable").TableKey<PlayerDeleteRequestDTO>[]} */
const makeColumns = (onDelete) => [
  {
    key: 'PlayerId',
    headerOptions: { title: "Player ID", },
    bodyOptions: { type: "LINK", to: (data) => `/acp/player/${data.PlayerId}` }
  },
  {
    key: "PlayerCreationDate",
    headerOptions: { title: "Player Since" },
    bodyOptions: { render: v => parseDate(v.PlayerCreationDate).dateTime() }
  },
  {
    key: "CreationDate",
    headerOptions: { title: "Requested At" },
    bodyOptions: { render: v => parseDate(v.CreationDate).dateTime() }
  },
  {
    key: 'Options',
    headerOptions: { title: "Confirm Delete", },
    bodyOptions: {
      render: (p) => (<Button
        size="sm"
        className="btn-icon"
        onClick={() => onDelete(p.PlayerId)}
        color="neutral"
        type="button"
      >
        <span className="btn-inner--icon">
          <i className="fas fa-trash" />
        </span>
      </Button>)
    }
  },

];