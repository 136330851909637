import React, { useEffect, useState } from "react";
import LoadingContainer from "../../Layout/LoadingContainer";
import DataGrid from "./DataGrid";
import * as PropTypes from "prop-types";

const DataContainer = ({ data, className, multiSelect }) => {
  const [selectedData, setSelectedData] = useState({});

  useEffect(() => {
    if (
      data &&
      !(Object.keys(data).length === 0 && data.constructor === Object)
    ) {
      setSelectedData({ ...data[Object.keys(data)[0]][0] });
    }
  }, [data]);


  const handleClick = (bucket) => setSelectedData(bucket);
  return (
    <DataGrid
      multiSelect={multiSelect}
      className={className}
      data={data}
      selectedData={selectedData}
      onClick={handleClick}
    />
  );
};

DataContainer.propTypes = {
  multiSelect: PropTypes.bool,
  className: PropTypes.any,
  data: PropTypes.shape({}),
};

export default DataContainer;
