import React, { useEffect, useState } from "react";
import {
  FormControl,
  FormLabel,
  Input,
  Box,
  Button,
  Flex,
  FormErrorMessage,
} from "@chakra-ui/core";
import { useForm } from "react-hook-form";
import { TimeSpanInput } from "../common/TimeSpanInput";
import TextInput from "../common/TextInput";
import { FormText } from "reactstrap";
import { parseDate } from "../../helpers/date-helper";

/**
 * @typedef {import("./RegattaDefinitionsList").RegattaDefinition} RegattaDefinition
 */

/**
 * @type {React.FC<{onSubmit?: (data: RegattaDefinition) => void, onCancel?: () => void, regattadefinitionData: RegattaDefinition, disabled: boolean}>} */
export const RegattaDefinitionForm = ({
  onSubmit,
  onCancel,
  hideStartDate,
  disabled,
  regattadefinitionData
}) => {
  const [duration, setDuration] = useState(regattadefinitionData?.Duration)
  const [cooldown, setCooldown] = useState(regattadefinitionData?.Cooldown)

  const submitRegattaDefinition = async (regattadefinition) => {
    /** @type {RegattaDefinition} */
    const def = {
      ...regattadefinition,
      Cooldown: cooldown,
      Duration: duration
    };
    
    try{
      await onSubmit(def);
    }catch{
      setError("StartDate");
    }
  };

  const { register, errors, handleSubmit, reset, setError} = useForm({
    reValidateMode: "onBlur"
  });

   useEffect(() => {
    reset({
      ...(regattadefinitionData || {}),
      GuildsQuantity: regattadefinitionData?.GuildsQuantity ?? 30,
      n:  parseDate(regattadefinitionData?.CreationDate).dateTime(),
      GuildLevelOffset: regattadefinitionData?.GuildLevelOffset || (!disabled ?  100 : undefined),
      StartDate: parseDate(regattadefinitionData?.CreationDate).addMin(regattadefinitionData?.StartDate || 0).inputFull()
    });
   }, [disabled, regattadefinitionData, reset]);

  return (
    <Box backgroundColor="whiteAlpha.400" textAlign="left">
      <form onSubmit={handleSubmit(submitRegattaDefinition)}>
      <Flex
        justifyContent="space-between"
        mb="1rem"
      >
      <FormControl width="25%" mr="5" isInvalid={Boolean(errors.Id)}>
        <FormLabel fontWeight={600}>Id</FormLabel>
        <Input
          name="Id"
          disabled={true}
          ref={register({ required: false})}
        />
      </FormControl>
        {!hideStartDate && 
        <FormControl flex={1} isInvalid={Boolean(errors.StartDate)}>
          <FormLabel fontWeight={600}>Start Date</FormLabel>
          <Input
            type="datetime-local"
            disabled={disabled}
            name="StartDate"
            ref={register({required: true})}
          />
          {!!errors.StartDate && <FormErrorMessage>Invalid Start Date.</FormErrorMessage>}
          <FormText>Only applies for the first Regatta</FormText>
        </FormControl>}
      </Flex>
   
      <TextInput disabled={disabled} ref={register({ required: true})} flex={1}  name="DataKey" label="Data Key"/>
      <TextInput disabled={disabled} ref={register({ required: true})} flex={1}  type="number" name="GuildLevelOffset" label="Level Offset"/>
      <TimeSpanInput disabled={disabled} value={duration} onChange={setDuration} name="Duration" label="Duration" />
      <TimeSpanInput disabled={disabled} value={cooldown} onChange={setCooldown} name="Cooldown" label="Cooldown" />
      <Flex justifyContent="space-between" gridGap="0.5rem" mt="1rem">
      <TextInput disabled={disabled} ref={register({ required: true})} flex={1} type="number" name="GuildsQuantity" label="Guild Count"/>
      <TextInput disabled={disabled} ref={register({ required: true})} flex={1} type="number" name="GuildMembersQuantity" label="Guild Members Count"/>
      </Flex>
      <br/>
      <Flex justifyContent="flex-end">
        {onSubmit && <Button
          onClick={handleSubmit(submitRegattaDefinition)}
          textAlign="left"
          display="inline"
          ml={10}
          mr={10}
          variantColor="lumenPrimary"
        >
          Confirm
        </Button>}
        {onCancel && <Button
          variant="outline"
          variantColor="lumenPrimary"
          onClick={onCancel}
        >
          Cancel
        </Button>}
      </Flex>
      </form>
    </Box>
  );
};

export default RegattaDefinitionForm;