import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";

const ConfirmDeleteModal = ({
  id,
  text,
  deleteEvent,
  open,
  modalToggle,
  callback,
}) => {
  const [loading, setLoading] = useState(false);

  const finallyCb = () => {
    toggle();
    setLoading(false);
  };

  const toggle = () => modalToggle(!open);
  const confirmDelete = () => {
    setLoading(true);
    deleteEvent({ id, thenCb: callback, finallyCb });
  };

  return (
    <Modal centered isOpen={open} toggle={toggle}>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <ModalHeader toggle={toggle}>Delete {text}</ModalHeader>
          <ModalBody>Are you sure you want to delete {text}?</ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={confirmDelete}>
              Delete
            </Button>
            <Button outline color="primary" onClick={toggle}>
              Cancel
            </Button>
          </ModalFooter>
        </>
      )}
    </Modal>
  );
};

export default ConfirmDeleteModal;
