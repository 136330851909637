import React from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import "./css/playerGiftStyles.css";
import ErrorBox from "../common/ErrorBox";
import MessageList from "../Messages/MessageList";

const PlayerGifts = React.memo(({ messages, getMessages, playerId }) => {

  if (messages.length === 0) {
    return (
      <ErrorBox className="h-100">
        This player doesn't have any messages.
      </ErrorBox>
    );
  } else {
    return (
      <div className="player-scroll">
        <PerfectScrollbar>
          <MessageList messages={messages} playerId={playerId} />
        </PerfectScrollbar>
      </div>
    );
  }
});

export default PlayerGifts;
