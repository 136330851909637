import React, { useEffect, useState } from "react";
import {
  SimpleGrid,
  FormControl,
  FormLabel,
  Input,
  FormErrorMessage,
  Box,
  Button,
  Flex,
  Select,
  Switch,
} from "@chakra-ui/core";

import "./css/userStyles.css";

import { useForm } from "react-hook-form";

import PropTypes from "prop-types";

const UserForm = ({
  roles,
  defaultRoleId,
  onSubmit,
  onCancel,
  Id,
  Email,
  FirstName,
  LastName,
  RoleId,
}) => {
  const [email, setEmail] = useState(Email);
  const [firstName, setFirstName] = useState(FirstName);
  const [lastName, setLastName] = useState(LastName);
  const [password, setPassword] = useState("");
  const [roleId, setRoleId] = useState(Number(RoleId) || defaultRoleId);
  const [willUpdatePassword, setWillUpdatePassword] = useState(false);

  const submitUser = () => {
    const user = {
      ...(Id && { id: Id }),
      email,
      firstName,
      lastName,
      ...(((Id && willUpdatePassword) || !Id) && {
        password,
      }),
      roleId,
    };
    onSubmit(user);
  };

  useEffect(() => {
    setEmail(Email);
    setFirstName(FirstName);
    setLastName(LastName);
    setRoleId(Number(RoleId) || defaultRoleId);
  }, [Email, FirstName, LastName, RoleId, defaultRoleId]);

  const { register, errors, handleSubmit } = useForm({
    reValidateMode: "onBlur",
  });
  return (
    <Box backgroundColor="whiteAlpha.400" textAlign="left">
      <FormControl isInvalid={Boolean(errors.email)}>
        <FormLabel fontWeight={600}>Email</FormLabel>
        <Input
          name="email"
          value={email}
          isDisabled={Email}
          onChange={(e) => setEmail(e.target.value)}
          ref={register({
            required: true,
            pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          })}
        />
        <FormErrorMessage className="no-margin-bottom">
          A valid email must contain '@' and a domain after.
        </FormErrorMessage>
      </FormControl>
      <SimpleGrid
        columns={2}
        spacingX={6}
        spacingY={6}
        backgroundColor="white"
        mt={2}
        mb={4}
      >
        <FormControl isInvalid={Boolean(errors.firstName)}>
          <FormLabel fontWeight={600}>First Name</FormLabel>
          <Input
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
            name="firstName"
            ref={register({
              required: true,
              pattern: /^(?![\s.]+$)[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i,
            })}
          />
          <FormErrorMessage className="no-margin-bottom">
            Are you sure this is your real name?
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errors.lastName)}>
          <FormLabel fontWeight={600}>Last Name</FormLabel>
          <Input
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
            name="lastName"
            ref={register({
              required: true,
              pattern: /^(?![\s.]+$)[A-Za-zÀ-ÖØ-öø-ÿ\s]+$/i,
            })}
          />
          <FormErrorMessage className="no-margin-bottom">
            Are you sure this is your real name?
          </FormErrorMessage>
        </FormControl>
      </SimpleGrid>
      {Id && (
        <FormControl>
          <FormLabel display="block">Update Password?</FormLabel>
          <Switch
            isChecked={willUpdatePassword}
            onChange={() => setWillUpdatePassword(!willUpdatePassword)}
          />
        </FormControl>
      )}
      {((Id && willUpdatePassword) || !Id) && (
        <>
          <FormControl isInvalid={Boolean(errors.password)}>
            <FormLabel fontWeight={600}>Password</FormLabel>
            <Input
              name="password"
              type="password"
              value={password}
              placeholder={Id && "Put your current password or change it"}
              onChange={(e) => setPassword(e.target.value)}
              ref={register({
                required: (Id && willUpdatePassword) || !Id,
                validate: (value) =>
                  (value && value.length > 5) ||
                  "Your password must be at least 6 characters long",
              })}
            />
            <FormErrorMessage className="no-margin-bottom">
              {errors.password &&
                (errors.password.type === "required"
                  ? "This field is required."
                  : errors.password.message)}
            </FormErrorMessage>
          </FormControl>
          <FormControl my={2} isInvalid={Boolean(errors.confirmPassword)}>
            <FormLabel fontWeight={600}>Confirm Password</FormLabel>
            <Input
              name="confirmPassword"
              type="password"
              ref={register({
                required: (Id && willUpdatePassword) || !Id,
                validate: (value) =>
                  (value && value === password) ||
                  "The passwords don't match. Please try again.",
              })}
            />
            <FormErrorMessage className="no-margin-bottom">
              {errors.confirmPassword &&
                (errors.confirmPassword.type === "required"
                  ? "This field is required."
                  : errors.confirmPassword.message)}
            </FormErrorMessage>
          </FormControl>
        </>
      )}
      <FormControl>
        <FormLabel fontWeight={600}>User Access Level</FormLabel>
        <Select
          defaultValue={roleId}
          marginBottom={6}
          icon="chevron-down"
          onChange={(e) => setRoleId(e.target.value.trim())}
        >
          {roles}
        </Select>
      </FormControl>
      <Flex justifyContent="flex-end">
        <Button
          onClick={handleSubmit(submitUser)}
          textAlign="left"
          display="inline"
          ml={10}
          mr={10}
          variantColor="lumenPrimary"
        >
          Confirm
        </Button>
        <Button
          variant="outline"
          variantColor="lumenPrimary"
          onClick={onCancel}
        >
          Cancel
        </Button>
      </Flex>
    </Box>
  );
};

UserForm.propTypes = {
  roles: PropTypes.array,
  defaultRoleId: PropTypes.number,
  onSubmit: PropTypes.func,
};

export default UserForm;
