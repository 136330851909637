import React from "react";
import axios from "axios";

export default class Docs extends React.Component {
  constructor(props) {
    super(props);
    let url = window.location.href;
    if (
      url.includes(".dev.") ||
      url.includes("localhost") ||
      url.includes(".staging.")
    ) {
      window.location.reload();
    } else if (url.includes("console.latino.starwars.tools")) {
      window.location.replace(url.substring(0, url.indexOf("/acp/docs")));
    } else {
      // TODO: Update it when discuss how to get the environment info
      axios.get("/api/serverinfo").then((environmentVar) => {
        if (environmentVar.data.m_environment === "release") {
          window.location.replace(url.substring(0, url.indexOf("/acp/docs")));
        } else {
          window.location.reload();
        }
      });
    }
  }
}
