import React, { useState } from "react";
import TagsContainer from "./TagsContainer";
import FilterInput from "./FilterInput";
import { Row, Col } from "reactstrap";
import { Text } from "@chakra-ui/core";
import classnames from "classnames";

const operators = {
  EQUALS: "==",
  GREATER: ">",
  LOWER: "<",
  GREATER_OR_EQUAL: ">=",
  LOWER_OR_EQUAL: "<=",
  BETWEEN: "BETWEEN",
};

const FilterContainer = ({ onSubmit, list }) => {
  const [filtersChanged, setFiltersChanged] = useState(false);
  const [tags, setTags] = useState([]);

  const getFilteredPlayers = (tags, operators) => {
    const params = {};
    // field can be 'Name', 'Value' or 'DeviceId'
    params.attributeFilters = tags.map((tag) => ({
      name: tag.key,
      operator: operators[tag.operator],
      value: tag.value,
    }));
    setFiltersChanged(false);
    if (!tags[0]) return null;
    return params;
  };

  const handleTagsInput = (tags) => {
    const separatedTags = tags.map((tag) => {
      const separatedTag = tag.split(" ");
      return {
        key: separatedTag[0],
        operator: separatedTag[1],
        value: separatedTag[2],
      };
    });
    setTags(separatedTags);
    setFiltersChanged(true);
  };

  const handleSubmit = () => {
    onSubmit(getFilteredPlayers(tags, operators));
  };

  const addTag = (key, operator, value) => {
    const tag = { key, operator, value };
    setTags([...tags, tag]);
    setFiltersChanged(true);
  };

  return (
    <>
      <Row>
        <Col>
          <Text fontSize="md" className="text-muted font-weight-bold">
            Filter by Attribute:
          </Text>
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <FilterInput
            onClick={addTag}
            onSubmit={handleSubmit}
            operators={operators}
            className={classnames({ "pb-3": tags })}
            disabled={!filtersChanged}
            list={list}
          />
        </Col>
      </Row>
      {tags.length > 0 && (
        <Row>
          <Col>
            <TagsContainer
              handleTagsInput={handleTagsInput}
              tags={tags.map(
                (tag) => tag.key + " " + tag.operator + " " + tag.value
              )}
            />
          </Col>
        </Row>
      )}
    </>
  );
};

export default FilterContainer;
