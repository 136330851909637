import { theme } from "@chakra-ui/core";

const swTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    lumenPrimary: {
      50: "#d9fdff",
      100: "#aef1fd",
      200: "#82e7f9",
      300: "#54dcf5",
      400: "#28d2f1",
      500: "#0eb8d7",
      600: "#008fa8",
      700: "#006679",
      800: "#003e4b",
      900: "#00161d",
    },
    lumenSecondary: {
      50: "#e6ecff",
      100: "#bcc6f8",
      200: "#919fee",
      300: "#6679e5",
      400: "#3b53dd",
      500: "#2339c4",
      600: "#1a2d99",
      700: "#11206e",
      800: "#081345",
      900: "#02051c",
    },
    borderColor: "#dee2e6",
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },
};
export default swTheme;
