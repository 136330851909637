import List from "list.js";
import React from "react";

// reactstrap components
import authHeader from "../helpers/auth-header.js";
import httpClient from "../helpers/http-client-singleton";
import SettingsComponent from "../components/Settings/Settings";
import UserModal from "../components/User/UserModal";
import RoleModal from "../components/Roles/RoleModal.jsx";
import { userService } from "../services/user.service.js";
import TableCard from "../components/Layout/TableCard.jsx";
import { Button } from "reactstrap";

const http = httpClient.getInstance();

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersData: [],
      user: {},
      loading: true,
      userModalOpen: false,
      roleModalOpen: false,
      roles: [],
      role: null,
      permissions: [],
    };

    this.notification = React.createRef();
    this.table = React.createRef();
    if(!userService.hasPermission("MANAGE_SIDIOUS_USERS")) return;

    this.fetchPermissions().then(() => this.fetchRoles());
    this.handleUserModalToggle = this.handleUserModalToggle.bind(this);
    this.handleUserCreation = this.handleUserCreation.bind(this);
    this.getUsers();
  }

  getUsers() {
    this.setState({ loading: true });
    http
      .get("/users/", { headers: authHeader() })
      .then((res) => {
        this.setState({ usersData: res.data.data, loading: false });
        new List(this.table.current, {
          valueNames: ["name", "email", "access_level"],
          listClass: "list",
        });
      })
      .catch((err) => {
        if (err.response.status === 400 || err.response.status === 500) {
          this.setState({ loading: false });
        }
      });
  }

  handleOpenUpdateModal = (Id, Email, FirstName, LastName, RoleId) => {
    this.setState({ user: { Id, Email, FirstName, LastName, RoleId } });
    this.handleUserModalToggle();
  };



  handleClose = () => this.setState({ user: {} });

  handleUserModalToggle = () => this.setState({ userModalOpen: !this.state.userModalOpen });
  handleRoleModalToggle = async (role = null) => {
    const newState = { roleModalOpen: !this.state.roleModalOpen };

    if(!role || role.Permissions?.length){
      newState.role = role;
    }else{
      const roleData = await http.get(`/roles/${role.Id}`, { headers: authHeader() }).then(({data}) => data.data.Role);
      const newRoles = [...this.state.roles];
      const targetRole = newRoles.findIndex(x => x.Id === role.Id);
      newRoles[targetRole] = roleData;

      newState.roles = newRoles;
      newState.role = newRoles[targetRole];
    }
    this.setState(newState);
  }

  handleUpdateRole = (role) => {
    this.setState({role});
    this.handleRoleModalToggle();
  }
  handleUserCreation(result) {
    if (result === "success") {
      this.getUsers();
    }
    this.notify(result);
  }


  updateRole = (role) => {
    this.handleRoleModalToggle(null);
    http.put("/roles/", {Role: role}, { headers: authHeader() }).then(({data}) => {
      this.notify("success", "update", "Role");
      const newData = this.state.roles;
      const targetIndex = newData.findIndex(x => x.Id === role.Id);
      newData[targetIndex] = data.data.Role;

      this.setState({
        roles: newData
      })
    });
  };

  deleteRole = (roleId) => {
    this.handleRoleModalToggle(null);
    http.delete(`/roles/${roleId}`, { headers: authHeader() }).then(({data}) => {
      this.notify("success", "delete", "Role");
      const newData = this.state.roles.filter(x => x.Id !== roleId);
      this.setState({
        roles: newData
      })
    });
  };

  createRole = (role) => {
    this.handleRoleModalToggle(null);
    http.post("/roles", {Role: role}, { headers: authHeader() }).then(({data}) => {
      this.notify("success", "create", "Role");
      this.setState({
        roles: [...this.state.roles,data.data.Role]
      })
    });
  };
  updateUser = (user) => {
    this.handleUserModalToggle();
    http.put("/users/", user, { headers: authHeader() }).then(() => {
      this.notify("success", "update");
      this.getUsers();
    });
  };

  createUser = (user) => {
    http
      .post("/users/register", user, { headers: authHeader() })
      .then(() => {
        this.handleUserCreation("success");
      })
      .catch(() => {
        this.handleUserCreation("danger");
      })
      .finally(() => {
        this.handleUserModalToggle();
      });
  };

  

  onSubmit = (user) => {
    if (user.id) this.updateUser(user);
    else this.createUser(user);
  };

  onSubmitRole = (role) => {
    if (role.Id) this.updateRole(role);
    else this.createRole(role);
  };

  notify(typeN, typeT = "create", entity= "User") {
    let text;
    if (typeT === "create") {
      text = ` Success! New ${entity} Created!`;
    } else {
      text = ` Success! ${entity} Updated`;
    }
    const options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            Success
          </span>
          <span data-notify="message">{text}</span>
        </div>
      ),
      type: typeN,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.notification.current.notificationAlert(options);
  }

  toggleNavs = (e, state, index) => {
    e.preventDefault();
    this.setState({
      [state]: index,
    });
  };

  fetchPermissions = () => {
    return http.get('/permissions',{ headers: authHeader() }).then(({data}) => {
      this.setState({
        permissions:data?.data?.Permissions || []
      });
    });
  }

  fetchRoles = () => {
    return http.get("/roles", { headers: authHeader() }).then(({data}) => {
      this.setState({
        roles: data.data.Roles,
        defaultRole: data.data.Roles.find((role) => role["Name"] === "User")?.Id,
      });
    });
  };

  populateRolesSelect = () => {
    if (this.state.roles === null || this.state.roles.length < 1) {
      this.fetchRoles();
      return null;
    }

    return this.state.roles?.map((r) => {
      return <option value={r.Id}>{r.Name}</option>;
    });
  };

  render() {
    if(!userService.hasPermission("MANAGE_ROLE_PERMISSIONS")){
      return (
        <TableCard>
        <center style={{padding: '5rem'}} >
          <p>You're not authorized to see this page.</p>
          <Button onClick={() => this.props.history.goBack()}>
            Go Back
          </Button>
        </center>          
        </TableCard>
      )
    }

    return (
      <>
        <SettingsComponent
          notificationRef={this.notification}
          onClick={this.handleUserModalToggle}
          onOpenRoleModal={this.handleRoleModalToggle}
          loading={this.state.loading}
          getUsers={() => this.getUsers()}
          tableRef={this.table}
          users={this.state.usersData}
          roles={this.state.roles}
          handleOpenUpdateModal={this.handleOpenUpdateModal}
          onUpdateRole={this.handleUpdateRole}
        />
        <UserModal
          handleModalToggle={this.handleUserModalToggle}
          isOpen={this.state.userModalOpen}
          defaultRole={this.state.defaultRole}
          populateRolesSelect={this.populateRolesSelect}
          sendUser={this.onSubmit}
          onClose={this.handleClose}
          {...this.state.user}
        />
        <RoleModal
          handleModalToggle={this.handleRoleModalToggle}
          isOpen={this.state.roleModalOpen}
          sendRole={this.onSubmitRole}
          onClose={this.handleClose}
          role={this.state.role}
          permissions={this.state.permissions}
          onDelete={this.deleteRole}
        />
      </>
    );
  }
}

export default Settings;
