import React from "react";
// reactstrap components
import {
  Container,
  Button,
  Card,
  CardBody,
  CardHeader,
  Table,
  Row,
  Col,
} from "reactstrap";
import LoadingContainer from "../components/Layout/LoadingContainer.jsx";
import authHeader from "../helpers/auth-header.js";
import PerfectScrollbar from "react-perfect-scrollbar";
import NotificationAlert from "react-notification-alert";
import SimplePaginationComponent from "../components/common/SimplePaginationComponent.jsx";
import SimpleHeader from "../components/Headers/SimpleHeader.jsx";
import EventProfileModal from "../components/EventProfile/EventProfileModal.jsx";
import httpClient from "../helpers/http-client-singleton";
import ErrorBox from "../components/common/ErrorBox";
import Swal from "sweetalert2";

const http = httpClient.getInstance();

export default class EventsProfiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      success: false,
      alertWarn: "",
      loading: false,
      tableData: [],
      objectsPerPage: 20,
      currentPage: 0,
      totalObjects: null,
      search: "",
      keySearch: "",
      alert: false,
      totalPages: 0,
      accordionIndex: null,
      showPopup: false,
      eventProfileId: null,
      eventProfileName: null,
      eventProfileTaskcount: null,
      eventProfileOffset: null,
    };

    this.openPopupHandler = this.openPopupHandler.bind(this);
    this.closePopupHandler = this.closePopupHandler.bind(this);
  }

  componentDidMount() {
    this.listEventsProfilesPage(0);
  }

  updatePage = () => {
    this.setState({ search: "", accordionIndex: null });
    this.listEventsProfilesPage(0);
  };

  notifyUpdateSuccess = () => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
            Success
          </span>

          <span data-notify="message">
            Success! Event Profile{" "}
            {this.state.popupType === "update" ? "Updated" : "Created"} !
          </span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  notificationEmitter = (typeN, typeT = "other") => {
    let text;
    if (typeT === "delete") {
      text =
        typeN === "success"
          ? " Snapshot has been deleted!"
          : " There has been an error, please try again!";
    } else {
      text =
        typeN === "success"
          ? " Success!"
          : " Invalid information given, please try again";
    }
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {typeN === "success" ? " Success" : " Error"}
          </span>
          <span data-notify="message">{text}</span>
        </div>
      ),
      type: typeN,
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  listEventsProfilesPage = async (pageNumber) => {
    let auxPage = 0;
    if (pageNumber === "next") {
      auxPage = this.state.currentPage + 1;
      this.setState({
        currentPage: this.state.currentPage + 1,
        loading: true,
      });
    } else if (pageNumber === "prev") {
      auxPage = this.state.currentPage - 1;
      this.setState({
        currentPage: this.state.currentPage - 1,
        loading: true,
      });
    } else {
      auxPage = pageNumber;
      this.setState({
        currentPage: pageNumber,
        loading: true,
      });
    }

    http
      .get("/events/profiles", {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.data === undefined) {
          this.setState({ search: "", deviceIdSearch: "", keySearch: "" });
          this.notificationEmitter("danger");
          this.listEventsProfilesPage(0);
        } else {
          this.setState({
            tableData: response.data.data,
            objectsPerPage: 30,
            currentPage: auxPage,
            totalObjects: response.data.data.length,
            search: "",
          });
        }
      })
      .finally(() => this.setState({ loading: false }));
  };

  onDeleteClick(index) {
    function onDelete() {
      return http.delete(`events/profiles/${index}`, { headers: authHeader() }).catch(() => ({ error: true }));;
    }
    
    Swal.fire({
      title: "Delete Event Profile",
      text: "Are you sure you want to delete this profile?",
      showCancelButton: true,
      confirmButtonText: "Delete",
      showLoaderOnConfirm: true,
      preConfirm: () => onDelete(),
    }).then(({value,dismiss}) => {
      if(dismiss) return;      
      if(value?.error) return;
      this.setState({
        tableData: this.state.tableData.filter(x => x.Id !== Number.parseInt(index))
      })
    });
  };

  renderEventsProfilesTable = (tableData) => {
    return tableData.length > 0 ? (
      <Table
        hover
        className="align-items-center table-flush"
        size="sm"
        striped
        responsive
      >
        <thead className="thead-light">
          <tr>
          <th scope="col" className="sort col-2" data-sort="id">
              ID
            </th>
            <th scope="col" className="sort col-4" data-sort="name">
              NAME
            </th>
            <th scope="col" className="sort col-2" data-sort="taskcount">
              TASK COUNT
            </th>
            <th scope="col" className="sort col-2" data-sort="offset">
              OFFSET
            </th>
            <th className="sort col-4" scope="col" />
          </tr>
        </thead>
        <tbody className="accordion" id="accordionPersistenceInfo">
          {tableData.map((data, index) => (
              <tr key={"row" + index}>
                <td
                    style={{ }}
                    key={data["Id"]}
                    className="name"
                  >
                  {data["Id"]}
                </td>
                <td
                  style={{ fontWeight: "bold" }}
                  key={data["Name"]}
                  className="name"
                >
                  {data["Name"]}
                </td>
                <td key={data["Taskcount"]} className="taskcount">
                  {data["TaskCount"]}
                </td>
                <td key={data["Offset"]} className="offset">
                  {data["Offset"]}
                </td>
                <td>
                  <div>
                    <Button
                      size="sm"
                      className="fas fa-edit"
                      onClick={() => {
                        this.openPopupHandler(
                          "update",
                          data["Id"],
                          data["Name"],
                          data["TaskCount"],
                          data["Offset"]
                        );
                      }}
                      color="neutral"
                      type="button"
                    />
                      <Button
                      size="sm"
                      className="fas fa-trash"
                      onClick={() => this.onDeleteClick(data["Id"])}
                      color="warning"
                      type="button"
                    />
                  </div>
                </td>
              </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <ErrorBox>
        Couldn't find any event profiles to show.
      </ErrorBox>
    );
  };

  setStatePages = (number) => {
    if (number !== this.state.totalPages) {
      this.setState({ totalPages: number });
    }
  };

  openPopupHandler(Type, id, name, taskcount, offset) {
    this.setState({
      showPopup: true,
      popupType: Type,
      eventProfileId: id,
      eventProfileName: name,
      eventProfileTaskcount: taskcount,
      eventProfileOffset: offset,
    });
  }

  closePopupHandler() {
    this.setState({
      showPopup: false,
    });
  }

  render() {
    let contents = this.state.loading ? (
      <LoadingContainer />
    ) : (
      this.renderEventsProfilesTable(this.state.tableData)
    );

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>
        <SimpleHeader
          name="Events Profiles"
          clean={true}
          parentName="Dashboard"
        />
        <Container fluid>
          <Card className="mt--6">
            <CardHeader className="pb-0">
              <Row style={{ marginBottom: "0.8rem" }}>
                <Col style={{ display: "inline-block" }} xs="3">
                  <Button
                    className="btn-primary"
                    color="blue"
                    onClick={() =>
                      this.openPopupHandler("create", null, "", 0, 0)
                    }
                    size="sm"
                  >
                    Create
                  </Button>
                </Col>
              </Row>
            </CardHeader>
            <PerfectScrollbar>
              <CardBody className="p-0">
                {contents}
                {this.state.totalObjects !== null ? (
                  <SimplePaginationComponent
                    fetchPage={this.listEventsProfilesPage}
                    currentPage={this.state.currentPage}
                    objectsPerPage={this.state.objectsPerPage}
                    totalObjects={this.state.totalObjects}
                  />
                ) : null}
              </CardBody>
            </PerfectScrollbar>
          </Card>
          {this.state.showPopup ? (
            <EventProfileModal
              modal={this.state.showPopup}
              notifySuccess={this.notifyUpdateSuccess}
              loading={this.state.loading}
              notificationEmitter={this.notificationEmitter}
              close={this.closePopupHandler}
              s_key={this.props.s_key}
              update={this.updatePage}
              eventProfileTaskcount={this.state.eventProfileTaskcount}
              eventProfileId={this.state.eventProfileId}
              type={this.state.popupType}
              eventProfileName={this.state.eventProfileName}
              eventProfileOffset={this.state.eventProfileOffset}
            />
          ) : null}
        </Container>
      </>
    );
  }
}
