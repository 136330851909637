import React from "react";
import PropTypes from "prop-types";

/** @type {React.FC<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>} */
const FileInput = ({accept,  onChange, ...props}) => {
  return (
    <input
      type="file"
      accept={accept}
      onChange={(e) => onChange?.(e.target.files[0])}
      {...props}
    />
  );
};

FileInput.propTypes = {
  onChange: PropTypes.func
};

export default FileInput;
