import { theme } from "@chakra-ui/core";

const swTheme = {
  ...theme,
  colors: {
    ...theme.colors,
    lumenPrimary: {
      50: "#ffe3ea",
      100: "#ffb4c4",
      200: "#fb859d",
      300: "#f85475",
      400: "#f4244e",
      500: "#db0b34",
      600: "#ab0429",
      700: "#7a011c",
      800: "#4c000f",
      900: "#1f0005",
    },
    lumenSecondary: {
      50: "#ffe3ed",
      100: "#ffb3c8",
      200: "#fd82a3",
      300: "#fc517f",
      400: "#fb265b",
      500: "#e31543",
      600: "#b10c33",
      700: "#7e0525",
      800: "#4d0016",
      900: "#1e0007",
    },
    borderColor: "#dee2e6",
    brand: {
      900: "#1a365d",
      800: "#153e75",
      700: "#2a69ac",
    },
  },
};
export default swTheme;
