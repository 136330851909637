import React, { useEffect, useState } from "react";

import { Col, Row, Input, Button } from "reactstrap";
import PropTypes from "prop-types";

/**
 * @typedef {{
 * placeholder?: string,
 * className?: string,
 * onSearch: (data: string | undefined) => void,
 * onClear: () => void, 
 * disabled:  boolean,  
 * }} SearchInputProps
 */

/**
 * @type {React.FC<SearchInputProps>}  */
const SearchInput = ({
  placeholder,
  className,
  onSearch,
  onClear,
  children,
  disabled,
}) => {
  const [search, setSearch] = useState(undefined);

  const handleClick = () => onSearch(search);

  useEffect(() => {
    if (search === "") {
      onClear();
    }
  }, [search, onClear]);

  return (
    <Row className={className}>
      <Col xs={12} xl={6}>
        <Input
          maxLength="80"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          className="form-control-sm"
          placeholder={placeholder}
          type="search"
          disabled={disabled}
        />
      </Col>
      <Col xs={12} xl={6}>
        <Button
          className="btn-neutral"
          color="default"
          onClick={handleClick}
          size="sm"
          disabled={disabled}
        >
          <i className="fas fa-search" /> Search
        </Button>
        <Button onClick={() => setSearch("")} size="sm" disabled={disabled}>
          Clear
        </Button>
        {children}
      </Col>
    </Row>
  );
};

SearchInput.propTypes = {
  placeholder: PropTypes.string,
  onSearch: PropTypes.func.isRequired,
  onClear: PropTypes.func.isRequired,
};

export default SearchInput;
