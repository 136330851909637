import {  Flex, FormControl, FormLabel,Input,Button } from '@chakra-ui/core';
import React from 'react';
import { Modal, ModalHeader, ModalBody } from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import RegattaDefinitionForm from './RegattaDefinitionForm';
import { getRegattaRealEndDate, isRegattaFinished } from './utils';
import { Link } from "react-router-dom";
import { parseDate } from '../../helpers/date-helper';

/**
 * 
 * @typedef {{
 *   modalOpen: boolean,
 *   loading: boolean,
 *   onCancel: () => void,
 *   onDelete: (data: Regatta) => void,
 *   regatta: import('./RegattaList').Regatta
 * }} RegattaModalProps
 */

/**
 * @type {React.FC<RegattaModalProps>}*/
const RegattaModal = ({modalOpen,loading,onCancel,onDelete, regatta}) => {
  if(!modalOpen) return <></>;

  /** @type {import('./RegattaDefinitionModal').RegattaDefinition} */
  const definition = {
    Cooldown: regatta.Cooldown,
    CreationDate: regatta.CreationDate,
    DataKey: regatta.DataKey,
    Duration: regatta.Duration,
    GuildLevelOffset: regatta.GuildLevelOffset,
    GuildMembersQuantity: regatta.GuildMembersQuantity,
    GuildsQuantity: regatta.GuildsQuantity,
    Id: regatta.RegattaDefinitionId,
    StartDate: regatta.StartDate
  }

  return (
    <Modal isOpen={modalOpen} toggle={onCancel}>
    <ModalHeader >
      <span style={{color: regatta.IsDeleted ? "warning" : undefined}}>Regatta</span>
    </ModalHeader>
    <ModalBody>
      {loading ? (
        <LoadingContainer />
      ) : (
        <>
          <Flex
            justifyContent="space-between"
            flexDir="column"
            >
            <Flex flex={1} flexDir="row" justifyContent="space-between">
              <FormControl width="25%" mr="5" >
                <FormLabel>Id</FormLabel>
                <Input
                  name="Id"
                  disabled
                  value={regatta.Id}
                />
              </FormControl>
   
            </Flex>
            <Flex flex={1} flexDir="row" justifyContent="space-between">
              <FormControl w="49%" >
                <FormLabel >Competition Start</FormLabel>
                <Input
                  disabled
                  type="datetime-local"
                  p={0}
                  name="StartDate"
                  value={parseDate(regatta.EndDateLastRegatta).inputFull()}
                />
              </FormControl >
              <FormControl w="49%" >
                <FormLabel >Competition End</FormLabel>
                <Input
                  disabled
                  type="datetime-local"
                  p={0}
                  name="StartDate"
                  value={parseDate(regatta.EndDateLastRegatta).addMin(regatta.Duration).inputFull()}
                />
              </FormControl >
            </Flex>
            <Flex flex={1} flexDir="row" justifyContent="space-between">
              <FormControl w="49%" >
                <FormLabel >Creation Date</FormLabel>
                <Input
                  disabled
                  type="datetime-local"
                  p={0}
                  name="CreationDate"
                  value={parseDate(regatta.CreationDate).inputFull()}
                />
              </FormControl >
              <FormControl w="50%" >
                <FormLabel >Regatta End</FormLabel>
                <Input
                  disabled
                  type="datetime-local"
                  p={0}
                  name="RealEndDate"
                  value={getRegattaRealEndDate(regatta).inputFull()}
                />
              </FormControl>
            </Flex>
          </Flex>
          <FormControl as="fieldset" p="1rem" border="groove">
            <FormLabel as="legend" w="auto" p="0.2rem">Definition</FormLabel>
          <RegattaDefinitionForm disabled hideStartDate regattadefinitionData={definition}  />
          </FormControl>
          <br/>
          <Flex justifyContent="space-between">
          <Flex>
          <Button
            mr="1rem"
            variant="outline"
            variantColor="lumenPrimary"
            onClick={onCancel}
          >
            Close
          </Button>
          <Button
              as={Link}
              to={`/acp/regatta/${regatta.Id}`}
              variantColor="lumenPrimary"
            >
              Details
            </Button>
          </Flex>
          {!regatta.IsDeleted && !isRegattaFinished(regatta) && <Button
            variant="solid"
            variantColor="red"
            onClick={() => onDelete(regatta)}
          >
            Delete
          </Button>}
          </Flex>
        </>
      )}
    </ModalBody>
  </Modal>
  );
}

export default RegattaModal;