import React from "react";
import { Modal, ModalHeader } from "reactstrap";
import UserForm from "./UserForm";
import PropTypes from "prop-types";

const UserModal = ({
  handleModalToggle,
  isOpen,
  defaultRole,
  populateRolesSelect,
  sendUser,
  onClose,
  ...user
}) => {
  const handleClose = () => {
    onClose();
    handleModalToggle();
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={handleClose}
      className="modal-dialog-centered"
    >
      <ModalHeader>{user.Id ? "Update User" : "Create a new User"}</ModalHeader>
      <div className="modal-body">
        <UserForm
          roles={populateRolesSelect()}
          defaultRoleId={defaultRole}
          onSubmit={sendUser}
          onCancel={handleClose}
          {...user}
        />
      </div>
    </Modal>
  );
};

UserModal.propTypes = {
  handleModalToggle: PropTypes.func,
  handleUserCreation: PropTypes.func,
  isOpen: PropTypes.bool,
  defaultRole: PropTypes.number,
  populateRolesSelect: PropTypes.func,
  sendUser: PropTypes.func,
  onClose: PropTypes.func,
};

export default UserModal;
