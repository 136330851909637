import React from "react";
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import authHeader from "../../helpers/auth-header.js";
import SnapshotCreator from "../Snapshot/SnapshotCreator.jsx";
import Swal from "sweetalert2";
import httpClient from "../../helpers/http-client-singleton";
import DebuggerModal from "./DebuggerModal";
import MessageModal from "../Messages/MessageModal";
import { SOCIAL_MEDIAS } from "../../constants/social-medias";
import { stringifySlug } from "../../helpers/slugify";
import { userService } from "../../services/user.service.js";

const http = httpClient.getInstance();


export default class PlayerOptionsDropdown extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      updated: false,
      showPopup: false,
      PopupType: "",
      isLoadingDelete: false,
      debuggerOpen: false,
      loadingDebuggerModal: false,
    };
    this.notificationAlert = React.createRef();

    this.setLocalPersistencePlayerOutdated = this.setLocalPersistencePlayerOutdated.bind(
      this
    );
    this.deleteTimerTrunk = this.deleteTimerTrunk.bind(this);
    this.openPopupHandler = this.openPopupHandler.bind(this);
    this.closePopupHandler = this.closePopupHandler.bind(this);
  }

  toggleDebuggerModal = () =>
    this.setState({ debuggerModal: !this.state.debuggerModal });

  deletePlayer = () => {
    const playerId = (this.props.player && this.props.player.PlayerId) || this.props.playerId;
    http
      .delete(`/players/${playerId}`, {
        headers: {
          ...authHeader()
        },
      })
      .then(() => this.props.updateFunc());
  };

  sendMessage = (message) => {
    http
      .put(
        "/messages/player/",
        {
          ...message,
          PlayerId: this.props?.player?.PlayerId || this.props.playerId,
        },
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status) {
          Swal.fire({
            type: "success",
            title: "Success",
            text: "Message Sent!",
          });
          this.props.updateFunc();
        } else {
          Swal.fire({
            type: "error",
            title: "Error",
            text: "Please insert a valid information and try again.",
          });
        }
      });
  };

  onLastEventIdChange = () => {
    Swal.fire({
      type: "question",
      title: "Type the new Last Event ID",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: (eventId) =>
        this.setLastEventId(eventId, () => {
          Swal.showValidationMessage(
            "An error occurred when changing the event id. Are you sure this event exists?"
          );
        }),
      allowOutsideClick: () => !Swal.isLoading(),
    }).then((result) => {
      if (!result.dismiss)
        Swal.fire({
          title: "Last Event ID changed successfully!",
          icon: "success",
        });
    });
  };

  getPlayer = (playerId) => {
    return http
      .get(`/players/${playerId}`)
      .then((response) => response.data.data.Player);
  };

  setLastEventId = (eventId, onError) => {
    const playerId =
      (this.props.player && this.props.player.PlayerId) || this.props.playerId;
    return this.getPlayer(playerId).then((player) => {
      const newPlayer = { ...player, LastEventId: eventId || null };
      return http
        .put(`/players/${playerId}/updateplayer`, newPlayer, { headers: authHeader()})
        .then(this.props.updateFunc)
        .catch(onError);
    });
  };

  deleteDebuggerKey() {
    if (this.props.debugger !== null) {

      const playerId =  (this.props.player && this.props.player.PlayerId) ||
      this.props.playerId;

      http
        .delete(
          `/players/${playerId}/debugger/`,
          {
            headers: authHeader(),
          }
        )
        .then(() => this.props.updateFunc());
    }
  }

  onDeletePlayerClick = () => {
    Swal.fire({
      title: "Delete Player",
      text:
        "Are you sure you want to delete this player? This action can't be undone!",
      showCancelButton: true,
      confirmButtonText: "Submit",
      showLoaderOnConfirm: true,
      preConfirm: this.deletePlayer,
      allowOutsideClick: () => !Swal.isLoading(),
    })
      .then((result) => {
        if (!result.dismiss)
          Swal.fire({
            title: `Player deleted successfully!`,
            icon: "success",
          });
      })
      .catch(() => {
        Swal.fire({
          title: "Couldn't delete the player.",
          icon: "error",
        });
      });
  };

  setDebuggerKey = (timeToLive) => {
    this.setState({ loadingDebuggerModal: true });
    const playerId = (this.props.player && this.props.player.PlayerId) ||
    this.props.playerId;
    http
      .put(`/players/${playerId}/debugger/`, null, {
        headers: authHeader(),
        params: {
          timeToLive,
        },
      })
      .then(() => {
        this.toggleDebuggerModal();
        Swal.fire("Success", "Debugger Key set successfully!", "success").then(
          this.props.updateFunc
        );
      })
      .finally(() => {
        this.setState({ loadingDebuggerModal: false });
      });
  };

  setLocalPersistencePlayerOutdated() {

    const playerId  = (this.props.player && this.props.player.PlayerId) || this.props.playerId;

    http
      .put(`/players/${playerId}/persistence/outdated/`, null, {
        headers: authHeader(),
      })
      .then((response) => {
        if (response.data.success) {
          this.notify();
        }
        this.props.updateFunc();
      });
  }
  setPlayerAsHacker(isHacker) {
    const PlayerId = (this.props?.player?.PlayerId) || this.props.playerId;
    http
        .put(`/hackers/${PlayerId}`, null, {
          headers: { ...authHeader() },
          params: { isHacker },
        })
      .then(() => {
        this.props.updateFunc();
      });
  }

  deleteTimerTrunk() {
    Swal.fire({
      type: "question",
      title: 'Enter Timer Trunk id (* to delete all)',
      input: 'text',
      inputLabel: 'Timer_Trunk_Id',
      showCancelButton: true,
      inputValidator: (value) => {
        if (!value) {
          return 'You must write something!'
        }
      }
    }).then(({value,dismiss}) => {
      if(dismiss) return;
      
      const playerId = (this.props.player && this.props.player.PlayerId) ||
      this.props.playerId;

      http.delete(`players/${playerId}/attributes/${value}`, { headers: authHeader() }).then(() => {
        Swal.fire({
          type: "success",
          title: "Success",
          text: "Timer Trunk Removed!",
        })
      }).catch(() => {
        Swal.fire({
          title: "An error occurred.",
        });
      });
    })
  }

  sendMessage = (message) => {
    const playerId = (this.props?.player?.PlayerId) || this.props.playerId;
    http
      .put(
        `/messages/player/${playerId}`,
        message,
        {
          headers: authHeader(),
        }
      )
      .then((response) => {
        if (response.status) {
          Swal.fire({
            type: "success",
            title: "Success",
            text: "Message Sent!",
          });
          this.props.updateFunc();
        } else {
          Swal.fire({
            type: "error",
            title: "Error",
            text: "Please insert a valid information and try again.",
          });
        }
      });
  };

  notify() {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
          </span>
          <span data-notify="message">Player successfully updated</span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 4,
    };
    this.notificationAlert.current.notificationAlert(options);
  }

  notifySnapshot = () => {
    let options = {
      place: "tr",
      message: (
        <div className="alert-text">
          <span className="alert-title" data-notify="title">
            {" "}
          </span>
          <span data-notify="message">Snapshot Loaded!</span>
        </div>
      ),
      type: "success",
      icon: "ni ni-bell-55",
      autoDismiss: 3,
    };
    this.notificationAlert.current.notificationAlert(options);
  };

  openPopupHandler(Type) {
    this.setState({
      showPopup: true,
      PopupType: Type,
    });
  }

  closePopupHandler() {
    this.setState({
      showPopup: false,
    });
  }

  handleResetCache() {
    Swal.fire({
      title: "Are you sure?",
      text: "Player device cache will be invalidated!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        //Invalidating the player device cache
        const playerId =  (this.props.player && this.props.player.PlayerId) ||
        this.props.playerId;

        this.setState({ isLoadingCache: true });
        http
          .put(`/players/${playerId}/device/cache/invalid`, null, {
            headers: authHeader(),
          })
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                type: "success",
                title: "Success",
                text: "The device cache has been invalidated.",
                onClose: this.props.updateFunc,
              });
            } else {
              Swal.fire({
                type: "error",
                title: "Error",
                text: "The system was not able to reset the player progress.",
              });
            }
          })
          .then(() => this.setState({ isLoadingCache: false }));
      }
    });
  }

  handleResetPlayer = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "Player progress will be reseted!",
      type: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes",
    }).then((result) => {
      if (result.value) {
        //Creating a snapshot backup and deleting the player persistence

        this.setState({ isLoadingDelete: true });

        http
          .delete(
            `/persistences/${
              (this.props.player && this.props.player.PlayerId) ||
              this.props.playerId
            }`,
            {
              headers: {
                ...authHeader(),
              },
            }
          )
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                type: "success",
                title: "Success",
                text: "Player has been reseted.",
              });
              //Updating local persistence outdated to true and reloading the page
              this.props.updateFunc();
              //if (this.props.player !== undefined) {
              //  this.setLocalPersistencePlayerOutdated();
              //}
            } else {
              Swal.fire({
                type: "error",
                title: "Error",
                text: "The system was not able to reset the player progress.",
              });
            }
          })
          .then(() => this.setState({ isLoadingDelete: false }));
      }
    });
  };

  getSocialMediaNames = (userSocialMedias) => {
    const mediaNames = {};

    userSocialMedias.forEach((userSocialMedia) => {
      const socialMediaName = Object.keys(SOCIAL_MEDIAS).find(
        (socialMedia) => SOCIAL_MEDIAS[socialMedia] === userSocialMedia.Platform
      );
      if (socialMediaName) {
        mediaNames[userSocialMedia.Id] = stringifySlug(socialMediaName);
      }
    });
    return mediaNames;
  };

  getSocialMediaIdsToConnect = (userSocialMedias) => {
    const connectedSocialMedias = userSocialMedias.map(
      (socialMedia) => socialMedia.Platform
    );
    return Object.values(SOCIAL_MEDIAS).filter(
      (socialMedia) => !connectedSocialMedias.includes(socialMedia)
    );
  };

  disconnectFromSocialMedia = (
    socialMedia,
    onSuccess = () => {},
    onError = () => {}
  ) => {
    http
      .delete("/social-medias/disconnect", {
        headers: {
          ...authHeader(),
        },
        data: socialMedia,
      })
      .then(onSuccess)
      .catch(onError);
  };

  connectToSocialMedia = (
    socialMedia,
    onSuccess = () => {},
    onError = () => {}
  ) => {
    const playerId = (this.props.player && this.props.player.PlayerId) ||
    this.props.playerId;

    http
      .post(`/players/${playerId}/social-medias/connect`, socialMedia, {
        headers: authHeader(),
      })
      .then(onSuccess)
      .catch(onError);
  };

  getSocialMediaById = (socialMedias, socialMediaId) => {
    const media = socialMedias.find(
      (socialMedia) => socialMedia.Id === socialMediaId
    );
    return { id: socialMediaId, platform: media?.Platform };
  };

  onSocialMediaDisconnect = (socialMedias, socialMediaId) => {
    this.disconnectFromSocialMedia(
      this.getSocialMediaById(socialMedias, socialMediaId),
      this.props.updateFunc
    );
  };

  onSocialMediaConnect = (userSocialMediaId, socialMediaId) => {
    this.connectToSocialMedia(
      {
        id: userSocialMediaId,
        platform: socialMediaId,
      },
      this.props.updateFunc
    );
  };

  showDisconnectFromSocialMediaModal = (socialMedias) => {
    Swal.fire({
      title: "Disconnect Social Media",
      input: "select",
      inputOptions: this.getSocialMediaNames(socialMedias),
      inputPlaceholder: "Select a social media",
      focusConfirm: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Disconnect",
      showLoaderOnConfirm: true,
      preConfirm: (socialMedia) => {
        this.onSocialMediaDisconnect(socialMedias, socialMedia);
      },
    });
  };
  getSocialMediaOptions = (socialMedias) => {
    const socialMediasToConnect = this.getSocialMediaIdsToConnect(socialMedias);
    return socialMediasToConnect.map((socialMediaId) => {
      const socialMedia = Object.keys(SOCIAL_MEDIAS).find(
        (socialMediaName) => SOCIAL_MEDIAS[socialMediaName] === socialMediaId
      );

      return `<option value=${socialMediaId}>${stringifySlug(
        socialMedia
      )}</option>`;
    });
  };

  showConnectFromSocialMediaModal = (socialMedias) => {
    Swal.fire({
      title: "Connect Social Media",
      html:
        '<div class="input-group">' +
        `<select class="form-control" id="swal-input2">${this.getSocialMediaOptions(
          socialMedias
        )}</select>` +
        '<input class="form-control" placeholder="Social Media ID" id="swal-input1">' +
        "</div>",
      focusConfirm: false,
      showConfirmButton: true,
      showCancelButton: true,
      confirmButtonText: "Connect",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        this.onSocialMediaConnect(
          document.getElementById("swal-input1").value,
          document.getElementById("swal-input2").value
        );
      },
    });
  };

  LoadingDelete() {
    let timerInterval;
    Swal.fire({
      title: "Reseting...",
      timer: 10000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {}, 1);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    });
  }

  LoadingCache() {
    let timerInterval;
    Swal.fire({
      title: "Invalidating...",
      timer: 10000,
      onBeforeOpen: () => {
        Swal.showLoading();
        timerInterval = setInterval(() => {}, 1);
      },
      onClose: () => {
        clearInterval(timerInterval);
      },
    }).then((result) => {
      if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.timer
      ) {
      }
    });
  }

  render() {

    const CAN_RESET_PLAYER_PROGRESS = userService.hasPermission("RESET_PLAYER_PROGRESS");
    const CAN_UPDATE_PLAYER_PERSISTENCE = userService.hasPermission("DELETE_UPDATE_PLAYER_PERSISTENCE");
    const CAN_DELETE_TIMER_TRUNK = userService.hasPermission("CONTROL_TIMED_ATTRIBUTES");
    const CAN_SEND_MESSAGE = userService.hasPermission("SEND_MESSAGES");
    const CAN_CREATE_SNAPSHOT = userService.hasPermission("CREATE_SNAPSHOT");
    const CAN_DELETE_PLAYER = localStorage.getItem("env") !== "Production" && CAN_UPDATE_PLAYER_PERSISTENCE;

    return (
      <>
        <div className="rna-wrapper position-absolute">
          <NotificationAlert ref={this.notificationAlert} />
        </div>
        {this.state.isLoadingDelete ? this.LoadingDelete() : null}
        {this.state.isLoadingCache ? this.LoadingCache() : null}
        <UncontrolledDropdown direction="down">
          <DropdownToggle
            size={this.props.size}
            color="neutral"
            className={this.props.className}
          >
            {this.props.children}
          </DropdownToggle>
          <DropdownMenu className="player-dropdown dropdown-menu-arrow" positionFixed right>
            <DropdownItem disabled={!CAN_CREATE_SNAPSHOT} onClick={() => this.openPopupHandler("create")}>
              Create snapshot
            </DropdownItem>
            <DropdownItem onClick={() => this.openPopupHandler("load")}>
              Load Snapshot
            </DropdownItem>
            <div className="dropdown-divider" />
            <DropdownItem onClick={() => this.handleResetCache()}>
              Invalidate Device Cache
            </DropdownItem>
            <DropdownItem disabled={!CAN_SEND_MESSAGE} onClick={() => this.openPopupHandler("playerGift")}>
              Send Message
            </DropdownItem>
            <div className="dropdown-divider" />
            <DropdownItem onClick={(e) => e.preventDefault()} disabled>
              Set season
            </DropdownItem>
            <DropdownItem onClick={(e) => e.preventDefault()} disabled>
              Reset leaderboard
            </DropdownItem>
            <DropdownItem disabled={!CAN_DELETE_TIMER_TRUNK} onClick={this.deleteTimerTrunk}>
              Delete timer trunk
            </DropdownItem>
            <div className="dropdown-divider" />
            <DropdownItem onClick={this.onLastEventIdChange}>
              Set Last Event ID
            </DropdownItem>
            {this.props.debugger !== undefined ||
            this.props.player !== undefined ? (
              <>
                {this.props.debugger === null ? (
                  <DropdownItem onClick={this.toggleDebuggerModal}>
                    Set debugger key
                  </DropdownItem>
                ) : (
                  <>
                    <DropdownItem onClick={this.toggleDebuggerModal}>
                      Increment debugger duration
                    </DropdownItem>
                    <DropdownItem
                      onClick={() =>
                        Swal.fire({
                          title: "Confirmation",
                          text: "Are you sure to delete this key?",
                          type: "question",
                          showConfirmationButton: true,
                          showCancelButton: true,
                        }).then((result) => {
                          if (result.value) {
                            this.deleteDebuggerKey();
                          }
                        })
                      }
                    >
                      Delete debugger key
                    </DropdownItem>
                  </>
                )}
              </>
            ) : null}
            {this.props.socialMedias && (
              <>
                <div className="dropdown-divider" />
                <DropdownItem
                  onClick={() =>
                    this.showDisconnectFromSocialMediaModal(
                      this.props.socialMedias
                    )
                  }
                >
                  Disconnect Social Media
                </DropdownItem>
                <DropdownItem
                  onClick={() =>
                    this.showConnectFromSocialMediaModal(
                      this.props.socialMedias
                    )
                  }
                >
                  Connect Social Media
                </DropdownItem>
              </>
            )}
            <div className="dropdown-divider" />
            <DropdownItem onClick={this.setLocalPersistencePlayerOutdated}>
              Invalidate local persistence
            </DropdownItem>

            {this.props.isHacker !== undefined ||
            this.props.player !== undefined ? (
              <>
                <div className="dropdown-divider" />
                <DropdownItem
                  onClick={() => this.setPlayerAsHacker(true)}
                  disabled={this.props.player && this.props.player.IsHacker}
                >
                  Set Player as Hacker
                </DropdownItem>
                <DropdownItem
                  onClick={() => this.setPlayerAsHacker(false)}
                  disabled={!(this.props.player && this.props.player.IsHacker)}
                >
                  Unset Player as Hacker
                </DropdownItem>
              </>
            ) : null}
            <div className="dropdown-divider" />
            <DropdownItem disabled={!CAN_RESET_PLAYER_PROGRESS} onClick={() => this.handleResetPlayer()}>
              Reset Player Progress
            </DropdownItem>
            <DropdownItem
              onClick={this.onDeletePlayerClick}
              disabled={!CAN_DELETE_PLAYER}
            >
              Delete player
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
        <DebuggerModal
          open={this.state.debuggerModal}
          toggle={this.toggleDebuggerModal}
          onSubmit={this.setDebuggerKey}
          loading={this.state.loadingDebuggerModal}
        />
        {this.state.showPopup &&
        (this.state.PopupType === "load" ||
          this.state.PopupType === "create") ? (
          <SnapshotCreator
            modal={this.state.showPopup}
            snapshotType={this.state.PopupType}
            updateFunc={this.props.updateFunc}
            notifySnapshot={() => this.notifySnapshot}
            notify={this.props.notify}
            close={this.closePopupHandler}
            playerId={
              (this.props.player && this.props.player["PlayerId"]) ||
              this.props.playerId
            }
          />
        ) : null}
        {this.state.showPopup && this.state.PopupType === "playerGift" && (
          <MessageModal
            open={this.state.showPopup}
            onSubmit={this.sendMessage}
            playerId={
              (this.props.player && this.props.player["PlayerId"]) ||
              this.props.playerId
            }
            toggle={this.closePopupHandler}
          />
        )}
      </>
    );
  }
}
