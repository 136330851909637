import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  FormGroup,
  FormText,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Col,
  FormFeedback,
  UncontrolledTooltip,
  ModalHeader,
} from "reactstrap";

import LoadingContainer from "../Layout/LoadingContainer";
import { useForm } from "react-hook-form";
import "../common/css/tooltipStyles.css";
import "./css/eventModalStyles.css";
import classnames from "classnames";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Switch,
} from "@chakra-ui/core";
import { parseDate } from "../../helpers/date-helper";

const defaultAttributes = [
  "currency_amount",
  "boost_milliseconds_remaining",
  "currency_per_second",
  "attributes_timestamp",
];

/**@typedef {import("../common/DateTimeComponent").SwDate} SwDate */

const EventModal = (props) => {
  const [id, setId] = useState(props.Id);
  const [text, setText] = useState(props.content);
  /** @type {[SwDate,React.Dispatch<SwDate>]} */
  const [startDate, setStartDate] = useState(parseDate(props.StartDate));
  const [duration, setDuration] = useState(props.duration);
  /** @type {[object[], React.Dispatch<object[]>]} */
  const [leaderboards, setLeaderboards] = useState(props.leaderboards);
  const [eventProfile, setEventProfile] = useState(
    props.profileId || props.defaultProfile
  );

  const selectedProfile =
    props.profiles.find((x) => x.Id === Number.parseInt(eventProfile)) || {};

  const { handleSubmit, register, errors } = useForm({
    reValidateMode: "onBlur",
  });

  useEffect(() => {
    setId(props.Id);
    setText(props.Content);
    setStartDate(parseDate(props.StartDate));
    setDuration(props.Duration);
    setLeaderboards(props.leaderboards);
    setEventProfile(props.ProfileId || props.defaultProfile);
  }, [props]);

  const handleClose = () => {
    setDuration(null);
    setStartDate(null);
    setText(null);
    setEventProfile(null);
    setLeaderboards([]);
    props.toggleModalOpen();
  };

  const submitEvent = () => {
    props.setModalLoading(true);
    let formattedLeaderboards = [];
    // Only add leaderboards to be sent to the server if the it's a new event
      if (!id) {
      formattedLeaderboards = leaderboards.map((leaderboard) => ({
        ...leaderboard,
        AttributeNames: Array.isArray(leaderboard.AttributeNames)
          ? leaderboard.AttributeNames
          : leaderboard.AttributeNames.replace(/[\s\r\n]+/g, "")
            .split(",")
            .filter((attribute) => attribute !== ""),
      }));
    }
    const date = startDate.toUTC().toDate().toISOString();

    const resBody = {
      Id: id,
      Content: text,
      StartDate: date,
      Duration: duration,
      ProfileId: eventProfile,
      Deleted: props.Deleted,
    };
    //Only send leaderboards if the event doesn't exist yet.
    props.sendEvent(
      {
        body: resBody,
        thenCb: handleClose,
        finallyCb: () => props.setModalLoading(false),
      },
      formattedLeaderboards
    );
  };

  const handleLeaderboardAdd = (obj, index) => {
    // Creates a new array with a new leaderboard at [ìndex]
    const newLeaderboards = leaderboards.map((l, i) => i !== index
      ? l
      : { ...l, ...obj })
    setLeaderboards(newLeaderboards);
  };

  const addLeaderboard = () => {
    setLeaderboards([
      ...leaderboards,
      {
        Name: "",
        AttributeNames: defaultAttributes,
        Capacity: null,
        AutoJoin: true,
      },
    ]);
  };

  const removeLeaderboard = (index) => {
    const newLeaderboards = leaderboards.slice();
    newLeaderboards.splice(index, 1);
    setLeaderboards(newLeaderboards);
  };

  const modalBody = props.loading ? (
    <LoadingContainer />
  ) : (
    <>
      <div
        className="modal-body"
        style={{ maxHeight: "75vh", overflowY: "auto" }}
      >
        <form className="new-event--form">
          <FormGroup>
            <label className="form-control-label">Event text</label>
            <Input
              name="event_name"
              className="new-event--text"
              placeholder="Event text"
              type="text"
              value={text}
              /* invalid={errors.event_name} */
              onChange={(e) => setText(e.target.value.trim())}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Start Time</label>
                <Input
                  type="time"
                  name="time"
                    min="00:00"
                  max="23:59"
                  value={startDate.time()}
                  disabled={!!id && startDate.isBefore(new Date())}
                  onChange={(e) =>
                    setStartDate(startDate.setTime(e.target.value))
                  }
                />
                <FormText>You should type: hh:mm</FormText>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <label className="form-control-label">Event Duration</label>
                <Input
                  innerRef={register({
                    validate: (value) =>
                      value > 0 || "Must be a value greater than zero.",
                  })}
                  name="duration"
                  placeholder="Duration of an Event"
                  min={0}
                  max={500}
                  type="number"
                  step="1"
                  required
                  invalid={errors.duration}
                  value={duration}
                  disabled={
                    !!id && startDate.addHour(duration).isBefore(new Date())
                  }
                  onChange={(e) => setDuration(Number(e.target.value))}
                />
                {errors.duration && (
                  <FormFeedback>{errors.duration.message}</FormFeedback>
                )}
                <FormText>Duration in hours</FormText>
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <label className="form-control-label">Start Date</label>
            <InputGroup className="input-group-alternative">
              <InputGroupAddon addonType="prepend">
                <InputGroupText>
                  <i className="ni ni-calendar-grid-58" />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                type="date"
                name="date"
                id="exampleDate"
                value={startDate?.inputDate()}
                placeholder="Event's start date"
                disabled={!!id && startDate.isBefore(new Date())}
                onChange={(e) => {
                  const date = parseDate(e.target.value, true, true).setTime(
                    startDate.time()
                  );
                  setStartDate(date);
                }}
              />
            </InputGroup>
          </FormGroup>
          <FormControl as="fieldset" p="1rem" border="groove">
            <FormLabel as="legend" w="auto" p="0.2rem">
              Scaling Rule
            </FormLabel>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label">Event Profile</label>
                  <Input
                    type="select"
                    onChange={(e) => setEventProfile(e.target.value)}
                    value={eventProfile}
                    disabled={!!id}
                    required="required"
                  >
                    {props.profiles
                      ? props.profiles
                        .filter(
                          (x) =>
                            (x.Id === eventProfile && id) || !x.Deregistered
                        )
                        .map((profile) => {
                          return (
                            <option
                              defaultValue={eventProfile}
                              value={profile.Id}
                              key={profile.Id}
                            >
                              {profile.Deregistered ? "[Removed]" : ""}{" "}
                              {profile.Name}
                            </option>
                          );
                        })
                      : null}
                  </Input>
                  <FormText>
                    After saved the task count and offset can't be changed. (if
                    you need to, please create a new event)
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label">
                    Target Task Count
                  </label>
                  <Input value={selectedProfile.TaskCount} readOnly />
                  <FormText>Extra Tasks to be Created</FormText>
                </FormGroup>
              </Col>
              <Col md={6}>
                <FormGroup>
                  <label className="form-control-label">
                    Task StartUp Offset
                  </label>
                  <Input value={selectedProfile.Offset} readOnly />
                  <FormText>Time Offset in Minutes</FormText>
                </FormGroup>
              </Col>
            </Row>
          </FormControl>
          <>
            <label className="form-control-label">Leaderboards</label>
            {leaderboards.map((leaderboard, index) => (
              <Row key={(leaderboard.Id || index) + "ld"} className={classnames({ ["border-top pt-3"]: index !== 0 })}>
                <Col lg={8} xs={12}>
                  <FormGroup>
                    <label className="form-control-label">Name</label>
                    <Input
                      key={"leaderboard-name-" + index}
                      onChange={(e) =>
                        handleLeaderboardAdd(
                          {
                            Name: e.target.value,
                          },
                          index
                        )
                      }
                      value={leaderboard.Name}
                      disabled={!!id}
                      type="text"
                      name={"leaderboard-name-" + index}
                      placeholder="Name"
                      invalid={errors["leaderboard-name-" + index]}
                      innerRef={register({
                        required: true,
                      })}
                    />
                    {errors["leaderboard-name-" + index] && (
                      <FormFeedback>This field can't be empty</FormFeedback>
                    )}
                    {index === leaderboards.length - 1 && (
                      <FormText>The Leaderboard Definition Name</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col lg={3} xs={12}>
                  <FormGroup>
                    <label className="form-control-label">Capacity</label>
                    {index === 0 && (
                      <>
                        <i
                          id={"Capacity" + index}
                          className="fas fa-info-circle info-tooltip "
                        />
                        <UncontrolledTooltip
                          className="text-muted"
                          placement="top"
                          target={"Capacity" + index}
                        >
                          This value represents how many players can join in a
                          group. When a group reaches the max Capacity the
                          server will create a new group to allocate incoming
                          players.
                        </UncontrolledTooltip>
                      </>
                    )}
                    <Input
                      key={"Capacity" + index}
                      onChange={(e) =>
                        handleLeaderboardAdd(
                          {
                            Capacity: Number(e.target.value) || null,
                          },
                          index
                        )
                      }
                      value={leaderboard.Capacity}
                      type="number"
                      disabled={!!id}
                      name={"Capacity" + index}
                      invalid={errors["Capacity" + index]}
                      placeholder="Capacity"
                      innerRef={register({
                        validate: (value) => {
                          return (
                            value === null ||
                            value === "" ||
                            value > 0 ||
                            "This field must be greater than 0"
                          );
                        },
                      })}
                    />
                    {errors["Capacity" + index] && (
                      <FormFeedback>
                        {errors["Capacity" + index].message}
                      </FormFeedback>
                    )}
                    {index === leaderboards.length - 1 && (
                      <FormText>How many players can join a group</FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12} lg={1}>
                  <button
                    disabled={!!id}
                    onClick={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      removeLeaderboard(index);
                    }}
                  >
                    <i className="fas fa-minus border rounded-circle py-1 minus-icon btn-outline-primary" />
                  </button>
                </Col>
                <Col xs={12}>
                  <FormGroup>
                    <label className="form-control-label">Attributes</label>
                    {index === 0 && (
                      <>
                        <i
                          id={"attribute-name-label-" + index}
                          className="fas fa-info-circle info-tooltip "
                        />
                        <UncontrolledTooltip
                          className="text-muted"
                          placement="top"
                          target={"attribute-name-label-" + index}
                        >
                          This list represents the attributes that will be used
                          to calculate the leaderboard positions.
                        </UncontrolledTooltip>
                      </>
                    )}
                    <Input
                      key={"attribute-name-" + index}
                      onChange={(e) =>
                        handleLeaderboardAdd(
                          {
                            AttributeNames: e.target.value,
                          },
                          index
                        )
                      }
                      value={leaderboard.AttributeNames}
                      type="textarea"
                      disabled={!!id}
                      name={"attribute-name-" + index}
                      placeholder="attr1,attr2,..."
                      invalid={errors["attribute-name-" + index]}
                      innerRef={register({
                        required: true,
                      })}
                    />
                    {errors["attribute-name-" + index] && (
                      <FormFeedback>This field can't be empty</FormFeedback>
                    )}
                    {index === leaderboards.length - 1 && (
                      <FormText>
                        A list of attributes separated by comma.
                        {
                          "\n Each attribute will be saved using the following format: {event_text}.{event_edition}.{attribute_name}"
                        }
                      </FormText>
                    )}
                  </FormGroup>
                </Col>
                <Col xs={12}>
                  <FormControl>
                    <FormLabel>Auto Join</FormLabel>
                    <Switch
                      key={"auto-join-" + index}
                      name={"auto-join-" + index}
                      value={leaderboard.AutoJoin !== false}
                      isChecked={leaderboard.AutoJoin !== false}
                      isDisabled={!!id}
                      onChange={(e) =>
                        handleLeaderboardAdd(
                          {
                            AutoJoin: Boolean(e.target.checked),
                          },
                          index
                        )
                      }
                    />
                    <FormHelperText>
                      Turn off this option if the player shouldn't join
                      automatically a leaderboard
                    </FormHelperText>
                  </FormControl>
                </Col>
              </Row>
            ))}
          </>
          <div className="d-block">
            <Button
              disabled={!!id}
              className="pb-0"
              size="sm"
              color="primary"
              onClick={addLeaderboard}
            >
              <i className="ni ni-2x ni-fat-add" />
            </Button>
          </div>
        </form>
      </div>
      <div className="modal-footer">
        {id ? (
          <Button color="primary" onClick={submitEvent}>
            Update
          </Button>
        ) : (
          <Button
            className="new-event--add"
            color="primary"
            type="button"
            disabled={!(text && duration)}
            onClick={handleSubmit(submitEvent)}
          >
            Add event
          </Button>
        )}

        <Button
          className="ml-auto"
          color="link"
          type="button"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </>
  );

  return (
    <Modal
      isOpen={props.modalOpen}
      toggle={handleClose}
      className="modal-dialog-centered"
      size="lg"
    >
      <ModalHeader>
        <span>
          {id && startDate?.addHour(duration).isBefore(new Date())
            ? "[Already Finished]  "
            : ""}
          {props.Content}
        </span>
      </ModalHeader>
      {modalBody}
    </Modal>
  );
};

export default EventModal;
