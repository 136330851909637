import React, { useState } from "react";
import BatchUpdateComponent from "../components/Player/BatchUpdate/PlayerBatchUpdateComponent";
import httpClientCreator from "../helpers/http-client-singleton";

const http = httpClientCreator.getInstance();

const BatchPlayerUpdate = () => {
  const [loading, setLoading] = useState(false);


  const onUpdatePlayers = (playerList, asHacker) => {
    setLoading(true);
    const request = { Ids: playerList };
    return http
      .post(`hackers/${asHacker ? "ban" : "unban"}`, request)
      .finally(() => setLoading(false));
  };

  return (
    <BatchUpdateComponent
      onSubmit={onUpdatePlayers}
      loading={loading}
    />
  );
};

export default BatchPlayerUpdate;
