import React, { useEffect, useState, useCallback } from "react";
import {
  Stack,
  FormLabel,
} from "@chakra-ui/core";
import LeaderboardAttributeInput from "./LeaderboardAttributeInput";

const LeaderboardMockForm = ({
  leaderboard,
  onChange,
  name,
  errors,
  register,
}) => {
  const [attributes, setAttributes] = useState({});

  const onAttributeChange = useCallback(
    ({min,max, attribute}) => {
      const newAttributes = { ...attributes };
      if(!newAttributes[attribute]) newAttributes[attribute] = []
      if(min) newAttributes[attribute][0] = min;
      if(max) newAttributes[attribute][1] = max;
      setAttributes(newAttributes);
    },
    [attributes]
  );

  useEffect(() => {
    onChange({ attributes });
  }, [attributes, onChange]);

  //This creates an attribute: [minValue, maxValue] key/pair for each attribute
  useEffect(() => {
    const newAttributes = {};
    leaderboard.AttributeNames.forEach((attribute) => {
      // Form starts with min and max values set as zero
      newAttributes[attribute] = [0, 0];
    });
    setAttributes(newAttributes);
  }, [leaderboard]);

  return (
    <Stack spacing={2} backgroundColor="#fff" fontFamily="Open Sans">
      <FormLabel marginBottom={4} fontWeight={600}>
        Attributes
      </FormLabel>
      <Stack spacing={4}>
        {leaderboard.AttributeNames.map((attribute, index) => {
          return (
            <LeaderboardAttributeInput
              key={"attr" + index}
              attribute={attribute}
              name={`${name}-input-${index}`}
              register={register}
              errors={errors}
              values={attributes[attribute] || [0,0]}
              onChange={(newValues) =>{onAttributeChange({...newValues, attribute})}
              }
            />
          );
        })}
      </Stack>
    </Stack>
  );
};

export default LeaderboardMockForm;
