import axios from "axios";

export default class httpClientCreator {
  static instance = null;
  _baseUrl = "";
  

  /**
   * @returns {import('axios').AxiosInstance} */
  static getInstance() {
    if (httpClientCreator.instance === null)
      httpClientCreator.instance = axios.create();
    return this.instance;
  }

  /**
 * @returns {import('axios').AxiosInstance} */
  static create() {
    return axios.create();
  }

  static setEnvHeaders(environment) {
    httpClientCreator.instance.defaults.headers.common['Environment'] = environment;
  }

  static setVersionHeaders(version) {
    httpClientCreator.instance.defaults.headers.common['Version'] = version;
  }

  static setAuth(token) {
    httpClientCreator.instance.defaults.headers['Authorization'] = "Bearer " + token;
  }

  static setBaseUrl(BASE_URL) {
    httpClientCreator.instance.defaults.baseURL = BASE_URL;
  }

  get = (endpoint, config = {}) =>
    httpClientCreator.instance.get(endpoint, config);
  post = (endpoint, body = {}, config = {}) =>
    httpClientCreator.instance.post(endpoint, body, config);
  put = (endpoint, body = {}, config = {}) =>
    httpClientCreator.instance.put(endpoint, body, config);
  delete = (endpoint, config = {}) =>
    httpClientCreator.instance.delete(endpoint, config);
}
