import React, { useState } from "react";

import classnames from "classnames";
import {
  Card,
  CardHeader,
  Nav,
  NavLink,
  TabContent,
  NavItem,
} from "reactstrap";
import { userService } from "../../services/user.service";

const PlayerTabs = ({ children }) => {
  const [activeTab, toggleTab] = useState("1");

  return (
    <div>
      <Card id="player-tabs">
        <CardHeader className="p-0">
          <Nav tabs>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-first-tab", {
                  "active-player-tab": activeTab === "1",
                })}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "2",
                })}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Player Attributes
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "3",
                })}
                onClick={() => {
                  toggleTab("3");
                }}
              >
                Player Persistence
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "4",
                })}
                disabled={!userService.hasPermission("ACCESS_PURCHASES")}
                onClick={() => {
                  toggleTab("4");
                }}
              >
                Purchase History
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "5",
                })}
                onClick={() => {
                  toggleTab("5");
                }}
              >
                Messages
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "6",
                })}
                onClick={() => {
                  toggleTab("6");
                }}
              >
                Leaderboards
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames("nav-player-tabs", {
                  "active-player-tab": activeTab === "7",
                })}
                onClick={() => {
                  toggleTab("7");
                }}
              >
                Events
              </NavLink>
            </NavItem>
          </Nav>
        </CardHeader>
          <TabContent activeTab={activeTab}>
            { children }
          </TabContent>
      </Card>
    </div>
  );
};

export default PlayerTabs;
