import { Stack, Box } from "@chakra-ui/core";
import React from "react";
import PageContainer from "../common/PageContainer";
import RegattaDetailsGroupList from "./RegattaDetailsGroupList";
import RegattaDetailsHeader from "./RegattaDetailsHeader";


/** @typedef {import('../../views/Regattas').Regatta} Regatta */
/** @typedef {import("./RegattaDetailsGroupList").RegattaGroup} RegattaGroup */
/** @typedef {import("./RegattaGroupModal").RegattaGuild} RegattaGuild */

/**
 * @typedef {{
 * regatta: Regatta,
 * loading: boolean,
 * groups: RegattaGroup[],
 * onRemoveGuild: (g: RegattaGuild) => void,
 * onExportCsv: (guild?: string, regatta?: string) => void,
 * }} RegattaDetailsProps
 */

/** @type {React.FC<RegattaDetailsProps>} */
const RegattaDetailsComponent = ({regatta, groups,loading, onRemoveGuild, onExportCsv}) => {
  return (
    <PageContainer pageName="Regatta Details">
      <Stack position="relative">
        <Box>
          <RegattaDetailsHeader 
            regatta={regatta}  
            loading={loading}
            />
        </Box>
        <Box>
          <RegattaDetailsGroupList 
            groups={groups} 
            regatta={regatta}  
            onRemoveFromGroup={onRemoveGuild} 
            loading={loading}
            onExportCsv={onExportCsv}
          />
        </Box>
      </Stack>
    </PageContainer>
  );
}

export default RegattaDetailsComponent;
