import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import { Container, Row, Col } from "reactstrap";
import { headerStyle } from "../../helpers/environmentHeaderStyle.jsx";

const TimelineHeader = React.memo((props) => {
  return (
    <>
      <div className={headerStyle()}>
        <Container fluid>
          <div className="header-body">
            <Row className="align-items-center py-4">
              <Col xs={6}>
                <h6 className="fullcalendar-title h2 text-white d-inline-block mb-0">
                  {props.name}
                </h6>
              </Col>
              <Col xs={6}>{props.children}</Col>
            </Row>
          </div>
        </Container>
      </div>
    </>
  );
});

TimelineHeader.propTypes = {
  name: PropTypes.string,
  parentName: PropTypes.string,
};

export default TimelineHeader;
