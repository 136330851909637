export default function authHeader(content = null) {
  //define content-type in parameter
  // return authorization header with basic auth credentials
  let user = JSON.parse(localStorage.getItem("user"));
  if (content != null && user && user.Token) {
    return {
      Authorization: "Bearer " + user.Token,
      "Content-Type": content, //'application/json'
    };
  } else if (user && user.Token) {
    return { Authorization: "Bearer " + user.Token };
  } else {
    return {};
  }
}
