import React from "react";
import {
  Card,
  CardBody,
  CardImg,
  Col,
  Row,
  Container,
  Button,
} from "reactstrap";
import LoadingContainer from "../Layout/LoadingContainer";
import SimpleHeader from "../Headers/SimpleHeader";

import "./css/profileStyles.css";

const Profile = ({ user, loading, toggleModal, children }) => {

  const content = loading ? (
    <LoadingContainer />
  ) : (
    <>
      <Row className="justify-content-center">
        <Col className="order-lg-2 mb-4" lg="3">
          <div className="card-profile-image">
            <div>
              <img
                alt={user.FirstName}
                className="rounded-circle"
                src="http://www.gravatar.com/avatar/5e543256c480ac577d30f76f9120eb74"
              />
              <Button
                size="sm"
                className="rounded-circle edit-profile-btn border-0"
                onClick={toggleModal}
              >
                <i className="fas fa-edit" />
              </Button>
            </div>
          </div>
        </Col>
      </Row>
      <CardBody className="pt-3">
        <div className="text-center">
          <h5 className="h3">{`${user.FirstName} ${user.LastName}`}</h5>
          <div className="h5">
            <span className="text-muted">{user.Email}</span>
          </div>
          <div className="h5 mt-4">
            <i className="ni business_briefcase-24 mr-2" />
            {user.Role.Name} - Star Wars Console
          </div>
        </div>
      </CardBody>
    </>
  );
  return (
    <>
      <SimpleHeader name="My Profile" clean parentName="Dashboard" />
      <Container fluid className="mt--6">
        <Card className="card-profile">
          <CardImg
            className="img-fluid"
            alt="Profile Background"
            src={require("assets/img/profile_background.jpg")}
            top
          />
          {content}
        </Card>
      </Container>
      {children}
    </>
  );
};

export default Profile;
