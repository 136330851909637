import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Swal from "sweetalert2";
import authHeader from "../../helpers/auth-header.js";
import httpClient from "../../helpers/http-client-singleton";
import { userService } from "../../services/user.service.js";
import GiftMessageForm from "../Messages/GiftMessageForm";

const http = httpClient.getInstance();

/**@type {React.FC<{content: Message}>} */
const PlayerGiftModal = ({
  modal,
  update,
  className,
  content,
  close,
  global,
  playerId
}) => {
  const revokeGift = () => {
    Swal.fire({
      type: "warning",
      title: "Warning!",
      text: "This action will remove this message forever.",
      showCancelButton: true,
      confirmButtonText: "Revoke it",
      confirmButtonColor: "#f5365c",
    }).then((result) => {
      if (result.value) {
        if (!global) {
          http
            .delete("/messages/" + playerId + "/" + content.MsgId, {
              headers: authHeader(),
            })
            .then(() => {
              Swal.fire({
                type: "success",
                title: "Success",
                text: "Message Removed",
              }).then(() => {
                close();
                update();
              });
            })
            .catch(() => {
              Swal.fire({
                type: "error",
                title: "Error",
                text:
                  "There appears to be an error removing this gift, please try again.",
              }).then(() => {
                close();
                update();
              });
            });
        } else {
          http
            .delete("/messages/global/" + content.MsgId, {
              headers: authHeader(),
            })
            .then(() => {
              Swal.fire({
                type: "success",
                title: "Success",
                text: "Message Removed!",
              }).then(() => {
                close();
                update();
              });
            })
            .catch(() => {
              Swal.fire({
                type: "error",
                title: "Error",
                text:
                  "There appears to be an error removing this gift, please try again.",
              }).then(() => {
                close();
                update();
              });
            });
        }
      }
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={close} className={className} size="lg">
        <ModalHeader className="p-4" toggle={close}>
          {!global && "Global Message"}
        </ModalHeader>
        <hr className="m-0" />
        <ModalBody className="p-4 ">
          <GiftMessageForm readonly message={content} />
        </ModalBody>
        <ModalFooter className="p-4">
          <Button color="danger" disabled={!userService.hasPermission("SEND_MESSAGES")} onClick={revokeGift}>
            Revoke
          </Button>
          <Button color="secondary" onClick={close}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default PlayerGiftModal;
