import React, { useEffect, useRef, useState } from "react";

import { Button, Col, Row, Table } from "reactstrap";
// create classname components
import SimplePaginationComponent from "../common/SimplePaginationComponent.jsx";
import DateTimeComponent from "../common/DateTimeComponent.jsx";
import TableCard from "../Layout/TableCard.jsx";
import EventModal from "./EventModal.jsx";
import ConfirmDeleteModal from "./ConfirmDeleteModal.jsx";
import PaginationHeader from "../common/PaginationHeader.jsx";
import LeaderboardsMockModal from "./LeaderboardsMockModal";
import NotificationAlert from "react-notification-alert";
import { userService } from "../../services/user.service.js";
import { parseDate } from "../../helpers/date-helper.js";
import { Link } from "react-router-dom";
import ImportModal from "../common/ImportModal";
import {SplitCsvLine} from "../../helpers/csv";
import chunkArray from "../../helpers/chunk";

/** @type {(events: Record<string,any>[], id: number) => Record<string,any>} */
const getEventById = (events, id) => events.find((event) => event.Id === Number.parseInt(id));

/** @type {(count: number, noun: string, suffix?: string) => string} */
const pluralize = (count, noun, suffix = "s") => `${count} ${noun}${count !== 1 ? suffix : ""}`;

function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const EventsTable = ({
  selectedEventId,
  setSelectedEventId,
  events,
  loading,
  totalObjects,
  objectsPerPage,
  handleObjectsPerPage,
  currentPage,
  getPage,
  sendEvent,
  getEventLeaderboards,
  profiles,
  defaultProfileId,
  mockLeaderboards,
  deleteEvent,
  showDeleted,
  loadingMock,
  handleToggleChange,
  sendBatch,
  sendBatchDelete
}) => {
  const notificationRef = useRef();
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [openModalPopup, setOpenModalPopup] = useState(false);
  const [openDeleteModalPopup, setOpenDeleteModalPopup] = useState(false);
  const [eventLeaderboards, setEventLeaderboards] = useState([]);
  const [modalLoading, setModalLoading] = useState(false);
  const [leaderboardMockModalOpen, setLeaderboardMockModalOpen] = useState(false);
  const [selectedEventLeaderboards, setSelectedEventLeaderboards] = useState([]);

  const [deleteTarget, setDeleteTarget] = useState(undefined);
  const handleDeleteClick = (id) => setDeleteTarget(id);

  const CAN_CREATE_EVENT = userService.hasPermission("CREATE_EVENTS");
  const CAN_MODIFY_EVENT = userService.hasPermission("DELETE_UPDATE_EVENTS");

  useEffect(() => {
    if (!selectedEventId) {
      setSelectedEvent(null);
      setModalLoading(false);
      setEventLeaderboards([]);
      return;
    }

    const event = getEventById(events, selectedEventId);
    setModalLoading(true);
    setSelectedEvent(event);
    getEventLeaderboards(selectedEventId)
      .then(setEventLeaderboards)
      .finally(() => setModalLoading(false));
  }, [events, getEventLeaderboards, selectedEventId])


  const handleCloneClick = (id) => {
    setModalLoading(true);
    setSelectedEvent({ ...getEventById(events, id), Id: undefined, StartDate: parseDate(undefined, false).toUTC().addMin(1).inputFull(), Deleted: false });
    getEventLeaderboards(id)
      .then((leaderboards) => {
        const newLeaderboards = leaderboards.map(leaderboard => ({
          ...leaderboard,
          Id: undefined,
          EventId: undefined,
          AttributeNames: leaderboard.AttributeNames.map(a => a.split(".").slice(2).join("."))
        }));

        setEventLeaderboards(newLeaderboards)
      })
      .finally(() => setModalLoading(false));
  };



  const closeModal = () => {
    setSelectedEventId(null);
  };

  const handleMockClick = (eventId) => {
    setModalLoading(true);
    getEventLeaderboards(eventId)
      .then((leaderboards) => {
        setSelectedEventLeaderboards(leaderboards);
        setLeaderboardMockModalOpen(true);
      })
      .finally(() => setModalLoading(false));
  };

  /** @param {File} file*/
  async function onImportEvents(file) {
    const data = await file.text();
    const events = [];
    for (const line of data.split("\n")) {
      const [eventName, startDateRaw,duration,...rest] = SplitCsvLine(line);
      const leaderboards = chunkArray(rest,3);
      
      const startDate = new Date(startDateRaw);
      if(!isValidDate(startDate)){
        console.error(`Invalid date: ${startDateRaw}`);
        sendBatch(`Invalid date: ${startDateRaw} on Row ${line}`);
        return;
      }
      
      
      const evt = {
        Content: eventName,
        startDate: startDate,
        Duration: Number.parseFloat(duration),
        Deleted: false,
        ProfileId: defaultProfileId,
        LeaderboardDefinitionsArray: leaderboards.map(([name, capacity, attributes]) => ({
          Name: name,
          Capacity: Number.parseInt(capacity),
          AutoJoin: true,
          AttributeNames: attributes?.trim().replaceAll("\"", "").split(","),
        })).filter(d => d.Name && d.Capacity)
      }  
      
      events.push(evt);
    }
    
    if(sendBatch){
      sendBatch(events);
    }
    setOpenModalPopup(false)
  }

  /** @param {File} file*/
  async function onBatchDelete(file) {
    let data = (await file.text()).trim();
    data = data.replaceAll("\"", "");
    data = data.replaceAll("[", "");
    data = data.replaceAll("]", "");
    
    const eventIds = data.split(",").map(n => parseInt(n.trim()));

    if(sendBatchDelete){
      sendBatchDelete(eventIds);
    }
    setOpenDeleteModalPopup(false)
  }

  const header = (
    <Row className="flex align-items-center">
      <Col xs={12} xl={6}>
        <Button
          className="btn btn-round btn-icon"
          color="primary"
          disabled={!CAN_CREATE_EVENT}
          onClick={() => setSelectedEvent({})}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-flag" />
          </span>
          <span className="btn-inner--text">Create Event</span>
        </Button>
        <Button
            className="btn btn-round btn-icon"
            color="primary"
            disabled={!CAN_CREATE_EVENT}
            onClick={() => setOpenModalPopup(true)}
            size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-upload" />
          </span>
          <span className="btn-inner--text">Import CSV</span>
        </Button>
        <Button
            className="btn btn-round btn-icon"
            color="warning"
            disabled={!CAN_CREATE_EVENT}
            onClick={() => setOpenDeleteModalPopup(true)}
            size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-upload" />
          </span>
          <span className="btn-inner--text">Batch Delete</span>
        </Button>
        <div className="d-inline-flex align-items-center float-right">
          <span className="text-muted pr-1">Show deleted:
          </span>
          <label className="custom-toggle">
            <input checked={showDeleted} readOnly type="checkbox" />
            <span
              className="custom-toggle-slider rounded-circle"
              data-label-off="No"
              data-label-on="Yes"
              onClick={handleToggleChange}
            />
          </label>
        </div>
      </Col>
      <Col xs={12} xl={6}>
        <PaginationHeader
          total={totalObjects}
          skip={currentPage * objectsPerPage}
          take={objectsPerPage}
          onObjectsPerPageChange={handleObjectsPerPage}
        />
      </Col>
    </Row>
  );

  const renderTable = (events) => {
    return events.length > 0 ? (
      <Table hover className="align-items-center table-flush" responsive>
        <thead className="thead-light">
          <tr>
            <th scope="col">EVENT NAME</th>
            <th scope="col">EDITION</th>
            <th scope="col" className="text-center">
              START DATE
            </th>
            <th scope="col" className="text-center">
              END DATE
            </th>
            <th scope="col" className="text-center">
              DURATION
            </th>
            <th scope="col" className="text-center">
              DELETED
            </th>
            <th scope="col" className="text-center">
              PROFILE ID
            </th>
            <th className="sort" scope="col" />
          </tr>
        </thead>
        <tbody>
          {events.map((data, index) => (
            <tr key={data["Id"]}>
              <td className="content">
                <b>{data["Content"]}</b>
              </td>
              <td key={data["id"] + "-cnt"} className="id">
                {data["Id"]}
              </td>
              <td className="startDate text-center">
                <DateTimeComponent
                  date={data["StartDate"]}
                  tooltipId={"tooltip" + data["Id"] + index}
                />
              </td>
              <td className="startDate text-center">
                <DateTimeComponent
                  date={parseDate(data["StartDate"]).addHour(data["Duration"])}
                  tooltipId={"tooltipendDate" + data["Id"] + index}
                />
              </td>
              <td className="text-center duration">
                <b>{pluralize(data["Duration"], "hour")}</b>
              </td>
              <td className="deleted text-center">
                <i
                  className={
                    data["Deleted"]
                      ? "fa fa-check text-success"
                      : "fas fa-times text-gray"
                  }
                />
              </td>
              <td className="text-center profileId">{data["ProfileId"]}</td>
              <td>
                {localStorage.getItem("env") !== "Production" && (
                  <Button
                    size="sm"
                    value={data["Id"]}
                    className="btn-icon"
                    onClick={(e) => handleMockClick(e.currentTarget.value)}
                    color="neutral"
                    type="button"
                  >
                    <span className="btn-inner--icon">
                      <i className="fas fa-rocket" />
                    </span>
                  </Button>
                )}
                {CAN_MODIFY_EVENT && <>
                  <Link to={`/acp/events/${data["Id"]}`}>
                    <Button
                      size="sm"
                      value={data["Id"]}
                      className="btn-icon"
                      color="neutral"
                      type="button"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-edit" />
                      </span>
                    </Button>
                  </Link>
                  {!data["Deleted"] && (
                    <Button
                      size="sm"
                      className="btn-icon"
                      value={data["Id"]}
                      onClick={(e) => handleDeleteClick(e.currentTarget.value)}
                      color="neutral"
                      type="button"
                    >
                      <span className="btn-inner--icon">
                        <i className="fas fa-trash" />
                      </span>
                    </Button>
                  )}
                </>}
                <Button
                  size="sm"
                  value={data["Id"]}
                  className="btn-icon"
                  onClick={(e) => handleCloneClick(e.currentTarget.value)}
                  color="neutral"
                  type="button"
                >
                  <span className="btn-inner--icon">
                    <i className="fas fa-copy"></i>
                  </span>
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    ) : (
      <div style={{ padding: "1rem 0 0 1rem" }}>
        <p style={{ fontSize: "1.2rem" }}>No events to show</p>
      </div>
    );
  };
  const contents = loading ? "" : renderTable(events);
  const footer =
    totalObjects !== null ? (
      <SimplePaginationComponent
        fetchPage={getPage}
        currentPage={currentPage}
        objectsPerPage={objectsPerPage}
        totalObjects={totalObjects}
      />
    ) : null;

  //TODO: Create onConfirm method

  return (
    <>
      <div className="rna-wrapper">
        <NotificationAlert ref={notificationRef} />
      </div>
      <TableCard loading={loading} header={header} footer={footer}>
        {contents}
      </TableCard>
      <EventModal
        defaultProfile={defaultProfileId}
        profiles={profiles}
        sendEvent={sendEvent}
        deleteEvent={deleteEvent}
        leaderboards={eventLeaderboards}
        loading={modalLoading}
        setModalLoading={setModalLoading}
        modalOpen={!!selectedEvent}
        toggleModalOpen={closeModal}
        {...selectedEvent}
      />
      <ConfirmDeleteModal
        open={!!deleteTarget}
        modalToggle={() => setDeleteTarget(undefined)}
        deleteEvent={deleteEvent}
        id={deleteTarget}
        callback={() => setDeleteTarget(null)}
        text={deleteTarget ? <strong>{`${getEventById(events, deleteTarget)?.Content} (${deleteTarget})`}</strong> : ""}
      />
      <LeaderboardsMockModal
        open={leaderboardMockModalOpen}
        toggle={() => setLeaderboardMockModalOpen(!leaderboardMockModalOpen)}
        leaderboards={selectedEventLeaderboards}
        loading={loadingMock}
        onConfirm={mockLeaderboards}
        notificationRef={notificationRef}
      />
      <ImportModal
          handleModalToggle={() => setOpenModalPopup(s => !s)}
          isOpen={openModalPopup}
          onSubmit={onImportEvents}
          preview
          fileType={".csv"}
      />
      <ImportModal
          handleModalToggle={() => setOpenDeleteModalPopup(s => !s)}
          isOpen={openDeleteModalPopup}
          onSubmit={onBatchDelete}
          header={<div>
            <h3>Send a List to Delete</h3>
            <span>Input a txt containing the event ids, separated by comma</span>      
      </div>}
          preview
          fileType={".txt"}
      />
    </>
  );
};

export default EventsTable;
