import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Button, Table, Col, Row } from "reactstrap";
import LeaderboardDefinitionModal from "./LeaderboardDefinitionModal";
import DeleteLeaderboardModal from "./DeleteLeaderboardModal";
import SimpleHeader from "../Headers/SimpleHeader";
import TableCard from "../Layout/TableCard";
import SearchInput from "../common/SearchInput";

import PropTypes from "prop-types";
import { userService } from "../../services/user.service";


/**
 * @typedef {{
 *  leaderboards: any[],
 *  onDelete: (id: any) => void,
 *  onEdit: (id: any) => void,
 *  onClear: (id: any) => void,
 *  onSearch: (id: any) => void,
 *  deleteModalLoading: boolean,
 *  header: React.ReactNode,
 *  footer: React.ReactNode,
 *  loading: boolean,
 *  onExportCsv: () => void,
 *  downloading: boolean,
 * }} LeaderBoardListtingProps
 */


/**
 * @type {React.FC<LeaderBoardListtingProps>}
 */
const LeaderboardListing = ({
  leaderboards,
  onDelete,
  onEdit,
  deleteModalLoading,
  modalLoading,
  header,
  footer,
  loading,
  onClear,
  onSearch,
  onExportCsv,
  downloading,
}) => {
  const [selectedLeaderboard, setSelectedLeaderboard] = useState({});
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const getLeaderboardDefinitionById = (id) => {
    return leaderboards.find((leaderboard) => leaderboard.Id === Number(id));
  };

  const handleDeleteClick = (id) => {
    setSelectedLeaderboard({ id });
    setDeleteModalOpen(true);
  };

  const deleteConfirm = () => {
    onDelete(selectedLeaderboard.id);
    setDeleteModalOpen(false);
    setSelectedLeaderboard({});
  };

  const toggleDeleteModalOpen = () => setDeleteModalOpen(!deleteModalOpen);

  const handleEditClick = (id) => {
    setSelectedLeaderboard(getLeaderboardDefinitionById(id));
    toggleModal();
  };

  const onCancel = () => {
    setSelectedLeaderboard({});
    toggleModal();
  };

  const CAN_CREATE_LEADERBOARD_DEFS = userService.hasPermission("CREATE_UPDATE_LEADERBOARD_DEFS");
  const CAN_DELETE_LEADERBOARD_DEFS = userService.hasPermission("DELETE_LEADERBOARD_DEFS");


  const table = (
    <Table hover className="align-items-center table-flush" striped responsive>
      <thead className="thead-light">
        <tr>
          <th scope="col" width="5%">
            ID
          </th>
          <th scope="col" width="40%">
            DEFINITION NAME
          </th>
          <th scope="col" width="10%">
            EVENT EDITION
          </th>
          <th scope="col" width="10%">
            CAPACITY
          </th>
          <th scope="col" width="10%" />
        </tr>
      </thead>
      <tbody>
        {leaderboards.map((data) => (
          <tr key={data["Id"]}>
            <td>
              <Link className="text-primary" to={"leaderboard/" + data["Id"]}>
                <b>{data["Id"]}</b>
              </Link>
            </td>
            <td key={data["Name"] + "-cnt"}>
              <Link className="text-primary" to={"leaderboard/" + data["Id"]}>
                {data["Name"]}
              </Link>
            </td>
            <td>{data["EventId"] || "-"}</td>
            <td>{data["Capacity"] || <i className="fas fa-infinity" />}</td>
            <td>
              {CAN_CREATE_LEADERBOARD_DEFS && <Button
                size="sm"
                value={data["Id"]}
                className="btn-icon"
                disabled={!CAN_CREATE_LEADERBOARD_DEFS}
                onClick={(e) => handleEditClick(e.currentTarget.value)}
                color="neutral"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-edit" />
                </span>
              </Button>}
             {CAN_DELETE_LEADERBOARD_DEFS && <Button
                size="sm"
                className="btn-icon"
                value={data["Id"]}
                disabled={!CAN_DELETE_LEADERBOARD_DEFS}
                onClick={(e) => handleDeleteClick(e.currentTarget.value)}
                color="neutral"
                type="button"
              >
                <span className="btn-inner--icon">
                  <i className="fas fa-trash" />
                </span>
              </Button>}
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );

  const cardHeader = (
    <CardHeader onClear={onClear} onSearch={onSearch}>
      {header}
    </CardHeader>
  )

  return (
    <>
      <SimpleHeader name="Leaderboard Definitions" clean parentName="Dashboard">
      {userService.hasPermission("EXPORT_DATA") && <Button
          className="btn-neutral btn-round ml-2 float-right btn-icon"
          color="default"
          disabled={downloading || !userService.hasPermission("EXPORT_DATA")}
          onClick={() => onExportCsv() }
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
          <i className="fas fa-file-download" />
          </span>
          <span className="btn-inner--text">Export as .csv</span>
        </Button>}
        <Button
          className="btn-neutral btn-round float-right btn-icon"
          color="default"
          disabled={!CAN_CREATE_LEADERBOARD_DEFS}
          onClick={toggleModal}
          size="sm"
        >
          <span className="btn-inner--icon mr-1">
            <i className="fas fa-trophy" />
          </span>
          <span className="btn-inner--text">Create Leaderboard Definition</span>
        </Button>
      </SimpleHeader>
      <Container fluid className="mt--6">
        <TableCard loading={loading} header={cardHeader} footer={footer}>
          {table}
        </TableCard>
      </Container>
      <LeaderboardDefinitionModal
        onSubmit={(definition) => onEdit(definition, () => setModalOpen(false))}
        onCancel={onCancel}
        modalOpen={modalOpen}
        loading={modalLoading}
        {...selectedLeaderboard}
      />
      <DeleteLeaderboardModal
        deleteLeaderboard={deleteConfirm}
        open={deleteModalOpen}
        modalToggle={toggleDeleteModalOpen}
        loading={deleteModalLoading}
      />
    </>
  );
};


/**
 * @type {React.FC<{onSearch: ()=> void, onClear: () => {}}>}  */
export const CardHeader = ({children,onClear,onSearch}) => (
  <>
    <Row className="no-gutter align-items-center">
      <Col>
        <SearchInput
          onSearch={onSearch}
          onClear={onClear}
          placeholder="Leaderboard Definition Name"
        />
      </Col>
      <Col>{children}</Col>
    </Row>
  </>
);

LeaderboardListing.propTypes = {
  leaderboards: PropTypes.array,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  deleteModalLoading: PropTypes.bool,
  modalLoading: PropTypes.bool,
  header: PropTypes.any,
  footer: PropTypes.any,
  loading: PropTypes.bool,
  onClear: PropTypes.func,
  onSearch: PropTypes.func
}
export default LeaderboardListing;
