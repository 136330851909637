import React,{useState} from "react";
import PageContainer from "../common/PageContainer";

import {ConsolePreferences} from "./ConsolePreferences/ConsolePreferences";
import {EditPreferenceModal} from "./ConsolePreferences/EditPreferenceModal";

import { Button } from "reactstrap";
import ImportModal from "../common/ImportModal";
import { useUser } from "../../context/UserContext";

/** @typedef {import("./ConsolePreferences/ConsolePreferences").ConsolePreference} ConsolePreference*/

/** @type {React.FC<{consolePreferences: ConsolePreference[],loading:boolean, onSave: (pref:ConsolePreference) => Promise,onDelete: (pref: ConsolePreference) => Promise, onExport: () => void, onImport: () => Promise  }>} */
const SystemPreferences = React.memo(
  ({
    consolePreferences,loading, onSave,onDelete,onExport,onImport
  }) => {
    const {hasPermission} = useUser();
    const [selectedPref, setSelectedPref] = useState(undefined);
    const [isEditModalOpen, setEditModalOpen] = useState(false);
    const [isImportModalOpen, setImportModalOpen] = useState(false);


    function CreateNew(){
      setEditModalOpen(true); 
      setSelectedPref(undefined);
    }

    function handleSave(pref){
      onSave(pref);
      toggle();
    }

    function Edit(pref){
      setEditModalOpen(true); 
      setSelectedPref(pref);
    }

    function toggle(){
      setEditModalOpen(!isEditModalOpen);

      if(isEditModalOpen){
        setSelectedPref(undefined);
      }
    }

    function handleImport(file){
      onImport(file).finally(() => setImportModalOpen(false))
    }

    return (
      <>
      <PageContainer pageName="Console Preferences"
        header={
          <>
            <Button
              className="btn-neutral btn-round float-right btn-icon"
              color="default"
              size="sm"
              onClick={() => setImportModalOpen(true)}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-file-import" />
              </span>
              <span className="btn-inner--text">Import Preferences</span>
            </Button>
            <Button
              className="btn-neutral btn-round float-right btn-icon"
              color="default"
              size="sm"
              onClick={onExport}
            >
              <span className="btn-inner--icon mr-1">
                <i className="fas fa-file-download" />
              </span>
              <span className="btn-inner--text">Export Preferences</span>
            </Button>
            <Button
              className="btn-neutral btn-round float-right btn-icon"
              color="default"
              size="sm"
              onClick={CreateNew}
            >
              <span className="btn-inner--icon mr-1">
                <i className="ni ni-settings-gear-65" />
              </span>
              <span className="btn-inner--text">New Preference</span>
            </Button>
          </>
        } 
      >
          <ConsolePreferences
            consolePreferences={consolePreferences}
            loading={loading}
            onEdit={Edit}
            onDelete={ hasPermission("DELETE_CONSOLE_PREFERENCES") ? onDelete : undefined}
            
          />
      </PageContainer>
        <EditPreferenceModal isOpen={isEditModalOpen} preference={selectedPref} toggle={toggle} onSubmit={handleSave} />
        <ImportModal isOpen={isImportModalOpen}handleModalToggle={() => setImportModalOpen(!isImportModalOpen)} onSubmit={handleImport} preview>
          <small style={{color: 'red'}}>CAUTION: ALL EXISTING DATA WILL BE OVERWRITTEN</small>
        </ImportModal>
      </>
    );
  }
);

export default SystemPreferences;
